/* eslint-disable no-mixed-operators */
import { InsuranceCustomFields } from './InsuranceCustomFields';

export const InsuranceGeneralForm = ({ docId, area }) => {
    const docType = `${docId}`;

    return (
        <>

            <div style={{ marginBottom: '20px' }}>
                <h4>GeneralInformation</h4>
                <InsuranceCustomFields docId={docType} area={area} category="generalInformation" />
            </div>
        </>
    )
}