import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Modal, Message, Button, Input } from "semantic-ui-react"
import { fpalphaAPI } from "../../apis/FPAlphaAPI";
import { sysAPI } from "../../apis/SysAPI";
import { fetchCurrentAdvisorProfile } from "../../actions/advisor.actions";
import { responseError } from "../../helpers";
import { setSnackbar } from "../../actions/snackbar.actions";

export const ConfirmAdvisorActionModal = ({ action, setAction }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const advisorProfile = useSelector(state => state.currentAdvisorProfile);
    const [confirmPassword, setConfirmPassword] = useState('');

    const deleteAdvisorConfirmBtn_Click = async () => {
        try {
            await fpalphaAPI.post(`/tools/advisor/delete`, {
                id: advisorProfile?._id,
                password: confirmPassword,
                advisorEmail: advisorProfile?.email,
            });
            setConfirmPassword('');
            history.goBack();
        }
        catch (err) {
            responseError(err, undefined, "ConfirmAdvisorActionModal.deleteAdvisorConfirmBtn_Click", dispatch)
        }
    }

    const toggleBlockAdvisorConfirmBtn_Click = (status) => {
        return async () => {
            try {
                await sysAPI.post('/advisors/toggle-block', {
                    advisorId: advisorProfile?._id,
                    status,
                    password: confirmPassword,
                });

                dispatch(setSnackbar({message: `Advisor's account was ${status}ed successfully.`}))
                setConfirmPassword('');
                setAction(null);
                dispatch(fetchCurrentAdvisorProfile(advisorProfile._id));
            }
            catch (err) {
                responseError(err, undefined, "ConfirmAdvisorActionModal.toggleBlockAdvisorConfirmBtn_Click", dispatch)
            }
        }
    }

    const fullAcessBtn_Click = async () => {
        try {
            await sysAPI.post('/advisors/full-access', {
                advisorId: advisorProfile?._id,
                password: confirmPassword,
            });

            dispatch(setSnackbar({message: `${advisorProfile?.fullName} has now full access to the application.`}))
            setConfirmPassword('');
            setAction(null);
        }
        catch (err) {
            responseError(err, undefined, "ConfirmAdvisorActionModal.fullAcessBtn_Click", dispatch)
        }
    }

    return (
        <Modal size='mini' open={action?.length > 0} onClose={() => setAction(null)}>
            <Modal.Content>
                {action === 'removeUser' && <>
                    <p>Please input your password to confirm deleting the account for {advisorProfile?.fullName}:</p>
                    <Message color='red'>This action cannot be undone.</Message>
                </>}
                {action === 'blockUser' && <>
                    <p>Please input your password to confirm blocking the account for {advisorProfile?.fullName}:</p>
                </>}
                {action === 'unblockUser' && <>
                    <p>Please input your password to confirm unblocking the account for {advisorProfile?.fullName}:</p>
                </>}
                {action === 'fullAccessUser' && <>
                    <p>Please input your password to confirm granting <b>FULL ACCESS</b> to {advisorProfile?.fullName}:</p>
                </>}

                <Input fluid
                    type='password'
                    placeholder='Password'
                    value={confirmPassword}
                    onChange={e => setConfirmPassword(e.currentTarget.value)}
                />

                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '15px' }}>
                    <Button color='grey' onClick={() => setAction(null)}>Cancel</Button>

                    {action === 'removeUser' && <Button color='red' onClick={deleteAdvisorConfirmBtn_Click}>Delete</Button>}
                    {action === 'blockUser' && <Button color='orange' onClick={toggleBlockAdvisorConfirmBtn_Click('block')}>Block</Button>}
                    {action === 'unblockUser' && <Button color='green' onClick={toggleBlockAdvisorConfirmBtn_Click('unblock')}>Unblock</Button>}
                    {action === 'fullAccessUser' && <Button color='violet' onClick={fullAcessBtn_Click}>Confirm</Button>}
                </div>
            </Modal.Content>
        </Modal>
    )
}