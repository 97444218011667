import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Checkbox, Container, Dimmer, Dropdown, Form, Grid, /* GridRow, */ Header, Icon, Input, Loader, Menu, Message, Modal, /* Segment, */ Tab } from "semantic-ui-react";
import { resourcesAPI } from "../apis/ResourcesAPI";
import { TabContainer } from "../components/shared/TabContainer";
import { DashboardTemplate } from "../templates/DashboardTemplate";

const initials = {
    S: 'Single',
    HOH: 'Head of Household',
    MFJ: 'Married Filing Jointly',
    P: 'Premium',
    MFS: 'Married Filing Separately',
    QW: 'Qualifying Widow(er)',
    unifiedRateSchedule: 'Unified Rate Schedule',
    basicExclusion: 'Basic Exclusion',
    stateDeathTaxCredit: 'State Death Tax Credit',
    estateTaxCredit: 'Estate Tax Credit'
}

export const ResourceForm = ({ year, activeIndex, file }) => {
    const me = useSelector(state => state.me);
    const [data, setData] = useState({});
    const [columns, setColumns] = useState(1);
    const [fileDepth, setDepth] = useState(1);
    const [loading, setLoading] = useState(true);
    const [sendingData, setSendingData] = useState(false);
    const [format, setFormat] = useState('');
    const [rangeSize, setRangeSize] = useState(2);
    const [openModal, setOpenModal] = useState(false);
    const [rowKeyInput, setRowKeyInput] = useState('');
    const [depthThreeSelected, setDepthThreeSelected] = useState('');

    useEffect(() => {
        APIcall()

        // eslint-disable-next-line
    }, [year]);

    const APIcall = () => {
        setLoading(true);
        resourcesAPI.get(`/${file}?year=${year}&withDepth=true`).then(response => {
            const json = response.data.years;
            setData(json[year]);
            setDepth(response.data.format.depth);
            setColumns(response.data.format.columns);
            setLoading(false);
            setSendingData(false);
            setFormat(response.data.format.ranges);
            setRangeSize(response.data.format.rangeSize)
        }).catch(err => {
            console.error(err);
        });
    }

    const sendData = () => {
        setSendingData(true)
        resourcesAPI.put(`/${file}?year=${year}`, {
            data: data
        }).then(response => {
            APIcall();
            setOpenModal(false);
        }).catch(err => {
            console.error(err);
        });
    }

    const setValue = (newValue, key, depth, arrayIndex = null) => {
        if (depth === null) {
            if (arrayIndex !== null) {
                data[key][arrayIndex] = parseInt(newValue);
            }
            else {
                data[key] = parseInt(newValue);
            }
        } else {
            let keysNum = depth.split('.');
            if (arrayIndex !== null) {
                if (keysNum.length === 1) {
                    data[keysNum[0]][key][arrayIndex] = parseInt(newValue);
                }
                else if (keysNum.length === 2) {
                    data[keysNum[0]][keysNum[1]][key][arrayIndex] = parseInt(newValue);
                }
            } else {
                if (keysNum.length === 1) {
                    data[depth][key] = parseInt(newValue);
                }
                else if (keysNum.length === 2) {
                    data[keysNum[0]][keysNum[1]][key] = parseInt(newValue);
                }
            }
        }
        setData({ ...data });
    }

    const setKeyValue = (newValue, key, depth) => {
        const tempData = {};
        if (depth === null) {
            data[newValue] = data[key];
            delete data[key];
        } else {
            let keysNum = depth.split('.');
            if (keysNum.length === 1) {
                for (const value in data[depth]) {
                    if (value === key) {
                        tempData[newValue] = data[depth][value];
                    } else {
                        tempData[value] = data[depth][value];
                    }
                }
                data[depth] = tempData
            }
            else if (keysNum.length === 2) {
                for (const value in data[keysNum[0]][keysNum[1]]) {
                    if (value === key) {
                        tempData[newValue] = data[keysNum[0]][keysNum[1]][value];
                    } else {
                        tempData[value] = data[keysNum[0]][keysNum[1]][value];
                    }
                }
                data[keysNum[0]][keysNum[1]] = tempData;
            }
        }
        setData({ ...data });
    }

    const removeField = (key) => {
        let keyArray;
        const keys = key.split('.');
        if (keys.length > 1) {
            keyArray = Object.keys(data[keys[0]][keys[1]]);
            if (keyArray.length > 1) {
                delete data[keys[0]][keys[1]][keyArray[keyArray.length - 1]];
                setData({ ...data });
            }

        }
        else {
            keyArray = Object.keys(data[key]);
            if (keyArray.length > 1) {
                delete data[key][keyArray[keyArray.length - 1]];
                setData({ ...data });
            }
        }
    }

    const addField = (key) => {
        let keyArray;
        const keys = key.split('.');
        if (keys.length > 1) {
            keyArray = Object.keys(data[keys[0]][keys[1]]);
            const newKey = getKey(keyArray)
            if (!keyArray.includes(newKey)) {
                data[keys[0]][keys[1]][`${newKey}`] = Array(rangeSize).fill(0);
            }
            setData({ ...data });
        }
        else {
            keyArray = Object.keys(data[key]);
            const newKey = getKey(keyArray)
            if (!keyArray.includes(newKey)) {
                if (rangeSize > 1) {
                    data[key][`${newKey}`] = Array(rangeSize).fill(0);
                }
                else {
                    data[key][`${newKey}`] = 0;
                }
                
            }
            setData({ ...data });
        }
    }

    const getKey = (keyArray) => {
        let key;
        switch (format) {
            case 'percentage': {
                const num = parseInt(keyArray[keyArray.length - 1].replace('%', '')) + 1;
                key = `${num}%`;
                break;
            }
            case 'number': {
                if (keyArray.length === 0) {
                    key = 0;
                }
                else {
                    key = `${parseInt(keyArray[keyArray.length - 1]) + 1}.0`;
                }

                break;
            }
            case 'text': {
                key = '. . . .'
                break;
            }

            default:
                break;
        }
        return key
    }

    const addFields = (key) => {
        if (rowKeyInput === '') {
            alert('Enter the key to add to all statuses');
            return;
        }
        let keyArray;
        key = key.split('.');
        if (key.length > 1) {
            key = key[0];
            keyArray = Object.keys(data[key]);
            const newKey = rowKeyInput
            for (const arrayKey of keyArray) {
                const savedKeys = Object.keys(data[key][arrayKey]);
                if (!savedKeys.includes(newKey)) {
                    data[key][arrayKey][`${newKey}`] = Array(rangeSize).fill(0);
                }
            }
            setData({ ...data });
        }
        else {
            key = key[0];
            keyArray = Object.keys(data);
            const newKey = rowKeyInput
            for (const arrayKey of keyArray) {
                const savedKeys = Object.keys(data[arrayKey]);
                if (!savedKeys.includes(newKey)) {
                    data[arrayKey][`${newKey}`] = Array(rangeSize).fill(0);
                }
            }

            setData({ ...data });
        }
        setRowKeyInput('');
    }

    const removeFields = (key) => {
        let keyArray;
        key = key.split('.');
        if (key.length > 1) {
            key = key[0];
            keyArray = Object.keys(data[key]);
            for (const arrayKey of keyArray) {
                const savedKeys = Object.keys(data[key][arrayKey]);
                if (savedKeys.includes(rowKeyInput) && savedKeys.length > 1) {
                    delete data[key][arrayKey][rowKeyInput];

                }
            }
            setData({ ...data });
        }
        else {
            key = key[0];
            keyArray = Object.keys(data);
            for (const arrayKey of keyArray) {
                const savedKeys = Object.keys(data[arrayKey]);
                if (savedKeys.includes(rowKeyInput) && savedKeys.length > 1) {
                    delete data[arrayKey][rowKeyInput];
                }

            }

            setData({ ...data });
        }
        setRowKeyInput('');
    }

    const transformField = (data, key, depth = null) => {
        if (data[key] instanceof Array) {
            return (
                <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center', padding: '2px 0' }}>
                    {!['alaska', 'florida', 'nevada', 'south_dakota', 'texas', 'washington', 'wyoming'].includes(key) ?
                        (<>
                            <Input style={{ height: '30px', width: (/\d/.test(`${key}`) || key.includes('%')) ? '85px' : key.length <= 3 ? '55px' : file === 'magi-tiers' ? '390px' : '100px' }} value={key} onChange={e => setKeyValue(e.currentTarget.value, key, `${depth}`)} />
                            <Icon name='arrow right' />
                        </>)
                        : null
                    }
                    {data[key].map((input, index) => {
                        return (
                            <>
                                {index !== 0 &&
                                    <Header style={{ textAlign: 'center', margin: '0 4px' }} as={'h3'}>{'-'}</Header>}
                                <Input size={'small'} style={{ height: '30px', width: '120px', padding: '0' }} type="number" value={input} onChange={e => setValue(e.currentTarget.value, key, depth, index)} />
                            </>)
                    })}
                </div>
            )
        }
        else {
            return (<>
                {initials[key] ?
                    <Header style={{ textAlign: 'center', margin: '10px 5px' }} as={'h4'}>{initials[key]}</Header>
                    : format === null || format === 'alphabetical'
                        ? <Header style={{ textAlign: 'center', margin: '0 5px' }} as={'h4'}>{key}</Header>
                        : (<>
                            <Input style={{ height: '30px', width: (/\d/.test(`${key}`) || key.includes('%')) ? '85px' : key.length <= 3 ? '55px' : file === 'magi-tiers' ? '400px' : '100px' }} value={key} onChange={e => setKeyValue(e.currentTarget.value, key, `${depth}`)} />
                            <Icon name='arrow right' />
                        </>)}
                <Input style={{ height: '30px', width: '120px', padding: '0' }} type="number" value={data[key]} onChange={e => setValue(e.currentTarget.value, key, depth)} />
            </>)
        }
    }


    const IndividualButtons = ({ key }) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button icon style={{ height: '20px', width: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={v => removeField(key)}>
                    <Icon name='minus' size='small' />
                </Button>
                <Button icon style={{ height: '20px', width: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={v => addField(key)}>
                    <Icon name='plus' size='small' />
                </Button>
            </div>
        )
    }

    const RowButtons = ({ key }) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', position: 'absolute', right: '0' }}>
                <Input style={{ height: '25px', width: '200px', paddingRight: '10px' }} placeholder='Key name' value={rowKeyInput} onChange={e => setRowKeyInput(e.currentTarget.value)} />
                <Button icon style={{ height: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={v => addFields(key)}>
                    <label>Add</label>
                </Button>
                <Button icon style={{ height: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={v => removeFields(key)}>
                    <label>Remove</label>
                </Button>
            </div>
        )
    }

    const OneRange = ({ data, key }) => {
        return (
            <Grid.Column key={`${key}`} style={{ justifyContent: 'start', display: 'flex', alignItems: 'center', flexDirection: 'row' }} >
                <Header style={{ textAlign: 'left', margin: '10px 5px', width: columns === 1 ? '250px' : 'auto' }} as={'h4'}>{initials[key] || key}</Header>
                <Grid.Column key={`${key}`} style={{ justifyContent: 'start', display: 'flex', alignItems: 'center', flexDirection: 'row' }} >
                    {data[key].map((input, index) => {
                        return (
                            <>
                                {index !== 0 &&
                                    <Header style={{ textAlign: 'center', margin: '0 4px' }} as={'h3'}>{'-'}</Header>}
                                <Input style={{ height: '30px', width: '120px', padding: '0' }} type="number" value={input} onChange={e => setValue(e.currentTarget.value, key, null, index)} />
                            </>)
                    })}
                </Grid.Column>
            </Grid.Column>
        )
    }

    const DepthOne = ({ data }) => {

        return (<Grid columns={columns}>
            {Object.keys(data).map(key => {
                return (
                    !Array.isArray(data[key]) ?
                        <Grid.Column key={`${key}`} style={{ justifyContent: 'start', display: 'flex', alignItems: 'left', flexDirection: columns === 1 ? 'row' : 'column' }}>
                            <Header style={{ textAlign: 'left', margin: '0 5px', width: initials[key] && '200px' }} as={'h4'}>{initials[key] || key}</Header>
                            <Input style={{ height: '30px', padding: '0' }} type="number" value={data[key]} onChange={e => setValue(e.currentTarget.value, key, null)} />
                        </Grid.Column>
                        : <>
                            {OneRange({ data, key })}
                        </>
                )
            })}
        </Grid>)
    }

    const DepthTwo = ({ data }) => {
        return (
            <Grid columns={columns}>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', position: 'relative' }}>
                            <Header style={{ textAlign: 'center', color: fileDepth === 3 && file !== 'magi-tiers' ? 'rgb(64,83,250)' : 'black' }} as={'h3'}>{file.split('-').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ')}</Header>
                            {RowButtons({ key: Object.keys(data)[0] })}
                        </div>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    {Object.keys(data).map((key, index) => {
                        return data[key] !== null && !Array.isArray(data[key]) && typeof data[key] === 'object' &&
                            (<>
                                <Grid.Column style={{ padding: file !== 'magi-tiers' ? '1.5em 1.5em 1em 1.5em' : '1.5em .5em 1em .5em', borderLeft: (index % columns === 0 || index === 0) ? 'none' : '1px solid rgb(230,230,230)', borderBottom: (Object.keys(data).length - (Object.keys(data).length % columns)) > index ? '1px solid rgb(230,230,230)' : 'none', borderRight: Object.keys(data).length - 1 === index ? '1px solid rgb(230,230,230)' : '1px solid white' }} key={key}>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                                <Header style={{ textAlign: 'center', color: fileDepth === 3 && file !== 'magi-tiers' ? 'rgb(64,83,250)' : 'black' }} as={'h4'}>{initials[key] !== undefined ? initials[key] : key.charAt(0).toUpperCase() + key.slice(1)}</Header>
                                                {IndividualButtons({ key })}
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        {Object.keys(data[key]).map(key2 => {
                                            return (<>
                                                <Grid.Column style={{ margin: '25px 0', justifyContent: fileDepth === 2 && rangeSize > 1 ? 'space-between' : 'start', display: 'flex', alignItems: 'center', flexDirection: fileDepth === 2 ? 'row' : 'column' }} >
                                                    {transformField(data[key], key2, key)}
                                                </Grid.Column>
                                            </>)
                                        })}
                                    </Grid.Row>
                                </Grid.Column>
                            </>)
                    })}
                </Grid.Row>
            </Grid>)
    }

    const DepthThree = ({ data }) => {
        return (
            <div style={{ }}>
                <div style={{ width: '200px' }}>
                    <Dropdown
                    fluid
                        style={{ margin: '0 6px 0 0' }}
                        placeholder='State'
                        selection
                        value={depthThreeSelected}
                        options={Object.keys(data).map(key => { return { text: key.split('_').map(skey => skey.charAt(0).toUpperCase() + skey.slice(1)).join(' '), value: key, key: key } })}
                        onChange={(e, { value }) => { setDepthThreeSelected(value) }} />
                </div>
                <div>
                    {depthThreeSelected !== '' &&
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    {file !== 'estate-tax-rates' ?
                                        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', position: 'relative' }}>
                                            <Header style={{ textAlign: 'center', color: fileDepth === 3 && file !== 'magi-tiers' ? 'rgb(64,83,250)' : 'black' }} as={'h3'}>{depthThreeSelected.split('_').map(skey => skey.charAt(0).toUpperCase() + skey.slice(1)).join(' ')}</Header>
                                            {RowButtons({ key: `${depthThreeSelected}.` })}
                                        </div>
                                        : <Header style={{ textAlign: 'center', color: fileDepth === 3 && file !== 'magi-tiers' ? 'rgb(64,83,250)' : 'black' }} as={'h3'}>{depthThreeSelected.split('_').map(skey => skey.charAt(0).toUpperCase() + skey.slice(1)).join(' ')}</Header>}

                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={columns} >
                                {Object.keys(data[depthThreeSelected]).filter(key2 => data[depthThreeSelected][key2] !== null && !Array.isArray(data[depthThreeSelected][key2]) && typeof data[depthThreeSelected][key2] === 'object').map((key2, index) => {
                                    return (<>
                                        <Grid.Column style={{ padding: '1.5em 1.5em 1em 1.5em', borderLeft: (index % columns === 0 || index === 0) ? 'none' : '1px solid rgb(230,230,230)', borderBottom: (Object.keys(data[depthThreeSelected]).length - (Object.keys(data[depthThreeSelected]).length % columns)) > index ? '1px solid rgb(230,230,230)' : 'none', borderRight: Object.keys(data[depthThreeSelected]).length - 1 === index ? '1px solid rgb(230,230,230)' : '1px solid white' }}>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', }}>
                                                        <Header style={{ textAlign: 'center', padding: '2px 0' }} as={'h4'}>{initials[key2] !== undefined ? initials[key2] : key2}</Header>
                                                        {IndividualButtons({ key: `${depthThreeSelected}.${key2}` })}
                                                    </div>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row >
                                                {Object.keys(data[depthThreeSelected][key2]).map(key3 => {
                                                    return data[depthThreeSelected][key2][key3] !== null && !Array.isArray(data[depthThreeSelected][key2][key3]) && typeof data[depthThreeSelected][key2][key3] === 'object' ?
                                                        null
                                                        : <>
                                                            <Grid.Column style={{ justifyContent: 'start' }} key={`${depthThreeSelected}.${key2}`}>
                                                                {transformField(data[depthThreeSelected][key2], key3, `${depthThreeSelected}.${key2}`)}
                                                            </Grid.Column>
                                                        </>
                                                }
                                                )}
                                            </Grid.Row>
                                        </Grid.Column>
                                    </>)
                                })}
                                {Object.keys(data[depthThreeSelected]).filter(key2 => !(data[depthThreeSelected][key2] !== null && !Array.isArray(data[depthThreeSelected][key2]) && typeof data[depthThreeSelected][key2] === 'object')).map((key2, index) => {
                                    return (
                                        <Grid.Column style={{ justifyContent: fileDepth === 2 ? 'center' : 'start', display: 'flex', alignItems: 'start', flexDirection: fileDepth === 2 ? 'row' : 'column', padding: '1.5em 1.5em 1.5em 1.5em', borderLeft: (index % columns === 0 || index === 0) ? 'none' : '1px solid rgb(230,230,230)', borderBottom: (Object.keys(data).length - (Object.keys(data).length % columns)) > index ? '1px solid rgb(230,230,230)' : 'none', borderRight: Object.keys(data).length - 1 === index ? '1px solid rgb(230,230,230)' : '1px solid white' }} key={`${depthThreeSelected}.${key2}`} >
                                            {Array.isArray(data[depthThreeSelected][key2])
                                                ? OneRange({ data: data[depthThreeSelected], key: key2 })
                                                : transformField(data[depthThreeSelected], key2, depthThreeSelected)}
                                        </Grid.Column>
                                    )
                                })}
                            </Grid.Row>
                        </Grid>
                    }
                </div>
            </div>
        )
    }

    return (
        <>
            {loading
                ? <div style={{ height: '130px' }}>
                    <Loader active style={{ marginTop: '90px' }} />
                </div>
                : sendingData
                    ? <Dimmer inverted active>
                        <Loader active style={{ marginTop: '90px' }} />
                    </Dimmer>
                    : Object.keys(data).length > 0 ?
                        <>
                            {fileDepth === 1 && DepthOne({ data: data })}
                            {fileDepth === 2 && DepthTwo({ data: data })}
                            {fileDepth === 3 && DepthThree({ data: data })}
                            <div style={{ display: 'flex', justifyContent: 'end', marginTop: '25px' }}>
                                <Modal
                                    dimmer='inverted'
                                    size={'small'}
                                    open={openModal}
                                    onOpen={() => setOpenModal(true)}
                                    onClose={() => setOpenModal(false)}
                                >
                                    <Modal.Header>Update Resource</Modal.Header>
                                    <Modal.Content>
                                        <p>This changes will affect directly the FP Alpha site. Are you sure you want update this resource?</p>
                                    </Modal.Content>
                                    <Modal.Actions>
                                        <Button negative onClick={() => setOpenModal(false)}>
                                            No
                                        </Button>
                                        <Button positive onClick={sendData}>
                                            Yes
                                        </Button>
                                    </Modal.Actions>
                                </Modal>
                                {me.scope?.split(' ').includes('resources_edit') &&
                                    <Button onClick={() => setOpenModal(true)} >Update</Button>
                                }
                            </div>
                        </>
                        : <Message style={{ margin: '20px 0' }}>There are no inputs for this year</Message>
            }


        </>

    )
}

export const DashboardResourcesView = () => {
    const me = useSelector(state => state.me);
    const [resourcesInfo, setResourcesInfo] = useState([]);
    const [fileOptions, setFileOptions] = useState([]);
    const [file, setFile] = useState('');
    const [resources, setResources] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [openResourceModal, setOpenResourceModal] = useState(false);
    const [openFileModal, setOpenFileModal] = useState(false);
    const [newResource, setNewResource] = useState('');
    const [newYearFile, setNewYearFile] = useState({ year: '', copyData: false, depth: '', keys: { 1: '', 2: '' }, dataType: '', ranges: '', rangeSize: '', isOtherSelected: false, other: '' });
    const [newResourceValidation, setNewResourceValidation] = useState({ notEmpty: true })
    let [newFileValidation, setNewFileValidation] = useState({ notEmpty: true, dontExists: true, key: true, key2: true, other: true, datatype: true, range: true, rangeSize: true })
    const [openResourceInfoModal, setOpenResourceInfoModal] = useState(false)
    useEffect(() => {
        resourcesAPI.get(`/?withYears=true`).then(response => {
            const fileList = response.data;
            const list = fileList.map(file => ({
                key: file.resourceId,
                text: file.resourceId.split('-').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' '),
                value: file.resourceId
            }))
            setResourcesInfo(fileList)
            setFileOptions(list);
        }).catch(err => {
            console.error(err);
        });
    }, []);


    useEffect(() => {
        if (file !== '') {
            resourcesAPI.get(`years/${file}`).then(response => {
                const json = response.data.years;
                setResources(json);
                setLoading(false);
            }).catch(err => {
                console.error(err);
            });
        }
    }, [file]);

    useEffect(() => {
        if (newYearFile.year !== '') {
            setActiveIndex(resources.findIndex(y => y === newYearFile.year));
            setNewFileValidation({ notEmpty: true, dontExists: true, key: true, key2: true, other: true, datatype: true, range: true, rangeSize: true })
            setNewYearFile({ year: '', copyData: false, depth: '', keys: { 1: '', 2: '' }, dataType: '', ranges: '', rangeSize: '', isOtherSelected: false, other: '' })
        }
        else {
            setActiveIndex(0);
        }

    // eslint-disable-next-line
    }, [resources]);


    const handleChange = (e, data) => {
        setActiveIndex(data.activeIndex);
    }

    const createResource = () => {
        if (newResource === '') {
            setNewResourceValidation({ notEmpty: false });
        } else {
            setNewResourceValidation({ notEmpty: true });
            const resourceName = newResource.split(' ').map(w => w.toLocaleLowerCase()).join('-');
            resourcesAPI.post(`/${resourceName}`).then(response => {
                setNewResource('');
                setOpenResourceModal(false);
                const fileList = response.data;
                const list = fileList.map(file => ({
                    key: file,
                    text: file.split('-').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' '),
                    value: file
                }))
                setFileOptions(list);
                setFile(resourceName);
            }).catch(err => {
                alert('Resource already exists');
                console.error(err);
            });
        }
    }

    const setDepth = (e, { value }) => {
        newYearFile.keys['1'] = value;
        setNewYearFile({ ...newYearFile })
    }

    const setSubkeys = (e, { value }) => {
        if (value === 'maritalStatus') {
            newYearFile.isOtherSelected = false;
            newYearFile.other = '';
        } else {
            newYearFile.other = value
        }
        newYearFile.keys['2'] = value;
        setNewYearFile({ ...newYearFile })
    }


    const setRangeSize = (e, { value }) => {
        newYearFile.dataType = value;
        if (value === 'fixedValue' || value === 'oneRange') {
            newYearFile.ranges = null;
        }
        setNewYearFile({ ...newYearFile })
    }

    const addYearFile = () => {
        newFileValidation = { notEmpty: true, dontExists: true, key: true, key2: true, other: true, datatype: true, range: true, rangeSize: true };
        if (newYearFile.year === '') {
            newFileValidation.notEmpty = false;
        }
        else if (resources.length > 0 && resources.includes(newYearFile.year)) {
            newFileValidation.dontExists = false;
        }
        if (resources.length === 0) {
            if (newYearFile.keys['1'] === '') {
                newFileValidation.key = false;
            }
            if (newYearFile.keys['1'] === 'states') {
                if (newYearFile.keys['2'] === '') {
                    newFileValidation.key2 = false;
                }
                if (newYearFile.isOtherSelected && newYearFile.other === '') {
                    newFileValidation.other = false;
                }
            }
            if (newYearFile.dataType === '') {
                newFileValidation.datatype = false;
            }
            if (newYearFile.dataType === 'ranges') {
                if (newYearFile.ranges === '' || newYearFile.ranges === null) {
                    newFileValidation.range = false;
                }
                if (newYearFile.rangeSize === '') {
                    newFileValidation.rangeSize = false;
                }
            }
        }
        setNewFileValidation({ ...newFileValidation })
        if (Object.keys(newFileValidation).filter(val => !newFileValidation[val]).length === 0) {
            if (newYearFile.dataType === 'oneRange') {
                newYearFile.depth = 1;
                newYearFile.size = 2;
            }
            else if (newYearFile.dataType === 'fixedValue') {
                newYearFile.depth = 1;
                newYearFile.size = null;
            }
            else {
                newYearFile.depth = 2;
                newYearFile.size = parseInt(newYearFile.rangeSize);
            }
            if (newYearFile.keys['1'] === 'states') {
                newYearFile.depth = 3;
            }
            let format = {}
            if (resources.length === 0) {
                format = {
                    "year": newYearFile.year,
                    "depth": newYearFile.depth,
                    "keys": newYearFile.keys,
                    "ranges": newYearFile.ranges,
                    "rangeSize": newYearFile.size,
                }
            }
            else {
                format = {
                    "year": newYearFile.year,
                    "copyData": newYearFile.copyData
                }
            }
            resourcesAPI.post(`${file}`, {
                format
            }).then(response => {
                setFile('');
                setFile(file);
                setOpenFileModal(false);
            }).catch(err => {
                console.error(err);
            });
        }

    }

    const yearPanes = resources.map(year => ({
        menuItem: `${year}`,
        render: () => <ResourceForm year={year} activeIndex={activeIndex} file={file} />
    }));

    const panes = me.scope?.split(' ').includes('resources_edit') ? [...yearPanes,
    {
        menuItem: (
            <Menu.Item key='messages'>
                <Modal
                    dimmer={'inverted'}
                    size='large'
                    onClose={() => {
                        setOpenFileModal(false);
                        setNewFileValidation({ notEmpty: true, dontExists: true, key: true, key2: true, other: true, datatype: true, range: true, rangeSize: true })
                        setNewYearFile({ year: '', copyData: false, depth: '', keys: { 1: '', 2: '' }, dataType: '', ranges: '', rangeSize: '', isOtherSelected: false, other: '' })
                    }}
                    onOpen={() => {
                        setOpenFileModal(true); setNewFileValidation({ notEmpty: true, dontExists: true, key: true, key2: true, other: true, datatype: true, range: true, rangeSize: true })
                        setNewYearFile({ year: '', copyData: false, depth: '', keys: { 1: '', 2: '' }, dataType: '', ranges: '', rangeSize: '', isOtherSelected: false, other: '' })
                    }}
                    open={openFileModal}
                    trigger={
                        <Button style={{ display: 'flex', flexDirection: 'row', height: '30px', alignItems: 'center' }}>
                            <label>New</label>
                        </Button>}
                >
                    <Modal.Header>
                        <Header as={'h4'}>New Resource</Header>
                    </Modal.Header>
                    <Modal.Content>
                        <Container>
                            <Form>
                                <Form.Group grouped>
                                    <Form.Input fluid label='Year' placeholder='Year' value={newYearFile.year} onChange={(e => setNewYearFile({ ...newYearFile, year: e.currentTarget.value }))} />
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', margin: '-5px 0 0 0' }}>
                                        <small style={{ display: newFileValidation.notEmpty ? 'none' : 'block', color: 'red' }}>Year is required</small>
                                        <small style={{ display: newFileValidation.dontExists ? 'none' : 'block', color: 'red' }}>Year already exists</small>
                                    </div>
                                    {resources.length > 0 &&
                                        <Form.Field>
                                            <Checkbox checked={newYearFile.copyData} label='Copy main data from last saved year' onChange={e => setNewYearFile({ ...newYearFile, copyData: !newYearFile.copyData })} />
                                        </Form.Field>
                                    }
                                </Form.Group>
                                {resources.length === 0 &&
                                    <>
                                        < Form.Group grouped>
                                            <label>File Type</label>
                                            <Form.Radio
                                                label='Filing Status'
                                                value='maritalStatus'
                                                checked={newYearFile.keys['1'] === 'maritalStatus'}
                                                onChange={setDepth}
                                            />
                                            <Form.Radio
                                                label='USA States'
                                                value='states'
                                                checked={newYearFile.keys['1'] === 'states'}
                                                onChange={setDepth}
                                            />
                                            <small style={{ display: newFileValidation.key ? 'none' : 'block', color: 'red' }}>File Type is required</small>
                                            {newYearFile.keys['1'] === 'states' &&
                                                <div style={{ margin: '15px 30px' }}>
                                                    <Form.Group grouped>
                                                        <label>Sub data fields</label>
                                                        <Form.Radio
                                                            label='Filing Status'
                                                            value='maritalStatus'
                                                            checked={newYearFile.keys['2'] === 'maritalStatus'}
                                                            onChange={setSubkeys}
                                                        />
                                                        <Form.Radio
                                                            label='Other'
                                                            value='other'
                                                            checked={newYearFile.isOtherSelected}
                                                            onChange={() => {
                                                                newYearFile.keys['2'] = '';
                                                                newYearFile.isOtherSelected = true;
                                                                setNewYearFile({ ...newYearFile })
                                                            }}
                                                        />
                                                        {newYearFile.isOtherSelected && <>
                                                            <Form.Input fluid label='Other' placeholder='Type the sub data fields separated by a comma without spaces' value={newYearFile.other} onChange={setSubkeys} type='text' />
                                                            <small style={{ display: newFileValidation.other ? 'none' : 'block', color: 'red' }}>Other is required</small></>
                                                        }
                                                        <small style={{ display: (newFileValidation.key2 || !newFileValidation.other) ? 'none' : 'block', color: 'red' }}>Sub data is required</small>
                                                    </Form.Group>
                                                </div>
                                            }
                                        </Form.Group>
                                        <Form.Group grouped>
                                            <label>Data type</label>
                                            <Form.Radio
                                                label='Fixed Value(s)'
                                                value='fixedValue'
                                                checked={newYearFile.dataType === 'fixedValue'}
                                                onChange={setRangeSize}
                                            />
                                            <Form.Radio
                                                label='One Range'
                                                value='oneRange'
                                                checked={newYearFile.dataType === 'oneRange'}
                                                onChange={setRangeSize}
                                            />
                                            <Form.Radio
                                                label='Multiple Ranges'
                                                value='ranges'
                                                checked={newYearFile.dataType === 'ranges'}
                                                onChange={setRangeSize}
                                            />
                                            <small style={{ display: newFileValidation.datatype ? 'none' : 'block', color: 'red' }}>Data type is required</small>
                                            {newYearFile.dataType === 'ranges' &&
                                                <div style={{ margin: '15px 30px' }}>
                                                    <Form.Group grouped>
                                                        <label>Grouped By</label>
                                                        <Form.Radio
                                                            label='Percentage key'
                                                            value='percentage'
                                                            checked={newYearFile.ranges === 'percentage'}
                                                            onChange={() => setNewYearFile({ ...newYearFile, ranges: 'percentage' })}
                                                        />
                                                        <Form.Radio
                                                            label='Text key'
                                                            value='percentage'
                                                            checked={newYearFile.ranges === 'text'}
                                                            onChange={() => setNewYearFile({ ...newYearFile, ranges: 'text' })}
                                                        />
                                                        <Form.Radio
                                                            label='Number Key'
                                                            value='percentage'
                                                            checked={newYearFile.ranges === 'number'}
                                                            onChange={() => setNewYearFile({ ...newYearFile, ranges: 'number' })}
                                                        />
                                                        <small style={{ display: newFileValidation.range ? 'none' : 'block', color: 'red' }}>Grouped by is required</small>
                                                    </Form.Group>
                                                    <Form.Input fluid label='Range size' type='number' placeholder={2} value={newYearFile.rangeSize} onChange={(e, { value }) => setNewYearFile({ ...newYearFile, rangeSize: value })} />
                                                    <small style={{ display: newFileValidation.rangeSize ? 'none' : 'block', color: 'red' }}>Range size is required</small>
                                                </div>
                                            }
                                        </Form.Group>
                                    </>}
                            </Form>
                        </Container>

                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='black' onClick={() => setOpenFileModal(false)}>
                            Cancel
                        </Button>
                        <Button
                            content="Save"
                            labelPosition='right'
                            icon='checkmark'
                            onClick={() => addYearFile()}
                        />
                    </Modal.Actions>
                </Modal>
            </Menu.Item >
        ),
        render: () => <Container></Container>
    }] : yearPanes;

    return (
        <DashboardTemplate scope={'resources'} permission="resourses:resourse_view">
            <TabContainer
                title='Resources Management'
                subtitle='CRUD for Resources'
                icon='book'
            >
                <div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px 0' }}>
                        <Modal
                            dimmer={'inverted'}
                            onClose={() => setOpenResourceInfoModal(false)}
                            onOpen={() => setOpenResourceInfoModal(true)}
                            open={openResourceInfoModal}
                            trigger={<Button>Resources information</Button>}
                        >
                            <Modal.Header>
                                <Header as={'h4'}>Resources information</Header>
                            </Modal.Header>
                            <Modal.Content>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', flexWrap: 'wrap' }}>
                                    {
                                        resourcesInfo.map(file => {
                                            return (
                                                <div style={{ flex: '0 0 33.33%', padding: '10px' }}>
                                                    <label style={{ fontWeight: 'bold' }}>{file.text}</label>
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                        {file.years.map(year => {
                                                            return (
                                                                <label style={{ paddingLeft: '15px' }}>{year}</label>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button color='black' onClick={() => setOpenResourceInfoModal(false)}>
                                    Close
                                </Button>
                            </Modal.Actions>
                        </Modal>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Dropdown style={{ margin: '0 6px 0 0', width: '400px' }} placeholder='Resource' selection options={fileOptions} value={file} onChange={(e, { value }) => { setLoading(true); setFile(value) }} />
                        {me.scope?.split(' ').includes('resources_edit') &&
                            <Modal
                                dimmer={'inverted'}
                                onClose={() => setOpenResourceModal(false)}
                                onOpen={() => setOpenResourceModal(true)}
                                open={openResourceModal}
                                trigger={<Button>New</Button>}
                            >
                                <Modal.Header>
                                    <Header as={'h4'}>New Resource</Header>
                                </Modal.Header>
                                <Modal.Content>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <label>Resource Name:</label>
                                        <Input required style={{ width: '85%', padding: '0 5px' }} value={newResource} onChange={(e => setNewResource(e.currentTarget.value))} />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end', padding: '5px 0' }}>
                                        <small style={{ display: newResourceValidation.notEmpty ? 'none' : 'block', color: 'red' }}>Name is required</small>
                                    </div>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button color='black' onClick={() => setOpenResourceModal(false)}>
                                        Cancel
                                    </Button>
                                    <Button
                                        content="Save"
                                        labelPosition='right'
                                        icon='checkmark'
                                        onClick={() => createResource()}
                                    />
                                </Modal.Actions>
                            </Modal>
                        }
                    </div>
                </div>

                {loading
                    ? <Loader active style={{ marginTop: '100px' }} />
                    : (file !== '' && <Tab menu={{ pointing: true, secondary: true, }} panes={panes} activeIndex={activeIndex} onTabChange={handleChange} />)
                }
            </TabContainer>
        </DashboardTemplate>

    );
}