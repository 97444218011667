import { useEffect, useState } from "react"
import { Button } from "semantic-ui-react"
import { responseError } from "../../../helpers"
import { sysAPI } from "../../../apis/SysAPI"

export const UnlockUploaders = ({area, assetSelected, client}) => {
  const [isActive, setIsActive] = useState(false)
  const [loader, setLoader] = useState(false);

  const handleToggle = async () =>{
    setLoader(true)
    try {
      await sysAPI.post(`/change-uploader-status/${client._id}/${area}/${assetSelected}`,{
        isUploaderActive: !isActive
      });
      setIsActive(!isActive);
      client.financialAreas[area].assets[assetSelected].isUploaderActive = !isActive
    } catch (error) {
      responseError(error)
    }
    setLoader(false)
  }
  

  useEffect(() => {
    const isUploaderActive = client?.financialAreas?.[area]?.assets?.[assetSelected]?.isUploaderActive || false
    setIsActive(isUploaderActive)
    console.log({area, assetSelected, client,isUploaderActive})
  },[area, assetSelected, client])

  return <>
    <Button color={isActive ? 'green' : 'red'} onClick={() => handleToggle()} loading={loader}>{isActive ? 'Lock' : 'Unlock'} Uploaders </Button>
  </>
}

export const UnlockQuestionarie = ({area, assetSelected, client}) => {
  const [docStatus, setDocStatus] = useState("INVALID")
  const [loader, setLoader] = useState(false);

  const handleToggle = async () =>{
    setLoader(true)
    try {
      const newStatus = docStatus === "INVALID" ? "COMPLETE" : "INVALID"
      await sysAPI.post(`/change-document-status/${client._id}/${area}/${assetSelected}`,{
        documentStatus: newStatus
      });
      setDocStatus(newStatus);
      client.financialAreas[area].assets[assetSelected].documentStatus = newStatus
    } catch (error) {
      responseError(error)
    }
    setLoader(false)
  }
  

  useEffect(() => {
    const documentStatus = client?.financialAreas?.[area]?.assets?.[assetSelected]?.documentStatus || "INVALID"
    setDocStatus(documentStatus)
    console.log({area, assetSelected, client,documentStatus})
  },[area, assetSelected, client])

  return <>
    <Button color={docStatus==="COMPLETE" ? 'green' : 'red'} onClick={() => handleToggle()} loading={loader}>{docStatus==="COMPLETE" ? 'Lock' : 'Unlock'} Uploaders </Button>
  </>
}