import moment from "moment";

export const prettyDate = ({date, format="YYYY-MM-DD HH:mm:ss"}) => {
  date = filterDate(date)
  if(!date){
    return "-";
  }

  const date_from_now = moment(date).fromNow();
  const date_complete = moment(date).format(format);
  return `${date_from_now} (${date_complete})`
}

const filterDate = (date) => {
  const optionsToNull = ['N/A'];
  if(optionsToNull.includes(date)){
    return null;
  }
  return date;
}