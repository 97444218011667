import { useEffect, useState } from 'react';
import { TabContainer } from '../../components/shared/TabContainer';
import { DashboardTemplate } from '../../templates/DashboardTemplate';
import { Dimmer, Loader, Segment, Table } from 'semantic-ui-react';
import { responseError } from '../../helpers';
import { advisorAPI } from '../../apis/AdvisorsAPI';
import moment from 'moment';
import Update from '../../components/roles-enterprise-settings/Update';

const RolesEnterpriseSettings = () => {
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getRoles = async () => {
    setIsLoading(true);
    try {
      const { data } = await advisorAPI.get('/enterprise-settings/role');
      setRoles(data.data);
    } catch (error) {
      responseError(error, undefined, 'RolesEnterpriseSettings.getRoles');
    }
    setIsLoading(false);
  };

  const getPermissions = async () => {
    setIsLoading(true);
    try {
      const { data } = await advisorAPI.get('/enterprise-settings/permission');
      const orderPermissions = []
      for(const per of data.data){
        const [nameSection, namePermission] = per.key.split(":")
        const name = `${per.group} - ${per.subgroup} - ${nameSection}`;
        const findIndex_ = orderPermissions.findIndex(e => e.name === name);
        const objectToPush = {
          _id: per._id,
          key: per._id,
          originalKey: per.key,
          name: namePermission.replace(/_/g, " ").replace(/\./g, " - "),
        }
        if(findIndex_ !== -1){
          orderPermissions[findIndex_].permissions.push(objectToPush)
        }else{
          orderPermissions.push({
            name,
            permissions: [objectToPush]
          })
        }
      }
      setPermissions(orderPermissions);
    } catch (error) {
      responseError(error, undefined, 'RolesEnterpriseSettings.getPermissions');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getRoles();
    getPermissions();
  }, []);

  return (
    <>
      <DashboardTemplate permission="enteprise_settings:roles_view">
        <TabContainer title="Roles Enterprise Settings" subtitle="Manage Roles." icon="universal access">
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Key</Table.HeaderCell>
                <Table.HeaderCell>Total Permissios.</Table.HeaderCell>
                <Table.HeaderCell>Created</Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {isLoading && (
                <Table.Row>
                  <Table.Cell colSpan={5}>
                    <Segment style={{ height: '300px' }}>
                      <Dimmer active inverted>
                        <Loader>Loading</Loader>
                      </Dimmer>
                    </Segment>
                  </Table.Cell>
                </Table.Row>
              )}
              {!isLoading && roles.map((role, index) => <ItemRole role={role} key={index} getRoles={getRoles} permissions={permissions} />)}
            </Table.Body>
          </Table>
        </TabContainer>
      </DashboardTemplate>
    </>
  );
};

const ItemRole = ({ role, getRoles, permissions }) => {
  return (
    <Table.Row>
      <Table.Cell>{role.name}</Table.Cell>
      <Table.Cell>{role.key}</Table.Cell>
      <Table.Cell>{role.enterprisePermissions?.length || 0}</Table.Cell>
      <Table.Cell>{moment(role.createdAt).format('MM/DD/YYYY HH:mm:ss')}</Table.Cell>
      <Table.Cell>
        <div style={{ display: 'flex', gap: '5px' }}>
          <div>
            <Update refreshFunction={getRoles} dataInfo={role} permissions={permissions} />
          </div>
        </div>
      </Table.Cell>
    </Table.Row>
  );
};
export default RolesEnterpriseSettings;
