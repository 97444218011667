import { Button } from "semantic-ui-react";
import { fetchCurrentCompany } from "../../../actions/company.actions";
import { useDispatch } from "react-redux";
import { advisorAPI } from "../../../apis/AdvisorsAPI";
import { useState } from "react";
import { responseError } from "../../../helpers";



const SwitchWideSharing = ({ company, isButton = true }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)

  const isFirmwideClientAutoSharingEnabled = company?.enterpriseSettings?.isFirmwideClientAutoSharingEnabled
  const activeDisabled = async() => {
    setLoading(true)
    try {
      let confirmation = true
      if(!isFirmwideClientAutoSharingEnabled){
        // eslint-disable-next-line no-restricted-globals
        confirmation = confirm(`This action could take quite a while.`)
      }
      if(confirmation){
        await advisorAPI.put(`/companies/settings/preferences`, {
          "isFirmwideClientAutoSharingEnabled": !isFirmwideClientAutoSharingEnabled
        },{
          params:{
            companyId: company._id
          }
        });
        await dispatch(fetchCurrentCompany(company._id));
      }
    } catch (error) {
      responseError(error)
    }
    setLoading(false)
  }

  return (
    <>
      {isButton ? (
        <Button color={isFirmwideClientAutoSharingEnabled ? "green" : "red"} onClick={() => activeDisabled()} loading={loading}>
          Wide Sharing: {isFirmwideClientAutoSharingEnabled ? "Active" : "Disabled"}
        </Button>
      ) : (
        <div color={isFirmwideClientAutoSharingEnabled ? "green" : "red"} onClick={() => activeDisabled()} loading={loading} >
          {loading ? (
            <>
              🟠 Changing...
            </>
          ) : (
            <>
              {isFirmwideClientAutoSharingEnabled ? "🟢 Wide Sharing: Active" : "🔴 Wide Sharing: Disabled"}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default SwitchWideSharing;
