// eslint-disable-next-line
export default [
  {
    section: "Data",
    fields: [
      {
        name: "clientIDClientHasHomeInsurance",
        text: "Home Insurance?",
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDHasUmbrella",
        text: "Umbrella Insurance?",
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: 'clientIDCondoCoop',
        text: 'Does the client live in a condo or co-op?',
        type: 'string',
        options: ['Yes', 'No'],
      },
      {
        name: 'clientIDhomeRenewalDate',
        text: 'Home policy expiration date:',
        type: 'string',
      },
      /* {
        name: 'clientIDautoRenewalDate',
        text: 'Auto policy expiration date:',
        type: 'string',
      }, */
      {
        name: 'clientIDumbrellaRenewalDate',
        text: 'Umbrella policy expiration date:',
        type: 'string',
      },
      {
        name: "clientIDWantsToInputPolicyDataHome",
        text: "Client wants to input policy data home?",
        type: "string",
        options: ["Yes", "No"],
        default: 'Yes'
      },
      {
        name: "clientIDAssetName",
        text: "clientIDAssetName",
        type: "string",
      },
      {
        name: "clientIDHomeInsuranceCompany",
        text: "Insurance provider",
        type: "string",
        options: "provider",
      },
      /* {
        name: "clientIDAutoInsuranceCompany",
        text: "What is the client's auto insurance company?",
        type: "string",
        options: "provider",
      }, */
      {
        name: 'clientIDClientUmbrellaInsuranceCompany',
        text: "What is the client's umbrella insurance company?",
        type: 'string',
        options: "provider",
      },
    ],
  },

  {
    section: "Manual Input",
    fields: [
      {
        name: "clientIDtotalPremiumHome",
        text: "Total annual premium:",
        type: "number",
      },
      {
        name: "clientIDactualCashValue",
        text: "Does the policy have actual cash value for dwelling?",
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: 'clientIDguaranteedReplacement',
        text: 'Does the policy include guaranteed replacement cost coverage?',
        type: 'string',
        options: ['Yes', 'No'],
      },
      {
        name: 'clientIDextendedReplacement',
        text: 'Does the policy include extended replacement cost coverage?',
        type: 'string',
        options: ['Yes', 'No'],
      },
      {
        name: "clientIDbasedeductible",
        text: "Base deductible:",
        type: "number",
      },
      {
        name: "clientIDlargeLossDeductibleWaiver",
        text: "Does the policy include a large loss deductible waiver?",
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDhouseOneEstimatedContentsValueFromDocs",
        text: "Coverage amount for contents:",
        type: "number",
      },
      {
        name: "clientIDhouseOneDetachedStructuresCoverageAmountFromDocs",
        text: "Detached structures coverage amount:",
        type: "number",
      },
      {
        name: "clientIDlookingForLossCoverageDueToFlood",
        text: "Loss coverage due to flood:",
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDlookingForEquipmentBreakdown",
        text: "Equipment breakdown coverage:",
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDoffPremiseTheftCoverageFromDocs",
        text: "Does the policy include off-premises theft coverage?",
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDoffPremiseTheftCoverageAmountFromDocs",
        text: "Off-premises theft coverage amount",
        type: "number",
      },
      {
        name: "clientIDdwellingInsuredValue",
        text: "Dwelling insured value:",
        type: "number",
      },
      {
        name: "clientIDcashsettlementOptionFromDocs",
        text: "Does the policy include a cash settlement option?",
        type: "string",
        options: ["Yes", "No"],
      },

      {
        name: 'clientIDPersonalLiabilityCoverage',
        text: 'Personal liability coverage amount:',
        type: 'number',
      },
      {
        name: "clientIDrebuildingToCode",
        text: "Does the policy include rebuilding to code?",
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDwaterAndSewerBackup",
        text: "Does the policy have water and sewer back-up coverage?",
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDbasicOrBroadCoverage",
        text: "Does the policy include basic dwelling coverage?",
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDwindDamage",
        text: "Does the policy have wind damage coverage?",
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: 'clientIDNamedPerils',
        text: 'Is the policy written on a named-peril basis?',
        type: 'string',
        options: ['Yes', 'No'],
      },
      {
        name: "clientIDlossOfUse",
        text: "Is there loss of use protection on the policy?",
        type: "string",
        options: ["Yes", "No"],
      },

      {
        name: 'clientIDLossOfUseDollar',
        text: 'Loss of use dollar amount',
        type: 'number'
      },
      {
        name: 'clientIDLossOfUsePercentage',
        text: 'Loss of use percentage',
        type: 'number'
      },
      {
        name: "clientIDlossPayeeFromPolicy",
        text: "Is there a mortgagee on the policy?",
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDumbrellaCoverage",
        text: "Umbrella coverage:",
        type: "number",
      },
      {
        name: "clientIDpropertiesAutoLinkedToUmbrella",
        text: "Are all properties auto-linked to umbrella?",
        type: "string",
        options: ["Yes", "No"],
      },

      {
        name: 'clientIDUtilityLineExpense',
        text: 'Does the policy include an endorsement for Utility Line Service Expense?',
        type: 'string',
        options: ['Yes', 'No'],
      },
      {
        name: 'clientIDUmbrellaUMUIMExclusion',
        text: 'Does the umbrella policy include an exclusion for uninsured and underinsured motorist liability?',
        type: 'string',
        options: ['Yes', 'No'],
      },
      {
        name: "clientIDjewelryValueFromDocs",
        text: "Jewelry value covered on policy:",
        type: "number",
      },
      {
        name: 'clientIDHasJewelryDeductible',
        text: 'Does the policy have a jewelry deductible?',
        type: 'string',
        options: ['Yes', 'No'],
      },
      {
        name: 'clientIDJewelryDeductible',
        text: 'Jewelry deductible amount:',
        type: 'number',
      },
      {
        name: "clientIDsilverwareValueFromDocs",
        text: "Silverware value covered on policy:",
        type: "number",
      },
      {
        name: 'clientIDHasSilverwareDeductible',
        text: 'Does the policy have a silverware deductible?',
        type: 'string',
        options: ['Yes', 'No'],
      },
      {
        name: 'clientIDSilverwareDeductible',
        text: 'Silverware deductible amount:',
        type: 'number',
      },
      {
        name: "clientIDfineArtValueFromDocs",
        text: "Fine art value covered on policy:",
        type: "number",
      },
      {
        name: 'clientIDHasFineArtDeductible',
        text: 'Does the policy have fine art deductible?',
        type: 'string',
        options: ['Yes', 'No'],
      },
      {
        name: 'clientIDFineArtDeductible',
        text: 'Fine art deductible amount:',
        type: 'number',
      },
      {
        name: "clientIDfursFromDocs",
        text: "Furs value covered on policy:",
        type: "number",
      },
      {
        name: 'clientIDHasFursDeductible',
        text: 'Does the policy have a furs deductible?',
        type: 'string',
        options: ['Yes', 'No'],
      },
      {
        name: 'clientIDFursDeductible',
        text: 'Furs deductible amount:',
        type: 'number',
      },
      {
        name: "clientIDwineValueFromDocs",
        text: "Wine value covered on policy:",
        type: "number",
      },
      {
        name: 'clientIDHasWineDeductible',
        text: 'Does the policy have a wine deductible?',
        type: 'string',
        options: ['Yes', 'No'],
      },
      {
        name: 'clientIDWineDeductible',
        text: 'Wine deductible amount:',
        type: 'number',
      },
      {
        name: "clientIDantiquesValueFromDocs",
        text: "Antiques value covered on policy:",
        type: "number",
      },
      {
        name: 'clientIDHasAntiquesDeductible',
        text: 'Does the policy have a antiques deductible?',
        type: 'string',
        options: ['Yes', 'No'],
      },
      {
        name: 'clientIDAntiquesDeductible',
        text: 'Antiques deductible amount:',
        type: 'number',
      },
      {
        name: "clientIDmusicalInstrumentsValueFromsDocs",
        text: "Musical instruments value covered on policy:",
        type: "number",
      },
      {
        name: 'clientIDHasMusicalInstrumentsDeductible',
        text: 'Does the policy have a musical instruments deductible?',
        type: 'string',
        options: ['Yes', 'No'],
      },
      {
        name: 'clientIDMusicalInstrumentsDeductible',
        text: 'Musical instruments deductible amount;',
        type: 'number',
      },
      {
        name: "clientIDvaluePhysicalCashFromDocs",
        text: "Physical cash value covered on policy:",
        type: "number",
      },
      {
        name: 'clientIDHasPhysicalCashDeductible',
        text: 'Does the policy have a money deductible?',
        type: 'string',
        options: ['Yes', 'No'],
      },
      {
        name: 'clientIDPhysicalCashDeductible',
        text: 'Money deductible amount:',
        type: 'number',
      },
      {
        name: "clientIDotherCollectiblesFromDocs",
        text: "Other collectibles value covered on policy:",
        type: "number",
      },
      {
        name: "clientIDriderOrFloaterOnPolicy",
        text: "Is there a rider or a floater on policy?",
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: 'clientIDHasOtherCollectiblesDeductible',
        text: 'Does the policy have a collectibles deductible?',
        type: 'string',
        options: ['Yes', 'No'],
      },
      {
        name: 'clientIDOtherCollectiblesDeductible',
        text: 'Collectibles deductible amount:',
        type: 'number',
      },
      {
        name: 'clientIDValuableArticlesCoverage',
        text: 'Does the policy have coverage for valuable articles?',
        type: 'string',
        options: ['Yes', 'No'],
      },
      {
        name: "clientIDlookingForEarthquakeCoverage",
        text: "Does the policy include earthquake coverage?",
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDClientRents",
        text: "In addition to the client's home, does the client own and rent out an investment property?",
        //text: "In addition to your home, do you own and rent out an investment property?",
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDHouseOneDetachedStructuresCoverageAmount",
        text: "Detached structures coverage amount:",
        type: "number",
      },
      {
        name: "clientIDPOperatingBusiness",
        text: "{{Is the client operating a business from his/her home?",
        //text: "Are you operating a business from your home?",
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDHomesOwned",
        text: "clientIDHomesOwned", //FIXME no exist text in the fronend
        type: "number",
      },
      {
        name: "clientIDDetachedStructures1", //! I think this is a N fields in DB there are 1 and 2
        text: "clientIDDetachedStructures1",
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDClientOwnsAdditionalRealProperty",
        text: "Does the client own real estate property other than the house in which he/she live?",
        //text: "Do you own real estate property other than the house in which you live?",
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDClientIsConsideringPolicyOffering",
        text: "Is the client considering a policy offering coverage based on actual cash value rather than replacement cost coverage?",
        //text: "Are youconsidering a policy offering coverage based on actual cash value rather than replacement cost coverage?",
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDPolicyBeingConsideredNoWaterAndSewerCov",
        text: "Is the client considering a policy that does not have water and sewer backup coverage?",
        //text: "Are you considering a policy that does not have water and sewer backup coverage?",
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDConsideringBasicorBroadCoverage",
        text: "Is the client considering basic or broad form of coverage?",
        //text: 'Are you considering basic or broad form of coverage?',
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDPolicyConsideredHasHurricaneDamageDed",
        text: "Is the client considering a policy that has a hurricane damage deductible?",
        //text: 'Are you considering a policy that has a hurricane damage deductible?',
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDWillHaveAMortgage",
        text: "Will the client have a mortgage?",
        //text: "Will you have a mortgage?",
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDIsConsideringPurchasingUmbrellaLiabilityPolicy",
        text: "Is the client considering purchasing an umbrella liability policy?",
        //text: 'Are you considering purchasing an umbrella liability policy?',
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDIsConsideringUmbrellaLiabilityCoverageWithEnhancedCov",
        text: "Is the client considering umbrella liability coverage with enhanced coverage?",
        //text: "Are you considering umbrella liability coverage with enhanced coverage?",
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDLegalCostsMightBeincludedOutsidePolicyLimit",
        text: "Might legal costs be included outside the policy limit?",
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDOwnsValuableArticlesJewelrySilverwareETC",
        text: "Does the client own valuable articles? (i.e. jewelry, silverware, fine art, other collectibles.)",
        //text: "Do you own valuable articles? (i.e. jewelry, silverware, fine art, other collectibles.)"
        type: "string",
        options: ["Yes", "No"],
      },
    ],
  },

  {
    section: "About",
    fields: [
      {
        name: "clientIDHouseOrApartment",
        text: "Please select the property type:",
        type: "string",
        options: ["House", "Apartment", "Condo", "Co.op"],
        //default: "undefined",
      },
      {
        name: "clientIDApartmentLossAssessment",
        text: "Has loss assessment coverage been evaluated against building master policy?",
        type: "string",
        options: ["Yes", "No"],
        //default: "undefined",
      },
      {
        name: "clientIDApartmentFloor",
        text: "Is the client's apartment located on the first floor?",
        type: "string",
        options: ["Yes", "No", "undefined"],
        //default: "undefined",
      },
      /* {
        name: "clientIDRecentRenovation1", //! I think this is a N fields in DB there are 1 and 2
        text: "Has the client had a recent renovation of his/her home?",
        type: "string",
        options: ["Yes", "No", "undefined"],
        default: "undefined",
      },

      {
        name: "clientIDImprovements1", //! I think this is a N fields in DB there are 1 and 2
        text: "Did the client report the improvements to the insurance company?",
        type: "string",
        options: ["Yes", "No", "undefined"],
        default: "undefined",
      }, */
      {
        name: "clientIDLossPayee",
        text: "Does the client have a mortgage on this property?",
        type: "string",
        options: ["Yes", "No"],
        //default: "undefined",
      },
      {
        name: 'clientIDWidowed',
        text: 'clientIDWidowed', //FIXME no exist text in the fronend
        type: 'string',
        options: ['Yes', 'No'],
      },
      {
        name: 'clientIDDivorced',
        text: 'Have you provided your divorce decree to your tax advisor?',
        //text: "Has the client provided his/her divorce decree to his/her tax advisor?"
        type: 'string',
        options: ['Yes', 'No'],
      },
      {
        name: "clientIDHouseOneAboveStandardFinishes",
        text: "Does the property have above-standard finishes?",
        type: "string",
        options: ["Yes", "No"],
        //default: "undefined",
      },
      /* {
        name: "clientIDContentsPhotographed1", //! I think this is a N fields in DB there are 1 and 2
        text: "Are the contents of the client's home photographed?",
        type: "string",
        options: ["Yes", "No", "undefined"],
        default: "undefined",
      },
      {
        name: "clientIDInspectorEnteredHome1", //! I think this is a N fields in DB there are 1 and 2
        text: "When the client bought his/her insurance, did an inspector enter his/her house?",
        type: "string",
        options: ["Yes", "No", "undefined"],
        default: "undefined",
      }, */
      {
        name: "clientIDHouseOneEstimatedContentsValue",
        text: "What is the estimated value of the contents in the client's home?",
        line: "Contents include: furniture, clothing, linen, drapes, electronics, appliances, rugs, dishes, etc.",
        type: "number",
        default: "0"
      },
      {
        name: "clientIDJewelryValue",
        text: "Estimated jewelry value:",
        type: "number",
        default: "0"
      },
      {
        name: "clientIDSilverwareValue",
        text: "Estimated silverware value:",
        type: "number",
        default: "0"
      },
      {
        name: "clientIDFineArtValue",
        text: "Estimated fine art value:",
        type: "number",
        default: "0"
      },
      {
        name: "clientIDFurs",
        text: "Estimated furs value:",
        type: "number",
        default: "0"
      },
      {
        name: "clientIDWineValue",
        text: "Estimated wine value:",
        type: "number",
        default: "0"
      },
      {
        name: "clientIDAntiquesValue",
        text: "Estimated antiques value:",
        type: "number",
        default: "0"
      },
      {
        name: "clientIDMusicalInstrumentsValue",
        text: "Estimated musical instruments value:",
        type: "number",
        default: "0"
      },
      {
        name: "clientIDOtherCollectibles",
        text: "Estimated value of additional collectibles:",
        type: "number",
        default: "0"
      },
      {
        name: "clientIDLastTimeReappraised",
        text: "How many years ago were these items last appraised?",
        type: "number",
        default: "0"
      },
      {
        name: "clientIDValuePhysicalCash",
        text: "Estimated physical cash value:",
        type: "number",
        default: "0"
      },
    ],
  },

  {
    section: "Other Information",
    fields: [
      {
        name: "clientIDTotalAssetValue",
        text: "What is the approximate value of the client's home?",
        type: "number",
        default: "0"
      },
      {
        name: "clientIDLastTimeShopped",
        text: "When was the policy last shopped?",
        type: "number",
        default: "0"
      },
      {
        name: "clientIDCredits",
        text: "Is the client aware of all the credits his/her insurance company offers? (e.g. smoke detector, sprinklers, etc.) and have he/she made sure him/her have received all that apply?",
        type: "string",
        options: ["Yes", "No"],
        //default: "undefined",
      },
      {
        name: "clientIDAccessToGroupUmbrellaThroughEmployer",
        text: "Does the client have access to a group umbrella / excess liability through his/her employer?",
        type: "string",
        options: ["Yes", "No"],
        //default: "undefined",
      },
      {
        name: "clientIDEnrolledToGroupUmbrella",
        text: "Is the client enrolled?",
        type: "string",
        options: ["Yes", "No"],
        //default: "undefined",
      },
      {
        name: "clientIDItemsForExhibiting",
        text: "Does the client lend items for exhibiting?",
        type: "string",
        options: ["Yes", "No"],
        //default: "undefined",
      },
      {
        name: "clientIDExtraItemsForHomeInsurance",
        text: "Does the client have a boat / jet ski / ATV / golf cart?",
        type: "string",
        options: ["Yes", "No"],
        //default: "undefined",
      },
      {
        name: "clientIDHasSeparatePolicyForBoatsJetskisEtc",
        text: "Does the client have a separate policy for these vehicles?",
        type: "string",
        options: ["Yes", "No"],
        //default: "undefined",
      },
      {
        name: "clientIDOnAnyBoards", 
        text: "Is the client on any boards?",
        type: "string",
        options: ["Yes", "No"],
        //default: "undefined",
      },
      {
        name: "clientIDDomesticHelp",
        text: "Does the client have full-time domestic help?",
        type: "string",
        options: ["Yes", "No"],
        //default: "undefined",
      },
    ],
  },
]