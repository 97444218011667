import { Header, Icon } from 'semantic-ui-react';

export const TabContainer = ({ title, subtitle, children, headerOptions, icon, scope }) => {
    return (
        <div className='tab-container' style={{ padding: '10px' }}>
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', marginBottom: '14px' }}>
                <Header as='h2'>
                    <Icon name={icon || 'address book'} />
                    <Header.Content>
                        {title}
                        <Header.Subheader>
                            {subtitle}
                        </Header.Subheader>
                    </Header.Content>
                </Header>
                {headerOptions}
            </div>
            {children}
        </div>
    )
}