import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Icon, Modal } from "semantic-ui-react";
import { responseError } from "../../helpers";
import Form from "./Form";
import { advisorAPI } from "../../apis/AdvisorsAPI";

const UpdateEnterpriseSettings = ({refreshFunction, dataInfo, permissions=[]}) => {
  const me = useSelector((state) => state.me);
  const [openModal, setOpenModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [info, setInfo] = useState({});

  const fillForm = () => {
    setInfo({
      name: dataInfo.name,
      key: dataInfo.key,
      enterprisePermissions: dataInfo.enterprisePermissions
    })
  }

  const handleUpdate = async () => {
    setLoading(true)
    try {
      await advisorAPI.put(`/enterprise-settings/role/${dataInfo._id}`, info);
      refreshFunction()
      setOpenModal(false)
    } catch (error) {
      responseError(error, undefined, "UpdateEnterpriseSettings.handleUpdate")
    }
    setLoading(false)
  }

  useEffect(() => {
    fillForm();

    // eslint-disable-next-line
  },[])

  return (
    <>
      {me?.permissions?.includes("enteprise_settings:roles_update") && (
        <>
          <Button icon onClick={() => setOpenModal(true)}>
            <Icon name="pencil" />
          </Button>

          <Modal size="tiny" open={openModal} onClose={() => setOpenModal(false)}>
            <Modal.Header>Updating {dataInfo.name}</Modal.Header>
            <Modal.Content>
              <Form info={info} setInfo={setInfo} permissions={permissions}  />
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={() => setOpenModal(false)}>Cancel</Button>
              <Button onClick={handleUpdate} color="green" loading={loading}>
                Update
              </Button>
            </Modal.Actions>
          </Modal>
        </>
      )}
    </>
  );
}

export default UpdateEnterpriseSettings;