import { ButtonGroup, ButtonOr } from "semantic-ui-react";
import EditMapper from "./EditMapper";
import RemoveMapper from "./RemoveMapper";

const CardTaxMapper = ({data, index, companyProfile}) => {

    return <div className="card-border-line">
        <div style={{display:"flex", justifyContent:"space-between"}}>
            <div style={{fontSize:"20px", fontWeight:"bold"}}>
                Table Name: {data.tableName}
            </div>
            <div>
                <ButtonGroup size="mini">
                    <EditMapper data={data} index={index} companyProfile={companyProfile} />
                    <ButtonOr />
                    <RemoveMapper data={data} index={index} companyProfile={companyProfile} />
                </ButtonGroup>
            </div>
        </div>
        <div style={{marginTop:"10px"}}>Prop relation with Client: <strong>{data.keyClientId}</strong></div>
        <div>
            <div className="card-background-gray">
                <strong>PROP_OF_THEM:</strong> OUR_PROPS (DEFAULT_VALUES)
            </div>
            <div className="box-scroll">
            {data.props_mapper.map((e, i) => <div key={i} className="card-background-gray">
                <strong>{e.key}:</strong> {e.value} {e.defaultValue && `(${e.defaultValue})`} {e.forceUpdate && "⚠️ force update"}
            </div>)}
            </div>
        </div>
    </div>
}

export default CardTaxMapper;