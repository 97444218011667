import { useEffect, useState } from "react";
import { Input, Select } from "semantic-ui-react";
import * as helpers from "../../helpers/index";
import FormPermissions from "./FormPermissions";
const FormRole = ({ infoRole, setInfoRole, defaultRoles = [] }) => {
  const [name, setName] = useState(infoRole?.name || "");
  const [description, setDescription] = useState(infoRole?.description || "");
  const [permissions, setPermissions] = useState(infoRole?.permissions || []);
  const [key, setKey] = useState(infoRole?.key || []);
  const [color, setColor] = useState(infoRole?.color || []);

  //const defaultRoles = roles;
  const optionsColors = ["", "blue", "orange", "grey", "yellow", "green", "purple", "teal", "pink", "brown", "red", "violet", "black", "olive"];

  const handleChangeName = (e) => {
    setName(e.target.value);
    setKey(helpers.string_to_slug({ str: e.target.value, whitespaces: "_" }));
  };

  useEffect(() => {
    setInfoRole({
      name,
      description,
      permissions,
      color,
      key,
    });

    //eslint-disable-next-line
  }, [name, description, permissions, color, key]);

  return (
    <div>
      <div>
        <Input fluid value={name} onChange={handleChangeName} placeholder="Name" />
      </div>
      <div style={{ paddingTop: "10px" }}>
        <Input fluid value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Description" />
      </div>
      <div style={{ paddingTop: "10px" }}>
        <Input fluid value={key} onChange={(e) => setKey(e.target.value)} placeholder="Key example: this_is_a_key" />
      </div>
      <div style={{ paddingTop: "10px" }}>
        <Select
          fluid
          value={color}
          options={optionsColors.map((color) => {
            return { key: color, value: color, text: color || "Select a color" };
          })}
          onChange={(e, elem) => setColor(elem.value)}
        />
        {/* <Input
          fluid
          value={color}
          onChange={(e) => setColor(e.target.value)}
          placeholder="color"
        /> */}
      </div>
      <div style={{ paddingTop: "10px" }}>
        <FormPermissions
          permissions={permissions}
          setPermissions={setPermissions}
          defaultRoles={defaultRoles}
        />
      </div>
    </div>
  );
};

export default FormRole;
