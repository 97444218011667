import { useEffect, useState } from "react";
import { Button, Icon, Modal } from 'semantic-ui-react';
import { responseError } from "../../../helpers";
import DocumentCategorizationForm from './Form';
import { sysAPI } from "../../../apis/SysAPI";

const ButtonEditDocumentCategorization = ({id, data, refreshFunction, area, clientId, assetIndex}) => {
    const [openModal, setOpenModal] = useState(false);
    const [loader, setLoader] = useState(false);
    const [dataInfo, setDataInfo] = useState({})

    const handleUpdate = async (e) => {
        e.preventDefault();
        setLoader(true);
        try {
            await sysAPI.put(`/documents-categorization/${area}/${clientId}`,dataInfo, {
                params:{
                    index: assetIndex,
                    documentId: id
                }
            })
            setOpenModal(false);
            refreshFunction()
        } catch (error) {
            responseError(error, undefined, "handleUpdate");
        }
        setLoader(false);
    }

    useEffect(() => {
        //delete data._id;
        setDataInfo(data);

        // eslint-disable-next-line
    }, [data])

    return <>
        <Button icon size="mini" onClick={() => setOpenModal(!openModal)}>
            <Icon name="pencil"  />
        </Button>

        <Modal open={openModal}>
            <Modal.Header>Edit Document {data.name}</Modal.Header>
            <Modal.Content>
            <form id={`document-categorization-edit-file-id-${id}`} onSubmit={handleUpdate}>
                <div style={{display:"grid", gap: "10px"}}>
                    <DocumentCategorizationForm
                        dataForm={dataInfo}
                        setDataForm={setDataInfo}
                        showName={data.isManuallyAdded}
                    />

                </div>
            </form>
            </Modal.Content>

            <Modal.Actions>
                <Button onClick={() => setOpenModal(false)}>Cancel</Button>
                <Button form={`document-categorization-edit-file-id-${id}`} color="green" loading={loader}>
                    Update Document
                </Button>
            </Modal.Actions>
        </Modal>
    </>
}

export default ButtonEditDocumentCategorization;