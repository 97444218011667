export const areasExtractionsReducer = (areasExtractions = [], action) => {
    switch (action.type) {
        case 'set_areas_extractions': {
            return action.payload;
        }
        default: {
            return areasExtractions;
        }
    }
}

export const areasRecommendationsReducer = (areasRecommendations = [], action) => {
    switch (action.type) {
        case 'set_areas_recommendations': {
            return action.payload;
        }
        default: {
            return areasRecommendations;
        }
    }
}