export const currentCompanyReducer = (currentCompany = {}, action) => {
  switch (action.type) {
      case 'set_current_company':
          return action.payload;
      default:
          return currentCompany;
  }
}

export const currentCompanyCredits = (currentCompanyCredits = {}, action) => {
  switch (action.type) {
    case 'set_current_company_credits':
      return action.payload;
    default:
      return currentCompanyCredits;
  }
}

export const currentCompanyRequests = (currentCompanyRequests = {}, action) => {
  switch (action.type) {
    case 'set_current_company_requests':
      return action.payload;
    default:
      return currentCompanyRequests;
  }
}
