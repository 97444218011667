import moment from "moment";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchCurrentCompany } from "../../../actions/company.actions";
import { fetchCurrentCompanyCredits } from "../../../actions/company.actions";
import { AddAdvisorModal } from "../../modal/AddAdvisorModal";
import { ConvertToPurchasedModal } from "../../modal/ConvertToPurchasedModal";
import { Grid, Segment, Button, Form, Input, Dropdown, Icon } from "semantic-ui-react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { sysAPI } from "../../../apis/SysAPI";
import { advisorAPI } from "../../../apis/AdvisorsAPI";
import ChangeNameButton from "./ChangeName";
import RemoveCompany from "./RemoveCompany";
import SwitchHasSSOLogin from "./SwitchHasSSOLogin";
import ChangeUploadModeType from "./ChangeUploadModeType";
import SwitchWideSharing from "./SwitchWideSharing";

const CompanyGeneralInfo = ({ activeMenu }) => {
  const dispatch = useDispatch();
  const me = useSelector(state => state.me);
  const params = useParams();
  const { companyId } = params;
  const companyProfile = useSelector((state) => state.currentCompany);
  const [showModal, setShowModal] = useState(false);
  const [showPurchasedModal, setShowPurchasedModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [pocs, setPocs] = useState([]);
  const [renewalDateUpdate, setRenewalDateUpdate] = useState("");
  const [contractEndDate, setContractEndDate] = useState("");
  const [pocSelected, setPocSelected] = useState("");
  const [mainName, setMainName] = useState("")
  const [mainEmail, setMainEmail] = useState("")
  const [loading, setLoading] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  const modelOptions = {
    trial :'Trial',
    purchased: 'Licensed',
  };

  const modelTypesOptions = {
    legacy :'Legacy',
    adoption: 'Part Limited',
    limited: 'Full Limited',
  };

  const getData = async () => {
    try {
      const { data: responsePocs } = await sysAPI.get("/companies/pocs");
      setPocs(responsePocs.map((itm) => ({ key: itm?._id || itm?.name, value: itm?._id || itm?.name, text: itm?.name })));
    } catch (error) {
      console.error("ERROR 👇🏼");
      console.error({ error });
    }
  };

  const handleUpdate = async () => {
    setLoading(true);
    try {
      await advisorAPI.put(`/companies/${companyProfile._id}`, {
        poc: pocSelected,
        mainContact: {
          name: mainName,
          email: mainEmail,
        },
        renewalDate: renewalDateUpdate,
        contractEndDate,
      });
      dispatch(fetchCurrentCompany(companyProfile._id));

      alert("Company updated");
      setIsUpdating(false);
    } catch (error) {
      alert("Oops... the company was not update, try again please.");
      console.log("ERROR in update 👇🏼");
      console.log({ error });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!showModal || !showPurchasedModal) {
      dispatch(fetchCurrentCompany(companyId));
      dispatch(fetchCurrentCompanyCredits(companyId, "all"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal, showPurchasedModal]);

  useEffect(() => {
    getData();
    setOpenMenu(false)
  }, []);

  useEffect(() => {
    if (isUpdating) {
      //console.log(companyProfile);

      setRenewalDateUpdate(moment(companyProfile?.renewalDate).format("YYYY-MM-DD"));
      setContractEndDate(moment(companyProfile?.contractEndDate).format("YYYY-MM-DD"));
      setPocSelected(companyProfile?.poc?._id || "");
      setMainName(companyProfile?.mainContact?.name)
      setMainEmail(companyProfile?.mainContact?.email)
    }

    // eslint-disable-next-line
  }, [isUpdating]);
  const styleEndText = {
    padding: "10px 0px",
    display: "flex",
    justifyContent: "end",
  };

  const styleHeadeCard = {
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "5px",
  };

  return (
    <>
      {activeMenu === "general" && (
        <>
          <div className="flex-space-between" style={{marginBottom: "10px"}}>
            <div>
              <h1>{companyProfile?.name}</h1>
            </div>

            <div>
              <Dropdown
                trigger={
                  <Button icon onClick={() => setOpenMenu(!openMenu)}>
                    <Icon name={openMenu ? "close" : "bars"}/>
                  </Button>
                }
                close
                pointing="right" open={openMenu}
                icon={false}
              >
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <ChangeNameButton company={companyProfile} isButton={false} />
                  </Dropdown.Item>

                  {me?.permissions?.includes("companies:edit_company") &&
                    <Dropdown.Item>
                      <div onClick={() => setIsUpdating(!isUpdating)}>
                        <Icon name={isUpdating ? "close" : "pencil"} /> {isUpdating ? "Cancel Update" : "Edit Company"}
                      </div>
                    </Dropdown.Item>
                  }

                  {me?.permissions?.includes("advisors:add_advisor") &&
                    <Dropdown.Item>
                      <div color="blue" onClick={() => setShowModal(true)}>
                        <Icon name="address book" /> Add Advisor
                      </div>
                    </Dropdown.Item>
                  }

                  {companyProfile?.uploadModel?.type !== "legacy" &&
                    <Dropdown.Item>
                      <ChangeUploadModeType company={companyProfile} isButton={false} />
                    </Dropdown.Item>
                  }


                  {companyProfile?.modelType !== "purchased" && me?.permissions?.includes("companies:convert_to_purchased") && (
                      <Dropdown.Item>
                        <div color="green" onClick={() => setShowPurchasedModal(true)}>
                          <Icon name="dollar sign" /> Convert to purchased
                        </div>
                      </Dropdown.Item>
                  )}

                  <Dropdown.Item>
                    <SwitchHasSSOLogin company={companyProfile} isButton={false} />
                  </Dropdown.Item>

                  {me?.permissions?.includes("companies:button_wide_sharing") &&
                    <Dropdown.Item>
                      <SwitchWideSharing company={companyProfile} isButton={false} />
                    </Dropdown.Item>
                  }
                </Dropdown.Menu>
              </Dropdown>
              {/* <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}>
                  <div>
                    <ChangeNameButton company={companyProfile} />
                  </div>
                  <div>
                    <SwitchHasSSOLogin company={companyProfile} />
                  </div>
                  {me?.permissions?.includes("companies:edit_company") &&
                    <Button color={isUpdating ? "gray" : "yellow"} onClick={() => setIsUpdating(!isUpdating)}>
                      {isUpdating ? "Cancel Update" : "Edit Company"}
                    </Button>
                  }
                  {me?.permissions?.includes("advisors:add_advisor") &&
                    <Button color="blue" onClick={() => setShowModal(true)}>
                      Add Advisor
                    </Button>
                  }
                  {companyProfile?.modelType !== "purchased" && me?.permissions?.includes("companies:convert_to_purchased") && (
                    <Button color="green" onClick={() => setShowPurchasedModal(true)}>
                      Convert to purchased
                    </Button>
                  )}
                </div> */}
            </div>
          </div>
          <Grid columns={2}>
            <Grid.Column>
              <Segment>
                <div style={styleHeadeCard}>POC</div>

                {isUpdating ? (
                  <div>
                    <Dropdown
                      placeholder="POC"
                      className="icon create-company-custom-search"
                      options={pocs}
                      value={pocSelected}
                      onChange={(_, val) => {
                        setPocSelected(val.value);
                      }}
                      icon="user"
                      labeled
                      button
                      fluid
                      selection
                      search
                    />
                  </div>
                ) : (
                  <div>
                    <div>{companyProfile?.poc?.fullName}</div>
                  </div>
                )}
              </Segment>
              <Segment>
                <div style={styleHeadeCard}>Main Contact</div>
                {isUpdating ? (
                  <div>
                    <div style={{ width: "100%", marginBottom: "12px" }}>
                      <Form.Field control={Input} fluid label="Name" value={mainName} onChange={(_, val) => setMainName(val.value)} />
                    </div>
                    <div style={{ width: "100%" }}>
                      <Form.Field control={Input} fluid type="email" label="Email" value={mainEmail} onChange={(_, val) => setMainEmail(val.value)} />
                    </div>
                  </div>
                ) : (
                  <div>
                    <div>
                      <strong>Name: </strong> {companyProfile?.mainContact?.name}
                    </div>
                    <div>
                      <strong>Email: </strong> {companyProfile?.mainContact?.email}
                    </div>
                  </div>
                )}
              </Segment>
              <Segment>
                <div style={styleHeadeCard}>Renewal Date</div>
                {isUpdating ? (
                  <div>
                    <div style={{ width: "100%" }}>
                      <Form.Field
                        control={Input}
                        type="date"
                        fluid
                        value={renewalDateUpdate}
                        onChange={(_, val) => setRenewalDateUpdate(val.value)}
                        min={moment().add(1, "day").format("YYYY-MM-DD").toString()}
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    <div>{moment(companyProfile?.renewalDate).format("MM-DD-YYYY")}</div>
                  </div>
                )}
              </Segment>

              <Segment>
                <div style={styleHeadeCard}>Contract End Date</div>
                {isUpdating ? (
                  <div>
                    <div style={{ width: "100%" }}>
                      <Form.Field
                        control={Input}
                        type="date"
                        fluid
                        value={contractEndDate}
                        onChange={(_, val) => setContractEndDate(val.value)}
                        min={moment().add(1, "day").format("YYYY-MM-DD").toString()}
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    <div>{companyProfile?.contractEndDate ? moment(companyProfile?.contractEndDate).format("MM-DD-YYYY") : "---"}</div>
                  </div>
                )}
              </Segment>

              <div style={{display: "flex", gap:"10px"}}>
                <div>
                  {/* <Button color={isUpdating ? "gray" : "yellow"} onClick={() => setIsUpdating(!isUpdating)}>
                    {isUpdating ? "Cancel Update" : "Edit Company"}
                  </Button> */}
                </div>
                {isUpdating && (
                  <div>
                    <Button color="green" onClick={handleUpdate} disabled={loading}>
                      {loading ? "Updating..." : "Update"}
                    </Button>
                  </div>
                )}
              </div>
            </Grid.Column>

            <Grid.Column>
              <Segment>
                <div style={styleHeadeCard}>Type</div>
                <div>
                  <div>{modelOptions[companyProfile?.modelType]}/{modelTypesOptions[companyProfile?.uploadModel?.type]}</div>
                </div>
              </Segment>
            </Grid.Column>
          </Grid>

          <div style={{...styleEndText, fontSize: "20px"}}>
              <strong>Advisors:</strong> {companyProfile?.advisorList?.length}
          </div>
          <div style={styleEndText}>
            <div>
              <RemoveCompany company={companyProfile} />
            </div>
          </div>

          <AddAdvisorModal
            modalState={showModal}
            setModalState={(val) => setShowModal(val)}
            preselectedCompany={companyProfile?._id}
            companyType={companyProfile?.modelType}
          />
          <ConvertToPurchasedModal
            modalState={showPurchasedModal}
            setModalState={(val) => setShowPurchasedModal(val)}
            preselectedCompany={companyProfile?.name}
          />
        </>
      )}
    </>
  );
};

export default CompanyGeneralInfo;
