import { authAPI } from "../apis/AuthAPI";
import { documentAPI } from "../apis/DocumentAPI";
import { sysAPI } from "../apis/SysAPI";

export const setEstateManualInputField = (docType, field, value) => {
    return {
        type: 'set_estate_manual_input_field',
        payload: {
            docType,
            field,
            value,
        },
    }
};

export const removeEstateManualInputField = (docType, field) => {
    return {
        type: 'remove_estate_manual_input_field',
        payload: {
            docType,
            field,
        }
    }
};

export const addEstateManualInputAsset = (docType, id) => {
    return {
        type: 'add_estate_manual_input_asset',
        payload: {
            docType,
            id,
        }
    }
};

export const removeEstateManualInputAsset = (docType, assetId, fields, fieldsDocType) => {
    return (dispatch) => {
        dispatch({
            type: 'remove_estate_manual_input_asset',
            payload: {
                docType,
                id: assetId,
            }
        });

        for (const field of fields) {
            dispatch(removeEstateManualInputField(fieldsDocType || docType, `${field}___${assetId}`));
        }
    }
};

export const addEstateManualInputDocument = (docType, id, isJoint=false) => {
    return {
        type: 'add_estate_manual_input_document',
        payload: {
            docType,
            id,
            isJoint
        },
    }
};

export const removeEstateManualInputDocument = (id) => {
    return {
        type: 'remove_estate_manual_input_document',
        payload: { id },
    }
};

export const setEstateManualInputChangeField = (docType, field, value) => {
    return {
        type: 'set_estate_manual_input_change_field',
        payload: {
            docType,
            field,
            value,
        },
    }
};

export const addEstateManualInputChangeDocument =  (docType, field, value) => {
    return {
        type: 'add_estate_manual_input_change_document',
        payload: {
            docType,
            field,
            value,
        },
    }
};

export const addEstateManualInputChangeAsset = (docType, id) => {
    return {
        type: 'add_estate_manual_input_asset',
        payload: {
            docType,
            id,
        }
    }
};

export const saveManualInputForLater = (clientId, index, area = 'estate', showAlert = true) => {
    return async (dispatch, getState) => {
        const { estateManualInput, estateManualInputAssets, estateManualInputDocuments } = getState();

        const datapoints = {};

        for (const docType in estateManualInput) {
            for (const datapoint in estateManualInput[docType]) {
                if (datapoint !== 'children' && datapoint !== 'grandchildren' && datapoint !== 'maritalStatus') {
                    datapoints[`${docType}___${datapoint}`] = estateManualInput[docType][datapoint];
                }
            }
        }

        try {
            const response = await sysAPI.post(`/generic-manual-input`, {
                clientId,
                datapoints,
                assets: estateManualInputAssets,
                documents: estateManualInputDocuments,
                area,
                index,
            });
            console.log({response});
            if(showAlert){
                window.alert('Information saved correctly.');
            }
        }
        catch (err) {
            console.error(err);
            window.alert('ERROR: Something went wrong, please try again later.');
            return Promise.reject();
        }

        await updateJointsForVersion4(clientId, index, area);

        return Promise.resolve();
    }
};

export const fetchManualInputDataPoints = (clientId, index, area = 'estate') => {
    return async (dispatch) => {
        let datapoints = {};
        let assets = {};
        let documents = {};
        let changeLog = {};

        try {
            const response = await sysAPI.get(`/generic-manual-input?clientId=${clientId}&area=${area}&index=${index}`);
            datapoints = response.data.datapoints || {};
            assets = response.data.assets || {};
            documents = response.data.documents || {};
            changeLog = response.data.changeLog || {};
            // console.log({responseData: response.data})
        }
        catch (err) {
            console.error();
        }
        
        dispatch(resetEstateManualInputFields());
        dispatch(resetEstateManualInputAssets());
        dispatch(resetEstateManualInputDocuments());

        dispatch(fetchEstateManualInputRequest(clientId, index, area));


        for (const datapoint in datapoints) {
            const [docType, _field, assetId] = datapoint.split('___');
            const field = !assetId ? _field : `${_field}___${assetId}`;

            dispatch(setEstateManualInputField(docType, `${field}`, datapoints[datapoint]));
        }

        for (const docType in assets) {
            for (const asset of assets[docType]) {
                dispatch(addEstateManualInputAsset(docType, asset.id));
            }
        }

        for (const docId in documents) {
            dispatch(addEstateManualInputDocument(documents[docId].type, docId, documents[docId].isJoint));
        }

        if (Object.keys(changeLog).length > 0) {
            for (const datapoint in changeLog.datapoints || {}) {
                const [docType, _field, assetId] = datapoint.split('___');
                const field = !assetId ? _field : `${_field}___${assetId}`;

                dispatch(setEstateManualInputChangeField(docType, `${field}`, changeLog.saved?.datapoints[datapoint] || ''));
            }
        
            for (const docId in changeLog.documents || {}) {
                //console.log({doc: documents[docId]})
                if(documents[docId]){
                    dispatch(addEstateManualInputChangeDocument(documents[docId].type, docId));
                }
            }
        }
    }
};

export const finishReview = (clientId, index, area = 'estate', options=null) => {
    return async (dispatch) => {
        try {
            await dispatch(saveManualInputForLater(clientId, index, area));
            if(options && !options.ignoreValidation){
                const isValid = await validateEstatePreview({clientId, index, reviewer: options.reviewer, advisor: options.advisor});
                //console.log({isValid})
                if(!isValid){
                     // eslint-disable-next-line
                    throw {message: "There is an error in the snapshot, and it cannot be generated.\nPlease notify the development team."}
                }
            }
            await sysAPI.post(`/estate-manual-input/finish-review`, { clientId, index, area });
        }
        catch (err) {
            console.error(err);
            //alert("Something was wrong...");
            return Promise.reject();
        }

        return Promise.resolve();
    }
}

export const getAccessToken = async ({clientId, index, reviewer, advisor}) => {
    const token = await generateToken({clientId, index, reviewer, advisor});
    const {data} = await authAPI.post("/single-token/validate", {token});
    return data.access_token;
}

export const generateToken = async ({clientId, index, reviewer, advisor}) => {
    const {data: token} = await authAPI.post("/single-token",{
        clientId,
        reviewer,
        advisor,
        index
    });
    return token;
}

export const validateEstatePreview = async ({clientId, index, reviewer, advisor, showAlert=true}) => {
    const message = "There is an error in the snapshot, and it cannot be generated.\nPlease notify the development team."
    try {
        const accessToken = await getAccessToken({clientId, index, reviewer, advisor});
        const {data} = await documentAPI.get("/estate-snapshot",{
            params:{
                clientId,
                index,
                pdfVersion: false
            },
            headers:{
                Authorization: `Bearer ${accessToken}`
            }
        })
        if(data.status !== "Ready"){
            if(showAlert){
                alert(message)
            }
            throw new Error(message);
        }
        return true
    } catch (error) {
        console.log({error});
        if(showAlert){
            alert(message)
        }
        return false
    }
}

export const fetchEstateManualInputRequest = (clientId, index, area) => {
    const url = area === 'home' || area === 'auto'
        ? `/insurance-snapshot/status?clientId=${clientId}&area=${area}`
        : `/estate-manual-input/status?clientId=${clientId}&index=${index}`
    return async (dispatch) => {
        try {
            const response = await sysAPI.get(url);
            dispatch(setEstateManualInputRequest(response.data));
        }
        catch (err) {
            console.error(err);
        }
    }
}

export const setEstateManualInputRequest = (request) => {
    return {
        type: 'set_estate_manual_input_request',
        payload: request,
    }
}

export const resetEstateManualInputFields = () => {
    return {
        type: 'reset_estate_manual_input_fields',
        payload: null,
    }
}

export const resetEstateManualInputAssets = () => {
    return {
        type: 'reset_estate_manual_input_assets',
        payload: null,
    }
}

export const resetEstateManualInputDocuments = () => {
    return {
        type: 'reset_estate_manual_input_documents',
        payload: null,
    }
}

/**
 * *for version 4, is neccesary update the Joint together, so if we detect changes, is necessary update the index 2
 * *To use the joints too.
 */
const updateJointsForVersion4 = async (clientId, index, area) => {
    //if(index===1){
        try {
            const {data: manualInputIndexMain} = await sysAPI.get(`/generic-manual-input?`, {
                params: { clientId: clientId, area: area, index: index }
            });
            if(manualInputIndexMain.version === 4){
                const indexSpouse = index === 1 ? 2 : 1
                const {data: manualInputIndexSecondary} = await sysAPI.get(`/generic-manual-input?`, {
                    params: { clientId: clientId, area: area, index: indexSpouse }
                });
                if(manualInputIndexSecondary){
                    console.log("Is Index 1 and Version 4 and Spouse found, updating Joints in spouse too");
                    const documentsWithIsJoint = {};
                    for(const doc of Object.keys(manualInputIndexMain.documents || {})){
                        if(manualInputIndexMain.documents[doc].isJoint){
                            documentsWithIsJoint[doc] = manualInputIndexMain.documents[doc]
                        }
                    }

                    const datapointsWithIsJoint = {};
                    for(const datapoint of Object.keys(manualInputIndexMain.datapoints || {})){
                        const keysDocuments = Object.keys(documentsWithIsJoint);
                        const [keyDoc] = datapoint.split("___")
                        if(keysDocuments.includes(keyDoc)){
                            datapointsWithIsJoint[datapoint] = manualInputIndexMain.datapoints[datapoint]
                        }
                    }

                    const assetsWithIsJoint = {};
                    for(const asset of Object.keys(manualInputIndexMain.assets || {})){
                        const [keyDoc] = asset.split("_");
                        const keysDocuments = Object.keys(documentsWithIsJoint);
                        //console.log({keyDoc, keysDocuments})
                        if(keysDocuments.includes(keyDoc)){
                            assetsWithIsJoint[asset] = manualInputIndexMain.assets[asset]
                        }
                    }

                    const {data: responseSpouse} = await sysAPI.post(`/generic-manual-input`, {
                        clientId,
                        datapoints: {
                            ...manualInputIndexSecondary.datapoints,
                            ...datapointsWithIsJoint
                        },
                        assets: {
                            ...manualInputIndexSecondary.assets,
                            ...assetsWithIsJoint
                        },
                        documents: {
                            ...manualInputIndexSecondary.documents,
                            ...documentsWithIsJoint
                        },
                        area,
                        index: indexSpouse,
                    });
                    console.log({responseSpouse});

                }
            }
        } catch (error) {
            console.log({error})
        }
    //}
}