export const currentClientReducer = (client = null, action) => {
    switch (action.type) {
        case 'set_current_client':
            return action.payload;
        case 'update_current_client_prop': {
            if (client) {
                const auxClient = { ...client };
                auxClient[action.payload.prop] = action.payload.value;
                return auxClient;
            }

            return client;
        }
        default:
            return client;
    }
}