/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Modal, Table, Input, Message, Segment, Button, Form, Checkbox } from 'semantic-ui-react';
import { sysAPI } from '../../apis/SysAPI';
import { advisorAPI } from '../../apis/AdvisorsAPI';
import { fpalphaAPI } from '../../apis/FPAlphaAPI';
import { fetchCurrentCompany } from '../../actions/company.actions';

export const ConvertToPurchasedModal = ({ modalState, setModalState }) => {
    const dispatch = useDispatch();
    const companyProfile = useSelector((state) => state.currentCompany);
    const [loading, setLoading] = useState(false);
    const [disableSave, setDisableSave] = useState(true);
    const [stage, setStage] = useState('fetch');
    const [renewalDate, setRenewalDate] = useState('');
    const [contractEndDate, setContractEndDate] = useState('');
    const [selectedAdvidsors, setSelectedAdvidsors] = useState([]);
    const [deleteAdvisors, setDeleteAdvisors] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [disableConfirm, setDisableConfirm] = useState(true);

    const toggleAdvisor = (advisor) => {
        if (selectedAdvidsors.includes(advisor)) {
            setSelectedAdvidsors(selectedAdvidsors.filter(itm => itm !== advisor));
        } else {
            setSelectedAdvidsors(prev => ([...prev, advisor]));
        }
    };

    const confirmSave = async () => {
        setLoading(true);
        setDisableSave(true);
        setShowPassword(false);

        try {
            await advisorAPI.put(`/companies/${companyProfile._id}/convert-to-purchased`, {
                renewalDate,
                contractEndDate,
            });

            if (selectedAdvidsors.length >= 1) {
                console.log(selectedAdvidsors)
                for (const itm of selectedAdvidsors) {
                    await sysAPI.post('/advisors/full-access', {
                        advisorId: itm,
                        password,
                    });
                }
            };

            if (deleteAdvisors.length >= 1) {
                console.log(deleteAdvisors)
                for (const itm of deleteAdvisors) {
                    await fpalphaAPI.post(`/tools/advisor/delete`, {
                        id: itm,
                        password,
                        advisorEmail: companyProfile?.advisorList?.find(advisor => advisor._id === itm),
                    });
                }
            };

            console.log({ConvertRoPurchasedModal: companyProfile._id})
            dispatch(fetchCurrentCompany(companyProfile._id));

            setStage('success');
            setModalState(false);
        } catch (e) {
            console.error(e);
            setStage('error');
            setDisableSave(false);
            return false;
        }
    };

    const saveSettings = async () => {
        setShowPassword(true);
        setDeleteAdvisors(companyProfile?.advisorList?.map(itm => itm?._id).filter(itm => !selectedAdvidsors?.includes(itm)));
    };

    const passwordHandler = (pass) => {
        setPassword(pass);
        if (password.length >= 5) {
            setDisableConfirm(false);
        } else {
            setDisableConfirm(true);
        }
    };

    useEffect(() => {
        setLoading(false);
        setDisableSave(false);
        setStage('fetch');
        setRenewalDate('');
        setContractEndDate('');
        //setSelectedAdvidsors([]);
    }, [companyProfile]);

    useEffect(() => {
      if (modalState) {
        setSelectedAdvidsors(companyProfile?.advisorList?.map((itm) => itm._id));
        setDisableSave(true);
      }
    }, [modalState]);

    useEffect(() => {
      setDisableSave(true);
      if (renewalDate && contractEndDate) {
        setDisableSave(false);
      }
    }, [renewalDate, contractEndDate]);

    return (<>
        <Modal size="tiny" open={modalState} onClose={() => setModalState(false)} onOpen={() => setModalState(true)}>
            <Modal.Header>Give credits to Advisors</Modal.Header>

            <Modal.Content>
                <Segment>
                    <Form>
                        <Form.Group style={{ marginBottom: 0 }}>
                            <Form.Field
                                control={Input}
                                type="date"
                                label="Renewal date"
                                width={16}
                                min={moment().add(1, 'day').format('YYYY-MM-DD').toString()}
                                value={renewalDate}
                                onChange={(_, val) => { setRenewalDate(val.value) }}
                            />

                        </Form.Group>
                    </Form>
                </Segment>
                <Segment>
                    <Form>
                        <Form.Group style={{ marginBottom: 0 }}>
                            <Form.Field
                                control={Input}
                                type="date"
                                label="Contract End Date"
                                width={16}
                                min={moment().add(1, 'day').format('YYYY-MM-DD').toString()}
                                value={contractEndDate}
                                onChange={(_, val) => { setContractEndDate(val.value) }}
                            />

                        </Form.Group>
                    </Form>
                </Segment>

                <Segment>
                    <Message
                        color="yellow"
                        icon="warning"
                        content="The unchecked Advisors will be deleted from the company account. This action cannot be undone."
                    />

                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={2}></Table.HeaderCell>
                                <Table.HeaderCell>Advisor</Table.HeaderCell>
                                <Table.HeaderCell>Email</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {companyProfile?.advisorList?.map(advisor => (
                                <Table.Row key={advisor._id}>
                                    <Table.Cell
                                        width={2}
                                        style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                                    >
                                        <Checkbox
                                            toggle
                                            checked={selectedAdvidsors.includes(advisor?._id)}
                                            onChange={() => toggleAdvisor(advisor?._id)}
                                        />
                                    </Table.Cell>
                                    <Table.Cell>{advisor?.fullName}</Table.Cell>
                                    <Table.Cell>{advisor?.email}</Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </Segment>

                {loading &&
                    <Segment>
                        {stage === 'fetch' &&
                            <Message
                                color="blue"
                                icon="wait"
                                content="Executing. Please wait, and don't refresh this page…"
                            />}
                        {stage === 'success' &&
                            <Message
                                color="green"
                                icon="check"
                                content="Your settings have been saved successfully!"
                            />}
                        {stage === 'error' &&
                            <Message
                                color="red"
                                icon="warning"
                                content="Your request could not be processed."
                            />}
                    </Segment>}
            </Modal.Content>

            <Modal.Actions>
                <Button onClick={() => setModalState(false)}>Cancel</Button>
                <Button
                    color="green"
                    onClick={saveSettings}
                    disabled={disableSave}
                >Save</Button>
            </Modal.Actions>
        </Modal>

        <Modal size="mini" open={showPassword} onClose={() => setShowPassword(false)} onOpen={() => setShowPassword(true)}>
            <Modal.Content>
                <p>Please input your password to confirm this action.</p>
                <Form>
                    <Form.Group style={{ marginBottom: 0 }}>
                        <Form.Field
                            control={Input}
                            type="password"
                            label="Password"
                            value={password}
                            width={16}
                            onChange={(_, val) => passwordHandler(val.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Content>

            <Modal.Actions>
                <Button onClick={() => setShowPassword(false)}>Cancel</Button>
                <Button disabled={disableConfirm} color="blue" onClick={confirmSave}>Confirm</Button>
            </Modal.Actions>
        </Modal>
    </>);
};
