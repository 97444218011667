import { useState } from "react"
import { Button, Modal } from "semantic-ui-react"
import FromMapperTax from "./Form";
import { advisorAPI } from "../../../../../apis/AdvisorsAPI";
import { fetchCurrentCompany } from "../../../../../actions/company.actions";
import { useDispatch } from "react-redux";

const AddNewMapper = ({companyProfile}) => {
  const dispatch = useDispatch();
    const isAvailable = companyProfile?._integrations?.salesforce?.settings?.updateDataTax?.isActive

    const [dataSend, setDataSend] = useState({});
    const [loader, setLoader] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const handleAddMapper = async () => {
        setLoader(true)
        try {
          const originalData = companyProfile?._integrations?.salesforce?.settings?.updateDataTax?.taxMapper || []
          originalData.push(dataSend);
          // console.log({originalData})
          await advisorAPI.put(`/companies/${companyProfile._id}`, {
            [`_integrations.salesforce.settings.updateDataTax.taxMapper`]: originalData,
          });
          await dispatch(fetchCurrentCompany(companyProfile._id));
          setOpenModal(false)
          setDataSend({})
        } catch (error) {
            console.error({error});
            alert(error?.response?.data?.message || error?.message || "Error...");
        }
        setLoader(false)
    }

    return <div>
        <Button disabled={!isAvailable} onClick={() => setOpenModal(!openModal)}>Add New Mapper</Button>


        <Modal open={openModal}>
          <Modal.Header>Add New Mapper for Tax</Modal.Header>
          <Modal.Content>
            <div>
                <FromMapperTax dataSend={dataSend} setDataSend={setDataSend} />
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => setOpenModal(false)}>Cancel</Button>
            <Button onClick={handleAddMapper} color="green" loading={loader}>
              Add Table
            </Button>
          </Modal.Actions>
        </Modal>
    </div>
}

export default AddNewMapper