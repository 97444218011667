import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sysAPI } from "../../apis/SysAPI";
import { Button, Message, Modal, Select } from "semantic-ui-react";
import { fetchManualInputDataPoints } from "../../actions/estate-manual-input.actions";

const ButtonChangeStatus = ({area , clientId, assetIndex}) => {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const [openModal, setOpenModal] = useState(false)
    const [optionsStatus, setOptionsStatus] = useState([])
    const [newStatus, setNewStatus] = useState("");
    const [loader, setLoader] = useState(false)

    const estateManualInputRequest = useSelector(state => state.estateManualInputRequest);

    useEffect(() => {
        const options_status = ["In review", "Peer review", "On Hold"].map(e => {
            return {
                key: e,
                value: e,
                text: e,
            }
        })

        if(estateManualInputRequest?.finishReviewDate){
            options_status.push(...["Ready"].map(e => {
                return {
                    key: e,
                    value: e,
                    text: e,
                }
            }))
        }

        setOptionsStatus(options_status);
        setNewStatus(estateManualInputRequest?.status || "");

        // eslint-disable-next-line
    }, [estateManualInputRequest, openModal]);

    const handleChangeStatus = async () => {
        setLoader(true)
        try {
            const {data} = await sysAPI.put(`/change-status`, {status: newStatus}, {params: {
                clientId, area
            }})
            await dispatch(fetchManualInputDataPoints(clientId, assetIndex));
            alert(data.message || "status changed")
            setOpenModal(false)

        } catch (error) {
            console.log({error});
            alert(error?.response?.data?.message || error.message || "Something was wrong")
        }
        setLoader(false)
    }
    if(!me?.permissions?.includes('manual_inputs:show_button_change_status')){
        return <></>
    }

    return <>
        {estateManualInputRequest?.startReviewDate && estateManualInputRequest?.status &&
            <Button color="olive" onClick={() => setOpenModal(!openModal)}>Change Status</Button>
        }

        <Modal size="tiny" open={openModal}>
            <Modal.Header>Change Status</Modal.Header>
            <Modal.Content>
                <div>
                    <div style={{marginBottom: "10px"}}>
                        Actual Status is <strong>{JSON.stringify(estateManualInputRequest?.status)}</strong>
                    </div>
                    <div>
                        <div>Select new status</div>
                        <Select
                            value={newStatus}
                            options={optionsStatus}
                            fluid
                            onChange={(e,{value}) => setNewStatus(value)}
                        />
                    </div>
                    <div style={{padding: "20px 10px 0px 10px"}}>
                        {estateManualInputRequest?.finishReviewDate && estateManualInputRequest?.status !== "Ready" &&<>
                            <Message size="mini" color='green'>This Manual Input was finished before, you can change the status to Ready ✅</Message>
                        </>}
                    </div>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => setOpenModal(false)}>Cancel</Button>
                <Button onClick={handleChangeStatus} color="green" loading={loader}>
                    Update
                </Button>
            </Modal.Actions>
        </Modal>
    </>
}

export default ButtonChangeStatus