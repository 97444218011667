import { useEffect, useState } from "react"
import { Button, Modal, Table } from "semantic-ui-react"
import { responseError } from "../../../helpers";
import { sysAPI } from "../../../apis/SysAPI";
import moment from "moment";
import ButtonAddDocumentCategorization from "./ButtonAddDocumentCategorization";
import ButtonEditDocumentCategorization from "./ButtonEditDocumentCategorization";
import ButtonRemoveDocumentCategorization from "./ButtonRemoveDocumentCategorization";
import MessageTablesEmpty from "../../globals/MessageTablesEmpty";

const ViewDocumentModal = ({area, clientId, assetIndex, estateManualInputRequest}) => {
    const [openModal, setOpenModal] = useState(false);
    const [documents, setDocuments] = useState([]);

    const getDocuments = async () => {
        try {
            const {data} = await sysAPI.get(`/documents-categorization/${area}/${clientId}`, {params: {
                index: assetIndex
            }})
            setDocuments(data.data || []);
        } catch (error) {
            responseError(error, undefined, "getDocuments");
        }
    }

    useEffect(() => {
        if(openModal){
            getDocuments();
        }

        // eslint-disable-next-line
    },[openModal]);


    const RowDocument = ({document}) => {

        const printDate = (doc) => {
            if(doc.dateIsUnknown){
                return "Unknown"
            }
            else if(doc.dateNotSigned){
                return "Not Signed"
            }
            const date_ = moment(doc.date)
            return date_.isValid() ? date_.format("MM/DD/YYYY") : "Date is nos valid";
        }

        return <>
            <Table.Cell className="hidden-column"> {document._id} </Table.Cell>
            <Table.Cell> {document.name} </Table.Cell>
            <Table.Cell>{printDate(document)}</Table.Cell>
            <Table.Cell>{document.type}</Table.Cell>
            <Table.Cell>{document.jointOrSingle}</Table.Cell>
            <Table.Cell>{document.subType}</Table.Cell>
            <Table.Cell textAlign='center'>{document.isManuallyAdded ? 'Yes' : 'No'}</Table.Cell>
            <Table.Cell textAlign='center'>
                <div style={{display: "flex", gap: "10px"}}>
                    <div>
                        <ButtonEditDocumentCategorization id={document._id} data={document} refreshFunction={getDocuments} area={area} clientId={clientId} assetIndex={assetIndex}/>
                    </div>
                    <div>
                        <ButtonRemoveDocumentCategorization id={document._id} data={document} refreshFunction={getDocuments} area={area} clientId={clientId} assetIndex={assetIndex}/>
                    </div>
                </div>
            </Table.Cell>
        </>
    }

    return <>
        <Button color="teal" onClick={() => setOpenModal(!openModal)}>
            Documents for {estateManualInputRequest?.client?.displayName || "..."}
        </Button>

        <Modal size="large" open={openModal}>
            <Modal.Header>Documents for {estateManualInputRequest?.client?.displayName || "..."}</Modal.Header>
            <Modal.Content>
                <div style={{display:"flex", padding: "5px 0px", justifyContent:"flex-end"}}>
                    <div>
                        <ButtonAddDocumentCategorization area={"estate"} clientId={clientId} assetIndex={assetIndex} refreshFunction={getDocuments} />
                    </div>
                </div>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell className="hidden-column">ID</Table.HeaderCell>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.HeaderCell>Type</Table.HeaderCell>
                            <Table.HeaderCell>joint Or Single</Table.HeaderCell>
                            <Table.HeaderCell>sub type</Table.HeaderCell>
                            <Table.HeaderCell>Added Manually</Table.HeaderCell>
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {documents.map((document, index) => <Table.Row key={index}>
                            <RowDocument document={document} />
                        </Table.Row>)}
                        {documents.length < 1 && <MessageTablesEmpty counterSearched={1} />}
                        
                    </Table.Body>
                </Table>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => setOpenModal(false)}>Close</Button>
            </Modal.Actions>
        </Modal>
    </>
}


export default ViewDocumentModal