import { useSelector } from "react-redux"
import { InputItem } from "./EstateInputItem";

export const SelectFamilyMember = ({ docType, field, label, style, version, showBorder, withoutPages, message, only, indent, multiple }) => {
    const client = useSelector(state => state.currentClient);

    let options = client?.estateContactsList
        ?.map(contact => ({
            key: contact._id,
            value: contact._id,
            text: `${contact.firstName} ${contact.lastName} [${contact.relation}]`,
        })) || [];

    if (only) {
        switch (only) {
            case 'self and spouse':
                options = options.filter(c => c.text.includes('[Self]') || c.text.includes('[Spouse]'))
                break;

            default:
                break;
        }
    }

    return (
        multiple
        ? <InputItem
            docType={docType}
            label={label}
            nOptions={[{ key: '', value: '', text: '' }, ...options] || []}
            field={field}
            style={style || {}}
            withoutPages={withoutPages}
            ignoreBorder={!showBorder}
            version={version || [1]}
            message={message || ''}
            indent={indent || undefined}
            multiple
        />
        : <InputItem
            docType={docType}
            label={label}
            nOptions={[{ key: '', value: '', text: '' }, ...options] || []}
            field={field}
            style={style || {}}
            withoutPages={withoutPages}
            ignoreBorder={!showBorder}
            version={version || [1]}
            message={message || ''}
            indent={indent || undefined}
        />
    );
}