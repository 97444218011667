import { useEffect } from "react";
import { RootRouter } from "./routes/RootRouter"

export const App = () => {

    const disableScrollInManualInput = () => {
        const numberInputs = document.querySelectorAll('input[type=number]');
        console.log(`Inputs with type number: ${numberInputs.length}, disable the scroll or wheel`)

        numberInputs.forEach(input => {
            input.addEventListener('wheel', function(event) {
                event.preventDefault();
            });
        });

        setTimeout(() => {
            disableScrollInManualInput();
        }, 10000)
    }

    useEffect(() => {
        disableScrollInManualInput()
        // eslint-disable-next-line
    },[])

    return (
        <>
            <RootRouter />
        </>
    )
}