/* eslint-disable no-mixed-operators */
import { v4 as uuid } from 'uuid';
import { DashboardTemplate } from '../templates/DashboardTemplate'
import { TabContainer } from '../components/shared/TabContainer'
import { Button, Container, Grid, Icon, Menu, Message } from 'semantic-ui-react';
import { useEffect, useState } from 'react';
import { Will } from '../components/forms/WillForm';
import { Redirect, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addEstateManualInputDocument, fetchManualInputDataPoints, finishReview, removeEstateManualInputDocument, saveManualInputForLater } from '../actions/estate-manual-input.actions';
// import { sysAPI } from '../apis/SysAPI';
import { FamilyMembersModal } from '../components/modal/FamilyMembersModal';
import { fetchCurrentClient } from '../actions/client.actions';
import { AssetsModal } from '../components/modal/AssetsModal';
import { Trust } from '../components/forms/TrustForm';
import { authAPI } from '../apis/AuthAPI';
import { General } from '../components/forms/General';
import { PoaForm } from '../components/forms/PoaForm';
import { HealthCareForm } from '../components/forms/HealthCareForm';
import { Page404 } from './404';
import { sysAPI } from '../apis/SysAPI';
import ButtonChangeStatus from '../components/manualInputs/ButtonChangeStatus';
import ReAssignReviewers from '../components/manualInputs/ReAssignReviewers';


const FormContainer = (props) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '80%' }}>
                {props.children}
            </div>
        </div>
    )
}

export const EstateManualInput = () => {
    const params = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const dispatch = useDispatch();

    // const fileInput = useRef();
    // const [fileError, setFileError] = useState(null);
    // const [fileName, setFileName] = useState(null);
    // const [fileDocument, setFileDocument] = useState(null);
    const [showFamilyModal, setShowFamilyModal] = useState(false);
    const [showEntitiesModal, setShowEntitiesModal] = useState(false);
    const [showAssetsModal, setShowAssetsModal] = useState(false);
    const [activeTab, setActiveTab] = useState('will');
    const [allowSend, setAllowSend] = useState(false);

    const estateManualInputRequest = useSelector(state => state.estateManualInputRequest);
    const estateManualInputDocuments = useSelector(state => state.estateManualInputDocuments);
    const estateManualInputChangeDocuments = useSelector(state => state.estateManualInputChangeDocuments);
    const estateManualInput = useSelector(state => state.estateManualInput);
    const me = useSelector(state => state.me);
    const client = useSelector(state => state.currentClient);

    const [clientId, setClientId] = useState('');
    const [assetIndex, setAssetIndex] = useState(1);
    const [finished, setFinished] = useState(false);
    const versions = [1, 2, 3];

    const getData = async () => {
        try {
            const { clientId, assetIndex } = params;
            await sysAPI.get(`/clients/${clientId}`);
            setClientId(clientId);
            setAssetIndex(assetIndex);
            dispatch(fetchManualInputDataPoints(clientId, assetIndex));
            dispatch(fetchCurrentClient(clientId));
            // console.log({estateManualInputRequest})
        } catch (error) {
            setClientId("null")
        }
    }
    const verifyRequiredTrustFields = () => {
        const trustsDocs = [];
        let canSend = false;

        Object.keys(estateManualInputDocuments).forEach(itm => {
            if (estateManualInputDocuments?.[itm]?.type === 'Trust') {
                trustsDocs.push(itm);
            }
        });

        if (trustsDocs.length === 0) {
            canSend = true
        } else {
            let allowCounter = 0;
            trustsDocs.forEach(itm => {
                const type = estateManualInput?.[itm]?.type || '';
                const subtype = estateManualInput?.[itm]?.subtype || '';
                if (type?.length > 0 && subtype?.length > 0) {
                    allowCounter += 1;
                }
            });

            if (allowCounter === trustsDocs.length) {
                canSend = true;
            }
        }

        return canSend;
    };

    useEffect(() => {
        const trustRequired = verifyRequiredTrustFields();

        setAllowSend(trustRequired);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [estateManualInput]);

    const tabs = {
        // will: { label: 'Will', component: <Will />, id: 'will' }
        // eslint-disable-next-line
        ...(estateManualInputDocuments && Object.keys(estateManualInputDocuments).reduce((docs, docId, i) => ({ ...docs, [docId]: {
            label: (() => {
                if (estateManualInputDocuments?.[docId]?.type === 'Trust') {
                    const name = client?.estateContactsList
                        ?.find(c => c._id === estateManualInput?.[`${docId}`]?.name)?.firstName
                        || `${estateManualInputDocuments[docId].type} ${i + 1}`;
                    const type = estateManualInput?.[docId]?.type;
                    return type ? `${name} [${type}]` : name;
                }
                else if (estateManualInputDocuments?.[docId]?.type === 'Will') {
                    const ownerId = estateManualInput?.[docId]?.willOwner;
                    const owner = client?.estateContactsList?.find(c => c._id === ownerId);
                    return owner ? `${owner.firstName} ${owner.lastName}'s Will` : `Will ${i + 1}`;
                }
                else if (versions.includes(estateManualInputRequest.estateSnapshotVersion) && estateManualInputDocuments?.[docId]?.type === 'POA') {
                    return 'POA'
                }
                else if (versions.includes(estateManualInputRequest.estateSnapshotVersion) && estateManualInputDocuments?.[docId]?.type === 'Health Care') {
                    return 'Health Care'
                }
                else if (versions.includes(estateManualInputRequest.estateSnapshotVersion) && estateManualInputDocuments?.[docId]?.type === 'General') {
                    return 'General'
                }
            })(),
            component: estateManualInputDocuments[docId].type === 'Trust'
                ? <Trust docId={docId} docType={estateManualInputDocuments[docId].type} isJoint={estateManualInputDocuments[docId].isJoint} />
                : estateManualInputDocuments[docId].type === 'Will'
                    ? <Will docId={docId} />
                    : estateManualInputDocuments[docId].type === 'POA'
                        ? <PoaForm docId={docId} />
                        : estateManualInputDocuments[docId].type === 'Health Care'
                            ? <HealthCareForm docId={docId} />
                            : <General docId={docId}/>,
            id: docId,
            // eslint-disable-next-line
        } }), {}) || {}),
    };

    useEffect(() => {
        getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const panes = Object.keys(tabs).map(docId => ({ id: tabs[docId].id, menuItem: tabs[docId].label, render: <FormContainer>{tabs[docId].component}</FormContainer> }));

    /* const cloneSpouseDataBtn_Click = () => {
        sysAPI.post('/estate-manual-input/clone-spouse', {
            clientId,
            index: assetIndex,
        }).then(response => {
            if (response.data.status === 'SourceDataNotFound') {
                return window.alert('No information to clone was found.');
            }
            
            dispatch(fetchManualInputDataPoints(clientId, assetIndex));
        }).catch(err => {
            console.error(err);
        });
    }; */

    const addTrustBtn_Click = () => {
        const id = uuid();
        dispatch(addEstateManualInputDocument('Trust', id));
    };

    const addWillBtn_Click = () => {
        const id = uuid();
        dispatch(addEstateManualInputDocument('Will', id));
    };

    const addPOABtn_Click = () => {
        const id = uuid();
        dispatch(addEstateManualInputDocument('POA', id));
    };

    const addHealthCareBtn_Click = () => {
        const id = uuid();
        dispatch(addEstateManualInputDocument('Health Care', id));
    };

    const removeTrustBtn_Click = (id) => {
        return () => dispatch(removeEstateManualInputDocument(id));
    };

    const openPreview = () => {
        authAPI.post('/single-token', {
            clientId,
            reviewer: estateManualInputRequest.reviewer,
            advisor: estateManualInputRequest.advisor,
            index: assetIndex
        }).then(response => {
            window.open(`${process.env.REACT_APP_FPALPHA_URL}/external-snapshot-preview?token=${response.data}&area=estate`,'_blank')
        })
    }

    const HeaderOptions = () => {
        return (<div>
            {((estateManualInputRequest?.status === 'In review' && estateManualInputRequest.reviewer._id === me._id) || estateManualInputRequest?.status === 'Peer review') &&
                <div style={{textAlign: 'right', marginBottom: '1rem'}}>
                    <Button color='grey' onClick={saveForLaterBtn_Click}>Save for later</Button>
                    <Button style={{ paddingLeft: '50px', paddingRight: '50px' }} disabled={!allowSend} color='green' onClick={finishReviewBtn_Click}>Finish review</Button>
                </div>     
            }

            <div style={{display:"flex", padding: "5px 0px", justifyContent:"flex-end"}}>
                <div>
                    <ButtonChangeStatus area={"estate"} clientId={clientId} assetIndex={assetIndex} />
                </div>
                <div>
                    <ReAssignReviewers area={"estate"} clientId={clientId} assetIndex={assetIndex} />
                </div>
            </div>

            <div style={{textAlign: 'right', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-end', rowGap: '0.5rem'}}>
                <Button color='red' onClick={() => openPreview()}>
                    Preview
                </Button> 
                <Button color='orange' onClick={() => setShowFamilyModal(true)}>
                    Add Persons
                </Button>
                <Button color='purple' onClick={() => setShowEntitiesModal(true)}>
                    Add Entities
                </Button>
                <Button color='green' onClick={() => setShowAssetsModal(true)}>
                    Assets
                </Button>
                {/* estateManualInputRequest?.status === 'In review' && estateManualInputRequest.reviewer._id === me._id &&
                    <Button color='grey' onClick={cloneSpouseDataBtn_Click}>
                        Clone data from spouse
                    </Button>
                 */}
                {(estateManualInputRequest?.status !== 'Ready' || (estateManualInputRequest?.status === 'Ready' && me.permissions.includes('manual_inputs:open_documents'))) &&
                    <Button color='blue' onClick={() => window.open(`/dashboard/estate-manual-input/${clientId}/${assetIndex}/files`)}>
                        Open documents
                    </Button>
                }
            </div>
        </div>)
    }

    const saveForLaterBtn_Click = () => {
        dispatch(saveManualInputForLater(clientId, assetIndex));
    }

    const finishReviewBtn_Click = async () => {
        const confirm = window.confirm('This action will set as concluded the reviewing phase. Do you want to confirm this action?');
        if (confirm) {
            try {
                const forceFinish = queryParams.get('forceFinish');
                const finishReviewData_ = finishReview(clientId, assetIndex, 'estate', {
                    reviewer: estateManualInputRequest.reviewer,
                    advisor: estateManualInputRequest.advisor,
                    ignoreValidation: forceFinish === "true"
                })
                await dispatch(finishReviewData_)
                setFinished(true);
            } catch (error) {
                console.error({error})
            }
        }
    }

    if (finished) {
        return <Redirect to='/dashboard/manual-input' />
    }

    if([null, "null"].includes(clientId)){
        return <Page404 />
    }

    // const onDragOver = e => {
    //     e.preventDefault()
    // }

    // const onDragEnter = e => {
    //     e.preventDefault()
    // }

    // const onDragLeave = e => {
    //     e.preventDefault()
    // }

    // const fileInputClicked = e => {
    //     e.preventDefault()
    //     fileInput.current.click()
    // }

    // const fileDrop = (e) => {
    //     e.preventDefault()
    
    //     console.log(e.dataTransfer.files)
    //     console.log(e.dataTransfer.files[0].type)

    //     if('application/pdf' === e.dataTransfer.files[0].type) {
    //         setFileDocument(e.dataTransfer.files[0])
    //         setFileName(e.dataTransfer.files[0].name)
    //         setFileError(null)
    //     } else {
    //         setFileDocument(null)
    //         setFileName(null)
    //         setFileError('Invalid file type')
    //     }
    // }

    // const filesSelected = () => {
    //     if (fileInput.current.files.length) {
    //         if ('application/pdf' === fileInput.current.files[0].type) {
    //             setFileDocument(fileInput.current.files[0]);
    //             setFileName(fileInput.current.files[0].name);
    //             setFileError(null);
    //         } else {
    //             setFileDocument(null)
    //             setFileName(null)
    //             setFileError('Invalid file type')
    //         }
    //         console.log("FILE", fileInput.current.files)
    //     }
    // }

    // const uploadDeliverableBtn_Click = (e) => {
    //     if (!fileDocument) {
    //         return window.alert('Select a document for uploading the deliverable.');
    //     }

    //     const formData = new FormData();
    //     formData.append('file', fileDocument);
    //     formData.append('clientId', clientId);
    //     formData.append('index', assetIndex);

    //     sysAPI.post('/estate-manual-input/deliverable', formData).then(response => {
    //         console.log(response);
    //         window.alert('Deliverable sent correctly. This manual input has been marked as Ready.');
    //         setFinished(true);
    //     }).catch(err => {
    //         console.error(err);
    //     })
    // }

    const showV3 = estateManualInputRequest.estateSnapshotVersion === 3;

    return (
        <DashboardTemplate scope="manual_input">
            <TabContainer
                title={`Estate Manual Input for ${estateManualInputRequest?.client?.displayName}`}
                subtitle='Form for Estate documents such as wills, trusts, ILIT, etc.'
                headerOptions={<HeaderOptions />}
            >
                <Grid>
                    <Grid.Column width={4}>
                        <Menu fluid vertical>
                            {panes.map(pane =>
                                <Menu.Item name={pane.menuItem} active={activeTab === pane.id} onClick={() => setActiveTab(pane.id)} style={{boxShadow: estateManualInputChangeDocuments && `${pane.id}` in estateManualInputChangeDocuments ? 'rgba(21, 97, 198, 0.5) 0px 1px 10px' : 'none'}}>
                                    {pane.menuItem}
                                    {pane.menuItem !== 'General' && <Icon name='delete' onClick={removeTrustBtn_Click(pane.id)} />}
                                </Menu.Item>
                            )}
                        </Menu>
                        <Button onClick={addWillBtn_Click} style={{ marginBottom: '6px' }}>
                            Add will
                        </Button>
                        <Button onClick={addTrustBtn_Click} style={{ marginBottom: '6px' }}>
                            Add trust
                        </Button>
                        <Button onClick={addPOABtn_Click} style={{ marginBottom: '6px', display: showV3 ? 'inline-block' : 'none' }} >
                            Add POA
                        </Button>
                        <Button onClick={addHealthCareBtn_Click} style={{ marginBottom: '6px', display: showV3 ? 'inline-block' : 'none' }} >
                            Add Health Care
                        </Button>
                    </Grid.Column>
                    <Grid.Column width={12}>
                        {panes.find(p => p.id === activeTab)?.render}
                    </Grid.Column>
                </Grid>
                {((estateManualInputRequest?.status === 'In review' && estateManualInputRequest.reviewer._id === me._id) || estateManualInputRequest?.status === 'Peer review') &&
                    <Container fluid textAlign='right' style={{ marginTop: '20px' }}>
                        <Button color='grey' onClick={saveForLaterBtn_Click}>Save for later</Button>
                        <Button style={{ paddingLeft: '50px', paddingRight: '50px' }} disabled={!allowSend} color='green' onClick={finishReviewBtn_Click}>Finish review</Button>
                    </Container>
                }
                {estateManualInputRequest?.status === 'Ready' &&
                    <Message color='green'>This manual input has already been marked as REVIEWED.</Message>
                }
                {/* {estateManualInputRequest?.status === 'Reviewed' && // FOR UPLOADING DOCUMENT
                    <div style={{margin: '2rem 0 0', display: 'flex', columnGap: '2rem', alignItems: 'center', justifyContent: 'center'}}>
                        <div
                            style={{
                                margin: '0',
                                padding: '1.5rem 0',
                                minHeight: '12rem',
                                width: '50%',
                                border: '1px dashed gray',
                                borderRadius: '5px',
                                display: 'flex',
                                flexDirection: 'column',
                                rowGap: '1.5rem',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                            }}
                            onDragOver={onDragOver}
                            onDragEnter={onDragEnter}
                            onDragLeave={onDragLeave}
                            onDrop={fileDrop}
                        >
                            <span style={{color: fileError ? 'red' : 'black'}}>
                                <i aria-hidden="true" class={`${fileError ? 'x' : 'upload'} icon`}></i>
                                {fileName || fileError || 'Drop your Deliverable document in PDF file format.'}
                            </span>
                            <input
                                ref={fileInput}
                                style={{display: 'none'}}
                                type="file"
                                onChange={filesSelected}
                            />
                            <button className="ui blue icon right labeled button" onClick={fileInputClicked}>
                                Select Deliverable
                                <i aria-hidden="true" class="file icon"></i>
                            </button>
                        </div>
                        <button onClick={uploadDeliverableBtn_Click} className="ui blue button" style={{maxHeight: '36px'}}>
                            Upload Document
                        </button>
                    </div>
                } */}
            </TabContainer>
            <FamilyMembersModal open={showFamilyModal} closeBtn_Click={() => setShowFamilyModal(false)} editingEnabled={estateManualInputRequest?.status === 'In review' || estateManualInputRequest?.status === 'Peer review'} />
            <FamilyMembersModal type={'trusts'} open={showEntitiesModal} closeBtn_Click={() => setShowEntitiesModal(false)} editingEnabled={estateManualInputRequest?.status === 'In review' || estateManualInputRequest?.status === 'Peer review'} />
            <AssetsModal open={showAssetsModal} closeBtn_Click={() => setShowAssetsModal(false)} editingEnabled={estateManualInputRequest?.status === 'In review' || estateManualInputRequest?.status === 'Peer review'} />
        </DashboardTemplate>
    )
}