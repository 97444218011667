export const currentAdvisorProfileReducer = (currentAdvisorProfile = {}, action) => {
    switch (action.type) {
        case 'set_current_advisor_profile':
            return action.payload;
        default:
            return currentAdvisorProfile;
    }
}

export const currentAdvisorActivityReducer = (currentAdvisorActivity = {}, action) => {
    switch (action.type) {
        case 'set_current_advisor_activity': {
            const auxActivity = { ...currentAdvisorActivity };
            auxActivity[action.payload.section] = action.payload.activity;
            return auxActivity;
        }
        default:
            return currentAdvisorActivity;
    }
}

export const currentAdvisorClientsReducer = (currentAdvisorClients = [], action) => {
    switch (action.type) {
        case 'set_current_advisor_clients': {
            return action.payload;
        }
        default: {
            return currentAdvisorClients;
        }
    }
}