// eslint-disable-next-line
export default {
  clientIDIfIsSelfEmployedOrBusinessOwnerAndExpectsIncomeOf200000ForNextThreeYears: '',
  clientIDContributingToSEPIRA: '',

  // --> Income
  clientIDIncomeExpectationsFollowingYear: '',
  clientIDExpectsToHaveLargerIncomeGoingForward: '',
  clientIDExpectsToHaveWayLowerIncomeGoingForward: '',
  clientID2018Income: '0',
  clientIDIncomeREITorQPTP: '',
  clientIDIncomeREITorQPTP_2: '',
  /// -> Internal use only no rec triggered
  clientIDHasQuestionsAboutTaxRecordRetention: '',
  clientIDOwnAnyStocks: '',

  // --> Business Owner
  clientPrincipalAssetIsEmpoyees: '',
  clientIDBusinessProvideProfessionalConsultingServices: '',
  clientIDWorksAsAProfessionalInYourOwnSCorporation: '',
  clientIDWorksInOwnBusinessWhichRetainsIncome: '',
  clientIDEmployerDomestic: '',
  clientIDSelfEmployedOrSCorp: '',
//   clientIDSelfEmployedOrSCorp_2: '',
  clientIDBusinessProvidesMoreThan1OtherBusinessActivity: '',
  clientIDValuableAssetsToSellIn5YearsOrMore: '',
  clientIDFormingBusinessLeverageRealEstate: '',
  clientIDFormingInvestorsCTrustForeign: '',
  clientIDFutureExpectedLosses: '',
  clientIDHomeOfficeSqft: '',
  clientIDHomeOffice: '',
  clientIDClubOrg: '',
  clientIDMantainRetPlanWithholdWage: '',
  clientIDSelfEMployedAlone: '',
  clientIDExpensesMealsEntertain: '',
  clientIDHiredEmployees: '',
  clientIDIncomeProfession: '',
  clientIDCapitalizingBusiness: '',
  clientIDCCorpCapitalizingBusiness: '',
  clientIDCarPersonalBusiness: '',
  clientIDExpenseCarriedFuture: '',
  /// -> New questions Cares act (Aug 10 2020 - RT)
  clientIDEmployeePayrollTaxes: '',
  clientIDEmployeeCovidExpenses: '',
  clientIDEmployeeCovidWages: '',

  // --> Charity and gifts
  clientIDHasQuestionsAboutCharitableGifting: '', /// -> Internal use only no rec triggered
  clientIDWillingToDonate5000: '',
  clientIDCharitableGiftsTicketsorMembership: '',
  clientIDHouseOrFarmToCharity: '',
  clientIDGiftToPayEducation: '',
  clientIDGiftToDisabled: '',
  clientIDFinanciallySupportsToAdultWhoIsOver18: '',
//   clientIDFinanciallySupportsToAdultWhoIsOver18_2: '',
  clientID2020ItemizedCharitableDonations: '', /// -> New question Cares act (Aug 10 2020 - RT)
  clientIDSignificantCharitableDonations: '', /// -> New question Cares act (Aug 10 2020 - RT)

  // --> Investment
  clientIDShortTermCapGainAndUnrealizedLosses: '',
//   clientIDShortTermCapGainAndUnrealizedLosses_2: '',
  clientIDNetLossesCapGainLosses: '',
//   clientIDNetLossesCapGainLosses_2: '',
  clientIDHasUnrealizedLosses: '',
  clientIDHasUnrealizedLosses_2: '',
  clientIDSellAtLoss: '',
  clientIDSellAtLoss_2: '',
  clientIDLongTimeHorizon: '',
  clientIDLongTimeHorizon_2: '',
  clientIDHasOldTradeBusinessStock: '',
  clientIDHasOldTradeBusinessStock_2: '',
  clientIDLittleValueNonLiquidAssets: '',
  clientIDLittleValueNonLiquidAssets_2: '',
  clientHasForm1040SchedCLineG: '',
  clientIDHasForm1040SchedCLineG_2: '',
  clientIDHasInvestmentIncome: '',
  clientIDHasInvestmentIncome_2: '',
  clientIDInvestmentInterestExpenseCarryover: '',
  clientIDInvestmentInterestExpenseCarryover_2: '',
  clientIDPurchaseInvestmentNotSold: '',
  clientIDPurchaseInvestmentNotSold_2: '',
  clientIDPlansToHaveGainsOrLossesFromRealEstateArtworkETCInNearFuture: '',
  clientIDPlansToHaveGainsOrLossesFromRealEstateArtworkETCInNearFuture_2: '',

  // --> Employer
  clientIDEmployerDependentCareBenefit: '',
//   clientIDEmployerDependentCareBenefit_2: '',
  clientIDEmployerHealthCareBenefit: '',
//   clientIDEmployerHealthCareBenefit_2: '',
  clientIDEmployerFSA: '',
//   clientIDEmployerFSA_2: '',
  clientIDStocOptCompPack: '',
//   clientIDStocOptCompPack_2: '',
  clientIDStockOptCorpEmployer: '',
//   clientIDStockOptCorpEmployer_2: '',
  clientIDISOEmployer: '',
//   clientIDISOEmployer_2: '',
  clientIDExerciseISO: '',
//   clientIDExerciseISO_2: '',
  clientIDExcersiceISOPrior: '',
//   clientIDExcersiceISOPrior_2: '',
  clientIDTerminateEmployISO: '',
  clientIDTerminateEmployISO_2: '',
  clientIDVestProperty: '',
//   clientIDVestProperty_2: '',
  clientIDIndependentContractorISO: '',
//   clientIDIndependentContractorISO_2: '',
  clientIDIsOnMedicare: '',
//   clientIDIsOnMedicare_2: '',
  clientIDHasOptionToChooseHighDeductibleMedicalPlan: '',
//   clientIDHasOptionToChooseHighDeductibleMedicalPlan_2: '',
  clientIDIsOnHighDeductibleMedicanPlan: '',
//   clientIDIsOnHighDeductibleMedicanPlan_2: '',
  clientIDIsMaximizingPermittedContributionstoHealthSavingsAccount: '',
//   clientIDIsMaximizingPermittedContributionstoHealthSavingsAccount_2: '',

  // --> Retirement
  clientIDAboutToRetire: '',
  clientIDAboutToRetire_2: '',
  clientIDHasAccessToWorkplaceRetirementPlan: '',
//   clientIDHasAccessToWorkplaceRetirementPlan_2: '',
  clientIDIsMaximizingContributions: '',
//   clientIDIsMaximizingContributions_2: '',
  clientIDHasOrSpouseAccessToRoth401k: '',
  clientIDPlanPermitsToMakeAfterTaxContributionsTo401k: '',
//   clientIDPlanPermitsToMakeAfterTaxContributionsTo401k_2: '',
  clientIDHasAccessToFlexibleSpendingAccount: '',
//   clientIDHasAccessToFlexibleSpendingAccount_2: '',
  clientIDIsParticipatingInFSAAndMatchingContributionsToExpectedHealthExpenses: '',
//   clientIDIsParticipatingInFSAAndMatchingContributionsToExpectedHealthExpenses_2: '',
  clientIDWorksForNonProfitOrGovernmentalEntity: '',
//   clientIDWorksForNonProfitOrGovernmentalEntity_2: '',
  clientIDHasAccessTo457Plan: '',
//   clientIDHasAccessTo457Plan_2: '',
  clientIDIsMaximizingContributionsOn457Plan: '',
//   clientIDIsMaximizingContributionsOn457Plan_2: '',
  clientIDHasTraditionalOrRolloverIRA: '',
//   clientIDHasTraditionalOrRolloverIRA_2: '',
  clientIDWithdrawNoPenalty: '',
//   clientIDWithdrawNoPenalty_2: '',
  clientIDDistPrincipalResidence: '',
//   clientIDDistPrincipalResidence_2: '',
  clientIDDistMedExpenses: '',
//   clientIDDistMedExpenses_2: '',
  clientIDDistHealthInsurance: '',
//   clientIDDistHealthInsurance_2: '',
  clientIDDistEducExpense: '',
//   clientIDDistEducExpense_2: '',
  clientIDTakeDist: '',
//   clientIDTakeDist_2: '',
  clientID70WorkNoOwner: '',
//   clientID70WorkNoOwner_2: '',
  clientIDExpensesIRA: '',
//   clientIDExpensesIRA_2: '',
  clientIDBorrowFromRetPlan: '',
//   clientIDBorrowFromRetPlan_2: '',
  clientIDConsideringContributionIRA: '',
//   clientIDConsideringContributionIRA_2: '',
  clientIDIncomeHighToRothIRA: '',
//   clientIDIncomeHighToRothIRA_2: '',
  clientIDContributionNonWorkingSpouseIRA: '',
//   clientIDContributionNonWorkingSpouseIRA_2: '',
  clientIDIRAInvestCollectibles: '',
//   clientIDIRAInvestCollectibles_2: '',
  clientIDIRAInvestMasterLtdPartner: '',
//   clientIDIRAInvestMasterLtdPartner_2: '',
  clientIDTrusteeOwnRetPlan: '',
//   clientIDTrusteeOwnRetPlan_2: '',
  clientIDIRAInvestIliquidAssets: '',
//   clientIDIRAInvestIliquidAssets_2: '',
  clientIDSEPSameRulesPSP: '',
//   clientIDSEPSameRulesPSP_2: '',
  clientIDSetRAForYearEnded: '',
//   clientIDSetRAForYearEnded_2: '',
  clientIDDistDeathDisab: '',
//   clientIDDistDeathDisab_2: '',
  clientIDRMDRetPlan: '', /// -> New question Cares act (Aug 10 2020 - RT)
  clientIDHasAccessTo401KPlan: '',

  // --> Social Security
  clientIDPayMedicare: '',
//   clientIDPayMedicare_2: '',
  clientIDMoreMinimumMedicare: '',
//   clientIDMoreMinimumMedicare_2: '',
  clientIDOutBD: '',
//   clientIDOutBD_2: '',
  clientIDWorkProvideMedicare: '',
//   clientIDWorkProvideMedicare_2: '',
  clientIDSSBenDefer: '',
//   clientIDSSBenDefer_2: '',

  // --> Family
  clientIDChildrenCreditAllowed: '',
//   clientIDChildrenCreditAllowed_2: '',
  clientIDSellingExchangeProperty: '',
//   clientIDSellingExchangeProperty_2: '',
  clientIDQualifyingExpensesIncurred: '',
//   clientIDQualifyingExpensesIncurred_2: '',
  clientIDNewChildCredit2000: '',
//   clientIDNewChildCredit2000_2: '',
  clientIDIncomeNetInvestmentTaxLowChildIncome: '',
//   clientIDIncomeNetInvestmentTaxLowChildIncome_2: '',
  clientIDSavingsLowRateChildHighInterest: '',
//   clientIDSavingsLowRateChildHighInterest_2: '',
  clientIDHaveBusinessChildWOrk: '',
//   clientIDHaveBusinessChildWOrk_2: '',
  clientIDSelfEmployChildAbleWork: '',
//   clientIDSelfEmployChildAbleWork_2: '',

  // --> Children
  clientIDHasAccessThroughEmployerToDependentCareAccount: '',
//   clientIDHasAccessThroughEmployerToDependentCareAccount_2: '',
  clientIDIsMaximizzingContributionsDependentCareAccount: '',
//   clientIDIsMaximizzingContributionsDependentCareAccount_2: '',

  // --> Tuition
  clientIDWillBePayingForTuitionOfChildrenOrGrandchildren: '',
  clientIDWillBePayingForPrivateTuitionOfChildrenOrGrandchildren: '',
  clientIDIsTheirTuitionFundedFullyFundedThrough529PlanAccounts: '',
  clientIDHasMoneyBeenContributedTo529PlanToFundUpTo10000AYearOfExpenses: '',

  // --> Lending
  clientIDPayStudentForDeductionJointly: '',
//   clientIDPayStudentForDeductionJointly_2: '',
  clientIDNotgiftButLoan: '',
//   clientIDNotgiftButLoan_2: '',
  clientIDLoanForResidence: '',
//   clientIDLoanForResidence_2: '',
  clientIDForgiveLoanAsGift: '',
//   clientIDForgiveLoanAsGift_2: '',
  clientIDPayStudentLoanInterestsOtherIncurred: '',
//   clientIDPayStudentLoanInterestsOtherIncurred_2: '',
  clientIDInterestDebtBuyInv: '',
//   clientIDInterestDebtBuyInv_2: '',
  clientIDBorrowInvestPersonal: '',
//   clientIDBorrowInvestPersonal_2: '',
  clientIDBorrowBuySecuritiesInvestLtdPart: '',
//   clientIDBorrowBuySecuritiesInvestLtdPart_2: '',
  clientIDInvestmentIncomePayPersonalDebt: '',
//   clientIDInvestmentIncomePayPersonalDebt_2: '',
  clientIDInterestExpenseInvestmentMunicipal: '',
//   clientIDInterestExpenseInvestmentMunicipal_2: '',
  clientIDBadDebtLosses: '',
//   clientIDBadDebtLosses_2: '',

  // --> Other
  clientIDDoYouHaveAnInterestInRentalProperty: '',
  clientIDWorthlessInvestments: '',
  clientIDSpouseUnknowledgeableOtherIncomeOrReporting: '',
  clientIDSpouseUnknowledgeableOtherIncomeOrReporting_2: '',
  clientIDMoveToLowerTaxState: '',
  clientIDABLEAccountAGI: '',
  clientIDBuyElectirCar: '',
  clientIDSubjectToAMT: '',
  clientIDTheftCasualtyLosses: '',
  clientIDPayTaxPreparationPlanning: '',
  clientIDUSInvestmentIncomeLimitedIncome: '',
  clientIDSeparateMAGI: '',
  clientIDIsNonResidentAlienOrHaveFinancialAccountOutsideUS: '', /// -> Internal use only no rec triggered
  clientIDForeignResident: '',
  clientIDIncomeForeignAssets: '',
  clientIDForeignBalance: '',
  clientIDMarriedAlien: '',
  clientIDImproveNoResidential: '',
  clientIDInvestmentExpenses: '',
  clientIDHasLTMedInsurance: '',

  // --> Advisor section
  advisorIDYearToDateRealizedGains: '0',
  clientIDInvManagerManagesClientsIRAAndManagementFeesDeductedProrataBasis: '',
  clientIDRealizedLongTermCapitalGains: '0',
  advisorIDUnrealizedLongTermCapitalGains: '',
  advisorIDUnrealizedLongTermCapitalGainsAmount: '0',
  advisorIDUnrealizedLongTermCapitalLosses: '',
  advisorIDClientsHasSeparatelyManagedTaxableInvAccount: '',
  advisorIDOneSpouseWorks: '',
  advisorIDClientHasSubstantialPortfolioLargerThanNeeds: '',
  clientIDDualResident: '',
  clientIDResidentAlien: '',
  advisorIDDoesClientHasMunicipalBonds: '',
  clientIDBusinessNY: '',
  clientIDCorporationqualifiesAsSCorp: '',
  clientIDEmployeeBenefitsAsC: '',
  clientIDLossesWagesControlledCorp: '',
  clientIDPersonalPropertyBusiness: '',
  clientIDBusinessMaintainInventory: '',
  clientIDLineBusiness: '',
  clientIDNOLCarryover: '',
  clientIDRDExpenses: '',
  clientIDOperatesBusinessTaxedAsCCorpAndNotLikelyToBeInHighTaxBraket: '',
  clientIDPartnerOfLLCAndReceiveAGuaranteedPaymentForServices: '',
  clientIDBuyingAssetsUnincorporatedBusiness: '',
  clientIDPartnershipsDepreciate: '',
  clientIDBusinessLoss: '',
  clientIDRelatedLendBorrow: '',

  // --> Stranded
  clientIDOtherThanHouseOrFarmToCharity: ''
}