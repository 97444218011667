import moment from "moment";
import { useState } from "react"
import { Button, Input, Modal } from "semantic-ui-react"
import { sysAPI } from "../../apis/SysAPI";

export const UpdateRenewalDateModal = ({ advisor, setEditRenewal, updatePage }) => {
    const [renewalDate, setRenewalDate] = useState(moment(advisor?.renewalDate).format('YYYY-MM-DD'));

    const noRenewalDate = '1970-01-01';

    const noRenewalExpectedBtn_Click = () => {
        setRenewalDate(noRenewalDate);
    }; 

    const cancelBtn_Click = () => {
        setEditRenewal(null);
    };

    const udpateBtn_Click = () => {
        sysAPI.post(`/advisor/${advisor._id}/update`, {
            renewalDate,
        }).then(response => {
            if (response.status === 200) {
                setEditRenewal(null);
                updatePage();
            }
        }).catch(err => {
            window.alert(`Couldn't update renewal date for ${advisor.fullName}. Please try again later.`);
        });
    };

    return (
        <Modal open={advisor !== null} size='mini'>
            <Modal.Header>Update Renewal Date</Modal.Header>
            <Modal.Content>
                <p>Select the new renewal date for {advisor?.fullName}</p>
                <Input
                    type="date"
                    fluid
                    value={renewalDate}
                    onChange={(e, { value }) => setRenewalDate(value)}
                />
                <Button
                    fluid
                    onClick={noRenewalExpectedBtn_Click}
                    style={{ marginTop: '10px' }}
                >
                        Set advisor with no renewal expected
                </Button>
            </Modal.Content>
            <Modal.Actions>
                <Button color="grey" onClick={cancelBtn_Click}>Cancel</Button>
                <Button color="green" onClick={udpateBtn_Click}>Update</Button>
            </Modal.Actions>
        </Modal>
    )
}
