// eslint-disable-next-line
export default {
  clientIDClientDrivesAndDoesNotOwnCar: "",
  clientIDCarLastTimeShopped: "0", 
  clientIDInsurancePurchase: " ", // Espacio en blanco asignado como string con comillas
  clientIDCreditsAndDiscountsAuto: "",
  clientIDCarsOnSamePolicy: "",
  clientIDHasAAA: "",
  clientIDLowMileage: "0", 
  clientIDCarAge1: "0", 
  clientIDCarValue1: "0", 
  clientIDWouldRepairCar1: "",
}
