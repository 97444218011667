import { useState } from 'react';
import { Button } from 'semantic-ui-react';
import { advisorAPI } from '../../../../../apis/AdvisorsAPI';
import CardTaxMapper from './CardTaxMapper';
import { fetchCurrentCompany } from '../../../../../actions/company.actions';
import { useDispatch } from 'react-redux';
import AddNewMapper from './AddNewMapper';

const MethodPostTax = ({ companyProfile }) => {
  const dispatch = useDispatch();
  const [loaderActive, setLoaderActive] = useState(false);
  const [isActive, setIsActive] = useState(companyProfile?._integrations?.salesforce?.settings?.updateDataTax?.isActive || false);
  const taxMapper  = companyProfile?._integrations?.salesforce?.settings?.updateDataTax?.taxMapper || [];

  const disableMethodPostTax = async () => {
    setLoaderActive(true);
    try {
      await advisorAPI.put(`/companies/${companyProfile._id}`, {
        [`_integrations.salesforce.settings.updateDataTax.isActive`]: !isActive,
      });
      await dispatch(fetchCurrentCompany(companyProfile._id));
      setIsActive(!isActive);
    } catch (error) {
      console.error({ error });
      alert(error?.response?.data?.message || error?.message || "Error...");
    }
    setLoaderActive(false);
  };

  return (
    <div>
      <h2>Method Post Tax</h2>
      <div className='flex-gap-5'>
        <div>
          <Button color={isActive ? 'green' : 'red'} onClick={() => disableMethodPostTax()} loading={loaderActive}>
            {isActive ? 'Active' : 'No Active'}
          </Button>
        </div>
        <div>
          <AddNewMapper companyProfile={companyProfile} />
        </div>
      </div>
      <div className="flex-gap-5" style={{ gap: '25px', marginTop: '15px' }}>
        {taxMapper.map((e, index) => (
          <div key={index}>
            <CardTaxMapper data={e} index={index} companyProfile={companyProfile} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MethodPostTax;
