import { Button, Checkbox, Icon, Popup } from "semantic-ui-react";

const FormPermissions = ({permissions, setPermissions, defaultRoles}) => {
  const handleUpdatePermissions = (permission) => {
    let newPermissions = JSON.parse(JSON.stringify(permissions));
    if (newPermissions.includes(permission)) {
      newPermissions = newPermissions.filter((per) => per !== permission);
    } else {
      newPermissions.push(permission);
    }
    setPermissions(newPermissions);
  };

  const handleSelectAll = () => {
    let allPermissions = [];
    if (permissions.length <= 0) {
      for (const defRol of defaultRoles) {
        allPermissions = [...allPermissions, ...defRol.permissions.map((per) => per.key)];
      }
    }
    setPermissions(allPermissions);
  };

  const handleAddFontSize = (isChecked) => {
    const styles = {
      fontSize: '15px'
    }

    if(isChecked){
      styles.fontWeight = "bold";
      styles.fontSize = "'17px'";
    }

    return styles
  }

  return (
    <>
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h2>Permissions</h2>
          <div>
            <Button onClick={() => handleSelectAll()}>{permissions.length > 0 ? 'Unselect' : 'Select'} All</Button>
          </div>
        </div>
        <div
          style={{
            maxHeight: '400px',
            overflowY: 'auto',
            //border: "1px solid #ccc",
            padding: '2px',
          }}
        >
          {defaultRoles.map((role, index) => (
            <div key={index}>
              <h3 style={{ paddingTop: '10px' }}>{role.name}</h3>
              <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', gap: '10px' }}>
                {role.permissions.map((per, index_2) => (
                  <div key={index_2} style ={{
                    backgroundColor: "#0000002b",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
                    borderRadius: "10px",
                    padding: "5px 10px",
                    margin: "2px 5px",
                  }}>
                    <div style={{ display: 'flex', gap: '5px' }}>
                      <div>
                        <Checkbox
                          toggle
                          label={per.name}
                          onClick={() => handleUpdatePermissions(per.key)}
                          checked={permissions.includes(per.key)}
                          style={handleAddFontSize(permissions.includes(per.key))}
                        />
                      </div>
                      {per.description && (
                        <div>
                          <Popup content={per.description} trigger={<Icon name="help" />} />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              {index + 1 < defaultRoles.length && <hr />}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default FormPermissions;