import axios from 'axios';
import { fpalphaAPI } from './FPAlphaAPI';
import createAuthRefreshInterceptor from 'axios-auth-refresh';


export const authAPI = axios.create({
    baseURL: process.env.REACT_APP_AUTH_API_URL,
});

const refreshAuthLogic = async (failedRequest) => {
    const refreshToken = localStorage.getItem('refreshToken');
    console.log('Refreshing...' + refreshToken);

    return axios.post(`${fpalphaAPI.defaults.baseURL}/sys/auth/refresh`, { refreshToken }).then(response => {
        const { accessToken } = response.data;
        if (accessToken) {
            localStorage.setItem('accessToken', accessToken);
            return Promise.resolve();
        }

        return Promise.reject();
    }).catch(err => {
        console.log(err);
        // window.location = '/login?error=session_expired';
    });
}

createAuthRefreshInterceptor(authAPI, refreshAuthLogic);

authAPI.interceptors.request.use((request) => {
    const accessToken = localStorage.getItem('accessToken');
    request.headers.authorization = `Bearer ${accessToken}`;
    return request;
});