import { sysAPI } from "../apis/SysAPI";
import { advisorAPI } from "../apis/AdvisorsAPI";

export const fetchCurrentCompany = (companyId) => {
    return async (dispatch) => {
        try {
            const {data} = await sysAPI.get(`/companies-v2/${companyId}`);
            //console.log({data})
            //console.log("Se ejecuta?")
            //console.log({companyId})
            dispatch(setCurrentCompany(data.data));
        }
        catch (err) {
            console.error(err);
        }
    }
}

export const setCurrentCompany = (company) => {
    return {
        type: 'set_current_company',
        payload: company,
    };
}


export const setCurrentCompanyCredits = (data) => {
    return {
        type: 'set_current_company_credits',
        payload: data,
    };
};

export const setCurrentCompanyRequests = (data) => {
    return {
        type: 'set_current_company_requests',
        payload: data,
    };
};

export const fetchCurrentCompanyCredits = (companyId, area) => {
    return async (dispatch) => {
        try {
            if (area === 'all') {
                const all = await advisorAPI.get(`/companies/upload-credits?companyId=${companyId}`);
                dispatch(setCurrentCompanyCredits(all.data))
            } else {
                const { data } = await advisorAPI.get(`/companies/upload-credits?companyId=${companyId}&area=${area}`);
                dispatch(setCurrentCompanyCredits(data.data))
            }
        } catch (err) {
            console.log(err)
        }
    }
}

export const fetchCurrentCompanyRequests = (companyId, area) => {
    return async (dispatch) => {
        try {
            const { data } = await advisorAPI.get(`/companies/upload-credits/detail?companyId=${companyId}${area ? `&area=${area}` : ''}`);
            dispatch(setCurrentCompanyRequests(data))
        } catch (err) {
            console.log(err)
        }
    }
}