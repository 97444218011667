// eslint-disable-next-line
export default [
    {
        section: 'Form 1040',
        fields: [
            {
                name: 'wages',
                text: 'Wages',
                line: 'Line 1',
                type: 'number',
            },
            {
                name: 'taxExemptInterest',
                text: 'Tax-exempt interest',
                line: 'Line 2a',
                type: 'number',
            },
            {
                name: 'taxInterest',
                text: 'Taxable interest',
                line: 'Line 2b',
                type: 'number',
            },
            {
                name: 'qualifiedDividends',
                text: 'Qualified dividends',
                line: 'Line 3a',
                type: 'number',
            },
            {
                name: 'totalDividends',
                text: 'Ordinary dividends',
                line: 'Line 3b',
                type: 'number',
            },
            {
                name: 'iraDistribution',
                text: 'IRA distributions',
                line: 'Line 4a',
                type: 'number',
            },
            {
                name: 'taxableIRA',
                text: 'IRA distributions taxable amount',
                line: 'Line 4b',
                type: 'number',
            },
            {
                name: 'pension',
                text: 'Pensions and annuities',
                line: 'Line 5a',
                type: 'number',
            },
            {
                name: 'taxablePension',
                text: 'Pensions and annuities taxable amount',
                line: 'Line 5b',
                type: 'number',
            },
            {
                name: 'socialSecurityBenefits',
                text: 'Social security benefits',
                line: 'Line 6a',
                type: 'number',
            },
            {
                name: 'socialSecurity',
                text: 'Social security benefits taxable amount',
                line: 'Line 6b',
                type: 'number',
            },
            {
                name: 'capitalGainOrLoss',
                text: 'Capital gain or (loss)',
                line: 'Line 7',
                type: 'number',
            },
            {
                name: 'otherIncome',
                text: 'Other income',
                line: 'Line 8',
                type: 'number',
            },
            {
                name: 'totalIncome',
                text: 'Total income',
                line: 'Line 9',
                type: 'number',
            },
            {
                name: 'adjustedIncome',
                text: 'Adjustments to income',
                line: 'Line 10c',
                type: 'number',
            },
            {
                name: 'adjustedGrossIncome',
                text: 'Adjusted gross income',
                line: 'Line 11',
                type: 'number',
            },
            {
                name: 'standardDeductions',
                text: 'Standard deductions or itemized deductions',
                line: 'Line 12',
                type: 'number',
            },
            {
                name: 'charitableContributions1040',
                text: 'Charitable contributions',
                line: 'Line 10b',
                type: 'number',
            },
            {
                name: 'qbIncome',
                text: 'Qualified business income deductions',
                line: 'Line 13',
                type: 'number',
            },
            {
                name: 'sumDeductions',
                text: 'Sum of deductions',
                line: 'Line 14',
                type: 'number',
            },
            {
                name: 'taxableIncome',
                text: 'Taxable income',
                line: 'Line 15',
                type: 'number',
            },
            {
                name: 'taxes',
                text: 'Taxes',
                line: 'Line 16',
                type: 'number',
            },
            {
                name: 'line17_1040',
                text: 'Amount from schedule 2 line 3',
                line: 'Line 17',
                type: 'number',
            },
            {
                name: 'line18_1040',
                text: 'Add lines 16 and 17',
                line: 'Line 18',
                type: 'number',
            },
            {
                name: 'qualifyingDependentCreditClaimed',
                text: 'Nonrefundable child tax credit',
                line: 'Line 19',
                type: 'number',
            },
            {
                name: 'line20_1040',
                text: 'Amount from schedule 3 line 8',
                line: 'Line 20',
                type: 'number',
            },
            {
                name: 'line21_1040',
                text: 'Add lines 19 and 20',
                line: 'Line 21',
                type: 'number',
            },
            {
                name: 'line22_1040',
                text: 'Substract line 21 from line 18',
                line: 'Line 22',
                type: 'number',
            },
            {
                name: 'otherTaxesWithSelfEmployment',
                text: 'Other taxes',
                line: 'Line 23',
                type: 'number',
            },
            {
                name: 'totalTax',
                text: 'Total tax',
                line: 'Line 24',
                type: 'number',
            },
            {
                name: 'federalIncomeTaxWithheld',
                text: 'Federal income tax withheld from Form(s) W-2, 1099 and others',
                line: 'Line 25d',
                type: 'number',
            },
            {
                name: 'estimatedTaxPaymentsAmountForLastYear',
                text: '2021 estimated tax payments and amount applied from 2020 return',
                line: 'Line 26',
                type: 'number',
            },
            {
                name: 'earnedIncomeCredit',
                text: 'Earned income credit (EIC)',
                line: 'Line 27',
                type: 'number',
            },
            {
                name: 'additionalChildTaxCredit',
                text: 'Additional child tax credit',
                line: 'Line 28',
                type: 'number',
            },
            {
                name: 'americanOpportunityCreditTaken',
                text: 'American opportunity credit from Form 8863, line 8',
                line: 'Line 29',
                type: 'number',
            },
            {
                name: 'recoveryRebateCredit',
                text: 'Recovery rebate credit',
                line: 'Line 30',
                type: 'number',
            },
            {
                name: 'amountfromSchedule3line15',
                text: 'Amount from Schedule 3, line 15',
                line: 'Line 31',
                type: 'number',
            },
            {
                name: 'totalOtherPayments',
                text: 'Add lines 27 through 31. These are your total other payments and refundable credits',
                line: 'Line 32',
                type: 'number',
            },
            {
                name: 'totalPayments',
                text: 'Add lines 25d, 26, and 32. These are your total payments',
                line: 'Line 33',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule 1',
        fields: [
            {
                name: 'clientHasSchedule1',
                text: 'Has Schedule 1?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name:'taxableRefunds',
                text: 'Taxable refunds',
                line: 'Line 1',
                type: 'number',
            },
            {
                name: 'alimonyReceived',
                text: 'Alimony received',
                line: 'Line 2a',
                type: 'number',
            },
            {
                name: 'businessIncome',
                text: 'Business income/loss',
                line: 'Line 3',
                type: 'number',
            },
            {
                name: 'otherGainsOrLosses',
                text: 'Other gains or losses',
                line: 'Line 4',
                type: 'number',
            },
            {
                name: 'rentalRealEstate',
                text: 'Rental real estate',
                line: 'Line 5',
                type: 'number',
            },
            {
                name: 'farmIncome',
                text: 'Farm income',
                line: 'Line 6',
                type: 'number',
            },
            {
                name: 'unemployementCompensation',
                text: 'Unemployment compensation',
                line: 'Line 7',
                type: 'number',
            },
            {
                name: 'otherIncomeSchedule1',
                text: 'Net operating loss',
                line: 'Line 8',
                type: 'number',
            },
            {
                name: 'educatorExpenses',
                text: 'Educator expenses',
                line: 'Line 10',
                type: 'number',
            },
            {
                name: 'certainBusinessExpenses',
                text: 'Certain business expenses',
                line: 'Line 11',
                type: 'number',
            },
            {
                name: 'healthSavings',
                text: 'Health savings',
                line: 'Line 12',
                type: 'number',
            },
            {
                name: 'movingExpenses',
                text: 'Moving expenses',
                line: 'Line 13',
                type: 'number',
            },
            {
                name: 'deductibleOfSelfEmployment',
                text: 'Deductible part of self-employment',
                line: 'Line 14',
                type: 'number',
            },
            {
                name: 'selfEmployedSEP',
                text: 'Self-employment SEP, SIMPLE and qualified plans',
                line: 'Line 15',
                type: 'number',
            },
            {
                name: 'selfEmployedHealthInsurance',
                text: 'Self-employment health insurance deduction',
                line: 'Line 16',
                type: 'number',
            },
            {
                name: 'penaltyEarlyWithdrawal',
                text: 'Penalty on early withdrawl or savings',
                line: 'Line 17',
                type: 'number',
            },
            {
                name: 'alimonyPaid',
                text: 'Alimony paid',
                line: 'Line 18a',
                type: 'number',
            },
            {
                name: 'iraDeduction',
                text: 'IRA deduction',
                line: 'Line 19',
                type: 'number',
            },
            {
                name: 'studentLoanInterestDeduction',
                text: 'Student loan interest deduction',
                line: 'Line 20',
                type: 'number',
            },
            {
                name: 'adjustmentToIncome',
                text: 'Adjustments to income',
                line: 'Line 22',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule 3',
        fields: [
            {
                name: 'clientHasSchedule3',
                text: 'Has Schedule 3?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'foreignTax',
                text: 'Foreign tax',
                line: 'Line 1',
                type: 'number',
            },
            {
                name: 'creditForChildCareTF',
                text: 'Credit for child/dependent care?',
                line: 'Line 2',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'creditForChildCare',
                text: 'Credit for child/dependent care',
                line: 'Line 2',
                type: 'number',
            },
            {
                name: 'educationCredits',
                text: 'Education credits',
                line: 'Line 3',
                type: 'number',
            },
            {
                name: 'retiramentSavings',
                text: 'Retirament savings contributions credit',
                line: 'Line 4',
                type: 'number',
            },
            {
                name: 'energyCredits',
                text: 'Residential energy credits',
                line: 'Line 5',
                type: 'number',
            },
            {
                name: 'otherCredits',
                text: 'Other Credits',
                line: 'Line 6',
                type: 'number',
            },
            {
                name: 'estimatedTax',
                text: 'Add lines 1 through 5 and 7',
                line: 'Line 8',
                type: 'number',
            },
            {
                name: 'netPremiumTaxCredit',
                text: 'Net premium tax credit',
                line: 'Line 9',
                type: 'number',
            },
            {
                name: 'requestForExtension',
                text: 'Amount paid with request for extension to file',
                line: 'Line 10',
                type: 'number',
            },
            {
                name: 'excessSocialSecurity',
                text: 'Excess social security and tier 1 RRTA tax withheld',
                line: 'Line 11',
                type: 'number',
            },
            {
                name: 'creditForFederalTaxFuels',
                text: 'Credit for federal tax on fuels',
                line: 'Line 12',
                type: 'number',
            },
            {
                name: 'form2439',
                text: 'Form 2439',
                line: 'Line 13a',
                type: 'number',
            },
            {
                name: 'qualifiedSickCredits',
                text: 'Qualified sick and family leave credits from Schedule Ha and Form 7202 before April 1',
                line: 'Line 13b',
                type: 'number',
            },
            {
                name: 'healthCoverageCredit',
                text: 'Health coverage tax credit from Form 8885',
                line: 'Line 13c',
                type: 'number',
            },
            {
                name: 'creditForRepayment',
                text: 'Credit for repayment of amounts included in income from earlier years',
                line: 'Line 13d',
                type: 'number',
            },
            {
                name: 'futureUse13e',
                text: 'Reserved for future use',
                line: 'Line 13e',
                type: 'number',
            },
            {
                name: 'deferredAmount965',
                text: 'Deferred amount of net 965 tax liability',
                line: 'Line 13f',
                type: 'number',
            },
            {
                name: 'creditChildDependent2441',
                text: 'Credit for child and dependent care expenses from Form 2441',
                line: 'Line 13g',
                type: 'number',
            },
            {
                name: 'qualifiedSickCreditsSchH',
                text: 'Qualified sick and family leave credits from Schedule Ha and Form 7202 after March 31',
                line: 'Line 13h',
                type: 'number',
            },
            {
                name: 'otherPaymentsOrRefundableCredits',
                text: 'Other payments or refundable credits',
                line: 'Line 13z',
                type: 'number',
            },
            {
                name: 'refundableCredits',
                text: 'Total other payments or refundable credits',
                line: 'Line 14',
                type: 'number',
            },
            {
                name: 'sum9to14',
                text: 'Add lines 9 through 12 and 14',
                line: 'Line 15',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule A',
        fields: [
            {
                name: 'clientHasScheduleA',
                text: 'Has Schedule A?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'medicalExpenses',
                text: 'Medical and dental expenses',
                line: 'Line 1',
                type: 'number',
            },
            {
                name: 'medicalExpenses2',
                text: 'Multiply line 2 by 7.5%',
                line: 'Line 3',
                type: 'number',
            },
            {
                name: 'medicalExpenses3',
                text: 'Line 3 minus line 1',
                line: 'Line 4',
                type: 'number',
            },
            {
                name: 'taxesYouPaid5a',
                text: 'State and local income taxes or general sales taxes',
                line: 'Line 5a',
                type: 'number',
            },
            {
                name: 'taxesYouPaid5b',
                text: 'State and local real estate taxes',
                line: 'Line 5b',
                type: 'number',
            },
            {
                name: 'taxesYouPaid5c',
                text: 'State and local personal property taxes',
                line: 'Line 5c',
                type: 'number',
            },
            {
                name: 'taxesYouPaid5d',
                text: 'Taxes you paid',
                line: 'Line 5d',
                type: 'number',
            },
            {
                name: 'taxesYouPaid5e',
                text: 'Enter the smaller of line 5d or $10,000',
                line: 'Line 5e',
                type: 'number',
            },
            {
                name: 'taxesYouPaid',
                text: 'Deductions allowed for taxes paid',
                line: 'Line 7',
                type: 'number',
            },
            {
                name: 'mortgageInterests',
                text: 'Home mortgage interest and points reported to you on Form 1098',
                line: 'Line 8a',
                type: 'number',
            },
            {
                name: 'mortgageInterestsNotReported',
                text: 'Home mortgage interest not reported to you on Form 1098',
                line: 'Line 8b',
                type: 'number',
            },
            {
                name: 'pointsNotReported',
                text: 'Points not reported to you on Form 1098',
                line: 'Line 8c',
                type: 'number',
            },
            {
                name: 'mortgageInsurancePremiums',
                text: 'Mortgage insurance premiums',
                line: 'Line 8d',
                type: 'number',
            },
            {
                name: 'sumOfLines8',
                text: 'Add lines 8a through 8d',
                line: 'Line 8e',
                type: 'number',
            },
            {
                name: 'investmentInterest',
                text: 'Investment interest',
                line: 'Line 9',
                type: 'number',
            },
            {
                name: 'interestYouPaid',
                text: 'Interest you paid (total)',
                line: 'Line 10',
                type: 'number',
            },
            {
                name: 'giftsByCash',
                text: 'Gifts by cash or check',
                line: 'Line 11',
                type: 'number',
            },
            {
                name: 'giftsByLand',
                text: 'Other than by cash or check',
                line: 'Line 12',
                type: 'number',
            },
            {
                name: 'giftsToCharity',
                text: 'Gifts to charity (total)',
                line: 'Line 14',
                type: 'number',
            },
            {
                name: 'casualtyLosses',
                text: 'Casualty and theft losses',
                line: 'Line 15',
                type: 'number',
            },
            {
                name: 'otherItemizedDeductions',
                text: 'Other itemized deductions',
                line: 'Line 16',
                type: 'number',
            },
            {
                name: 'summationsItemizedDeductions',
                text: 'Total itemized deductions',
                line: 'Line 17',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule B Part 1',
        fields: [
            {
                name: 'clientHasScheduleB',
                text: 'Has Schedule B?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'scheduleb_1_0_text',
                text: 'scheduleb_1_0_text',
                line: 'Line 0 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_0_amount',
                text: 'scheduleb_1_0_amount',
                line: 'Line 0 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_1_text',
                text: 'scheduleb_1_1_text',
                line: 'Line 1 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_1_amount',
                text: 'scheduleb_1_1_amount',
                line: 'Line 1 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_2_text',
                text: 'scheduleb_1_2_text',
                line: 'Line 2 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_2_amount',
                text: 'scheduleb_1_2_amount',
                line: 'Line 2 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_3_text',
                text: 'scheduleb_1_3_text',
                line: 'Line 3 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_3_amount',
                text: 'scheduleb_1_3_amount',
                line: 'Line 3 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_4_text',
                text: 'scheduleb_1_4_text',
                line: 'Line 4 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_4_amount',
                text: 'scheduleb_1_4_amount',
                line: 'Line 4 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_5_text',
                text: 'scheduleb_1_5_text',
                line: 'Line 5 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_5_amount',
                text: 'scheduleb_1_5_amount',
                line: 'Line 5 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_6_text',
                text: 'scheduleb_1_6_text',
                line: 'Line 6 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_6_amount',
                text: 'scheduleb_1_6_amount',
                line: 'Line 6 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_7_text',
                text: 'scheduleb_1_7_text',
                line: 'Line 7 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_7_amount',
                text: 'scheduleb_1_7_amount',
                line: 'Line 7 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_8_text',
                text: 'scheduleb_1_8_text',
                line: 'Line 8 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_8_amount',
                text: 'scheduleb_1_8_amount',
                line: 'Line 8 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_9_text',
                text: 'scheduleb_1_9_text',
                line: 'Line 9 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_9_amount',
                text: 'scheduleb_1_9_amount',
                line: 'Line 9 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_10_text',
                text: 'scheduleb_1_10_text',
                line: 'Line 10 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_10_amount',
                text: 'scheduleb_1_10_amount',
                line: 'Line 10 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_11_text',
                text: 'scheduleb_1_11_text',
                line: 'Line 11 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_11_amount',
                text: 'scheduleb_1_11_amount',
                line: 'Line 11 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_12_text',
                text: 'scheduleb_1_12_text',
                line: 'Line 12 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_12_amount',
                text: 'scheduleb_1_12_amount',
                line: 'Line 12 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_13_text',
                text: 'scheduleb_1_13_text',
                line: 'Line 13 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_13_amount',
                text: 'scheduleb_1_13_amount',
                line: 'Line 13 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_14_text',
                text: 'scheduleb_1_14_text',
                line: 'Line 14 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_14_amount',
                text: 'scheduleb_1_14_amount',
                line: 'Line 14 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_15_text',
                text: 'scheduleb_1_15_text',
                line: 'Line 15 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_15_amount',
                text: 'scheduleb_1_15_amount',
                line: 'Line 15 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_2',
                text: 'Add the amounts on line 1',
                line: 'Line 2',
                type: 'number',
            },
            {
                name: 'scheduleb_3',
                text: 'Attach from 8815',
                line: 'Line 3',
                type: 'number',
            },
            {
                name: 'scheduleb_4',
                text: 'Substract line 3 from line 2',
                line: 'Line 4 AMOUNT',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule B Part 2',
        fields: [
            // line 5
            {
                name: 'scheduleb_5_0_text',
                text: 'scheduleb_5_0_text',
                line: 'Line 0 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_0_amount',
                text: 'scheduleb_5_0_amount',
                line: 'Line 0 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_1_text',
                text: 'scheduleb_5_1_text',
                line: 'Line 1 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_1_amount',
                text: 'scheduleb_5_1_amount',
                line: 'Line 1 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_2_text',
                text: 'scheduleb_5_2_text',
                line: 'Line 2 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_2_amount',
                text: 'scheduleb_5_2_amount',
                line: 'Line 2 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_3_text',
                text: 'scheduleb_5_3_text',
                line: 'Line 3 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_3_amount',
                text: 'scheduleb_5_3_amount',
                line: 'Line 3 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_4_text',
                text: 'scheduleb_5_4_text',
                line: 'Line 4 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_4_amount',
                text: 'scheduleb_5_4_amount',
                line: 'Line 4 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_5_text',
                text: 'scheduleb_5_5_text',
                line: 'Line 5 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_5_amount',
                text: 'scheduleb_5_5_amount',
                line: 'Line 5 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_6_text',
                text: 'scheduleb_5_6_text',
                line: 'Line 6 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_6_amount',
                text: 'scheduleb_5_6_amount',
                line: 'Line 6 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_7_text',
                text: 'scheduleb_5_7_text',
                line: 'Line 7 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_7_amount',
                text: 'scheduleb_5_7_amount',
                line: 'Line 7 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_8_text',
                text: 'scheduleb_5_8_text',
                line: 'Line 8 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_8_amount',
                text: 'scheduleb_5_8_amount',
                line: 'Line 8 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_9_text',
                text: 'scheduleb_5_9_text',
                line: 'Line 9 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_9_amount',
                text: 'scheduleb_5_9_amount',
                line: 'Line 9 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_10_text',
                text: 'scheduleb_5_10_text',
                line: 'Line 10 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_10_amount',
                text: 'scheduleb_5_10_amount',
                line: 'Line 10 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_11_text',
                text: 'scheduleb_5_11_text',
                line: 'Line 11 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_11_amount',
                text: 'scheduleb_5_11_amount',
                line: 'Line 11 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_12_text',
                text: 'scheduleb_5_12_text',
                line: 'Line 12 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_12_amount',
                text: 'scheduleb_5_12_amount',
                line: 'Line 12 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_13_text',
                text: 'scheduleb_5_13_text',
                line: 'Line 13 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_13_amount',
                text: 'scheduleb_5_13_amount',
                line: 'Line 13 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_14_text',
                text: 'scheduleb_5_14_text',
                line: 'Line 14 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_14_amount',
                text: 'scheduleb_5_14_amount',
                line: 'Line 14 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_15_text',
                text: 'scheduleb_5_15_text',
                line: 'Line 15 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_15_amount',
                text: 'scheduleb_5_15_amount',
                line: 'Line 15 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_6',
                text: 'Add the amounts on line 5',
                line: 'Line 6 AMOUNT',
                type: 'number',
            },
        ]
    },
    {
        section: 'Schedule C',
        fields: [
            {
                name: 'clientHasScheduleC',
                text: 'Has Schedule C?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'grossReceipts',
                text: 'Gross Receipts',
                line: 'Line 1',
                type: 'number',
            },
            {
                name: 'returns',
                text: 'Returns',
                line: 'Line 2',
                type: 'number',
            },
            {
                name: 'line3Sch',
                text: 'Substract line 2 from line 1',
                line: 'Line 3',
                type: 'number',
            },
            {
                name: 'costOfGoods',
                text: 'Cost of Goods',
                line: 'Line 4',
                type: 'number',
            },
            {
                name: 'grossProfit',
                text: 'Gross Profit',
                line: 'Line 5',
                type: 'number',
            },
            {
                name: 'otherIncomeSchC',
                text: 'Other Income',
                line: 'Line 6',
                type: 'number',
            },
            {
                name: 'grossIncome',
                text: 'Gross Income',
                line: 'Line 7',
                type: 'number',
            },
            {
                name: 'advertising',
                text: 'Advertising',
                line: 'Line 8',
                type: 'number',
            },
            {
                name: 'carExpenses',
                text: 'Car Expenses',
                line: 'Line 9',
                type: 'number',
            },
            {
                name: 'commissions',
                text: 'Commissions',
                line: 'Line 10',
                type: 'number',
            },
            {
                name: 'contractLabor',
                text: 'Contract Labor',
                line: 'Line 11',
                type: 'number',
            },
            {
                name: 'depletion',
                text: 'Depletion',
                line: 'Line 12',
                type: 'number',
            },
            {
                name: 'depreciation',
                text: 'Depreciation',
                line: 'Line 13',
                type: 'number',
            },
            {
                name: 'employeeBenefit',
                text: 'Employee Benefit',
                line: 'Line 14',
                type: 'number',
            },
            {
                name: 'insurance',
                text: 'Insurance',
                line: 'Line 15',
                type: 'number',
            },
            {
                name: 'interestMortgage',
                text: 'Interest Mortgage',
                line: 'Line 16a',
                type: 'number',
            },
            {
                name: 'interestOther',
                text: 'Interest Other',
                line: 'Line 16b',
                type: 'number',
            },
            {
                name: 'legal',
                text: 'Legal and professional services',
                line: 'Line 17',
                type: 'number',
            },
            {
                name: 'officeExpenses',
                text: 'Office Expenses',
                line: 'Line 18',
                type: 'number',
            },
            {
                name: 'pensionPlans',
                text: 'Pension Plans',
                line: 'Line 19',
                type: 'number',
            },
            {
                name: 'vehiclesMachinery',
                text: 'Vehicles Machinery',
                line: 'Line 20a',
                type: 'number',
            },
            {
                name: 'otherBusinessProperty',
                text: 'Other Business Property',
                line: 'Line 20b',
                type: 'number',
            },
            {
                name: 'repairs',
                text: 'Repairs',
                line: 'Line 21',
                type: 'number',
            },
            {
                name: 'supplies',
                text: 'Supplies',
                line: 'Line 22',
                type: 'number',
            },
            {
                name: 'taxesAndLicenses',
                text: 'Taxes and Licenses',
                line: 'Line 23',
                type: 'number',
            },
            {
                name: 'travel',
                text: 'Travel',
                line: 'Line 24a',
                type: 'number',
            },
            {
                name: 'meals',
                text: 'Meals',
                line: 'Line 24b',
                type: 'number',
            },
            {
                name: 'utilities',
                text: 'Utilities',
                line: 'Line 25',
                type: 'number',
            },
            {
                name: 'wagesLessEmployeeCredits',
                text: 'Wages less employee credits',
                line: 'Line 26',
                type: 'number',
            },
            {
                name: 'otherExpenses_schC',
                text: 'Other Expenses',
                line: 'Line 27a',
                type: 'number',
            },
            {
                name: 'totalExpenses',
                text: 'Total Expenses',
                line: 'Line 28',
                type: 'number',
            },
            {
                name: 'tentativeProfit',
                text: 'Tentative Profit',
                line: 'Line 29',
                type: 'number',
            },
            {
                name: 'expensesForHomeBusinessTF',
                text: 'Expenses for business use of your home?',
                line: 'Line 30',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'expensesForHomeBusiness',
                text: 'Expenses for business use of your home',
                line: 'Line 30',
                type: 'number',
            },
            {
                name: 'netProfitOrLoss',
                text: 'Net profit or (loss) from business',
                line: 'Line 31',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule D',
        fields: [
            {
                name: 'clientHasScheduleD',
                text: 'Has Schedule D?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'shortTermLossCarryover',
                text: 'Short term capital loss carryover',
                line: 'Line 6',
                type: 'number',
            },
            {
                name: 'netShortTermCapitalGains',
                text: 'Net short-term capital gain or (loss)',
                line: 'Line 7',
                type: 'number',
            },
            {
                name: 'longTermLossCarryover',
                text: 'Long term capital loss carryover',
                line: 'Line 14',
                type: 'number',
            },
            {
                name: 'netLongTermCapitalGains',
                text: 'Net long-term capital gain (or loss)',
                line: 'Line 15',
                type: 'number',
            },
        ],
    },
    {
        section: 'Other Ones',
        fields: [
            {
                name: 'contributionMade457_1',
                text: 'Contribution made to plan 457',
                line: 'Form 8880 Line 2',
                type: 'number',
            },
            {
                name: 'contributionMade457_2',
                text: 'Spouse contribution made to plan 457',
                line: 'Form 8880 Line 2',
                type: 'number',
            },
            {
                name: 'clientHasScheduleSE',
                text: 'Has Schedule SE?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientHasScheduleE',
                text: 'Has Schedule E?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientHasForm2441',
                text: 'Has Form 2441?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientHasForm8995',
                text: 'Has Form 8995?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'hasForm8863',
                text: 'Has Form 8863?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientHasForm1120',
                text: 'Has Form 1120?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientHasForm1120S',
                text: 'Has Form 1120S?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientHasForm8839',
                text: 'Has Form 8839?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientHasForm6251',
                text: 'Has Form 6251?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientHasForm5695',
                text: 'Has Form 5695?',
                type: 'string',
                options: ['Yes', 'No'],
            },
        ],
    },
]