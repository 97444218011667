import { useState, useEffect } from "react"
import { Button, Modal, Dropdown, Table, Message, Input, /* Icon */ } from "semantic-ui-react"
import { sysAPI } from "../../apis/SysAPI"

export const BetaUsersModal = ({ open, onClose }) => {
    const [selectedAccess, setSelectedAccess] = useState('')
    const [betaAdvisorsList, setBetaAdvisorsList] = useState([])
    const [deleteError, setDeleteError] = useState(false)
    const [newAdvisors, setNewAdvisors] = useState('')
    const [newAdvisorsError, setNewAdvisorsError] = useState(false)
    const [saveAdvisorsError, setSaveAdvisorsError] = useState(false)

    const modules = {
        earlyAccessUsers: 'Prospects',
        willSummaryEarlyAccess: 'Will Summary',
        riskalyzeEarlyAccess: 'Riskalyze',
        stripeEarlyAccess: 'Stripe',
        noGeneralQuest: 'No General Questionnaire Workflow',
        estateSnapshot: 'Estate Snapshot',
        solomonEstateSnapshot: 'Solomon Estate Snapshot',
        taxProjections2: 'Tax Projections 2.0',
        costSavings: 'Cost Savings',
        estateSnapshotPreview: 'Estate Snapshot Preview',
        orionIntegration: 'Orion',
        lab2EarlyAccess: 'Estate Lab 2',
        insuranceSnapshot: 'Insurance Snapshot'
    };
    
    const betaAccessOptions = Object.entries(modules).map((e, i) => ({ key: i, text: e[1], value: e[0] })) || []

    const handleSelectAccess = (_, data) => {
        console.log(data.value)
        setSelectedAccess(data.value)
    }

    const getBetaModules = async (selectedModule) => {
        const moduleRequest = await sysAPI.get(`/advisors/beta?module=${selectedModule}`)
        setBetaAdvisorsList(moduleRequest.data)
    }

    const handleDeleteBetaAdvisor = async (e) => {
        console.log(e.target.name)

        const advisorList = { module: selectedAccess, advisorIds: [e.target.name] }
        const deleteBetaAdvisor = await sysAPI.post('/advisors/remove-beta', advisorList)

        if (deleteBetaAdvisor.status === 201 || deleteBetaAdvisor.status === 200) {
            getBetaModules(selectedAccess)
            setDeleteError(false)
        } else {
            setDeleteError(true)
        }
    }

    const handleDismissErrorMessage = (errorType) => {
        errorType === 'detele' && setDeleteError(false)
        errorType === 'emails' && setNewAdvisorsError(false)
        errorType === 'save' && setSaveAdvisorsError(false)
    }

    const handleNewAdvisorsEmails = (_, data) => {
        setNewAdvisors(data.value)
    }

    const handleAddNewAdvisors = async () => {
        if (newAdvisors === '') {
            setNewAdvisorsError(true)
            return
        }

        /**
         * TODO
         * Better email verification
         */

        const payload = {
            module: selectedAccess,
            advisorEmails: newAdvisors.split(',').map(email => email.split(' ').join('')),
        }

        const saveNewAdvisors = await sysAPI.post('/advisors/add-beta', payload)

        if (saveNewAdvisors.status >= 400) {
            setSaveAdvisorsError(true)
            return
        }

        getBetaModules(selectedAccess)
        setNewAdvisors('');
    }

    useEffect(() => {
        if (selectedAccess !== '') {
            getBetaModules(selectedAccess)
        }
    }, [selectedAccess])

    return (
        <Modal open={open}>
            <Modal.Header>Beta Users</Modal.Header>
            <Modal.Content>
                <div>
                    <Dropdown
                        selection
                        fluid
                        placeholder="Select an access type"
                        options={betaAccessOptions}
                        value={selectedAccess}
                        onChange={handleSelectAccess}
                    />
                </div>

                <div style={{ margin: '10px 0'}}>
                    <Input
                        fluid
                        placeholder="Add new Advisors writing the emails separated by a comma."
                        action
                        value={newAdvisors}
                        onChange={handleNewAdvisorsEmails}
                    >
                        <input />
                        <Button type="submit" onClick={handleAddNewAdvisors}>Add Advisors</Button>
                    </Input>
                </div>

                {deleteError && <Message
                    onDismiss={() => handleDismissErrorMessage('delete')}
                    color="red"
                    content='It was not possible to delete the Advisor.'
                />}

                {newAdvisorsError && <Message
                    onDismiss={() => handleDismissErrorMessage('emails')}
                    color="red"
                    content='Invalid emails input.'
                />}

                {saveAdvisorsError && <Message
                    onDismiss={() => handleDismissErrorMessage('save')}
                    color="red"
                    content='It was not possible to save the new Advisors.'
                />}

                <Table style={{ margin: '0px' }}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Full name</Table.HeaderCell>
                            <Table.HeaderCell>Email</Table.HeaderCell>
                            <Table.HeaderCell textAlign="right"></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {betaAdvisorsList.length < 1 && <Table.Row>
                            <Table.Cell colSpan={3} textAlign="center">There are no registered advisors for {modules[selectedAccess]} beta access.</Table.Cell>
                        </Table.Row>}
                        
                        {betaAdvisorsList.length > 0 && betaAdvisorsList.map(e => (
                            <Table.Row key={e._id}>
                                <Table.Cell>{e.fullName}</Table.Cell>
                                <Table.Cell>{e.email}</Table.Cell>
                                <Table.Cell textAlign="right">
                                    <Button name={e._id} onClick={handleDeleteBetaAdvisor}>Remove</Button>
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => onClose()}>Close</Button>
            </Modal.Actions>
        </Modal>
    )
}