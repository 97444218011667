import moment from 'moment';
import { useEffect, useState } from 'react';
import { Checkbox, Dropdown, Input, Select } from 'semantic-ui-react';

const DocumentCategorizationForm = ({ dataForm, setDataForm, showName = false }) => {
  const [nameFile, setNameFile] = useState(null);
  const [documentTypeSelected, setDocumentTypeSelected] = useState('');
  const [dateDoc, setDateDoc] = useState('');
  const [dateDocIsUnknown, setDateDocIsUnknown] = useState(false);
  const [dateDocIsNotSigned, setDateDocIsNotSigned] = useState(false);
  const [jointOrSingle, setJointOrSingle] = useState(''); //default Joint
  const [subType, setSubType] = useState(''); // default Amendment
  const [typingTimer, setTypingTimer] = useState(null);

  const docTypes = [
    {
      text: 'Will',
      value: 'Will',
    },
    {
      text: 'Revocable trust',
      value: 'Revocable trust',
    },
    {
      text: 'Irrevocable trust',
      value: 'Irrevocable trust',
    },
    {
      text: 'POA',
      value: 'POA',
    },
    //{
    //    text: 'Life insurance',
    //    value: 'Life insurance',
    //},
    {
      text: 'Medical directives',
      value: 'Medical directives',
    },
    {
      text: 'Bundled',
      value: 'Bundled',
    },
    {
      text: 'Other',
      value: 'Other',
    },
  ];
  const handleAddTextPDF = (value) => {
    if (typingTimer) {
      clearTimeout(typingTimer);
    }

    setTypingTimer(setTimeout(() => {
      if(value && !value?.toLocaleLowerCase()?.endsWith(".pdf")){
        setNameFile(value + ".pdf");
      }
    }, 1500));
  }

  useEffect(() => {
    const auxData = JSON.parse(JSON.stringify(dataForm || {}));
    if(nameFile){
      auxData.nameDinamic = nameFile
    }
    if(documentTypeSelected === "" ||  documentTypeSelected){
      auxData.documentType = documentTypeSelected
    }
    if(dateDoc){
      auxData.date = dateDoc
    }
    
    auxData.dateIsUnknown = !!dateDocIsUnknown

    auxData.dateNotSigned = !!dateDocIsNotSigned

    if(jointOrSingle){
      auxData.jointOrSingle = jointOrSingle
    }
    if(subType === "" || subType){
      auxData.subType = subType
    }
    setDataForm(auxData)

  // eslint-disable-next-line
  }, [
    documentTypeSelected,
    dateDoc,
    dateDocIsUnknown,
    dateDocIsNotSigned,
    jointOrSingle,
    subType,
    nameFile
  ])

  useEffect(() => {
    console.log("Hola mundo")
    if(dataForm.name){
      setNameFile(dataForm.name)
    }
    if(dataForm.date){
      const auxDate = moment(dataForm.date)
      setDateDoc(auxDate.isValid() ? auxDate.format("MM/DD/YYYY") : "")
    }
    setDateDocIsUnknown(dataForm.dateIsUnknown);
    setDateDocIsNotSigned(dataForm.dateNotSigned);
    if(dataForm.jointOrSingle){
      setJointOrSingle(dataForm.jointOrSingle)
    }
    if(dataForm.subType){
      setSubType(dataForm.subType)
    }
    if(dataForm.type){
      setDocumentTypeSelected(dataForm.type);
    }

    // eslint-disable-next-line
  },[])

  useEffect(() => {
    // Cleanup timeout on component unmount
    return () => {
      if (typingTimer) {
        clearTimeout(typingTimer);
      }
    };
  }, [typingTimer]);

  return (
    <div style={{display:"grid", gap: "15px"}}>
      {showName && <div>
        <div>
          Name of the File:
        </div>
        <div>
          <Input
            value={nameFile}
            onChange={(e, {value}) => {
              setNameFile(value);
              handleAddTextPDF(value);
            }}
            fluid
            required
          />
        </div>
      </div>}
      <div>
        <div>Select Document</div>
        <Dropdown
          placeholder="Select document…"
          options={docTypes}
          selection
          value={documentTypeSelected}
          onChange={(e, data) => setDocumentTypeSelected(data.value)}
          style={{ width: '100%' }}
          required
        />
      </div>
      <div>
        <div>Date:</div>
        <Input
          value={dateDoc}
          onChange={(_, d) => setDateDoc(d.value)}
          placeholder="mm/dd/aaaa"
          pattern="\d{2}/\d{2}/\d{4}"
          className="dateValidation"
          maxLength={10}
          fluid
          required
          disabled={dateDocIsUnknown || dateDocIsNotSigned}
        />
        <div style={{ display: 'flex', gap: '10px' }}>
          <Checkbox
            checked={dateDocIsUnknown}
            label="Date is Unknown"
            onChange={(_, e) => {
              setDateDocIsUnknown(e.checked);
              setDateDocIsNotSigned(false);
            }}
          />
          <Checkbox
            checked={dateDocIsNotSigned}
            label="Not Signed"
            onChange={(_, e) => {
              setDateDocIsNotSigned(e.checked);
              setDateDocIsUnknown(false);
            }}
          />
        </div>
      </div>
      {['Revocable trust', 'Irrevocable trust'].includes(documentTypeSelected) && (
        <div>
          <div>Select Joint or Single</div>
          <Select
            value={jointOrSingle}
            onChange={(_, d) => setJointOrSingle(d.value)}
            placeholder="Joint or Single"
            fluid
            options={['', 'Joint', 'Single'].map((e, index) => ({ key: index, text: e, value: e }))}
            required
          />
        </div>
      )}
      {['Revocable trust', 'Irrevocable trust', 'Will'].includes(documentTypeSelected) && (
        <div>
          <div>Select a subtype</div>
          <Select
            value={subType}
            onChange={(_, d) => setSubType(d.value)}
            placeholder="Select  a subtype..."
            fluid
            options={['', 'Amendment', 'Restatement', 'Codicil', 'Other'].map((e, index) => ({ key: index, text: e, value: e }))}
            required
          />
        </div>
      )}
    </div>
  );
};

export default DocumentCategorizationForm;
