import { sysAPI } from "../apis/SysAPI";

export const fetchAreasExtractions = (start, end, withFP = true) => {
    return async (dispatch) => {
        try {
            const response = await sysAPI.get(`/advisors/areas-extractions?startDate=${start}&endDate=${end}&withFP=${withFP}`);
            dispatch(setAreasExtractions(response.data.advisorsList));
        }
        catch (err) {
            console.error(err);
        }
    }
}

export const setAreasExtractions = (areasExtractions) => {
    return {
        type: 'set_areas_extractions',
        payload: areasExtractions,
    }
}

export const fetchAreasRecommendations = (start, end, withFP = true) => {
    return async (dispatch) => {
        try {
            const response = await sysAPI.get(`/advisors/areas-recommendations?startDate=${start}&endDate=${end}&withFP=${withFP}`);
            dispatch(setAreasRecommendations(response.data.advisorsList));
        }
        catch (err) {
            console.error(err);
        }
    }
}

export const setAreasRecommendations = (recommendations) => {
    return  {
        type: 'set_areas_recommendations',
        payload: recommendations,
    }
}