import { sysAPI } from "../apis/SysAPI";

export const fetchMe = () => {
    return async (dispatch) => {
        try {
            const response = await sysAPI.get('/me');
            dispatch(dispatch(setMe(response.data.profile)));
        }
        catch (err) {
            dispatch(setMe(null));
            console.error(err);
        }
    }
}

export const setMe = (profile) => {
    return {
        type: 'set_me',
        payload: profile,
    }
}