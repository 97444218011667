import { useState } from "react";
import { Button, Dimmer, Icon, Loader, Modal } from "semantic-ui-react";
import FormAdmin from "./Form";
import { sysAPI } from "../../apis/SysAPI";
import { useSelector } from "react-redux";

const AdminCreate = ({ refreshFunction, sysRoles }) => {
  const me = useSelector((state) => state.me);
  const [openModal, setOpenModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [info, setInfo] = useState({});

  const handleCreate = async () => {
    setLoader(true);
    try {
      //console.log({info})
      await sysAPI.post(`/users`, { ...info });
      refreshFunction();
      setOpenModal(false);
    } catch (error) {
      console.log({ error });
      alert(error.message);
    }
    setLoader(false);
  };

  if (!me.permissions.includes("admin:admin_add")) {
    return <div></div>;
  }

  return (
    <div>
      <Button icon onClick={() => setOpenModal(!openModal)}>
        <Icon name="add" />
      </Button>

      <Modal size="tiny" open={openModal} onClose={() => setOpenModal(false)} onOpen={() => setOpenModal(true)}>
        <Modal.Header>Create an Sys Admin Account</Modal.Header>
        <Modal.Content>
          <div>
            <FormAdmin info={info} setInfo={setInfo} sysRoles={sysRoles} />
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpenModal(false)}>Cancel</Button>
          <Button onClick={handleCreate} color="green">
            Create
          </Button>
        </Modal.Actions>
        <Dimmer active={loader}>
          <Loader size="large" indeterminate>
            Creating advisor account...
          </Loader>
        </Dimmer>
      </Modal>

    </div>
  );
};

export default AdminCreate;
