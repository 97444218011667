
import { useEffect, useState } from "react";
import { Button, Form, Input, Modal, /* Icon */ } from "semantic-ui-react";
//import { useDispatch, useSelector } from "react-redux";
import { fpalphaAPI } from "../../apis/FPAlphaAPI";

const PasswordConfirmationModal = ({ setValidationToken, modalState, setModalState }) => {
    //const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const [password, setPassword] = useState("");
    const [showPassowrd, setShowPassword] = useState(false)
    //const me = useSelector(state => state.me);

    const validatePassword = async () => {
        setLoader(true)
        try {
            const { data } = await fpalphaAPI.post(`sys/auth/validate-password`, {
                password: password
            })
            setValidationToken(data.token);
            setModalState(false)
            setPassword("");
            setShowPassword(false);
            // alert(data.message || "")
        } catch (error) {
            console.log({ error })
            const messageError = error.response ? error.response.data.message : error.message
            alert(messageError)
        }
        setLoader(false)
    }

    useEffect(() => {

    })

    return (
        <div>
            <Modal open={modalState} size="mini">
                <Modal.Header>
                    Action Confirm
                </Modal.Header>
                <Modal.Content>
                    <div>
                        <p>
                            Please input your password to confirm this action.
                        </p>
                        <Form>
                            <Form.Field
                                control={Input}
                                action={{ icon: showPassowrd ? 'eye' : 'eye slash', onClick: () => setShowPassword(!showPassowrd) }}
                                label="Password"
                                type={showPassowrd ? 'text' : 'password'}
                                defaultValue={password}
                                fluid
                                onChange={(e) => setPassword(e?.target?.value)}
                            />
                        </Form>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setModalState(false)} disabled={loader}>
                        Cancel
                    </Button>
                    <Button onClick={() => validatePassword()} color="green" disabled={loader}>
                        {loader ? 'Loading...' : 'Confirm'}
                    </Button>
                </Modal.Actions>
            </Modal>
        </div>
    )
}

export default PasswordConfirmationModal; 