import { setSnackbar } from "../actions/snackbar.actions";

export const commifyNumber = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const isRealEstateAsset = (type) => {
  return ['primary_residence', 'investment_property', 'commercial_property', 'other_property', 'land'].includes(type) || type.startsWith('real_estate');
};

export const isRetirementAccountAsset = (type) => {
  return ['traditional_ira', '401k_plan', '457_plan', 'roth_ira'].includes(type);
};

export const string_to_slug = ({ str, whitespaces = "-", finalCharacterAdmited=""}) => {
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  let from = "åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
  let to = "aaaaaaeeeeiiiioooouuuunc------";

  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, whitespaces) // collapse whitespace and replace by -
    .replace(/-+/g, whitespaces) // collapse dashes
    .replace(/^-+/, "") // trim - from start of text
    .replace(/-+$/, finalCharacterAdmited); // trim - from end of text

  return str;
};

export const copyToClipboard = (texto, showAlert=true) => {
  let elementoTemporar = document.createElement("input");
  document.body.appendChild(elementoTemporar);
  elementoTemporar.setAttribute("value", texto);
  elementoTemporar.select();
  document.execCommand("copy");
  document.body.removeChild(elementoTemporar);
  if(showAlert){
    alert("text copied in the clipboard: " + texto);
  }
}

export const responseError = (error, messageDefault = "Something was wrong...", nameFunction = "", dispatch) => {
  console.error({error})
  if(nameFunction){
    console.error(`Error in ${nameFunction}`)
  }
  const message = error?.response?.data?.message || error?.message || messageDefault
  if(dispatch){
    dispatch(setSnackbar({message, status:"error"}))
  }else{
    alert(message)
  }
  return message
}
