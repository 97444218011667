/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-operators */
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux"
import { Button, Input, Segment, Select } from 'semantic-ui-react';
import { addEstateManualInputAsset, removeEstateManualInputAsset, setEstateManualInputField } from '../../actions/estate-manual-input.actions';
import { v4 as uuid } from 'uuid';

export const InsuranceCustomFields = ({docId, category, area}) => {
  const dispatch = useDispatch();
  const [labelOptions, setLabelOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [textOptions, setTextOptions] = useState([])

  const estateManualInputAssets = useSelector(state => state.estateManualInputAssets);
  const estateManualInput = useSelector(state => state.estateManualInput);

  const insuranceAssets = estateManualInputAssets?.[`${docId}_${area}Insurance`];

  const sectionNames = {
    homeProperty: 'Property',
    homePersonalLiability: 'Personal Liability',
    homePersonalInlandMarineInsurance: 'Personal Inland Marine Insurance',
    homeMortgageeLienholder: 'Mortgagee / Lienholder',
    homeOtherCoverages: 'Other Coverages',
  };

  const labelOptionsArray = {
    homeProperty: [
      'Dwelling Limit',
      'Contents Limit',
      'Loss of Use Limit',
      'Additional Structures Limit',
      'Earthquake Limit',
      'Flood Limit',
      'Sump Pump Overflow Limit',
      'Water/Drain backup Limit',
      'Property Deductible',
      'Non Applicable',
    ],
    homePersonalLiability: [
      'Liability Limit',
      'Aggregate Limit of Liability',
      'Medical Payments',
      'Non Applicable',
    ],
    homePersonalInlandMarineInsurance: [
      'Jewelry Limit/Deductible',
      'Fine Art Limit/Deductible',
      'Silverware Limit/Deductible',
      'Non Applicable',
    ],
    homeMortgageeLienholder: [
      'Name',
      'Address',
      'Loan Number',
      'Non Applicable',
    ],
    homeOtherCoverages: [
      'Name Limit',
      'Non Applicable',
    ],
    umbrellaPolicyLimit: [
      'Annual Aggregate Limit of liability',
      'Aggregate Limit of Liability',
      'Non Applicable',
    ],
    underlyingLimits: [
      'Personal Liability',
      'Automobile Liability',
      'Watercraft Liability',
      'Non Applicable',
    ],
    autoLiability: [
      'Bodily Injury, Property Damage Liability Aggregate Limit',
      'Property Damage Liability Per person/Each Accident',
      'Bodily Injury Liability Per Person/Accident Limit',
      'Personal Injury Protection (PIP)',
      'Medical Payments Per Person',
      'Uninsured Motorist Limit Per Person/Per Accident',
      'Underinsured Motorist Limit Per Person/Per Accident',
      'Additional Insured - Lessor',
      'Non Applicable',
    ],
    autoPhysicalDamage: [
      'Comprehensive Deductible Per Person/Per Accident',
      'Collision Deductible Per Person/Per Accident',
      'Full Safety Glass Coverage',
      'Loss Payable Clause',
      'Transportation Expenses. Amount Per Day/Maximum of Days',
      'Towing Costs',
      'Auto Loan/Lease Gap Coverage',
      'Non Applicable',
    ],
    generalInformation: [
      'Total Policy Premium',
      'Non Applicable',
    ],
  };

  const typeOptionsArray = [
    'text only',
    'number only',
    'money only',
    'percentage only',
    'yes-no only',
    'money/money',
    'money/number',
    'money/percentage',
    'money/select',
    'money/text',
    'percentage/money',
    'percentage/number',
    'percentage/percentage',
    'percentage/select',
    'percentage/text',
    'number/money',
    'number/number',
    'number/percentage',
    'number/select',
    'number/text',
    'yes-no/select',
    'yes-no/text',
    'yes-no/number',
    'yes-no/money',
    'text/money',
    'text/number',
    'text/percentage',
    'text/select',
    'text/text',
    'text/text/text',
    'text/text/money',
  ];

  const textOptionsArray = [
    'Per Covered Loss',
    'Per Person',
    'Per Accident',
    'Per Occurrence',
    'Amount per day',
    'Maximum of days',
    'Maximum amount covered',
    'Non Applicable',
  ];

  const addTrusteeBtn_Click = (trusteeType) => {
    return () => {
      const newId = uuid();
      dispatch(addEstateManualInputAsset(`${docId}_${area}Insurance`, newId));
      dispatch(setEstateManualInputField(docId, `${area}Insurance___${newId}`, trusteeType));
    }
  };

  const removeTrusteeBtn_Click = (asset) => {
    return () => {
      dispatch(removeEstateManualInputAsset(
        `${docId}_${area}Insurance`,
        asset.id,
        ['homeInsurance', 'autoInsurance', 'umbrellaInsurance'],
        docId,
      ));
    }
  };

  const onChange = (field, value, assetId) => {
    const doc = estateManualInput?.[docId]?.[`${area}Insurance___${assetId}`];

    switch (field) {
      case 'valueOne':
        if (!doc.inputs[0]) doc.inputs[0] = {};
        doc.inputs[0].value = value;
        doc.inputs[0].type = doc.type.endsWith('only') ? doc.type.split(' ')[0] : doc.type.split('/')[0];
        break;

      case 'valueTwo':
        if (!doc.inputs[1]) doc.inputs[1] = {};
        doc.inputs[1].value = value;
        doc.inputs[1].type = doc.type.endsWith('only') ? doc.type.split(' ')[1] : doc.type.split('/')[1];
        break;

      case 'valueThree':
        if (!doc.inputs[2]) doc.inputs[2] = {};
        doc.inputs[2].value = value;
        doc.inputs[2].type = doc.type.endsWith('only') ? doc.type.split(' ')[2] : doc.type.split('/')[2];
        break;

      default:
        doc[field] = value;
        doc.inputs = [];
        break;
    };

    if (doc.type?.endsWith('only')) {
      doc.inputs = [doc.inputs[0]];
    }

    dispatch(setEstateManualInputField(docId, `${area}Insurance___${assetId}`, doc));
  };

  useEffect(() => {
    insuranceAssets?.forEach(asset => {
      if (
        estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.category
        && estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.label
      ) {
        labelOptionsArray?.[estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.category]?.push(
          estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.label
        );
      }
    });
  }, []);

  useEffect(() => {
    setLabelOptions(labelOptionsArray[category].map(itm => ({key:uuid(), text:itm, value:itm})));
    setTypeOptions(typeOptionsArray.map(itm => ({key:uuid(), text:itm, value:itm})));
    setTextOptions(textOptionsArray.map(itm => ({key:uuid(), text:itm, value:itm})));
  }, []);
  // console.log('AQUIIII:', estateManualInputAssets)
  // console.log('AQUIIII:', estateManualInput)
  // console.log('AQUIIII:', insuranceAssets)
  // console.log('<------------------------------------------->')

  return (
    <div style={{ marginBottom: '50px' }}>
      {insuranceAssets?.length > 0 &&
        <Segment.Group>
          {insuranceAssets?.map(asset => {
            if (estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.category === category) {
              return (
                <Segment key={asset.id}>
                  <div style={{ padding: '10px 0', marginBottom: '10px', borderBottom: '1px solid #e8e8e8', display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', columnGap: '8px'}}>
                      <h4 style={{marginTop:'12px', marginRight:'8px', width:'100px'}}>Label</h4>
                      <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', flexGrow: 1, columnGap: '8px'}}>
                        <Select
                          value={estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.label}
                          placeholder="Select or type an option..."
                          onChange={(e, elem) => {onChange('label', elem.value, asset.id)}}
                          options={labelOptions || []}
                          style={{
                            flex: 1,
                            border: '1px solid rgba(34,36,38,.15)',
                            boxShadow: 'none',
                          }}
                          fluid
                          selection
                          search
                          allowAdditions
                          onAddItem={(e, { value }) => setLabelOptions([...labelOptions, {value, text: value}])}
                        />
                        <Button color='red' onClick={removeTrusteeBtn_Click(asset)}>Remove</Button>
                      </div>
                  </div>

                  <div style={{paddingLeft: '100px', width:'100%'}}>
                    <div style={{
                      padding: '10px 0',
                      marginBottom: '10px',
                      borderBottom: '1px solid #e8e8e8',
                      alignItems: 'flex-start',
                      justifyContent: 'space-between',
                      columnGap: '8px',
                      display: estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.label?.length > 0 ? 'flex' : 'none',
                    }}>
                        <h4 style={{marginTop:'12px', marginRight:'8px', width:'100px'}}>Type</h4>
                        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', flexGrow: 1, columnGap: '8px'}}>
                          <Select
                            value={estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.type}
                            placeholder="Select or type an option..."
                            onChange={(e, elem) => {onChange('type', elem.value, asset.id)}}
                            options={typeOptions || []}
                            style={{
                              flex: 1,
                              border: '1px solid rgba(34,36,38,.15)',
                              boxShadow: 'none',
                            }}
                            fluid
                            selection
                            search
                            onAddItem={(e, { value }) => setTypeOptions([...typeOptions, {value, text: value}])}
                          />
                        </div>
                    </div>

                    <div style={{
                      padding: '10px 0',
                      marginBottom: '10px',
                      borderBottom: '1px solid #e8e8e8',
                      alignItems: 'flex-start',
                      justifyContent: 'space-between',
                      columnGap: '8px',
                      display:
                        estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.label?.length > 0
                        && estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.type?.length > 0
                          ? 'flex'
                          : 'none',
                    }}>
                        <h4 style={{marginTop:'12px', marginRight:'8px', width:'100px'}}>Values</h4>
                        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', flexGrow: 1, columnGap: '8px'}}>

                        {/* <--------------- First Value Inputs ---------------> */}
                        <Input
                            value={estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.inputs?.[0]?.value || ''}
                            onChange={(e, elem) => {onChange('valueOne', elem.value, asset.id)}}
                            style={{ flex: 1,
                              border: '1px solid rgba(34,36,38,.15)',
                              borderRadius: '0.28571429rem',
                              boxShadow: 'none',
                              display:
                                estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.label?.length > 0
                                && !estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.type?.startsWith('yes-no')
                                  ? 'flex'
                                  : 'none',
                            }}
                            placeholder={
                              estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.type?.startsWith('money')
                                ? '$'
                                : estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.type?.startsWith('percentage')
                                  ? '%'
                                  : ''
                            }
                            type={estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.type?.startsWith('text') ? 'text': 'number'}
                            fluid
                          />

                          <Select
                            value={estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.inputs?.[0]?.value}
                            placeholder="Select or type an option..."
                            onChange={(e, elem) => {onChange('valueOne', elem.value, asset.id)}}
                            options={[{key: 'yesone', text: 'Yes', value: 'Yes'}, {key: 'noone', text: 'No', value: 'No'}]}
                            fluid
                            style={{
                              flex: 1,
                              border: '1px solid rgba(34,36,38,.15)',
                              boxShadow: 'none',
                              display:
                                estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.label?.length > 0
                                && estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.type?.startsWith('yes-no')
                                  ? 'flex'
                                  : 'none',
                            }}
                          />

                          {/* <--------------- Second Value Inputs ---------------> */}
                          <Input
                            value={estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.inputs?.[1]?.value || ''}
                            onChange={(e, elem) => {onChange('valueTwo', elem.value, asset.id)}}
                            placeholder={
                              estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.type?.split('/')[1] ==='money'
                                ? '$'
                                : estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.type?.split('/')[1] ==='percentage'
                                  ? '%'
                                  : ''
                            }
                            type={estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.type?.split('/')[1] === 'text' ? 'text' : 'number'}
                            fluid
                            style={{
                              flex: 1,
                              border: '1px solid rgba(34,36,38,.15)',
                              borderRadius: '0.28571429rem',
                              boxShadow: 'none',
                              display:
                                estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.label?.length > 0
                                && (estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.type?.split('/')[1] === 'money'
                                || estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.type?.split('/')[1] === 'percentage'
                                || estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.type?.split('/')[1] === 'text'
                                || estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.type?.split('/')[1] === 'number')
                                  ? 'flex'
                                  : 'none',
                            }}
                          />

                          <Select
                            value={estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.inputs?.[1]?.value}
                            placeholder="Select or type an option..."
                            onChange={(e, elem) => {onChange('valueTwo', elem.value, asset.id)}}
                            options={textOptions}
                            fluid
                            selection
                            search
                            allowAdditions
                            onAddItem={(e, { value }) => setTextOptions([...textOptions, {value, text: value}])}
                            style={{
                              flex: 1,
                              border: '1px solid rgba(34,36,38,.15)',
                              boxShadow: 'none',
                              display:
                                estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.label?.length > 0
                                && estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.type?.split('/')[1] === 'select'
                                  ? 'flex'
                                  : 'none',
                            }}
                          />

                          <Select
                            value={estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.inputs?.[1]?.value}
                            placeholder="Select or type an option..."
                            onChange={(e, elem) => {onChange('valueTwo', elem.value, asset.id)}}
                            options={[{key: 'yesone', text: 'Yes', value: 'Yes'}, {key: 'noone', text: 'No', value: 'No'}]}
                            fluid
                            style={{
                              flex: 1,
                              border: '1px solid rgba(34,36,38,.15)',
                              boxShadow: 'none',
                              display:
                                estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.label?.length > 0
                                && estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.type?.split('/')[1] === 'yes-no'
                                  ? 'flex'
                                  : 'none',
                            }}
                          />

                          {/* <--------------- Third Value Inputs ---------------> */}
                          <Input
                            value={estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.inputs?.[2]?.value || ''}
                            onChange={(e, elem) => {onChange('valueThree', elem.value, asset.id)}}
                            placeholder={
                              estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.type?.endsWith('money')
                                ? '$'
                                : estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.type?.endsWith('percentage')
                                  ? '%'
                                  : ''
                            }
                            type={estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.type?.endsWith('text') ? 'text' : 'number'}
                            fluid
                            style={{
                              flex: 1,
                              border: '1px solid rgba(34,36,38,.15)',
                              borderRadius: '0.28571429rem',
                              boxShadow: 'none',
                              display:
                                estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.label?.length > 0
                                && (estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.type?.split('/')[2]?.endsWith('money')
                                || estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.type?.split('/')[2]?.endsWith('percentage')
                                || estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.type?.split('/')[2]?.endsWith('text')
                                || estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.type?.split('/')[2]?.endsWith('number'))
                                  ? 'flex'
                                  : 'none',
                            }}
                          />

                          <Select
                            value={estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.inputs?.[2]?.value}
                            placeholder="Select or type an option..."
                            onChange={(e, elem) => {onChange('valueThree', elem.value, asset.id)}}
                            options={textOptions}
                            fluid
                            selection
                            search
                            allowAdditions
                            onAddItem={(e, { value }) => setTextOptions([...textOptions, {value, text: value}])}
                            style={{
                              flex: 1,
                              border: '1px solid rgba(34,36,38,.15)',
                              boxShadow: 'none',
                              display:
                                estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.label?.length > 0
                                && estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.type?.split('/')[2]?.endsWith('select')
                                  ? 'flex'
                                  : 'none',
                            }}
                          />

                          <Select
                            value={estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.inputs?.[2]?.value}
                            placeholder="Select or type an option..."
                            onChange={(e, elem) => {onChange('valueThree', elem.value, asset.id)}}
                            options={[{key: 'yesone', text: 'Yes', value: 'Yes'}, {key: 'noone', text: 'No', value: 'No'}]}
                            fluid
                            style={{
                              flex: 1,
                              border: '1px solid rgba(34,36,38,.15)',
                              boxShadow: 'none',
                              display:
                                estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.label?.length > 0
                                && estateManualInput?.[docId]?.[`${area}Insurance___${asset.id}`]?.type?.split('/')[2]?.endsWith('yes-no')
                                  ? 'flex'
                                  : 'none',
                            }}
                          />
                        </div>
                    </div>
                  </div>
                </Segment>
              )
            } else {
              return '';
            }
          })}
        </Segment.Group>
      }
      <Button onClick={addTrusteeBtn_Click({ category })}>Add {sectionNames[category]} data</Button>
    </div>
  )
}
