// eslint-disable-next-line
export default [
    {
        section: 'Form 1040',
        fields: [
            {
                name: 'wages',
                text: 'Wages',
                line: 'Line 1',
                type: 'number',
            },
            {
                name: 'taxExemptInterest',
                text: 'Tax-exempt interest',
                line: 'Line 2a',
                type: 'number',
            },
            {
                name: 'taxInterest',
                text: 'Taxable interest',
                line: 'Line 2b',
                type: 'number',
            },
            {
                name: 'qualifiedDividends',
                text: 'Qualified dividends',
                line: 'Line 3a',
                type: 'number',
            },
            {
                name: 'totalDividends',
                text: 'Ordinary dividends',
                line: 'Line 3b',
                type: 'number',
            },
            {
                name: 'iraDistribution',
                text: 'IRA distributions',
                line: 'Line 4a',
                type: 'number',
            },
            {
                name: 'taxableIRA',
                text: 'IRA distributions taxable amount',
                line: 'Line 4b',
                type: 'number',
            },
            {
                name: 'pension',
                text: 'Pensions and annuities',
                line: 'Line 4c',
                type: 'number',
            },
            {
                name: 'taxablePension',
                text: 'Pensions and annuities taxable amount',
                line: 'Line 4d',
                type: 'number',
            },
            {
                name: 'socialSecurityBenefits',
                text: 'Social security benefits',
                line: 'Line 5a',
                type: 'number',
            },
            {
                name: 'socialSecurity',
                text: 'Social security benefits taxable amount',
                line: 'Line 5b',
                type: 'number',
            },
            {
                name: 'capitalGainOrLoss',
                text: 'Capital gain or (loss)',
                line: 'Line 6',
                type: 'number',
            },
            {
                name: 'otherIncome',
                text: 'Other income',
                line: 'Line 7a',
                type: 'number',
            },
            {
                name: 'totalIncome',
                text: 'Total income',
                line: 'Line 7b',
                type: 'number',
            },
            {
                name: 'adjustedIncome',
                text: 'Adjustments to income',
                line: 'Line 8a',
                type: 'number',
            },
            {
                name: 'adjustedGrossIncome',
                text: 'Adjusted gross income',
                line: 'Line 8b',
                type: 'number',
            },
            {
                name: 'standardDeductions',
                text: 'Standard deductions or itemized deductions',
                line: 'Line 9',
                type: 'number',
            },
            {
                name: 'qbIncome',
                text: 'Qualified business income deductions',
                line: 'Line 10',
                type: 'number',
            },
            {
                name: 'sumDeductions',
                text: 'Sum of deductions',
                line: 'Line 11a',
                type: 'number',
            },
            {
                name: 'taxableIncome',
                text: 'Taxable income',
                line: 'Line 11b',
                type: 'number',
            },
            {
                name: 'taxes',
                text: 'Taxes',
                line: 'Line 12a',
                type: 'number',
            },
            {
                name: 'totalTax',
                text: 'Total tax',
                line: 'Line 16',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule 1',
        fields: [
            {
                name: 'clientHasSchedule1',
                text: 'Has Schedule 1?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name:'taxableRefunds',
                text: 'Taxable refunds',
                line: 'Line 1',
                type: 'number',
            },
            {
                name: 'alimonyReceived',
                text: 'Alimony received',
                line: 'Line 2a',
                type: 'number',
            },
            {
                name: 'businessIncome',
                text: 'Business income/loss',
                line: 'Line 3',
                type: 'number',
            },
            {
                name: 'otherGainsOrLosses',
                text: 'Other gains or losses',
                line: 'Line 4',
                type: 'number',
            },
            {
                name: 'rentalRealEstate',
                text: 'Rental real estate',
                line: 'Line 5',
                type: 'number',
            },
            {
                name: 'farmIncome',
                text: 'Farm income',
                line: 'Line 6',
                type: 'number',
            },
            {
                name: 'unemployementCompensation',
                text: 'Unemployment compensation',
                line: 'Line 7',
                type: 'number',
            },
            {
                name: 'otherIncomeSchedule1',
                text: 'Net operating loss',
                line: 'Line 8',
                type: 'number',
            },
            {
                name: 'educatorExpenses',
                text: 'Educator expenses',
                line: 'Line 10',
                type: 'number',
            },
            {
                name: 'certainBusinessExpenses',
                text: 'Certain business expenses',
                line: 'Line 11',
                type: 'number',
            },
            {
                name: 'healthSavings',
                text: 'Health savings',
                line: 'Line 12',
                type: 'number',
            },
            {
                name: 'movingExpenses',
                text: 'Moving expenses',
                line: 'Line 13',
                type: 'number',
            },
            {
                name: 'deductibleOfSelfEmployment',
                text: 'Deductible part of self-employment',
                line: 'Line 14',
                type: 'number',
            },
            {
                name: 'selfEmployedSEP',
                text: 'Self-employment SEP, SIMPLE and qualified plans',
                line: 'Line 15',
                type: 'number',
            },
            {
                name: 'selfEmployedHealthInsurance',
                text: 'Self-employment health insurance deduction',
                line: 'Line 16',
                type: 'number',
            },
            {
                name: 'penaltyEarlyWithdrawal',
                text: 'Penalty on early withdrawl or savings',
                line: 'Line 17',
                type: 'number',
            },
            {
                name: 'alimonyPaid',
                text: 'Alimony paid',
                line: 'Line 18a',
                type: 'number',
            },
            {
                name: 'iraDeduction',
                text: 'IRA deduction',
                line: 'Line 19',
                type: 'number',
            },
            {
                name: 'studentLoanInterestDeduction',
                text: 'Student loan interest deduction',
                line: 'Line 20',
                type: 'number',
            },
            {
                name: 'adjustmentToIncome',
                text: 'Adjustments to income',
                line: 'Line 22',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule 3',
        fields: [
            {
                name: 'clientHasSchedule3',
                text: 'Has Schedule 3?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'foreignTax',
                text: 'Foreign tax',
                line: 'Line 1',
                type: 'number',
            },
            {
                name: 'creditForChildCareTF',
                text: 'Credit for child/dependent care?',
                line: 'Line 2',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'creditForChildCare',
                text: 'Credit for child/dependent care',
                line: 'Line 2',
                type: 'number',
            },
            {
                name: 'educationCredits',
                text: 'Education credits',
                line: 'Line 3',
                type: 'number',
            },
            {
                name: 'retiramentSavings',
                text: 'Retirament savings contributions credit',
                line: 'Line 4',
                type: 'number',
            },
            {
                name: 'energyCredits',
                text: 'Residential energy credits',
                line: 'Line 5',
                type: 'number',
            },
            {
                name: 'otherCredits',
                text: 'Other Credits',
                line: 'Line 6',
                type: 'number',
            },
            {
                name: 'estimatedTax',
                text: 'Add lines 1 through 5 and 7',
                line: 'Line 8',
                type: 'number',
            },
            {
                name: 'netPremiumTaxCredit',
                text: 'Net premium tax credit',
                line: 'Line 9',
                type: 'number',
            },
            {
                name: 'requestForExtension',
                text: 'Amount paid with request for extension to file',
                line: 'Line 10',
                type: 'number',
            },
            {
                name: 'excessSocialSecurity',
                text: 'Excess social security and tier 1 RRTA tax withheld',
                line: 'Line 11',
                type: 'number',
            },
            {
                name: 'creditForFederalTaxFuels',
                text: 'Credit for federal tax on fuels',
                line: 'Line 12',
                type: 'number',
            },
            {
                name: 'form2439',
                text: 'Form 2439',
                line: 'Line 13a',
                type: 'number',
            },
            {
                name: 'qualifiedSickCredits',
                text: 'Qualified sick and family leave credits from Schedule Ha and Form 7202 before April 1',
                line: 'Line 13b',
                type: 'number',
            },
            {
                name: 'healthCoverageCredit',
                text: 'Health coverage tax credit from Form 8885',
                line: 'Line 13c',
                type: 'number',
            },
            {
                name: 'creditForRepayment',
                text: 'Credit for repayment of amounts included in income from earlier years',
                line: 'Line 13d',
                type: 'number',
            },
            {
                name: 'futureUse13e',
                text: 'Reserved for future use',
                line: 'Line 13e',
                type: 'number',
            },
            {
                name: 'deferredAmount965',
                text: 'Deferred amount of net 965 tax liability',
                line: 'Line 13f',
                type: 'number',
            },
            {
                name: 'creditChildDependent2441',
                text: 'Credit for child and dependent care expenses from Form 2441',
                line: 'Line 13g',
                type: 'number',
            },
            {
                name: 'qualifiedSickCreditsSchH',
                text: 'Qualified sick and family leave credits from Schedule Ha and Form 7202 after March 31',
                line: 'Line 13h',
                type: 'number',
            },
            {
                name: 'otherPaymentsOrRefundableCredits',
                text: 'Other payments or refundable credits',
                line: 'Line 13z',
                type: 'number',
            },
            {
                name: 'refundableCredits',
                text: 'Total other payments or refundable credits',
                line: 'Line 14',
                type: 'number',
            },
            {
                name: 'sum9to14',
                text: 'Add lines 9 through 12 and 14',
                line: 'Line 15',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule A',
        fields: [
            {
                name: 'clientHasScheduleA',
                text: 'Has Schedule A?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'medicalExpenses',
                text: 'Medical and dental expenses',
                line: 'Line 1',
                type: 'number',
            },
            {
                name: 'medicalExpenses2',
                text: 'Multiply line 2 by 7.5%',
                line: 'Line 3',
                type: 'number',
            },
            {
                name: 'medicalExpenses3',
                text: 'Line 3 minus line 1',
                line: 'Line 4',
                type: 'number',
            },
            {
                name: 'taxesYouPaid5a',
                text: 'State and local income taxes or general sales taxes',
                line: 'Line 5a',
                type: 'number',
            },
            {
                name: 'taxesYouPaid5b',
                text: 'State and local real estate taxes',
                line: 'Line 5b',
                type: 'number',
            },
            {
                name: 'taxesYouPaid5c',
                text: 'State and local personal property taxes',
                line: 'Line 5c',
                type: 'number',
            },
            {
                name: 'taxesYouPaid5d',
                text: 'Taxes you paid',
                line: 'Line 5d',
                type: 'number',
            },
            {
                name: 'taxesYouPaid5e',
                text: 'Enter the smaller of line 5d or $10,000',
                line: 'Line 5e',
                type: 'number',
            },
            {
                name: 'taxesYouPaid',
                text: 'Deductions allowed for taxes paid',
                line: 'Line 7',
                type: 'number',
            },
            {
                name: 'mortgageInterests',
                text: 'Home mortgage interest and points reported to you on Form 1098',
                line: 'Line 8a',
                type: 'number',
            },
            {
                name: 'mortgageInterestsNotReported',
                text: 'Home mortgage interest not reported to you on Form 1098',
                line: 'Line 8b',
                type: 'number',
            },
            {
                name: 'pointsNotReported',
                text: 'Points not reported to you on Form 1098',
                line: 'Line 8c',
                type: 'number',
            },
            {
                name: 'mortgageInsurancePremiums',
                text: 'Mortgage insurance premiums',
                line: 'Line 8d',
                type: 'number',
            },
            {
                name: 'sumOfLines8',
                text: 'Add lines 8a through 8d',
                line: 'Line 8e',
                type: 'number',
            },
            {
                name: 'investmentInterest',
                text: 'Investment interest',
                line: 'Line 9',
                type: 'number',
            },
            {
                name: 'interestYouPaid',
                text: 'Interest you paid (total)',
                line: 'Line 10',
                type: 'number',
            },
            {
                name: 'giftsByCash',
                text: 'Gifts by cash or check',
                line: 'Line 11',
                type: 'number',
            },
            {
                name: 'giftsByLand',
                text: 'Other than by cash or check',
                line: 'Line 12',
                type: 'number',
            },
            {
                name: 'giftsToCharity',
                text: 'Gifts to charity (total)',
                line: 'Line 14',
                type: 'number',
            },
            {
                name: 'casualtyLosses',
                text: 'Casualty and theft losses',
                line: 'Line 15',
                type: 'number',
            },
            {
                name: 'otherItemizedDeductions',
                text: 'Other itemized deductions',
                line: 'Line 16',
                type: 'number',
            },
            {
                name: 'summationsItemizedDeductions',
                text: 'Total itemized deductions',
                line: 'Line 17',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule C',
        fields: [
            {
                name: 'expensesForHomeBusiness',
                text: 'Expenses for business use of your home',
                line: 'Line 30',
                type: 'number',
            },
            {
                name: 'netProfitOrLoss',
                text: 'Net profit or (loss) from business',
                line: 'Line 31',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule D',
        fields: [
            {
                name: 'clientHasScheduleD',
                text: 'Has Schedule D?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'shortTermLossCarryover',
                text: 'Short term capital loss carryover',
                line: 'Line 6',
                type: 'number',
            },
            {
                name: 'netShortTermCapitalGains',
                text: 'Net short-term capital gain or (loss)',
                line: 'Line 7',
                type: 'number',
            },
            {
                name: 'longTermLossCarryover',
                text: 'Long term capital loss carryover',
                line: 'Line 14',
                type: 'number',
            },
            {
                name: 'netLongTermCapitalGains',
                text: 'Net long-term capital gain (or loss)',
                line: 'Line 15',
                type: 'number',
            },
        ],
    },
    {
        section: 'Other Ones',
        fields: [
            {
                name: 'hasScheduleSE',
                text: 'Has Schedule SE?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'hasScheduleE',
                text: 'Has Schedule E?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'hasForm8863',
                text: 'Has Form 8863?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientHasForm1120',
                text: 'Has Form 1120?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientHasForm1120S',
                text: 'Has Form 1120S?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientHasForm8839',
                text: 'Has Form 8839?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientHasForm6251',
                text: 'Has Form 6251?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientHasForm5695',
                text: 'Has Form 5695?',
                type: 'string',
                options: ['Yes', 'No'],
            },
        ],
    },
]