
import { useEffect, useState } from "react";
import { Button, Form, Input, Modal, Icon } from "semantic-ui-react";
import { advisorAPI } from "../../../apis/AdvisorsAPI";
import { useDispatch, useSelector } from "react-redux";
import { fetchCurrentCompany } from "../../../actions/company.actions";

const ChangeName = ({company, isButton = true}) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [openModal, setOpenModal] = useState(false)
  const [openModalConfirm, setOpenModalConfirm] = useState(false)
  const [companyName, setCompanyName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassowrd, setShowPassword] = useState(false)
  const me = useSelector(state => state.me);

  const handleChangeName = async () => {
    setLoader(true)
    try {
      const {data} = await advisorAPI.put(`companies/${company._id}/name`, {
        name: companyName,
        password_sys: password
      })
      setOpenModal(false)
      setOpenModalConfirm(false)
      setPassword("");
      setShowPassword(false);
      alert(data.message || "Change company name updated!")
      dispatch(fetchCurrentCompany(company._id));
    } catch (error) {
      console.log({error})
      const messageError = error.response ? error.response.data.message : error.message
      alert(messageError)
    }
    setLoader(false)
  }

  useEffect(() => {
    setCompanyName(company?.name || "")
  },[company])

  if(!me?.permissions.includes("companies:change_name")){
    return <div></div>
  }

  return (
    <div>
      { isButton ? (
          <Button onClick={() => setOpenModal(!openModal)} color="orange">
            Change Name
          </Button>
        ) : (
          <div onClick={() => setOpenModal(!openModal)}>
            <Icon name="pencil" /> Change Name
          </div>
        )

      }

      <Modal open={openModal} size="tiny">
        <Modal.Header>
          Change Company Name
        </Modal.Header>
        <Modal.Content>
          <div>
            <Form>
              <Form.Field
                control={Input}
                placeholder="Company Name..."
                label="Company Name"
                defaultValue={companyName}
                fluid
                onChange={(e) => setCompanyName(e?.target?.value)}
              />
            </Form>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpenModal(!openModal)}>
            Cancel
          </Button>
          <Button onClick={() => setOpenModalConfirm(true)} color="green">
            Save
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal open={openModalConfirm} size="mini">
        <Modal.Header>
          Confirm your password!
        </Modal.Header>
        <Modal.Content>
          <div>
            <p>
              Please input your password to confirm this action.
            </p>
            <Form>
              <Form.Field
                control={Input}
                action={{ icon: showPassowrd ? 'eye' : 'eye slash', onClick: () => setShowPassword(!showPassowrd) }}
                label="Password"
                type={showPassowrd ? 'text' : 'password'}
                defaultValue={password}
                fluid
                onChange={(e) => setPassword(e?.target?.value)}
              />
            </Form>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpenModalConfirm(false)} disabled={loader}>
            Cancel
          </Button>
          <Button onClick={() => handleChangeName()} color="green" disabled={loader}>
            {loader ? 'Loading...' : 'Confirm'}
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  )
}

export default ChangeName;