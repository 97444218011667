import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router'
import { Modal, TextArea, Button, Form } from 'semantic-ui-react';
import { sysAPI } from '../../apis/SysAPI';

export const SetOnHoldModal = ({onHoldModal, setOnHoldModal, setSuccessMessage }) => {
  const messageArea = useRef();
  const params = useParams();
  const [message, setMessage] = useState('');
  const [clientId, setClientId] = useState(undefined);
  const [index, setIndex] = useState(undefined);
  const [unfilled, setUnfilled] = useState(false);

  const handleMessage = e => {
    setMessage(e.target.value);
  };

  const sendToOnHold = () => {
    if (!message || message.length <= 5) {
      setUnfilled('You are required to enter a reason for submitting on hold.');
      return true;
    }


    if (unfilled) setUnfilled(false);
    sysAPI.post('/estate-manual-input/sendtohold', {clientId, index, message})
      .then(res => {
        if (res.status === 200) {
          setSuccessMessage('Request moved to On Hold successfully!');
          setOnHoldModal(null);
        }
      })
      .catch(err => setUnfilled(err.message));
  };

  useEffect(() => {
    messageArea.current.focus();
    const { clientId, assetIndex } = params;
    setClientId(clientId);
    setIndex(assetIndex);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal open={onHoldModal !== null}>
      <Modal.Header>Why are you moving this request to On Hold?. Please, be specific.</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <TextArea ref={messageArea} value={message} onChange={handleMessage} />
          </Form.Field>
        </Form>
        <p style={{color: 'red', marginTop: '0.8em'}}>{unfilled || ''}</p>
      </Modal.Content>
      <Modal.Actions>
          <Button onClick={() => setOnHoldModal(null)}>Cancel</Button>
          <Button
            onClick={sendToOnHold}
            color="yellow"
          >
            Send to hold
          </Button>
      </Modal.Actions>
    </Modal>
  );
}
