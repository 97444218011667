import { Button } from "semantic-ui-react";
import { fetchCurrentCompany } from "../../../actions/company.actions";
import { useDispatch } from "react-redux";
import { advisorAPI } from "../../../apis/AdvisorsAPI";
import { useState } from "react";



const SwitchHasSSOLogin = ({ company, isButton = true }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)

  const activeDisabled = async() => {
    setLoading(true)
    try {
      const hasSSOLogin = company?.settings?.hasSSOLogin
      console.log({company, hasSSOLogin, newSOO: !hasSSOLogin})
      await advisorAPI.put(`/companies/${company._id}`, {
        "settings.hasSSOLogin": !hasSSOLogin
      });
      await dispatch(fetchCurrentCompany(company._id));
    } catch (error) {
      const message = error?.response?.data?.message || error.message
      console.log({error})
      alert(message)
    }
    setLoading(false)
  }
  return (
    <>
      {isButton ? (
        <Button color={company?.settings?.hasSSOLogin ? "green" : "red"} onClick={() => activeDisabled()} loading={loading}>
          Single Sing-On: {company?.settings?.hasSSOLogin ? "Active" : "Disabled"}
        </Button>
      ) : (
        <div color={company?.settings?.hasSSOLogin ? "green" : "red"} onClick={() => activeDisabled()} loading={loading} >
          {loading ? (
            <>
              🟠 changing...
            </>
          ) : (
            <>
              {company?.settings?.hasSSOLogin ? "🟢" : "🔴"} Single Sing-On: {company?.settings?.hasSSOLogin ? "Active" : "Disabled"}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default SwitchHasSSOLogin;
