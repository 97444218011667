import { useState } from "react";
import { Button, Grid, Icon, Input, Message, Modal, Segment, Select } from "semantic-ui-react";
import { fetchCurrentCompany } from "../../../../../actions/company.actions";
import { useDispatch } from "react-redux";
import { advisorAPI } from "../../../../../apis/AdvisorsAPI";

const TableConfigurationCreate = ({ companyProfile }) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [idExist, setIdExists] = useState(false);
  const [id, setId] = useState("");
  const [label, setLabel] = useState("");
  const [qualifiedApiName, setQualifiedApiName] = useState("");
  const [tabs, setTabs] = useState([]);
  const [relations, setRelations] = useState({});
  const tables = companyProfile?._integrations?.salesforce?.settings?.tables || [];

  const verifyExistId = (value) => {
    const existTable = tables.find((t) => t.id?.toLowerCase() === value.toLowerCase());
    if (existTable || !value) {
      setIdExists(true);
    } else {
      setIdExists(false);
    }
    setId(value);
  };

  const handleDeleteProperty = (propertyKey) => {
    const __relations = { ...relations };
    delete __relations[propertyKey];
    setRelations(__relations);
  };

  const handleAddTable = async () => {
    setLoader(true);
    try {
      const newTable = {
        id,
        label,
        qualifiedApiName,
        tabs,
        relations,
        showMapperFields: false,
      };
      tables.push(newTable);

      await advisorAPI.put(`/companies/${companyProfile._id}`, {
        [`_integrations.salesforce.settings.tables`]: tables,
      });
      dispatch(fetchCurrentCompany(companyProfile._id));

      setIdExists("");
      setId("");
      setLabel("");
      setQualifiedApiName("");
      setTabs([]);
      setRelations({});
      setOpenModal(false);
    } catch (error) {
      console.log({ error });
      alert(error.message);
    }
    setLoader(false);
  };
  return (
    <>
      <div>
        <Button size="small" onClick={() => setOpenModal(!openModal)}>
          Create table
        </Button>

        <Modal open={openModal}>
          <Modal.Header>Add Table</Modal.Header>
          <Modal.Content>
            <div style={{ display: "grid", gap: "10px" }}>
              <Message color="orange">We can't delete tables from Sys by security</Message>

              <div>
                <label>ID</label>
                {idExist && (
                  <div style={{ fontSize: "10px", margin: "10px 0px" }} negative>
                    <Message color="red">This ID already exist</Message>
                  </div>
                )}
                <div>
                  <Input placeholder="ID" fluid defaultValue={id} onChange={(e, _) => verifyExistId(_.value)} />
                </div>
                <div style={{ fontSize: "10px" }}>This ID cannot be modified in a future</div>
              </div>
              <div>
                <label>Label</label>
                <div>
                  <Input placeholder="Label" fluid defaultValue={label} onChange={(e, _) => setLabel(_.value)} />
                </div>
                <div style={{ fontSize: "10px" }}>Just as it appears in salesforce</div>
              </div>
              <div>
                <label>Qualified Api Name</label>
                <div>
                  <Input placeholder="Qualified Api Name" fluid defaultValue={qualifiedApiName} onChange={(e, _) => setQualifiedApiName(_.value)} />
                </div>
                <div style={{ fontSize: "10px" }}>Api Table</div>
              </div>
              <div>
                <label>Show in Tabs</label>
                <div>
                  <Select
                    placeholder=""
                    fluid
                    options={["Personal", "Family", "Assets"].map((e) => ({ key: e, value: e, text: e }))}
                    multiple
                    defaultValue={tabs}
                    onChange={(e, _) => setTabs(_.value)}
                  />
                </div>
                <div style={{ fontSize: "10px" }}>Whats tabs appears in the frontend</div>
              </div>
              <div>
                <label>Relations</label>
                <div>
                  {Object.keys(relations || {}).map((relation, index) => (
                    <Segment key={index}>
                      <Grid columns={3} stackable>
                        <Grid.Column>
                          <Input
                            placeholder="Key"
                            fluid
                            value={relation}
                            onChange={(e, { value }) => {
                              const __relations = JSON.parse(JSON.stringify(relations));
                              const newKey = value;

                              if (newKey !== relation && !__relations[newKey]) {
                                delete __relations[relation];
                                __relations[newKey] = relations[relation];
                                setRelations(__relations);
                              }
                            }}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Input
                            placeholder="Value"
                            fluid
                            value={relations[relation]}
                            onChange={(e, { value }) => {
                              const __relations = JSON.parse(JSON.stringify(relations));
                              __relations[relation] = value;
                              setRelations(__relations);
                            }}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Button icon color="red" onClick={() => handleDeleteProperty(relation)}>
                            <Icon name="trash" />
                          </Button>
                        </Grid.Column>
                      </Grid>
                    </Segment>
                  ))}

                  <Button
                    onClick={() => {
                      const newKey = ""; //`z-${Object.keys(relations || {}).length}`;
                      const __relations = JSON.parse(JSON.stringify(relations));

                      if (!__relations[newKey]) {
                        __relations[newKey] = "";
                        setRelations(__relations);
                      }
                    }}
                  >
                    Add Property
                  </Button>
                </div>
                <div style={{ fontSize: "10px" }}>
                  Add the relationships with other tables, in case you don't know, have a developer edit it from DB, IT CAN'T ADD MORE FIELDS IN UPDATE
                </div>
              </div>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => setOpenModal(false)}>Cancel</Button>
            <Button onClick={handleAddTable} color="green" disabled={idExist} loading={loader}>
              Add Table
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    </>
  );
};

export default TableConfigurationCreate;
