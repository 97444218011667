/* eslint-disable no-sequences */
import { useEffect, useState } from "react";
import { responseError } from "../../../helpers";
import { advisorAPI } from "../../../apis/AdvisorsAPI";
import { useSelector } from "react-redux";
import { Button, Label } from "semantic-ui-react";
import { areasSimpleComplexNameMatch } from "../../../helpers/nameAreas";

const TabShowPermissions = ({activeTab}) => {
  const [loading, setLoading] = useState(false)
  const [permissionsFromAllAdvisorGroups, setPermissionsFromAllAdvisorGroups] = useState({})
  const [permissionsOfTheAdvisor, setPermissionsOfTheAdvisor] = useState({})
  const [showAreas, setShowAreas] = useState({})
  const advisorProfile = useSelector(state => state.currentAdvisorProfile);

  const getPermissions = async () => {
    setLoading(true)
    try {
      const {data} = await advisorAPI.get(`/${advisorProfile?._id}/enterprise-permissions`);
      for (const key in data.permissionsActiveFromAllAdvisorGroupsSectioned) {
        data.permissionsActiveFromAllAdvisorGroupsSectioned[key].fields = 
          data.permissionsActiveFromAllAdvisorGroupsSectioned[key].fields.filter(permission => !data.advisorPermissions[key].fields.includes(permission))
      }
      const sort = (object) => {
        return Object.keys(object).sort().reduce((r, k) => (r[k] = object[k], r), {})
      }
      const permissionsActiveFromAllAdvisorGroupsSectioned = sort(data.permissionsActiveFromAllAdvisorGroupsSectioned)
      const advisorPermissions = sort(data.advisorPermissions);
      setPermissionsFromAllAdvisorGroups(permissionsActiveFromAllAdvisorGroupsSectioned);
      setPermissionsOfTheAdvisor(advisorPermissions);
    } catch (error) {
      responseError(error, undefined, "TabShowPermmissions.getPErmissions");
    }
    setLoading(false)
  }

  useEffect(() => {
    if(advisorProfile?._id){
      getPermissions();
    }

    // eslint-disable-next-line
  },[advisorProfile])

  const transformKey = (key) => {
    return key.toUpperCase().replace('_', ' ')
  }

  return <>
    {activeTab === "Permissions" && <>
      {loading ? <>Loading...</> : <>
        <div className="flex-fpalpha " style={{gap: "15px", flexWrap: 'wrap'}} >
          <div className="card-border-line" style={{width: "100%"}}>
            <h3>
              Permissions in Groups
            </h3>
            <div style={{display: 'flex', flexWrap: 'wrap'}}>
              {Object.keys(permissionsFromAllAdvisorGroups).map((key,index) => (
                <div style= {{width: key === 'areas' ? '100%' :'25%', marginBottom: '10px'}} key={index}>
                  <div>
                    <strong>{transformKey(key)} <span className='grayaccent'>{permissionsFromAllAdvisorGroups[key].fields.length || 0}</span></strong>
                  </div>
                  {!showAreas[key] &&
                    <div className="flex-wrap_fp" style={{marginTop: "5px"}}>
                      {permissionsFromAllAdvisorGroups[key].fields.map((field, index2) => (
                        <div key={index2}>
                          <Label>
                            {areasSimpleComplexNameMatch[field] || field}
                          </Label>
                        </div>
                      ))}
                    </div>
                  }
                  <Button basic className='helper_button' size='mini' onClick={() => setShowAreas({...showAreas, [key]: !showAreas[key]})}>{showAreas[key] ? 'Show' : 'Hide'}</Button>
                </div>
              ))}
            </div>
          </div>
          <div className="card-border-line" style={{width: "100%"}}>
            <h3>
              Permissions in User
            </h3>
            <div style={{display: 'flex', flexWrap: 'wrap'}}>
              {Object.keys(permissionsOfTheAdvisor).map((key,index) => (
                <div style= {{width: key === 'areas' ? '100%' :'25%', marginBottom: '10px'}} key={index}>
                  <div>
                  <strong>{transformKey(key)} <span className='grayaccent'>{permissionsOfTheAdvisor[key].isActive ? permissionsOfTheAdvisor[key].fields.length || 0 : 0}</span></strong>
                  </div>
                  {!showAreas[key] &&
                    <div className="flex-wrap_fp" style={{marginTop: "5px"}}>
                      {permissionsOfTheAdvisor[key].isActive && permissionsOfTheAdvisor[key].fields.map((field, index2) => (
                        <div key={index2}>
                          <Label>
                            {areasSimpleComplexNameMatch[field] || areasSimpleComplexNameMatch[`${key}:${field}`] || field}
                          </Label>
                        </div>
                      ))}
                    </div>
                  }
                  <Button basic className='helper_button' size='mini' onClick={() => setShowAreas({...showAreas, [key]: !showAreas[key]})}>{showAreas[key] ? 'Show' : 'Hide'}</Button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </>}
    </>}
  </>
}

export default TabShowPermissions;