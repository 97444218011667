import { useEffect, useState } from "react";
import { sysAPI } from "../apis/SysAPI";
import { useSelector } from "react-redux";
import { DashboardTemplate } from "../templates/DashboardTemplate"
import { Button, Pagination, Container, Table, Input, Tab, Segment, Dimmer, Loader } from 'semantic-ui-react'
import { TabContainer } from '../components/shared/TabContainer';
import { ManualInputModal } from "../components/modal/ManualInputModal";
import { ComponentTemplate } from "../templates/ComponentTemplate";
import EstateManualInputRequestsTab from "../components/tabs/EstateManualInputRequestsTab/index";
import { HomeRequestsTab } from "../components/tabs/HomeRequestsTab";
import { AutoRequestsTab } from "../components/tabs/AutoRequestsTab";
import MessageTablesEmpty from "../components/globals/MessageTablesEmpty";

export const ManualInput = () => {
    const pageSize = 15;

    const [activePage, setActivePage] = useState(1);
    const [advisorsList, setAdvisorsList] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [searchInput, setSearchInput] = useState('');
    const [selectedAdvisor, setSelectedAdvisor] = useState(null);
    // eslint-disable-next-line
    const [showEstateRequests, setShowEstateRequests] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [filterButtonIsDisabled, setFilterButtonIsDisabled] = useState(true)
    const [counterSearched, setCounterSearched] = useState(0)

    const pageChange = (e, { activePage }) => {
        setActivePage(activePage);
    }

    useEffect(() => {
        searchBtn_Click(false);

        // eslint-disable-next-line
    }, [activePage])

    const searchBtn_Click = async (ShowAlert = true) => {
        if(filterButtonIsDisabled){
            if(ShowAlert){
                alert("not enough params to search");
            }
            return
        }
        setIsLoading(true)
        try {
            const response = await sysAPI.get(`/advisors`, {
                params: {
                    page: activePage,
                    size: pageSize,
                    query: searchInput
                }
            })

            const { advisorsList, totalPages } = response.data;
            setAdvisorsList(advisorsList);
            setTotalPages(totalPages);
            setCounterSearched(counterSearched+1)
        } catch (error) {
            console.log({error})
            alert(error?.response?.data?.message || error.message || "something is wrong, try again...");
        }
        setIsLoading(false)
    }

    const advisorRow_Click = (advisor) => {
        return () => {
            setSelectedAdvisor(advisor);
        }
    }

    useEffect(() => {
        if(searchInput.length <= 2){
            setFilterButtonIsDisabled(true)
        }else{
            setFilterButtonIsDisabled(false)
        }
    },[searchInput])

    return (
        <ComponentTemplate scope={'questionnaire_manual_input'} permission="manual_inputs:questionnaire_manual_input_view">
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <div>
                    <Input
                        value={searchInput}
                        onChange={e => setSearchInput(e.currentTarget.value)}
                        icon='search'
                        action={{ content: 'Search', onClick: searchBtn_Click, loading:isLoading, disabled:(isLoading || filterButtonIsDisabled) }}
                        iconPosition='left'
                        style={{ marginRight: '10px', width: '500px' }}
                        onKeyPress={e => (e.charCode === 13 || e.keyCode === 13 || e.which === 13) && searchBtn_Click()}
                    />
                    <Button onClick={() => setSearchInput('')} disabled={(isLoading)}>Clear filter</Button>
                </div>
            </div>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Full Name</Table.HeaderCell>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                        <Table.HeaderCell>Company</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {isLoading && <Table.Row>
                        <Table.Cell colSpan={12}>
                            <Segment style={{ width: '100%', height: '300px' }}>
                                <Dimmer active inverted>
                                    <Loader inverted>Loading</Loader>
                                </Dimmer>
                            </Segment>
                        </Table.Cell>
                    </Table.Row>}
                    {!isLoading && advisorsList?.map(advisor => (
                        <Table.Row key={advisor._id} style={{ color: advisor.hasActiveSession ? 'green' : 'black' }}>
                            <Table.Cell>
                                <span onClick={advisorRow_Click(advisor)} style={{ cursor: 'pointer' }}>
                                    {advisor.fullName}
                                </span>
                            </Table.Cell>
                            <Table.Cell>{advisor.email}</Table.Cell>
                            <Table.Cell>{advisor.nameCompany}</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            {!isLoading && advisorsList.length < 1 &&  <MessageTablesEmpty counterSearched={counterSearched} />}
            <Container fluid textAlign='right'>
                <Pagination totalPages={totalPages} activePage={activePage} onPageChange={pageChange} />
            </Container>
            {selectedAdvisor &&
                <ManualInputModal advisor={selectedAdvisor} setAdvisor={setSelectedAdvisor} />
            }
        </ComponentTemplate>
    )
}

export const DashboardManualInputView = () => {
    const me = useSelector(state => state.me);

    const tabPanes = [];

    if (me?.permissions?.includes("manual_inputs:questionnaire_manual_input_view")) {
        tabPanes.push({ menuItem: { key: 'manualInput', content: 'Manual Input' }, render: () => <ManualInput /> });
    }

    if (me?.permissions?.includes("manual_inputs:estate_manual_input_view")) {
        tabPanes.push({ menuItem: { key: 'estateInput', content: 'Estate Manual Input' }, render: () => <EstateManualInputRequestsTab /> });
    }

    if (me?.permissions?.includes("manual_inputs:p&c_manual_input_view")) {
        tabPanes.push({ menuItem: { key: 'home', content: 'Home Manual Input' }, render: () => <HomeRequestsTab /> });
        tabPanes.push({ menuItem: { key: 'auto', content: 'Auto Manual Input' }, render: () => <AutoRequestsTab /> });
    }

    return (
        <DashboardTemplate scope={'manual_input'} permission="manual_inputs:manual_input_view">
            <TabContainer
                title='Manual Input'
                subtitle={`Input data for a client's questionnaire`}
            >
                <Tab menu={{ fluid: true, vertical: true }} panes={tabPanes} style={{ marginTop: '20px' }}  grid={{ paneWidth: 14, tabWidth: 2 }} />
            </TabContainer>
        </DashboardTemplate>
    )
}