import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from '../../actions/snackbar.actions';

const Snackbar = () => {
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const dispatch = useDispatch();

  const infoSnackbar_aux = useSelector((state) => state.snackbar);
  const colorStatus = {
    success: {
      background: '#72ce77',
      text: 'white',
    },
    error: {
      background: '#ce7272',
      text: 'white',
    },
    warning: {
      background: '#ffa136',
      text: 'white',
    },
  };
  const infoSnackbar = {
    message: infoSnackbar_aux.message || '',
    timeout: infoSnackbar_aux.timeout || 3000,
    color: infoSnackbar_aux.color || colorStatus[infoSnackbar_aux.status]?.background || '#000000',
    colorText: infoSnackbar_aux.colorText || colorStatus[infoSnackbar_aux.status]?.text || 'white',
    position: infoSnackbar_aux.position || 'center',
    size: infoSnackbar_aux.size || 'small',
  };

  //console.log({ infoSnackbar });

  const showSnackbar = () => {
    setSnackbarOpen(true);
    setTimeout(() => {
      closeSnakbar()
    }, infoSnackbar.timeout); // El snackbar se cierra automáticamente después de 3 segundos
  };

  const closeSnakbar = () => {
    setSnackbarOpen(false);
    setTimeout(() => {
      dispatch(setSnackbar({}));
    }, 500);
  }

  useEffect(() => {
    if (infoSnackbar.message) {
      showSnackbar();
    }

    // eslint-disable-next-line
  }, [infoSnackbar.message]);

  // Estilos del Snackbar
  const styles = {
    snackbar: {
      position: 'fixed',
      bottom: '50px',
      left: infoSnackbar.position === 'center' ? '50%' : infoSnackbar.position === 'end' ? '90%' : '10%',
      transform: `translateX(-50%) ${isSnackbarOpen ? 'translateY(0)' : 'translateY(20px)'}`,
      backgroundColor: infoSnackbar.color,
      color: infoSnackbar.colorText,
      padding: infoSnackbar.size === 'small' ? '10px 20px' : infoSnackbar.size === 'large' ? '20px 25px' : '15px 20px',
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      zIndex: 10000,
      borderRadius: '15px 2px 15px 2px',
      opacity: isSnackbarOpen ? 1 : 0,
      transition: 'opacity 0.5s ease, transform 0.5s ease',

    },
    closeButton: {
      background: 'transparent',
      border: 'none',
      color: infoSnackbar.colorText,
      fontSize: '16px',
      cursor: 'pointer',
    },
  };

  return (
    <div>
      <div style={styles.snackbar}>
        {infoSnackbar.message}
        <button style={styles.closeButton} onClick={() => closeSnakbar()}>✖</button>
      </div>
    </div>
  );
};

export default Snackbar;
