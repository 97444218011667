import { Menu } from "semantic-ui-react"

export const AdvisorMenu = ({  activeTab, setActiveTab }) => {
    return (
        <Menu attached='top'>
            <Menu.Item active={activeTab === 'Overview'} onClick={() => setActiveTab('Overview')} name='Overview' />
            <Menu.Item active={activeTab === 'Clients'} onClick={() => setActiveTab('Clients')} name='Clients' />
            <Menu.Item active={activeTab === 'Activity'} onClick={() => setActiveTab('Activity')} name='Activity' />
            <Menu.Item active={activeTab === 'Permissions'} onClick={() => setActiveTab('Permissions')} name='Permissions' />
        </Menu>
    )
}