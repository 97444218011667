import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sysAPI } from "../../apis/SysAPI";
import { Button, Modal, Select } from "semantic-ui-react";
import { fetchManualInputDataPoints } from "../../actions/estate-manual-input.actions";

const ReAssignReviewers = ({area , clientId, assetIndex}) => {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const [openModal, setOpenModal] = useState(false)
    const [users, setUsers] = useState([])
    const [reviewerId, setReviewerId] = useState("");
    const [loader, setLoader] = useState(false)


    const estateManualInputRequest = useSelector(state => state.estateManualInputRequest);
    const getUsers = async () => {
        try {
            const {data} = await sysAPI.get(`/list-reviewers`);
            const optionsUser = data.data.map(user => {
                return {
                    key: user._id,
                    value: user._id,
                    text: `${user.firstName} ${user.lastName} (${user.email})`,
                }
            })

            setUsers(optionsUser);
        } catch (error) {
            console.error({error})
        }
    }

    const handleReasign = async () => {
        setLoader(true)
        try {
            const {data} = await sysAPI.put(`/change-reviewer`, {reviewer_id: reviewerId}, {params: {
                clientId, area
            }})
            alert(data.message || "Reviewer changed")
            dispatch(fetchManualInputDataPoints(clientId, assetIndex));
            setOpenModal(false)
        } catch (error) {
            console.error({error})
            alert(error.response?.data?.message || error.message || "Something was wrong...")
        }
        setLoader(false)
    }

    useEffect(() => {
        getUsers()

    }, [])

    useEffect(() => {
        setReviewerId(estateManualInputRequest?.reviewer?._id || "");
        if(estateManualInputRequest?.reviewer?._id){
            const sysUserActual = estateManualInputRequest?.reviewer
            const existsInTheUsers = users.find(e => e.value === sysUserActual?._id);
            if(!existsInTheUsers){
                setUsers([...users, {key:sysUserActual?._id, value: sysUserActual?._id, text: `${sysUserActual.firstName} ${sysUserActual.lastName} (${sysUserActual.email})`}])
            }
        }

    // eslint-disable-next-line
    },[estateManualInputRequest, openModal])

    if(!me?.permissions?.includes('manual_inputs:can_reassign_reviewers')){
        return <></>
    }

    return <div>
        {estateManualInputRequest?.reviewer?._id &&
            <Button color="yellow" onClick={() => setOpenModal(!openModal)}>Reasign Reviewer</Button>
        }

        <Modal size="tiny" open={openModal}>
        <Modal.Header>Reasign the reviewer</Modal.Header>
        <Modal.Content>
          <div>
            <div style={{marginBottom: "10px"}}>
                Actual Reviewer is <strong>{estateManualInputRequest?.reviewer?.firstName} {estateManualInputRequest?.reviewer?.lastName} ({estateManualInputRequest?.reviewer?.email})</strong>
            </div>
            <div>
                <div>Select new Reviewer</div>
                <Select
                    value={reviewerId}
                    options={users}
                    fluid
                    onChange={(e,{value}) => setReviewerId(value)}
                />
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpenModal(false)}>Cancel</Button>
          <Button onClick={handleReasign} color="green" loading={loader}>
            Reasign
          </Button>
        </Modal.Actions>
        </Modal>
    </div>
}

export default ReAssignReviewers;