/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import {
    Button,
    Tab,
    Input,
    Dropdown,
    Message,
} from "semantic-ui-react"
import { sysAPI } from "../../../apis/SysAPI";
import { ComponentTemplate } from "../../../templates/ComponentTemplate";
import LoaderCustom from "./LoaderCustom";
import RequestsTable from "./RequestsTable";
import moment from "moment";

const EstateManualInputRequestsTab = () => {
    const [requests, setRequests] = useState([]);
    const [pending, setPending] = useState([]);
    const [inReview, setInReview] = useState([]);
    const [peerReview, setPeerReview] = useState([]);
    const [onHold, setOnHold] = useState([]);
    const [ready, setReady] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    // eslint-disable-next-line
    const [changeMaps, setChangeMaps] = useState(0);
    const [fromDate, setFromDate] = useState(moment().add(-1, "month").format("YYYY-MM-DD"));
    const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
    const [sort, setSort] = useState('requestDate');
    const [direction, setDirection] = useState('ASC');
    // eslint-disable-next-line
    const [initDate, setInitDate] = useState('');
    const [companiesList, setCompaniesList] = useState([]);
    const [companySelected, setCompanySelected] = useState('');
    const [pocsLists, setPocsLists] = useState([]);
    const [pocSelected, setPocSelected] = useState('');
    const [refreshList, setRefreshList] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");

    const getRequests = async () => {
        setIsLoading(true);
        try {
            const params__ = {
                area: "estate"
            }
            if(sort){
                params__.filter = sort;
                params__.order = direction;
            }
            if(fromDate){
                params__.initDate = fromDate;
            }
            if(toDate){
                params__.finishDate = toDate;
            }

            if(companySelected !== '' && companySelected !== 'N/A'){
                params__.tabFilter = "company";
                params__.tabValue = companySelected;
            }

            if(pocSelected !== '' && pocSelected !== 'N/A'){
                params__.tabFilter = "poc";
                params__.tabValue = pocSelected;
            }
            const {data} = await sysAPI.get(`/estate-manual-input/requests`,{
                params: params__
            });
            setRequests(data.requests);
            if(data.requests.length <= 0){
                alert('No matches found for your current filters.');
            }
        } catch (error) {
            console.log({error});
            alert(error?.response?.data?.message || error?.message || "something was wrong...")
        }

        setIsLoading(false);
    };

    const getInitData = async () => {
        try {
            const response = await sysAPI.get(`/companies/emi`)
            const { companiesList } = response.data;
            const list = companiesList.map(company => ({ key: company, text: company, value: company }))
            list.unshift({ key: 'all', text: 'All', value: '' });
            setCompaniesList(list);

            const response_ = await sysAPI.get('/companies/pocs')
            const pocs = response_.data.map(advisor => ({ key: advisor._id, text: advisor.name, value: advisor.name}));
            pocs.unshift({ key: 'all', text: 'All', value: '' });
            setPocsLists(pocs);
        } catch (error) {
            console.log({error});
        }
    }

    useEffect(() => {
        getRequests();
    }, [sort, direction, initDate]);

    useEffect(() => {
        if (refreshList > 0) {
            getRequests();
        }
    }, [refreshList]);

    useEffect(() => {
        if (requests.length >= 0) {
            const mapPending = requests.filter(r => r.status === 'Pending') || [];
            const mapInReview = requests.filter(r => r.status === 'In review') || [];
            const mapPeerReview = requests.filter(r => r.status === 'Peer review') || [];
            const mapOnHold = requests.filter(r => r.status === 'On Hold') || [];
            const mapReady = requests.filter(r => r.status === 'Ready') || [];

            setPending(mapPending);
            setInReview(mapInReview);
            setPeerReview(mapPeerReview);
            setOnHold(mapOnHold);
            setReady(mapReady);
        }
    }, [requests]);

    useEffect(() => {
        if (changeMaps >= 1) {
            setIsLoading(true);
            getRequests();
        }
    }, [changeMaps]);

    useEffect(() => {
        getInitData();
    }, []);

    useEffect(() => {
        console.log({fromDate, toDate})
        const fromDateMoment = moment(fromDate);
        const toDateMoment = moment(toDate);
        if(toDateMoment.diff(fromDateMoment, "months") > 3){
            setErrorMessage("We cannot search between dates with a difference of more than 3 months");
        }else{
            setErrorMessage("")
        }
    },[fromDate, toDate])

    const countRequests = (status) => {
        return requests?.filter(r => r.status === status).length;
    }

    const panes = () => ([
        { menuItem: `Pending (${countRequests('Pending')})`, render: () => <RequestsTable requests={pending} stage='Pending' setSort={setSort} setDirection={setDirection} sort={sort} direction={direction} /> },
        { menuItem: `In Review (${countRequests('In review')})`, render: () => <RequestsTable requests={inReview} stage='In review' setSort={setSort} setDirection={setDirection} sort={sort} direction={direction} /> },
        { menuItem: `On Hold (${countRequests('On Hold')})`, render: () => <RequestsTable requests={onHold} stage='On Hold' setSort={setSort} setDirection={setDirection} sort={sort} direction={direction} refreshList={setRefreshList} /> },
        { menuItem: `Under Peer Review (${countRequests('Peer review')})`, render: () => <RequestsTable requests={peerReview} stage='Peer review' setSort={setSort} setDirection={setDirection} sort={sort} direction={direction} /> },
        { menuItem: `Ready (${countRequests('Ready')})`, render: () => <RequestsTable requests={ready} stage='Ready' setSort={setSort} setDirection={setDirection} sort={sort} direction={direction} /> },
    ]);

    return (
        <ComponentTemplate scope={'estate_manual_input'} permission="manual_inputs:estate_manual_input_view">
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    gap: '12px',
                }}
            >
                <div>
                    <div>Filter by Company: </div>
                    <Dropdown
                        placeholder='Filter by Company'
                        selection
                        search
                        options={companiesList}
                        value={companySelected}
                        onChange={(e, { value }) => {setPocSelected(''); setCompanySelected(value)}}
                    />
                </div>
                <div>
                    <div>Filter by POC: </div>
                    <Dropdown
                        placeholder='Filter by POC'
                        selection
                        search
                        options={pocsLists}
                        value={pocSelected}
                        onChange={(e, { value }) => {setCompanySelected(''); setPocSelected(value)}}
                    />
                </div>
                <div>
                    <div>
                        From Date:
                    </div>
                    <Input
                        value={fromDate}
                        onChange={e => setFromDate(e.currentTarget.value)}
                        icon='calendar alternate outline'
                        iconPosition='left'
                        type='date'
                        min='2022-05-31'
                    />
                </div>
                <div>
                    <div>
                        To Date:
                    </div>
                    <Input
                        value={toDate}
                        onChange={e => setToDate(e.currentTarget.value)}
                        icon='calendar alternate outline'
                        iconPosition='left'
                        type='date'
                    />
                </div>
                <Button onClick={() => getRequests()} disabled={errorMessage}>Filter</Button>
            </div>
            {errorMessage && 
                <div style={{display: "flex", justifyContent: "flex-end"}}>
                    <Message color="red" size="mini"> {errorMessage} </Message>
                </div>
            }

            <Tab
                menu={{ secondary: true, pointing: true }}
                panes={panes()}
            />
            {isLoading && <LoaderCustom />}
        </ComponentTemplate>
    )
}

export default EstateManualInputRequestsTab;