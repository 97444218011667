/* eslint-disable no-mixed-operators */
import { useEffect, /* useState */ } from "react"
import { DashboardTemplate } from "../templates/DashboardTemplate"
import { TabContainer } from "../components/shared/TabContainer"
import { paymentAPI } from "../apis/PaymentAPI";
//import { Redirect } from "react-router-dom";

export const QuickbooksCallbackView = () => {


    useEffect(() => {
        paymentAPI.post('/quickbooks/callback', {url: window.location.href})
        .then(response => {
            window.location.replace('/dashboard/quickbooks')
        });
    }, []);



    return (
        <DashboardTemplate scope={'admin'}>
            <TabContainer>
                <p>Pending request...</p>
            </TabContainer>
        </DashboardTemplate>
    )
}
