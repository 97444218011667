import { useEffect, useState } from "react";
import { Button, Icon, Input, Select } from "semantic-ui-react";
import { advisorAPI } from "../../../../../apis/AdvisorsAPI";
import { fetchCurrentCompany } from "../../../../../actions/company.actions";
import { useDispatch } from "react-redux";

const TableConfiguration = ({ companyProfile }) => {
  const configuration = companyProfile?._integrations?.salesforce;
  const [tablesSettings, setTablesSettings] = useState([]);

  useEffect(() => {
    setTablesSettings(configuration?.settings?.tables || []);

    // eslint-disable-next-line
  }, [companyProfile]);

  return (
    <div>
      <div className="flex-gap-5" style={{ gap: "25px" }}>
        {tablesSettings.map((table, index) => (
          <div key={index}>
            <CardTable tables={configuration?.settings?.tables || []} table={table} index={index} companyProfile={companyProfile} />
          </div>
        ))}
      </div>
    </div>
  );
};

const CardTable = ({ tables, table, index, companyProfile }) => {
  const dispatch = useDispatch();
  const optionsTabs = ["Personal","Family","Assets"].map(e => ({key: e, value: e, text:e}))
  const [loadingMapper, setLoadingMapper] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showRelation, setShowRelation] = useState(false);
  const [selectTabs, setSelectTabs] = useState([])
  const [relations, setRelations] = useState({})
  const [label, setLabel] = useState("")
  const [qualifiedApiName, setQualifiedApiName] = useState("")

  const handleChangeShowMapper = async () => {
    setLoadingMapper(true)
    try {
      const newTable = JSON.parse(JSON.stringify(table));
      const newtables = tables

      if(!newtables[index]){
        // eslint-disable-next-line
        throw { message: "not exists the position"}
      }
      newTable.showMapperFields = !newTable.showMapperFields
      newtables[index] = newTable

      await advisorAPI.put(`/companies/${companyProfile._id}`, {
        [`_integrations.salesforce.settings.tables`]: newtables,
      });

      await dispatch(fetchCurrentCompany(companyProfile._id));
    } catch (error) {
      console.log({error});
      alert("Error.");
    }
    setLoadingMapper(false)
  }

  const handleSaveChanges = async () => {
    setLoading(true)
    try {
      const newTable = JSON.parse(JSON.stringify(table));
      const newtables = tables

      if(!newtables[index]){
        // eslint-disable-next-line
        throw { message: "not exists the position"}
      }

      newTable.label = label
      newTable.qualifiedApiName = qualifiedApiName
      newTable.tabs = selectTabs
      newTable.relations = relations
      newtables[index] = newTable
      await advisorAPI.put(`/companies/${companyProfile._id}`, {
        [`_integrations.salesforce.settings.tables`]: newtables,
      });

      await dispatch(fetchCurrentCompany(companyProfile._id));
    } catch (error) {
      console.log({error})
      alert("error in save table")
    }
    setLoading(false)
  }

  useEffect(() => {
    setSelectTabs(table?.tabs || [])
    setLabel(table?.label || "")
    setQualifiedApiName(table?.qualifiedApiName || "")
    setRelations(table?.relations || {})

     // eslint-disable-next-line
  },[companyProfile])
  return (
    <>
      <div className="card-border-line" style={{minWidth:"300px"}}>
        <div>
          <strong>ID: </strong> {table.id}
        </div>
        <div>
          <Button loading={loadingMapper} onClick={() => handleChangeShowMapper()} color={table.showMapperFields ? 'green' : 'red'}>Show Mapper Fields: {table.showMapperFields ? 'Yes' : 'No'}</Button>
        </div>
        <div style={{marginTop: "10px"}}>
          <div>
            Label
          </div>
          <div>
            <Input defaultValue={label} onChange={(e,_) => setLabel(_.value)} fluid />
          </div>
        </div>
        <div style={{marginTop: "10px"}}>
          <div>
            Qualified Api Name
          </div>
          <div>
            <Input defaultValue={qualifiedApiName} onChange={(e,_) => setQualifiedApiName(_.value)} fluid />
          </div>
        </div>
        <div style={{marginTop: "10px"}}>
          <div>
            Show in Tabs
          </div>
          <div>
            <Select options={optionsTabs} multiple value={selectTabs} onChange={(e,_) => setSelectTabs(_.value)} fluid  />
          </div>
        </div>
        <div style={{marginTop: "10px"}}>
          <div style={{cursor:"pointer"}} onClick={() => setShowRelation(!showRelation)}>
            Relations ({Object.keys(relations || {}).length}) <Icon name={showRelation ? "angle up" : "angle down"} />
          </div>
          {showRelation && (
            <div>
              {
                Object.keys(relations || {}).map((key, index) => (
                  <div key={index}>
                    <div>
                      {key}
                    </div>
                    <div>
                      <Input fluid  defaultValue={relations[key]} onChange={(e,_) => setRelations({...relations, [`${key}`]: _.value})} />
                    </div>
                  </div>
                ))
              }
            </div>
          )}
        </div>
        <div style={{marginTop: "5px"}}>
          <Button fluid loading={loading} color="green" onClick={() => handleSaveChanges()}>Save changes</Button>
        </div>
      </div>
    </>
  );
};

export default TableConfiguration;
