import { useEffect, useState } from "react";
import { Button, Icon, Input, Label, Message, Modal } from "semantic-ui-react";
import { advisorAPI } from "../../../../../apis/AdvisorsAPI";
import { fetchCurrentCompany } from "../../../../../actions/company.actions";
import { useDispatch } from "react-redux";

const DontMap = ({ settingsType, companyProfile, propType }) => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [dontMap, setDontMap] = useState([]);

  const addNoMap = () => {
    const newValue = {
      key: "",
      values: [],
    };
    const dontMap__ = JSON.parse(JSON.stringify(dontMap));
    dontMap__.push(newValue);
    setDontMap(dontMap__);
  };

  useEffect(() => {
    const dontMap__ = settingsType?.dontMap || [];
    setDontMap(dontMap__);

    // eslint-disable-next-line
  }, [companyProfile]);

  const handleSave = async () => {
    setLoader(true)
    try {
      // eslint-disable-next-line
      const {data} = await advisorAPI.put(`/companies/${companyProfile._id}`, {
        [`_integrations.salesforce.settings.mapperTypes.${propType}.dontMap`]: dontMap,
      });
      await dispatch(fetchCurrentCompany(companyProfile._id));
      setOpenModal(false)
    } catch (error) {
      console.log({error});
      alert(error.response?.data?.message || error.message || "Somethins was wrong... try again please.")
    }
    setLoader(false)
  }

  return (
    <>
      <Button size="mini" onClick={() => setOpenModal(!openModal)}>
        Don't Map
      </Button>

      <Modal open={openModal} >
        <Modal.Header>Updating Don't Map</Modal.Header>
        <Modal.Content>
          {dontMap.map((noMap, index) => (
            <div key={index}>
              <NoMap dontMap={dontMap} setDontMap={setDontMap} noMap={noMap} index={index} />
            </div>
          ))}

          <div className="flex-alpha justify-content-end" style={{marginTop: "5px"}}>
            <Button onClick={() => addNoMap()} size="mini">
              Add Key
            </Button>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpenModal(false)} disabled={loader}>Cancel</Button>
          <Button onClick={() => handleSave()} color="green" loading={loader}>
            Save Changes
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

const NoMap = ({ dontMap, setDontMap, noMap, index }) => {
  const [key, setKey] = useState("");
  const [values, setValues] = useState([]);
  const [newValue, setNewValue] = useState("")


  const removeNoMap = (index) => {
    const dontMap__ = JSON.parse(JSON.stringify(dontMap));
    dontMap__.splice(index, 1);
    setDontMap([]);
    setTimeout(() => {
      setDontMap(dontMap__);
    }, 50);
  };

  const addValue = (value) => {
    if(!value){
      alert("It can't be empty");
      return
    }
    if(values.includes(value)){
      alert(`"${value}" already exists!`)
      return
    }

    const values__ = JSON.parse(JSON.stringify(values));
    values__.push(value);
    setValues(values__);
    setNewValue("")
  };

  const removeValue = (index) => {
    const values__ = JSON.parse(JSON.stringify(values))
    values__.splice(index, 1);
    setValues(values__);
  }

  useEffect(() => {
    setKey(noMap.key)
    setValues(noMap.values)

    // eslint-disable-next-line
  },[])

  useEffect(() => {
    const dontMap__ = JSON.parse(JSON.stringify(dontMap));
    dontMap__[index] = {
      key, values
    }
    setDontMap(dontMap__)

    // eslint-disable-next-line
  }, [key, values])

  return (
    <>
      <div style={{display: "grid", gap:"10px"}}>
        <div className="flex flex-space-between items-center">
          <h3>Key #{index + 1}</h3>
          <div>
            <Button icon onClick={() => removeNoMap(index)}>
              <Icon name="trash" />
            </Button>
          </div>
        </div>
        <div>
          <div>Name key: </div>
          <Input defaultValue={key} fluid onChange={(e, { value }) => setKey(value)} />
        </div>
        <div>
          <div>Add new word</div>
          <Input
            value={newValue}
            fluid onChange={(e, {value}) => setNewValue(value)}
            onKeyPress={e => (e.charCode === 13 || e.keyCode === 13 || e.which === 13) && addValue(newValue)}
            icon={<Icon name='plus' inverted circular link onClick={() => addValue(newValue)} />}
          />
        </div>
        <div>
          Keys added:
          <div className="flex-fpalpha" style={{gap:"5px", flexWrap: "wrap"}}>
            {values.length < 1 && <Message color="orange" size="mini">No words added...</Message> }
            {values.map((value, index2) => (
              <div key={index2} >
                <Label>
                  {value} <Icon name='delete' onClick={() => removeValue(index2)} />
                </Label>
              </div>
            ))}
          </div>
        </div>
        <hr />
      </div>
    </>
  );
};

export default DontMap;
