export const setSnackbar = (data) => {
    const info = {
        message: data.message,
        timeout: data.timeout,
        color: data.color,
        colorText: data.colorText,
        position: data.position,
        size: data.size,
        status: data.status || "success"
    }
    return {
        type: 'set_snackbar',
        payload: info,
    };
}
