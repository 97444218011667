import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { advisorAPI } from '../../apis/AdvisorsAPI';
import { Modal, Input, Message, Segment, Button, Form } from 'semantic-ui-react';


export const AddCreditsModal = ({ modalState, setModalState }) => {
  const companyProfile = useSelector((state) => state.currentCompany);
  //const [area, setArea] = useState('estate');
  //const [quantity, setQuantity] = useState(5);
  const [areas, setAreas] = useState({});
  const [advisorLength, setAdvisorLength] = useState(0);
  const areasNames = {
    estate: 'Estate',
    home: 'Home',
    auto: 'Auto',
    fulltax: 'Fulltax',
  }

  useEffect(() => {
    const areasAux = {};
    for (const areaKey in areasNames) {
      areasAux[areaKey] = companyProfile.uploadModel?.areas?.[areaKey]?.included || false;
    }
    setAreas(areasAux);
    const super_admins = companyProfile?.advisorList?.filter(e => e.enterpriseRole?.key === "super_admin").length || 0
    const admins = companyProfile?.advisorList?.filter(e => e.enterpriseRole?.key === "admin").length || 0
    const full_advisor = companyProfile?.advisorList?.filter(e => e.enterpriseRole?.key === "full_advisor").length || 0
    //console.log({super_admins, admins, full_advisor})
    setAdvisorLength(super_admins+admins+full_advisor);

    // eslint-disable-next-line
  }, [companyProfile?._id]);

  const setIncluded = (area, value) =>{
    const areaAux = areas;
    areaAux[area] = +value;
    setAreas({...areaAux})
  }
  const purchaseCredits = async () => {
    try {
      for (const areaKey in areas) {
        if (areaKey === 'fulltax' && companyProfile?.uploadModel?.type !== 'limited') {
          continue;
        }
        const data = {
          companyId: companyProfile?._id || '',
          area: areaKey,
          credits: +areas[areaKey],
        }
        if (+companyProfile?.uploadModel?.areas[areaKey].included !== +areas[areaKey]) {
          await advisorAPI.post('/companies/upload-credits/add-credits', data);
        }
      }

        setModalState(false);
    } catch (error) {
      console.log(error)
    }
  };

  const AreaField = (area) => {
    if (area === 'fulltax' && companyProfile?.uploadModel?.type !== 'limited') {
      return (<></>);
    }
    return (
      <div key={areasNames[area]} style={{display: 'flex', justifyContent: 'space-evenly', margin: '2px 0', alignItems: 'center'}}>
        <label style={{width: '20%'}}>{areasNames[area]}</label>
        <Input style={{width: '20%'}} value={areas[area] || 0} type='number' step={10} min={10} onChange={(e) => setIncluded(area, e.target.value)}/>
        <label style={{width: '25%'}}>{advisorLength * areas[area]}</label>
        {/* <Form.Field control={Radio} label="Estate" checked={area === 'estate'} onChange={() => setArea('estate')} /> */}
      </div>
    )
  }

  return(
    <Modal size='tiny' open={modalState} onClose={() => setModalState(false)} onOpen={() => setModalState(true)}>
      <Modal.Header>Rewrite Credits</Modal.Header>
      <Modal.Content>
      <Segment>
          <Form>
            <Message 
              color="yellow"
              icon="warning"
              content="The number of credits that should be given to the company, per advisor."
            />
          </Form>
      </Segment>
        <Segment>
          <Form>
          <div style={{display: 'flex', justifyContent: 'space-evenly', margin: '10px 0', alignItems: 'center'}}>
            <label style={{width: '20%'}}><h4>Area</h4></label>
            <label style={{width: '20%'}} ><h4>Credits</h4></label>
            <label style={{width: '25%'}}><h4>Total in Company</h4></label>
            
          </div>
            {Object.keys(areasNames).map(itm => (
              AreaField(itm)
            ))}
            <div style={{display: 'flex', justifyContent: 'end', margin: '20px 0 0 0', alignItems: 'center'}}>
              <label><h4>Total Full Account Advisors:</h4></label>
              <label style={{marginLeft: '15px'}} >{advisorLength}</label>
              
            </div>
          </Form>
        </Segment>
        <Segment>
          <Form>
            <Message 
              color="blue"
              content="This is the number of credits the company will receive annually.  Credits will expire and then re-load on the company's renewal date."
            />
          </Form>
      </Segment>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={() => setModalState(false)}>Cancel</Button>
        <Button
          color="green"
          onClick={purchaseCredits}
        >Submit</Button>
      </Modal.Actions>
    </Modal>
  );
};
