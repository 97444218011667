import { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import { Button, Icon } from "semantic-ui-react"
import { fetchCurrentCompany, fetchCurrentCompanyCredits } from "../../../actions/company.actions";
import { advisorAPI } from "../../../apis/AdvisorsAPI";

const ChangeUploadModeType = ({company, isButton=true}) => {
  const dispatch = useDispatch();
  const [isLegacy, setIsLegacy] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const text = {
    limited: "Adoption",
    adoption: "Limited"
  }

  useEffect(() => {
    if(company){
      setIsLegacy(company?.uploadModel?.type === "legacy");
    }
  },[company]);

  const handleChangeUploadModel = async() => {
    setIsLoading(true)

    // eslint-disable-next-line no-restricted-globals
    const confirmation = confirm(`Are you sure to change Upload Model Type to "${text[company?.uploadModel?.type] || "..."}"`);

    try {
      if(!confirmation){
        // eslint-disable-next-line no-throw-literal
        throw { message: "Canceled by user" }
      }
      const {data} = await advisorAPI.put(`/companies/${company?._id}/change-limited-or-adoption`, {
        uploadModelType: company?.uploadModel?.type === "adoption" ? "limited" : "adoption"
      });

      dispatch(fetchCurrentCompany(company._id));
      dispatch(fetchCurrentCompanyCredits(company._id, 'all'));
      alert(`${data.message}`);
    } catch (error) {
      console.error({error})
      alert(`Error: ${error?.response?.data?.message || error?.message || "Error"}`)
    }
    setIsLoading(false)
  }

  return <>
    {!isLegacy && (
      <>
        {isButton ? (
          <Button color="green" onClick={() => handleChangeUploadModel()} loading={isLoading} disabled={isLoading}>
            Change Upload Model to {text[company?.uploadModel?.type] || "..."}
          </Button>
        ) : (
          <div color="green" onClick={() => handleChangeUploadModel()}>
            <Icon name="exchange" /> {!isLoading ? `Change Upload Model to ${text[company?.uploadModel?.type] || "..."}` : `Changing, please wait...`}
          </div>
        )}
      </>
    )}
  </>
}

export default ChangeUploadModeType