import { useSelector } from "react-redux"
import { /* Card, */ Label, /* List, */ Header, Message, Tab, Table, /* Accordion */ } from 'semantic-ui-react';
import moment from 'moment';
//import { useState } from "react";

const ALL_AREAS = {
    'estate': 'Estate Planning',
    'auto': 'Auto',
    'crossborder': 'Cross Border',
    'disability': 'Disability',
    'home': 'Home',
    'medical': 'Medical',
    'identity': 'Identity',
    'student': 'Student Loan',
    'expense': 'Expense',
    'fulltax': 'Tax Planning',
    'ltc': 'LTC',
    'mortgage': 'Mortgage',
    'life': 'Life Planning',
    'divorce': 'Divorce Planning',
    'property': 'Property',
    'creditor': 'Creditor',
    'elder': 'Elder care',
};

export const AdvisorActivityTab = ({ activeTab }) => {
    const advisorActivity = useSelector(state => state.currentAdvisorActivity);
    const clients = useSelector(state => state.currentAdvisorClients);
    // eslint-disable-next-line
    const findClientFullName = (clientId) => {
        const client = clients.find(c => c._id === clientId);
        // eslint-disable-next-line
        return client && `${client.clientIDFirstName} ${client.clientIDLastName}` || '';
    }

    const timeDelta = (timestamps) => {
        const start = timestamps?.start;
        const end = timestamps?.end;

        if (!start || !end || !moment(start).isValid() || !moment(end).isValid()) {
            return 'N/A';
        }

        const delta = moment(end).diff(start);
        const duration = moment.duration(delta);
        return `${duration.minutes()}m ${duration.seconds()}s`;
    }

    const timestampFormat = (timestamp) => {
        if (!timestamp || !moment(timestamp).isValid()) {
            return 'N/A';
        }

        return moment(timestamp).format('MM/DD/YYYY HH:mm:ss');
    }

    return (
        <div style={{ display: activeTab === 'Activity' ? 'block' : 'none' }}>
            <Header>Processes by area</Header>
            <Tab menu={{attached: false , borderless: true,}} grid={{ paneWidth: 15, tabWidth: 1}}
                panes={[
                    {
                        menuItem: 'Extractions',
                        render: () => <Tab.Pane style={{ border: 'none'}}>
                            {
                                advisorActivity?.areasExtractions?.length > 0 &&
                                <Tab renderActiveOnly={true} style={{ border: 'none' }}
                                    panes={advisorActivity?.areasExtractions?.map(area => ({
                                        menuItem: ALL_AREAS[area.area],
                                        render: () =>
                                            <Tab.Pane>
                                                <Table>
                                                    <Table.Header>
                                                        <Table.Row>
                                                            <Table.Cell>Status</Table.Cell>
                                                            <Table.Cell>Client</Table.Cell>
                                                            <Table.Cell>Date</Table.Cell>
                                                            <Table.Cell>Start</Table.Cell>
                                                            <Table.Cell>End</Table.Cell>
                                                            <Table.Cell>Time Delta</Table.Cell>
                                                        </Table.Row>
                                                    </Table.Header>
                                                    <Table.Body>
                                                        {area.data?.map?.(item => {
                                                            return (<Table.Row>
                                                                <Table.Cell>
                                                                    <Label horizontal color={item.data.isValid ? 'green' : 'red'}>
                                                                        {item.data.isValid ? 'Valid' : 'Invalid'}
                                                                    </Label>
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                {item.clientData.firstName !== 'Unassigned' ? `${item.clientData.firstName} ${item.clientData.lastName}` : 'N/A'}
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {moment(item.date).format('MM/DD/YYYY HH:mm')}
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {timestampFormat(item.data.timestamps?.start)}
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {timestampFormat(item.data.timestamps?.end)}
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {timeDelta(item.data.timestamps)}
                                                                </Table.Cell>
                                                            </Table.Row>
                                                            )
                                                        })}
                                                    </Table.Body>
                                                </Table>
                                            </Tab.Pane>
                                    }))} 
                                />
                            }
                            {
                                advisorActivity?.areasExtractions?.length === 0 &&
                                <Message>No processes have been run by this advisor.</Message>
                            }
                        </Tab.Pane>
                    },
                    {
                        menuItem: 'Recommendations',
                        render: () =>
                            <Tab.Pane style={{ border: 'none' }}>
                                {
                                    advisorActivity?.areasRecommendations?.length > 0 &&
                                    <Tab renderActiveOnly={true}
                                        panes={advisorActivity?.areasRecommendations?.map(area => ({
                                            menuItem: ALL_AREAS[area.area],
                                            render: () =>
                                                <Tab.Pane>
                                                    <Table>
                                                        <Table.Header>
                                                            <Table.Row>
                                                                <Table.Cell>Generated recs</Table.Cell>
                                                                <Table.Cell>Client</Table.Cell>
                                                                <Table.Cell>Date</Table.Cell>
                                                                {/* <Table.Cell>Start</Table.Cell>
                                                                <Table.Cell>End</Table.Cell>
                                                                <Table.Cell>Time Delta</Table.Cell> */}
                                                            </Table.Row>
                                                        </Table.Header>
                                                        <Table.Body>
                                                            {area.data?.map?.(item => {
                                                                return (<Table.Row>
                                                                    <Table.Cell>
                                                                        <Label horizontal color={'purple'}>
                                                                            {item.data.recommendations || 0}
                                                                        </Label>
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                    {item.clientData.firstName !== 'Unassigned' ? `${item.clientData.firstName} ${item.clientData.lastName}` : 'N/A'}
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        {moment(item.date).format('MM/DD/YYYY HH:mm')}
                                                                    </Table.Cell>
                                                                    {/* <Table.Cell>
                                                                        {timestampFormat(item.timestamps?.start)}
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        {timestampFormat(item.timestamps?.end)}
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        {timeDelta(item.timestamps)}
                                                                    </Table.Cell> */}
                                                                    </Table.Row>
                                                                )
                                                            })}
                                                        </Table.Body>
                                                    </Table>
                                                </Tab.Pane>
                                        }))} 
                                    />
                                }
                                {
                                    advisorActivity?.areasRecommendations?.length === 0 &&
                                    <Message>No processes have been run by this advisor.</Message>
                                }
                            </Tab.Pane>

                    }
                ]}
            />
        </div>
    )
}