import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from 'react-router-dom';
import { fetchMe } from "../actions/sysuser.actions";

export const ProtectedViewTemplate = ({ children }) => {
    const dispatch = useDispatch();
    // eslint-disable-next-line
    const [state, setState] = useState(0);
    const me = useSelector(state => state.me);

    useEffect(() => {
        dispatch(fetchMe());
        
    // eslint-disable-next-line
    }, []);

    if (me === {}) {
        return <p>Loading...</p>
    }

    if (me === null) {
        return <Redirect to='/login' />
    }

    return <>{children}</>;
}