import { useState } from "react";
import { Button, Icon, Modal } from "semantic-ui-react";
import FormPermissions from "../roles/FormPermissions";
import { responseError } from "../../helpers";
import { sysAPI } from "../../apis/SysAPI";
import { useSelector, useDispatch } from "react-redux";
import { fetchMe } from "../../actions/sysuser.actions";

const AddExcludePermissions = ({refreshFunction, dataInfo, sysPermissions}) => {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const [openModal, setOpenModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [permissionsAditionals, setPermissionsAditionals] = useState(dataInfo?.additional_permissions || []);
  const [permissionsExclude, setPermissionsExclude] = useState(dataInfo?.exclude_permissions || []);

  const handleUpdate = async () => {
    setLoader(true);
    try {
      await sysAPI.put(`/users/${dataInfo._id}`,{
        additional_permissions: permissionsAditionals,
        exclude_permissions: permissionsExclude
      })
      refreshFunction();
      setOpenModal(false);
      dispatch(fetchMe());
    } catch (error) {
      responseError(error)
    }
    setLoader(false)
  }

  const cardStyle = {
    flex: "1",
    backgroundColor: "white",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
    borderRadius: "10px",
    padding: "10px"
  }

  if (!me.permissions.includes("admin:add_exluce_additional_permissions")) {
    return <div></div>;
  }

  return (
    <>
      <Button icon onClick={() => setOpenModal(!openModal)}>
        <Icon name="cogs" />
      </Button>

      <Modal size="large" open={openModal} onClose={() => setOpenModal(false)} onOpen={() => setOpenModal(true)}>
        <Modal.Header>
          Add/Exclude Permissions for {dataInfo.firstName} {dataInfo.lastName}
        </Modal.Header>
        <Modal.Content>
          <div style={{display: "flex", gap: "10px"}}>
            <div style={{...cardStyle, backgroundColor: "#bfebbca3"}}>
              <h2>Add Permissions</h2>
              <div>
                <FormPermissions
                  permissions={permissionsAditionals}
                  setPermissions={setPermissionsAditionals}
                  defaultRoles={sysPermissions}
                />
              </div>
            </div>
            <div style={{...cardStyle, backgroundColor: "#ebbcbca3"}}>
              <h2>Exclude Permissions</h2>
              <div>
                <FormPermissions
                  permissions={permissionsExclude}
                  setPermissions={setPermissionsExclude}
                  defaultRoles={sysPermissions}
                />
              </div>
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpenModal(false)} disabled={loader}>Cancel</Button>
          <Button onClick={handleUpdate} color="green" loading={loader}>
            Update
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default AddExcludePermissions;
