import { Button, Container } from "semantic-ui-react"

export const Page404 = () => {
    const styles_ = {
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    }
    const buttons_styles = {
        borderRadius: "15px"
    }
    return <>
        <Container textAlign="center" style={styles_}>
            <div>
                <h2 style={{fontSize: "150px", color:"#3451FD"}}>
                    404
                </h2>
                <p style={{fontSize: "50px"}}>
                    Oops! It looks like you're lost...
                </p>
                <div style={{display: "flex", justifyContent:"center", gap: "20px"}}>
                    <Button style={buttons_styles} primary href="/">Go to Home</Button>
                    <Button style={buttons_styles} secondary onClick={() => window.history.go(-1)}>Go back</Button>
                    <Button style={buttons_styles} secondary onClick={() => window.location.reload()}>Refresh Page</Button>
                </div>
            </div>
        </Container>
    </>
}