import React from 'react';
import ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css'
import 'draft-js/dist/Draft.css';
import './DashboardAdvisorView.css';
import "./css/custom-input-number.css"
import "./css/globals.css"
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore, compose } from 'redux';
import reduxThunk from 'redux-thunk';
import reducers from './reducers';
import Snackbar from './components/globals/Snackbar';


// const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const store = createStore(reducers, compose(applyMiddleware(reduxThunk), window.devToolsExtension ? window.devToolsExtension() : f => f));


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
      <Snackbar />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
