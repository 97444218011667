import { paymentAPI } from "../apis/PaymentAPI";

export const fetchCurrentAdvisorInvoices = (advisorId) => {
    return async (dispatch) => {
        try {
            const response = await paymentAPI.get(`/invoice/advisor?advisorId=${advisorId}`);
            dispatch(setCurrentAdvisorInvoices(response.data.invoices));
        }
        catch (err) {
            console.error(err);
        }
    }
}

export const setCurrentAdvisorInvoices = (invoices) => {
    return {
        type: 'set_current_advisor_invoices',
        payload: invoices,
    }
}