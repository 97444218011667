import { Switch, Route, /* Redirect */ } from "react-router-dom"
import { DashboardAdvisorsView } from "../views/DashboardAdvisorsView"
import { AdvisorProfileView } from '../views/AdvisorProfileView';
import { DashboardSalesView } from "../views/DashboardSalesView";
import { ProtectedViewTemplate } from "../templates/ProtectedViewTemplate";
import { DashboardMetricsView } from "../views/DashboardMetricsView";
import { DashboardManualInputView } from "../views/DashboardManualInputView";
import { InitialDashboardRouteDecider } from "../components/shared/InitialDashboardRouteDecider";
import { AdvisorSalesProfileView } from "../views/AdvisorSalesProfileView";
import { ClientEstateFilesViews } from "../views/ClientEstateFilesView";
import { EstateManualInput } from "../views/EstateManualInput";
import EstateManualInputV4 from "../views/EstateManualInputV4";
import { AdminView } from "../views/AdminView";
import { DashboardClientsView } from "../views/DashboardClientsView";
import { DashboardResourcesView } from "../views/DashboardResourcesView";
import { QuickbooksView } from "../views/QuickbooksView";
import { QuickbooksCallbackView } from "../views/QuickbooksCallbackView";
import { QuickbooksDisconnectedView } from "../views/QuickbooksDisconnectedView";
import DashboardCompanies from "../views/Company/DashboardCompanies";
import CompanyProfile from "../views/Company/CompanyProfile";
import { InsuranceSnapshot } from "../views/InsuranceSnapshot";
import DashboardAdvisorsSnapshotsView from "../views/DashboardAdvisorsSnapshotView";
import RolesView from "../views/RolesView";
import SamlView from "../views/Saml/SamlView";
import { Page404 } from "../views/404";
import RolesEnterpriseSettings from "../views/RolesEnterpriseSettings";

export const DashboardRouter = () => {
    return (
        <ProtectedViewTemplate>
            <Switch>
                <Route exact path='/dashboard' component={InitialDashboardRouteDecider} />
                <Route exact path='/dashboard/advisors' component={DashboardAdvisorsView} />
                <Route exact path='/dashboard/advisors/:advisorId' component={AdvisorProfileView} />
                <Route exact path='/dashboard/sales' component={DashboardSalesView} />
                <Route exact path='/dashboard/sales/:advisorId' component={AdvisorSalesProfileView} />
                <Route exact path='/dashboard/metrics' component={DashboardMetricsView} />
                <Route exact path='/dashboard/manual-input' component={DashboardManualInputView} />
                <Route exact path='/dashboard/estate-manual-input/:clientId/:assetIndex/files' component={ClientEstateFilesViews} />
                <Route exact path='/dashboard/estate-manual-input/:clientId/:assetIndex' component={EstateManualInput} />
                <Route exact path='/dashboard/estate-manual-input/:clientId/' component={EstateManualInputV4} />
                <Route exact path='/dashboard/admin' component={AdminView} />
                <Route exact path='/dashboard/admin-roles' component={RolesView} />
                <Route exact path='/dashboard/quickbooks' component={QuickbooksView} />
                <Route exact path='/dashboard/quickbooks/callback' component={QuickbooksCallbackView} />
                <Route exact path='/dashboard/quickbooks/disconnect' component={QuickbooksDisconnectedView} />
                <Route exact path='/dashboard/clients' component={DashboardClientsView} />
                <Route exact path='/dashboard/resources' component={DashboardResourcesView} />

                <Route exact path='/dashboard/companies' component={DashboardCompanies} />
                <Route exact path='/dashboard/companies/:companyId' component={CompanyProfile} />
                <Route exact path='/dashboard/insurance-snapshot/:clientId/:areaAsset' component={InsuranceSnapshot} />

                <Route exact path='/dashboard/advisors-snapshots' component={DashboardAdvisorsSnapshotsView} />

                <Route exact path='/dashboard/integrations-saml' component={SamlView} />
                <Route exact path='/dashboard/roles-enterprise-settings' component={RolesEnterpriseSettings} />

                <Route path="*">
                    <Page404 />
                </Route>
            </Switch>
        </ProtectedViewTemplate>
    )
}