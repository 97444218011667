import { useSelector } from 'react-redux';
import { Grid, Header, Card, Icon, Segment, Message } from 'semantic-ui-react';
import {prettyDate} from "../../helpers/moment-helpers"
import { Link } from 'react-router-dom';

export const AdvisorOverviewTab = ({ activeTab }) => {
    const advisorProfile = useSelector(state => state.currentAdvisorProfile);

    const IGNORE_INTEGRATIONS = ['salesforceClientsReadyToImport', 'salesforceFlowFinished', 'redtailClientsReadyToImport', 'redtailFlowFinished'];
    const integrations = advisorProfile?.integrations
        // eslint-disable-next-line
        && Object.keys(advisorProfile.integrations)
            .filter(i => advisorProfile.integrations[i] === true
                && !IGNORE_INTEGRATIONS.includes(i))
        // eslint-disable-next-line
        || [];


    return (
        <div style={{ display: activeTab === 'Overview' ? 'block' : 'none' }}>
            <Grid columns={2}>
                <Grid.Column>
                    <Header as='h3'>General Information</Header>
                    <Segment>
                        First time login: {prettyDate({date: advisorProfile?.firstTimeLoginDate})}
                    </Segment>
                    <Segment>
                        Has 2FA {advisorProfile?.hasEnabled2FA ? 'HAS' : 'NOT'}
                    </Segment>
                    <Segment>
                        Payment Modality: {advisorProfile?.paymentModality}
                    </Segment>
                    {advisorProfile?.settings?.needsPaymentMethod ? null :
                        <Segment>
                            {'Requested to not have a cc on file'}
                        </Segment>
                    }
                    <Segment>
                        Company Name: <Link to={`/dashboard/companies/${advisorProfile?.companyId}`}> {advisorProfile?.nameCompany} </Link>
                    </Segment>
                    <Segment>
                        Last Login: {prettyDate({date: advisorProfile?.activity?.lastLogin})}
                    </Segment>
                </Grid.Column>
                <Grid.Column>
                    <Header as='h3'>Active Integrations</Header>
                    {integrations.length > 0 &&
                        <Card.Group itemsPerRow={4}>
                            {integrations.length > 0 && integrations.map(integration => {
                                return (
                                    <Card>
                                        <Card.Content style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <Icon name='connectdevelop' size='huge' />
                                            <p style={{ marginTop: '5px', fontSize: '16px' }}>{integration}</p>
                                        </Card.Content>
                                    </Card>
                                )
                            })}
                        </Card.Group>
                    }
                    {integrations.length === 0 &&
                        <Message>There are no integrations connected by this advisor.</Message>
                    }
                </Grid.Column>
            </Grid>
        </div>
    )   
}