import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom"
import { /* Accordion, */ Button, /* Tab, */ Table, Icon, Input, Modal } from "semantic-ui-react";
import { fetchCurrentAdvisorProfile } from "../actions/advisor.actions";
import { fetchCurrentAdvisorInvoices } from "../actions/invoice.actions";
import { paymentAPI } from "../apis/PaymentAPI";
import { sysAPI } from "../apis/SysAPI";
import { CreateInvoiceModal } from "../components/modal/CreateInvoiceModal";
import { InvoiceModal } from "../components/modal/InvoiceModal";
import { TabContainer } from "../components/shared/TabContainer"
import { DashboardTemplate } from "../templates/DashboardTemplate"

export const AdvisorSalesProfileView = () => {
    const params = useParams();
    const dispatch = useDispatch();

    const [showCreateInvoiceModal, setShowCreateInvoiceModal] = useState(false);
    const [showpurchaseDateModal, setShowpurchaseDateModal] = useState(false);
    const [purchaseDate, setPurchaseDate] = useState('');
    const [selectedInvoice, setSelectedInvoice] = useState(null);

    const advisorProfile = useSelector(state => state.currentAdvisorProfile);
    const invoices = useSelector(state => state.currentAdvisorInvoices);

    useEffect(() => {
        console.log(purchaseDate)
    }, [purchaseDate]);

    useEffect(() => {
        const { advisorId } = params;
        dispatch(fetchCurrentAdvisorProfile(advisorId, false));
        dispatch(fetchCurrentAdvisorInvoices(advisorId));
        setPurchaseDate(moment(advisorProfile.purchaseDate).format('YYYY-MM-DD'))
        console.log(advisorProfile.purchaseDate);

    // eslint-disable-next-line
    }, []);

    const updateDate = () => {
        sysAPI.post(`/advisor/${params.advisorId }/update`, {purchaseDate: purchaseDate}).then(response => {
            setShowpurchaseDateModal(false)
        }).catch(err => {

        });
    }

    const HeaderOptions = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'end' }}>
                <Button onClick={() => setShowCreateInvoiceModal(true)} icon='add'></Button>
                <Modal
                    dimmer='inverted'
                    size={'mini'}
                    open={showpurchaseDateModal}
                    trigger={<Button onClick={() => setShowpurchaseDateModal(true)}>Purchased Date</Button>}
                    onOpen={() => setShowpurchaseDateModal(true)}
                    onClose={() => setShowpurchaseDateModal(false)}
                >
                    <Modal.Header>Update Purchase Date</Modal.Header>
                    <Modal.Content>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                        <Input type='date' value={purchaseDate} onChange={e => setPurchaseDate(e.currentTarget.value)} />
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button negative onClick={() => setShowpurchaseDateModal(false)}>
                            Cancel
                        </Button>
                        <Button positive onClick={updateDate}>
                            Save
                        </Button>
                    </Modal.Actions>
                </Modal>
                
            </div>
        )
    }

    const fetchInvoices = () => {
        console.log({ advisorId: advisorProfile?._id });
        dispatch(fetchCurrentAdvisorInvoices(advisorProfile?._id));
    }

    const deleteInvoiceBtn_Click = (invoiceId) => {
        return async () => {
            if (window.confirm('This invoice will be deleted')) {
                try {
                    // eslint-disable-next-line
                    const response = await paymentAPI.delete(`/invoice/${invoiceId}`);
                    fetchInvoices();
                }
                catch (err) {
                    console.error(err);
                    alert(`Couldn't delete invoice. Please try again later.`);
                }
            }
        }
    }

    const moreInfoBtn_Click = (invoice) => {
        return () => {
            setSelectedInvoice(invoice);
        }
    }

    return (
        <DashboardTemplate scope={'sales'}>
            <TabContainer
                title={advisorProfile?.fullName}
                subtitle={advisorProfile?.email}
                headerOptions={<HeaderOptions />}
            >
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Description</Table.HeaderCell>
                            <Table.HeaderCell>Created on</Table.HeaderCell>
                            {/* <Table.HeaderCell>Items</Table.HeaderCell> */}
                            <Table.HeaderCell>Total amount</Table.HeaderCell>
                            <Table.HeaderCell>Paid Units</Table.HeaderCell>
                            {/* <Table.HeaderCell>Payment Plan</Table.HeaderCell> */}
                            <Table.HeaderCell>Next Payment Date</Table.HeaderCell>
                            <Table.HeaderCell>Next Invoice Date</Table.HeaderCell>
                            {/* <Table.HeaderCell>Expiration Date</Table.HeaderCell> */}
                            {/* <Table.HeaderCell>Start Showing Date</Table.HeaderCell> */}
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {invoices?.map(invoice => <Table.Row>
                            <Table.Cell>{invoice.title || 'N/A'}</Table.Cell>
                            <Table.Cell>{moment(invoice.creationDate).format('MM/DD/YYYY')}</Table.Cell>
                            {/* <Table.Cell>{invoice.items.map(item => <>{item.quantity} {item.description} / ${item.amount}<br /></>)}</Table.Cell> */}
                            <Table.Cell>${invoice.total}</Table.Cell>
                            <Table.Cell>{invoice.paidUnits} / 12</Table.Cell>
                            {/* <Table.Cell>{invoice.paymentPlan === 'quarterly' ? 'Quarterly' : invoice.paymentPlan === 'annually' ? 'Annually' : 'N/A'}</Table.Cell> */}
                            <Table.Cell>{invoice.paidUnits === 12 ? 'Paid' : moment(invoice.nextPaymentDate).isValid() ? moment(invoice.nextPaymentDate).format('MM/DD/YYYY') : 'N/A'}</Table.Cell>
                            <Table.Cell>{moment(invoice.nextInvoiceDate).format('MM/DD/YYYY')}</Table.Cell>
                            {/* <Table.Cell>{moment(invoice.expirationDate).format('MM/DD/YYYY')}</Table.Cell> */}
                            {/* <Table.Cell>{moment(invoice.startShowingDate).format('MM/DD/YYYY')}</Table.Cell> */}
                            <Table.Cell style={{ textAlign: 'right' }}>
                                <Button icon onClick={moreInfoBtn_Click(invoice)} style={{ margin: '5px' }} >
                                    <Icon name={'info circle'} />
                                </Button>
                                <Button icon onClick={deleteInvoiceBtn_Click(invoice._id)} style={{ margin: '5px' }} >
                                    <Icon name={'trash alternate'} />
                                </Button>
                            </Table.Cell>
                        </Table.Row>)}
                    </Table.Body>
                </Table>
            </TabContainer>
            <CreateInvoiceModal
                show={showCreateInvoiceModal}
                setShow={setShowCreateInvoiceModal}
                fetchInvoices={fetchInvoices}
            />
            <InvoiceModal
                show={selectedInvoice !== null}
                hideEvent={() => setSelectedInvoice(null)}
                invoice={selectedInvoice}
            />
        </DashboardTemplate>
    )
}