import { useState } from "react";
import { sysAPI } from "../../apis/SysAPI";
import { Button, Icon, Modal } from "semantic-ui-react";
import FormRole from "./Form";
import { useDispatch, useSelector } from "react-redux";
import { fetchMe } from "../../actions/sysuser.actions";

const RoleUpdate = ({refreshFunction, dataInfo, defaultRoles}) => {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const [openModal, setOpenModal] = useState(false);
  const [infoRole, setInfoRole] = useState({
    name: dataInfo.name || "",
    description: dataInfo.description || "",
    permissions: dataInfo.permissions || [],
    color: dataInfo.color || "",
    key: dataInfo.key || "",
  })

  const handleUpdate = async () => {
    try {
      await sysAPI.put(`/sys-roles/${dataInfo._id}`, {
        ...infoRole
      })
      refreshFunction();
      setOpenModal(false)
      dispatch(fetchMe());
    } catch (error) {
      console.log({error})
      alert(error.message)
    }
  }

  if (!me.permissions.includes("roles:rol_edit")) {
    return <div></div>;
  }

  return (
    <div>
      <Button icon onClick={() => setOpenModal(true)}>
        <Icon name="pencil" />
      </Button>

      <Modal size='tiny' open={openModal} onClose={() => setOpenModal(false)} onOpen={() => setOpenModal(true)}>
        <Modal.Header>
          Update role {dataInfo.name}
        </Modal.Header>
        <Modal.Content>
          <div>
            <FormRole infoRole={infoRole} setInfoRole={setInfoRole} defaultRoles={defaultRoles} />
          </div>

        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpenModal(false)}>Cancel</Button>
          <Button onClick={handleUpdate} color="green">Update</Button>
        </Modal.Actions>
      </Modal>

    </div>
  );
}

export default RoleUpdate