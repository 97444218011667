import { useState } from 'react';
import { Button, Checkbox, Modal } from 'semantic-ui-react';
import { advisorAPI } from '../../../../../apis/AdvisorsAPI';
import { fetchCurrentCompany } from '../../../../../actions/company.actions';
import { useDispatch } from 'react-redux';

const RemoveMapper = ({ data, index, companyProfile }) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [agree, setAgree] = useState(false);

  const handleRemove = async () => {
    setLoader(true);
    try {
      const taxMapper = companyProfile?._integrations?.salesforce?.settings?.updateDataTax?.taxMapper || [];
      taxMapper.splice(index, 1);

      await advisorAPI.put(`/companies/${companyProfile._id}`, {
        [`_integrations.salesforce.settings.updateDataTax.taxMapper`]: taxMapper,
      });
      await dispatch(fetchCurrentCompany(companyProfile._id));
      setOpenModal(false);
    } catch (error) {
      console.error({ error });
      alert(error?.response?.data?.message || error?.message || 'Error...');
    }
    setLoader(false);
  };

  return (
    <>
      <Button size="mini" color="red" onClick={() => setOpenModal(!openModal)}>
        Delete
      </Button>

      <Modal open={openModal}>
        <Modal.Header>Confirmation required</Modal.Header>
        <Modal.Content>
          <div>This action can not be undone</div>
          <div>
            <Checkbox label="I agree" onClick={(e, { checked }) => setAgree(checked)} />
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpenModal(false)}>Cancel</Button>
          <Button onClick={handleRemove} color="red" loading={loader} disabled={!agree}>
            Remove Table
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default RemoveMapper;
