const CompanyEstateSnapshotTab = ({activeTab}) => {
  return <div>
    {activeTab === "estate_snapshot" &&
      <div>
        <h2> Coming soon... </h2>
      </div>
    } 
  </div>
}

export default CompanyEstateSnapshotTab