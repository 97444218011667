import { useEffect, useState } from "react"
import { Button, Input, Modal, TextArea } from "semantic-ui-react"
import { fpalphaAPI } from "../../../../../apis/FPAlphaAPI";
import { advisorAPI } from "../../../../../apis/AdvisorsAPI";
import { fetchCurrentCompany } from "../../../../../actions/company.actions";
import { useDispatch } from "react-redux";

const AddKey = ({settingsType, companyProfile, propType}) => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [isImport, setIsImport] = useState(false);
  const [loading, setLoading] = useState(false)
  const [loadingSave, setLoadingSave] = useState(false)
  const [baseURL, setBaseURL] = useState("");
  const [authTokenProvider, setAuthTokenProvider] = useState("");
  const [tableName, setTableName] = useState("");
  const [columnName, setColumnName] = useState("");

  const [propsToImport, setPropsToImport] = useState("");

  const handleGetProps = async () => {
    setLoading(true)
    try {
      const {data} = await fpalphaAPI.post("/advisor/integrations/generic/salesforce/table-describe", {
        baseURL,
        authTokenProvider,
        tableName,
        //columnName,
      })

      const column = data.data.fields.find(f => f.name === columnName);
      if(!column){
        // eslint-disable-next-line
        throw {message: "No column found..."}
      }

      const values = column.picklistValues.map(p => p.value)
      setPropsToImport(values.join("\n"))
      await advisorAPI.put(`/companies/${companyProfile._id}`, {
        [`_integrations.salesforce.settings.mapperTypes.${propType}.columnName`]: columnName,
        [`_integrations.salesforce.settings.mapperTypes.${propType}.tableName`]: tableName,
      });
    } catch (error) {
      console.log({error});
      alert("Error...")
    }
    setLoading(false)
  }

  const handleSaveProps = async () => {
    setLoadingSave(true)
    try {
      const valuesType = JSON.parse(JSON.stringify(settingsType?.values || {}))
      const keysExistens = Object.keys(valuesType)
      for(const value of propsToImport?.split("\n") || [""]){
        if(!keysExistens.includes(value) && value){
          valuesType[value] = ""
        }
      }

      await advisorAPI.put(`/companies/${companyProfile._id}`, {
        [`_integrations.salesforce.settings.mapperTypes.${propType}.values`]: valuesType,
      });
      await dispatch(fetchCurrentCompany(companyProfile._id));
      setOpenModal(false)
    } catch (error) {
      console.log({error});
      alert("Error...")
    }
    setLoadingSave(false)
  }




  useEffect(() => {
    setTableName(settingsType?.tableName)
    setColumnName(settingsType?.columnName)
    // eslint-disable-next-line
  },[companyProfile])


  return <div>
    <Button size="mini" onClick={() => setOpenModal(!openModal)}>
      Import/Add Prop
    </Button>

    <Modal size="tiny" open={openModal} onClose={() => setOpenModal(false)} onOpen={() => setOpenModal(true)}>
        <Modal.Header>Add Props</Modal.Header>
        <Modal.Content>
          <div>
            <div>
              <h3>
                Import from Salesforce <Button size="mini" onClick={() => setIsImport(!isImport)}>{isImport ? 'Hidden' : 'Show'} options</Button>
              </h3>
              {isImport && (<div style={{marginTop: "10px" }} className="form-separate card-border-line ">
                <div>
                  <div>Base URL</div>
                  <div>
                    <Input fluid onChange={(e,_) => setBaseURL(_.value)} defaultValue={baseURL} placeholder={"https://ID_SALESFORCE.my.salesforce.com/services/data/v59.0"} />
                  </div>
                </div>
                <div>
                  <div>Auth Token Provider</div>
                  <div>
                    <Input fluid onChange={(e,_) => setAuthTokenProvider(_.value)} defaultValue={authTokenProvider} />
                  </div>
                </div>
                <div>
                  <div>Table Name</div>
                  <div>
                    <Input fluid onChange={(e,_) => setTableName(_.value)} defaultValue={tableName} />
                  </div>
                </div>
                <div>
                  <div>Column Name</div>
                  <div>
                    <Input fluid onChange={(e,_) => setColumnName(_.value)} defaultValue={columnName} />
                  </div>
                </div>
                <div>
                  <Button loading={loading} color={"green"} fluid onClick={() => handleGetProps()}>Get Props</Button>
                </div>
              </div>)}
            </div>
            <hr />
            <div>
              <TextArea style={{width: "100%"}} placeholder={"example 1\nexample 2\nexample ..."} rows={6} value={propsToImport} onChange={(e,_) => setPropsToImport(_.value)} />
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpenModal(false)}>Cancel</Button>
          <Button  color="green" onClick={() => handleSaveProps()} loading={loadingSave}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>


  </div>
}

export default AddKey