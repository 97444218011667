import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchCurrentAdvisorProfile, setCurrentAdvisorActivity, setCurrentAdvisorClients, setCurrentAdvisorProfile } from "../actions/advisor.actions";
import { DashboardTemplate } from "../templates/DashboardTemplate"
import { Button, Icon, Header, Segment } from 'semantic-ui-react'
import { AdvisorMenu } from "../components/menu/AdvisorMenu";
import { AdvisorClientsTab } from "../components/tabs/AdvisorClientsTab";
import { AdvisorOverviewTab } from "../components/tabs/AdvisorOverviewTab";
import { ConfirmAdvisorActionModal } from "../components/modal/ConfirmAdvisorActionModal";
import { AdvisorActivityTab } from "../components/tabs/AdvisorActivityTab";
//import { sysAPI } from "../apis/SysAPI";
import EnableDisable2Fa from "../components/tabs/advisors/EnableDisable2Fa";
//import EnableDisableFeatures from "../components/tabs/advisors/EnableDisableFeatures";
import ChangeNameOrEmail from "../components/tabs/advisors/ChangeNameOrEmail";
import TabShowPermissions from "../components/tabs/advisors/TabShowPermissions";
import ImportClients from "../components/tabs/advisors/ImportClients";

export const AdvisorProfileView = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState('Overview');

    const advisorProfile = useSelector(state => state.currentAdvisorProfile);
    const [modalName, setModalName] = useState(false);

    useEffect(() => {
        const { advisorId } = params;
        dispatch(fetchCurrentAdvisorProfile(advisorId));

        return () => {
            dispatch(setCurrentAdvisorProfile({}));
            dispatch(setCurrentAdvisorClients([]));
            dispatch(setCurrentAdvisorActivity({}));
        }

    // eslint-disable-next-line
    }, []);

    /* const switchPaymentModality = () => {
        sysAPI.post(`/advisor/${params.advisorId }/update`, {paymentModality: advisorProfile.paymentModality === 'company' ? 'advisor': 'company'}).then(response => {
            window.alert('Switched Payment Modality, refresh to see changes')
        }).catch(err => {

        });
    } */

    /* const dontRequestPaymentMethod = () => {
        sysAPI.post(`/advisor/${params.advisorId }/update-preferences`, {'settings.needsPaymentMethod': !advisorProfile.settings.needsPaymentMethod }).then(response => {
            window.alert('Updated preferences, refresh to see changes')
        }).catch(err => {

        });
    } */

    return (
        <DashboardTemplate scope='advisors'>
            <Header as='h2'>
                <Icon name='user circle' />
                <Header.Content>
                    {advisorProfile?.fullName}
                    {advisorProfile?.hasActiveSession
                        ? <span style={{ color: 'green' }}> (online)</span>
                        : <span style={{ color: 'grey' }}> (offline)</span>
                    }
                    {advisorProfile?.isLoginBlocked
                        ? <span style={{ color: 'red' }}> (BLOCKED)</span>
                        : <></>
                    }
                    <Header.Subheader>
                        {advisorProfile?.email} - {advisorProfile?.enterpriseRole?.name}
                    </Header.Subheader>
                </Header.Content>
            </Header>
            {/* {advisorProfile?.isOnFreeTrialPeriod &&
                <Button icon color='blue' labelPosition='left'>
                    <Icon size='large' name='calendar' />
                    Extend trial user license
                </Button>
            } */}
            {advisorProfile.isLoginBlocked ? <>
                <Button icon color='green' labelPosition='left' onClick={() => setModalName('unblockUser')}>
                    <Icon size='large' name='unlock' />
                    Unblock Login
                </Button>
            </> : <>
                <Button icon color='orange' labelPosition='left' onClick={() => setModalName('blockUser')}>
                    <Icon size='large' name='lock' />
                    Block Login
                </Button>
            </>
            }
            {/*advisorProfile?.isOnFreeTrialPeriod &&*/
                <Button icon color='violet' labelPosition='left' onClick={() => setModalName('fullAccessUser')}>
                    <Icon size='large' name='diamond' />
                    Grant full access
                </Button>
            }
            <ImportClients />
            {/* <Button icon color='purple' labelPosition='left' onClick={switchPaymentModality}>
                <Icon size='large' name='exchange' />
                Switch payment modality
            </Button>
            <Button icon color='pink' labelPosition='left' onClick={dontRequestPaymentMethod}>
                <Icon size='large' name='credit card outline' />
                {advisorProfile.settings?.needsPaymentMethod ? "Don't request payment method" : 'Request payment method'}
            </Button> */}

            <EnableDisable2Fa advisorProfile={advisorProfile} />

            {/* <EnableDisableFeatures advisorProfile={advisorProfile} /> */}

            <ChangeNameOrEmail advisorProfile={advisorProfile} />

            <Button icon color='red' labelPosition='left' onClick={() => setModalName('removeUser')}>
                <Icon size='large' name='remove user' />
                Delete advisor
            </Button>

            <AdvisorMenu activeTab={activeTab} setActiveTab={setActiveTab} />
            <Segment attached='bottom'>
                <AdvisorOverviewTab activeTab={activeTab} />
                <AdvisorClientsTab activeTab={activeTab} />
                <AdvisorActivityTab activeTab={activeTab} />
                <TabShowPermissions activeTab={activeTab} />
            </Segment>

            <ConfirmAdvisorActionModal
                action={modalName}
                setAction={setModalName}
            />
        </DashboardTemplate>
    )
}