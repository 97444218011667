import { useState } from 'react';
//import { useSelector } from 'react-redux';
import { Button, Modal } from 'semantic-ui-react';
import DocumentCategorizationForm from './Form';
import { responseError } from '../../../helpers';
import { sysAPI } from '../../../apis/SysAPI';

const ButtonAddDocumentCategorization = ({area, clientId, assetIndex, refreshFunction = () => {}}) => {
  //const me = useSelector((state) => state.me);
  const [openModal, setOpenModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [dataSend, setDataSend] = useState({
    jointOrSingle: "Joint",
    subType: "Amendment"
  });


  const handleAddDocument = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      await sysAPI.post('/estate-manual-input/document-categorization', {
        clientId,
        index: assetIndex,
        area,
        ...dataSend
      })

      refreshFunction();
      setOpenModal(false);

    } catch (error) {
      responseError(error, undefined, "handleAddDocument");
    }
    setLoader(false)
  }

  /* if (me?.permissions?.includes('manual_inputs:add_name_ocuments')) {
    return <></>;
  } */

  return (
    <>
      <Button color="green" onClick={() => setOpenModal(!openModal)}>
        Add Document
      </Button>

      <Modal size="tiny" open={openModal}>
        <Modal.Header>Add Document</Modal.Header>
        <Modal.Content>
          <form id="document-categorization-add-file-id" onSubmit={handleAddDocument}>
            <DocumentCategorizationForm
              dataForm={dataSend}
              setDataForm={setDataSend}
              showName={true}
            />
          </form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpenModal(false)}>Cancel</Button>
          <Button form="document-categorization-add-file-id" color="green" loading={loader}>
            Add Document
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default ButtonAddDocumentCategorization;
