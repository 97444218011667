/* eslint-disable react/jsx-pascal-case */
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { Button, /* Card, */ Grid, Header, Icon, Input, Modal, Segment, Select, /* Tab, */ Table } from "semantic-ui-react";
import { updateCurrentClientProp } from "../../actions/client.actions";
import { sysAPI } from "../../apis/SysAPI";
import { usStates } from "../../data/usStates";
import { commifyNumber, isRealEstateAsset, isRetirementAccountAsset } from "../../helpers";

const RealEstateAssets = ({ assets, removeBtn_Click }) => {
    const types = {
        'primary_residence': 'Primary Residence',
        'commercial_property': 'Commercial Property',
        'investment_property': 'Investment Property',
        'land': 'Land',
        'other_property': 'Other',
    };

    return (
        <div style={{ marginBottom: '20px', display: assets?.length > 0 ? 'block' : 'none' }}>
            <Header as='h4'>Real Estate</Header>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Type</Table.HeaderCell>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Value</Table.HeaderCell>
                        <Table.HeaderCell>Property titled to</Table.HeaderCell>
                        <Table.HeaderCell>Cost basis</Table.HeaderCell>
                        <Table.HeaderCell>State</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {assets?.map(asset => (<Table.Row>
                        <Table.Cell>{types[asset.type]}</Table.Cell>
                        <Table.Cell>{asset.name}</Table.Cell>
                        <Table.Cell>${asset.value || '0'}</Table.Cell>
                        <Table.Cell>{asset.ownership}</Table.Cell>
                        <Table.Cell>${commifyNumber(asset.addons?.costBasis || '0')}</Table.Cell>
                        <Table.Cell>{asset.state}</Table.Cell>
                        {removeBtn_Click && 
                            <Table.Cell style={{ width: '50px' }}>
                                <Icon size='large' name='remove' color='red' onClick={removeBtn_Click(asset._id)} />
                            </Table.Cell>
                        }
                    </Table.Row>))}
                </Table.Body>
            </Table>
        </div>
    )
}

const RetirementAccountAssets = ({ assets, removeBtn_Click }) => {
    return (
        <div style={{ marginBottom: '20px', display: assets?.length > 0 ? 'block' : 'none' }}>
            <Header as='h4'>Retirement Accounts</Header>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Type</Table.HeaderCell>
                        <Table.HeaderCell>Nickname</Table.HeaderCell>
                        <Table.HeaderCell>Value</Table.HeaderCell>
                        <Table.HeaderCell>Account title</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {assets?.map(asset => (<Table.Row>
                        <Table.Cell>{asset.type.split('retirement_account_').join('').split('_').join(' ')}</Table.Cell>
                        <Table.Cell>{asset.name}</Table.Cell>
                        <Table.Cell>${asset.value || '0'}</Table.Cell>
                        <Table.Cell>{asset.ownership}</Table.Cell>
                        {removeBtn_Click && 
                            <Table.Cell style={{ width: '50px' }}>
                                <Icon size='large' name='remove' color='red' onClick={removeBtn_Click(asset._id)} />
                            </Table.Cell>
                        }
                    </Table.Row>))}
                </Table.Body>
            </Table>
        </div>
    )
}

const BrokerageAccountAssets = ({ assets, removeBtn_Click }) => {
    return (
        <div style={{ marginBottom: '20px', display: assets?.length > 0 ? 'block' : 'none' }}>
            <Header as='h4'>Brokerage Accounts</Header>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Nickname</Table.HeaderCell>
                        <Table.HeaderCell>Balance</Table.HeaderCell>
                        <Table.HeaderCell>Account title</Table.HeaderCell>
                        {/* <Table.HeaderCell>State</Table.HeaderCell> */}
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {assets?.map(asset => (<Table.Row>
                        <Table.Cell>{asset.name}</Table.Cell>
                        <Table.Cell>${asset.value || '0'}</Table.Cell>
                        <Table.Cell>{asset.ownership}</Table.Cell>
                        {removeBtn_Click && 
                            <Table.Cell style={{ width: '50px' }}>
                                <Icon size='large' name='remove' color='red' onClick={removeBtn_Click(asset._id)} />
                            </Table.Cell>
                        }
                    </Table.Row>))}
                </Table.Body>
            </Table>
        </div>
    )
}

const LifeInsuranceAssets = ({ assets, removeBtn_Click }) => {
    return (
        <div style={{ marginBottom: '20px', display: assets?.length > 0 ? 'block' : 'none' }}>
            <Header as='h4'>Life Insurances</Header>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Nickname</Table.HeaderCell>
                        <Table.HeaderCell>Death benefit</Table.HeaderCell>
                        <Table.HeaderCell>Policy owner</Table.HeaderCell>
                        {/* <Table.HeaderCell>State</Table.HeaderCell> */}
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {assets?.map(asset => (<Table.Row>
                        <Table.Cell>{asset.name}</Table.Cell>
                        <Table.Cell>${asset.value || '0'}</Table.Cell>
                        <Table.Cell>{asset.ownership}</Table.Cell>
                        {removeBtn_Click && 
                            <Table.Cell style={{ width: '50px' }}>
                                <Icon size='large' name='remove' color='red' onClick={removeBtn_Click(asset._id)} />
                            </Table.Cell>
                        }
                    </Table.Row>))}
                </Table.Body>
            </Table>
        </div>
    )
}

const _529PlanAssets = ({ assets, removeBtn_Click }) => {
    return (
        <div style={{ marginBottom: '20px', display: assets?.length > 0 ? 'block' : 'none' }}>
            <Header as='h4'>529 Plans</Header>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Nickname</Table.HeaderCell>
                        <Table.HeaderCell>Value</Table.HeaderCell>
                        {/* <Table.HeaderCell>State</Table.HeaderCell> */}
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {assets?.map(asset => (<Table.Row>
                        <Table.Cell>{asset.name}</Table.Cell>
                        <Table.Cell>${asset.value || '0'}</Table.Cell>
                        {removeBtn_Click && 
                            <Table.Cell style={{ width: '50px' }}>
                                <Icon size='large' name='remove' color='red' onClick={removeBtn_Click(asset._id)} />
                            </Table.Cell>
                        }
                    </Table.Row>))}
                </Table.Body>
            </Table>
        </div>
    )
}

const UnknownAssets = ({ title, assets, removeBtn_Click }) => {
    return (
        <div style={{ marginBottom: '20px', display: assets?.length > 0 ? 'block' : 'none' }}>
            <Header as='h4'>{title}</Header>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Nickname</Table.HeaderCell>
                        <Table.HeaderCell>Value</Table.HeaderCell>
                        <Table.HeaderCell>Owner</Table.HeaderCell>
                        {/* <Table.HeaderCell>State</Table.HeaderCell> */}
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {assets?.map(asset => (<Table.Row>
                        <Table.Cell>{asset.name}</Table.Cell>
                        <Table.Cell>${asset.value || '0'}</Table.Cell>
                        <Table.Cell>{asset.ownership || '-'}</Table.Cell>
                        {removeBtn_Click && 
                            <Table.Cell style={{ width: '50px' }}>
                                <Icon size='large' name='remove' color='red' onClick={removeBtn_Click(asset._id)} />
                            </Table.Cell>
                        }
                    </Table.Row>))}
                </Table.Body>
            </Table>
        </div>
    )
}

export const AssetsModal = ({ open, closeBtn_Click, editingEnabled }) => {
    const dispatch = useDispatch();
    const client = useSelector(state => state.currentClient);

    const [type, setType] = useState('');
    const [subtype, setSubtype] = useState('');
    const [nickname, setNickname] = useState('');
    const [value, setValue] = useState('');
    const [owner, setOwner] = useState('');
    const [costBasis, setCostBasis] = useState('');
    const [specificOwner, setSpecificOwner] = useState('');
    const [state, setState] = useState('');

    const assetsExtras = [
        'net_income_and_principal',
        'personal_tangible_property',
        'specific_bequest',
        'residuary_estate',
        'other',
    ];

    const typeOptions = [
        // { key: 'real_estate', value: 'real_estate', text: 'Real Estate' },
        // { key: 'retirement_account', value: 'retirement_account', text: 'Retirement Account' },
        // { key: 'brokerage_account', value: 'brokerage_account', text: 'Brokerage Account' },
        // { key: 'life_insurance', value: 'life_insurance', text: 'Life Insurance' },
        // { key: 'cash', value: 'cash', text: 'Cash' },
        { key: 'net_income_and_principal', value: 'net_income_and_principal', text: 'Net Income and principal' },
        { key: 'personal_tangible_property', value: 'personal_tangible_property', text: 'Personal Tangible Property' },
        { key: 'specific_bequest', value: 'specific_bequest', text: 'Specific Bequest' },
        { key: 'residuary_estate', value: 'residuary_estate', text: 'Residuary Estate' },
        { key: 'other', value: 'other', text: 'Other' },
    ];

    const subtypeOptions = {
        'real_estate': [
            // { key: '', value: '', text: 'Property type...', disabled: true },
            { key: 'primary_residence', value: 'primary_residence', text: 'Primary Residence' },
            { key: 'investment_property', value: 'investment_property', text: 'Investment Property' },
            { key: 'commercial_property', value: 'commercial_property', text: 'Commercial Property' },
            { key: 'land', value: 'land', text: 'Land' },
            { key: 'other_property', value: 'other_property', text: 'Other' },
        ],
        'retirement_account': [
            // { key: '', value: '', text: 'Account type...', disabled: true },
            { key: 'traditional_ira', value: 'traditional_ira', text: 'Traditional IRA' },
            { key: 'roth_ira', value: 'roth_ira', text: 'Roth IRA' },
            { key: '401k_plan', value: '401k_plan', text: '401(k)' },
            { key: '457_plan', value: '457_plan', text: '457 Plan' },
        ],
    };

    // let titleOptions = client?.estateContactsList
    //     ?.filter(c => ['Self', 'Spouse'].includes(c.relation))
    //     .map(c => ({ key: c._id, value: c._id, text: `${c.firstName} ${c.lastName} [${c.relation === 'Self' ? 'Client' : c.relation}]` }))
    //     || [];
    // titleOptions = [{ key: '', value: '', text: '' }, ...titleOptions];

    const ownershipOpts = [
        { key: 'Client', value: 'Client', text: 'Client' },
        { key: 'Spouse', value: 'Spouse', text: 'Spouse' },
        { key: 'Joint', value: 'Joint', text: 'Joint' },
        { key: 'Corporation or trust', value: 'Corporation or trust', text: 'Corporation or trust' },
    ];

    const addAssetBtn_Click = () => {
        let asset = {};

        // const spouse = client?.estateContactsList?.find(c => c.relation === 'Spouse');
        // const self = client?.estateContactsList?.find(c => c.relation === 'Self');

        // eslint-disable-next-line default-case
        switch (type) {
            case 'real_estate': {
                asset.name = nickname || `Real Estate - ${subtypeOptions.real_estate.find(o => o.value === subtype)}`;
                asset.type = subtype;
                asset.addons = { costBasis: costBasis || '0', originalType: 'real_estate' };
                break;
            }
            case 'retirement_account': {
                asset.name = nickname;
                asset.type = subtype;
                asset.addons = { originalType: 'retirement_account' };
                break;
            }
            case 'brokerage_account': {
                asset.name = nickname;
                asset.type = type;
                // asset.addons = { primaryBeneficiaries: [] };
                break;
            }
            case 'life_insurance': {
                asset.name = nickname;
                asset.type = type;
                break;
            }
            case 'net_income_and_principal':
            case 'personal_tangible_property':
            case 'specific_bequest':
            case 'residuary_estate':
            case 'other': {
                asset.name = nickname;
                asset.type = type;
                break;
            }

            default: 
                break;
        }

        // Set default asset name if no input
        if (asset.name?.length === 0) {
            const defaultOption = subtypeOptions[type] !== undefined
                ? subtypeOptions[type].find(opt => opt.value === subtype)
                : typeOptions.find(opt => opt.value === type);

            asset.name = defaultOption.text;
        }

        asset.value = value || '0';
        asset.state = client?.livingState || '';
        asset.areaId = 'estate';
        asset.ownership = owner;
        asset.addons = {
            ...asset.addons,
            _id: asset.type,
            raw: true,
            primaryBeneficiaries: [],
        };

        if (asset.ownership === 'Corporation or trust') {
            asset.addons.corporationOrTrustOwnerId = specificOwner;
        }

        sysAPI.patch(`/clients/${client._id}/assets`, { assets: [asset] }).then(response => {
            response.data.assets = response.data.assets.map(asset => ({
                ...asset,
                value: commifyNumber((asset.value || '0') + ''),
            }));

            dispatch(updateCurrentClientProp('estateAssetsList', response.data.assets));
            setNickname('');
            setType('');
            setValue('');
            setSubtype('');
            setCostBasis('');
            setOwner('');
            setSpecificOwner('');
        }).catch(err => {
            console.error(err);
            window.alert('Error adding asset. Please try again later.');
        });
    };

    const removeAssetBtn_Click = editingEnabled ?
        (assetId) => {
            return () => {
                sysAPI.delete(`/clients/${client._id}/assets`, { data: { assetsIds: [assetId] } }).then(response => {
                    response.data.assets = response.data.assets.map(asset => ({
                        ...asset,
                        value: commifyNumber((asset.value || '0') + ''),
                    }));
                    dispatch(updateCurrentClientProp('estateAssetsList', response.data.assets));
                }).catch(err => console.error(err));
            }
        }
        : null;

    const statesOptions = [
        { key: '', value: '', text: '' },
        ...Object.keys(usStates).map(state => ({
            key: usStates[state],
            value: usStates[state],
            text: usStates[state],
        })),
    ];

    const specificOwnerOpts = client?.estateContactsList
        ?.filter(c => ['trust', 'corp'].includes(c.type))
        ?.map(opt => ({ text: `${opt.firstName} [${opt.relation}]`, value: opt._id }));

    const SpecificOwnerInput = () => {
        if (owner !== 'Corporation or trust') return <></>;

        return (
            <Select
                placeholder='Select corporation or trust...'
                fluid
                options={specificOwnerOpts}
                value={specificOwner}
                onChange={(e, elem) => setSpecificOwner(elem.value)} style={{ marginBottom: '10px' }}
            />
        )
    }

    //console.log(client?.estateAssetsList)

    return (
        <Modal open={open} size='large'>
            <Modal.Header>Assets</Modal.Header>
            <Modal.Content>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={11}>
                            <RealEstateAssets assets={client?.estateAssetsList?.filter(a => isRealEstateAsset(a.type.toLowerCase().replace(/\s/g, '_')))} removeBtn_Click={removeAssetBtn_Click} />
                            <RetirementAccountAssets assets={client?.estateAssetsList?.filter(a => isRetirementAccountAsset(a.type.toLowerCase().replace(/\s/g, '_')))} removeBtn_Click={removeAssetBtn_Click} />
                            <BrokerageAccountAssets assets={client?.estateAssetsList?.filter(a => a.type.toLowerCase().replace(/\s/g, '_').startsWith('brokerage_account'))} removeBtn_Click={removeAssetBtn_Click} />
                            <LifeInsuranceAssets assets={client?.estateAssetsList?.filter(a => a.type.toLowerCase().replace(/\s/g, '_').startsWith('life_insurance'))} removeBtn_Click={removeAssetBtn_Click} />
                            <_529PlanAssets assets={client?.estateAssetsList?.filter(a => a.type.toLowerCase().replace(/\s/g, '_').startsWith('529_plan'))} removeBtn_Click={removeAssetBtn_Click} />
                            <UnknownAssets title='Net Income and Principal' assets={client?.estateAssetsList?.filter(a => a.type.toLowerCase().replace(/\s/g, '_') === 'net_income_and_principal')} removeBtn_Click={removeAssetBtn_Click} />
                            <UnknownAssets title='Personal Tangible Properties' assets={client?.estateAssetsList?.filter(a => a.type.toLowerCase().replace(/\s/g, '_') === 'personal_tangible_property')} removeBtn_Click={removeAssetBtn_Click} />
                            <UnknownAssets title='Specific Bequest' assets={client?.estateAssetsList?.filter(a => a.type.toLowerCase().replace(/\s/g, '_') === 'specific_bequest')} removeBtn_Click={removeAssetBtn_Click} />
                            <UnknownAssets title='Redisuary Estate' assets={client?.estateAssetsList?.filter(a => a.type.toLowerCase().replace(/\s/g, '_') === 'residuary_estate')} removeBtn_Click={removeAssetBtn_Click} />
                            <UnknownAssets title='Cash' assets={client?.estateAssetsList?.filter(a => a.type.toLowerCase().replace(/\s/g, '_') === 'cash')} removeBtn_Click={removeAssetBtn_Click} />
                            <UnknownAssets title='Other Assets' assets={client?.estateAssetsList?.filter(a => a.type.toLowerCase().replace(/\s/g, '_') === 'other')} removeBtn_Click={removeAssetBtn_Click} />
                        </Grid.Column>
                        <Grid.Column width={5}>
                            {editingEnabled &&
                                <Segment>
                                    <Header as='h3'>Create asset</Header>
                                    <Select fluid options={typeOptions} value={type} placeholder='Type' onChange={(e, elem) => { setType(elem.value); setSubtype(''); }} style={{ marginBottom: '10px' }} />
                                    {subtypeOptions[type] &&
                                        <Select fluid
                                            options={subtypeOptions[type]}
                                            value={subtype}
                                            placeholder={type === 'real_estate' ? 'Property type' : 'Account type'}
                                            onChange={(e, elem) => setSubtype(elem.value)} style={{ marginBottom: '10px' }}
                                        />
                                    }

                                    {type === 'real_estate' && <div>
                                        <Input fluid placeholder='Property nickname' style={{ marginBottom: '10px' }} value={nickname} onChange={(e, elem) => setNickname(elem.value)} />
                                        <Input fluid placeholder='Market value' style={{ marginBottom: '10px' }} value={value} onChange={(e, elem) => setValue(elem.value)} />
                                        <Select fluid
                                            placeholder='Property titled to'
                                            // options={[...titleOptions, { key: 'Joint', value: 'Joint', text: 'Joint' }, { key: 'Corporation or trust', value: 'Corporation or trust', text: 'Corporation or trust' }]}
                                            options={ownershipOpts}
                                            value={owner} onChange={(e, elem) => setOwner(elem.value)}
                                            style={{ marginBottom: '10px' }}
                                        />
                                        <SpecificOwnerInput />
                                        <Input fluid placeholder='Cost basis' style={{ marginBottom: '10px' }} value={costBasis} onChange={(e, elem) => setCostBasis(elem.value)} />
                                        <Select fluid
                                            placeholder='State'
                                            options={statesOptions}
                                            value={state} onChange={(e, elem) => setState(elem.value)}
                                            style={{ marginBottom: '10px' }}
                                        />
                                    </div>}

                                    {type === 'retirement_account' && <div>
                                        <Input fluid placeholder='Account nickname' style={{ marginBottom: '10px' }} value={nickname} onChange={(e, elem) => setNickname(elem.value)}  />
                                        <Input fluid placeholder='Account value' style={{ marginBottom: '10px' }} value={value} onChange={(e, elem) => setValue(elem.value)} />
                                        <Select fluid
                                            placeholder='Account title'
                                            // options={[...titleOptions]}
                                            options={ownershipOpts}
                                            style={{ marginBottom: '10px' }}
                                            value={owner} onChange={(e, elem) => setOwner(elem.value)}
                                        />
                                        <SpecificOwnerInput />
                                        <Select fluid
                                            placeholder='State'
                                            options={statesOptions}
                                            value={state} onChange={(e, elem) => setState(elem.value)}
                                            style={{ marginBottom: '10px' }}
                                        />
                                    </div>}

                                    {type === 'brokerage_account' && <div>
                                        <Input fluid placeholder='Account nickname' style={{ marginBottom: '10px' }} value={nickname} onChange={(e, elem) => setNickname(elem.value)} />
                                        <Input fluid placeholder='Account balance' style={{ marginBottom: '10px' }} value={value} onChange={(e, elem) => setValue(elem.value)} />
                                        <Select fluid
                                            placeholder='Account title'
                                            // options={[...titleOptions, { key: 'Joint', value: 'Joint', text: 'Joint' }, { key: 'Corporation or trust', value: 'Corporation or trust', text: 'Corporation or trust' }]}
                                            options={ownershipOpts}
                                            style={{ marginBottom: '10px' }}
                                            value={owner} onChange={(e, elem) => setOwner(elem.value)}
                                        />
                                        <SpecificOwnerInput />
                                        <Select fluid
                                            placeholder='State'
                                            options={statesOptions}
                                            value={state} onChange={(e, elem) => setState(elem.value)}
                                            style={{ marginBottom: '10px' }}
                                        />
                                    </div>}

                                    {type === 'life_insurance' && <div>
                                        <Select fluid
                                            placeholder='Policy owner'
                                            // options={[...titleOptions, { key: 'Joint', value: 'Joint', text: 'Joint' }, { key: 'Trust', value: 'Trust', text: 'Trust' }]}
                                            options={ownershipOpts}
                                            style={{ marginBottom: '10px' }}
                                            value={owner} onChange={(e, elem) => setOwner(elem.value)}
                                        />
                                        <SpecificOwnerInput />
                                        <Input fluid placeholder='Policy nickname' style={{ marginBottom: '10px' }} value={nickname} onChange={(e, elem) => setNickname(elem.value)} />
                                        <Input fluid placeholder='Death benefit amount' style={{ marginBottom: '10px' }} value={value} onChange={(e, elem) => setValue(elem.value)} />
                                        <Select fluid
                                            placeholder='State'
                                            options={statesOptions}
                                            value={state} onChange={(e, elem) => setState(elem.value)}
                                            style={{ marginBottom: '10px' }}
                                        />
                                    </div>}

                                    {assetsExtras.includes(type) && <div>
                                        <Input fluid placeholder='Nickname' style={{ marginBottom: '10px' }} value={nickname} onChange={(e, elem) => setNickname(elem.value)} />
                                        <Input fluid placeholder='Value' style={{ marginBottom: '10px' }} value={value} onChange={(e, elem) => setValue(elem.value)} />
                                        <Select fluid
                                            placeholder='Owner'
                                            // options={[...titleOptions, { key: 'Joint', value: 'Joint', text: 'Joint' }, { key: 'Corporation or trust', value: 'Corporation or trust', text: 'Corporation or trust' }]}
                                            options={ownershipOpts}
                                            style={{ marginBottom: '10px' }}
                                            value={owner} onChange={(e, elem) => setOwner(elem.value)}
                                        />
                                        <SpecificOwnerInput />
                                        <Select fluid
                                            placeholder='State'
                                            options={statesOptions}
                                            value={state} onChange={(e, elem) => setState(elem.value)}
                                            style={{ marginBottom: '10px' }}
                                        />
                                    </div>}

                                    {type?.length > 0 &&
                                        <Button fluid onClick={addAssetBtn_Click}>Add asset</Button>
                                    }
                                </Segment>
                            }
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={closeBtn_Click}>Close</Button>
            </Modal.Actions>
        </Modal>
    )
}