import { useState } from "react";
import { Button, Icon } from "semantic-ui-react";
import { sysAPI } from "../../../apis/SysAPI";
import { fetchCurrentAdvisorProfile } from "../../../actions/advisor.actions";
import { useDispatch, useSelector } from "react-redux";

const EnableDisable2Fa = ({ advisorProfile }) => {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const [loader, setLoader] = useState(false);
  console.log({ advisorProfile });

  const handleChangeStatus = async () => {
    setLoader(true);
    try {
      const status = advisorProfile?.hasEnabled2FA
      await sysAPI.post(`/advisor/${advisorProfile._id}/update`,{
        hasEnabled2FA: !status
      })
      dispatch(fetchCurrentAdvisorProfile(advisorProfile._id));
    } catch (error) {
      console.log({ error });
      alert(error.message);
    }
    setLoader(false);
  };

  return (
    <>
      {me?.permissions?.includes("advisors:enable_disable_2fa") && <>
        <Button color={advisorProfile?.hasEnabled2FA ? "orange" : "green"} loading={loader} onClick={() => handleChangeStatus()}>
          <Icon name="key" /> {advisorProfile?.hasEnabled2FA ? "Disable" : "Enable"} 2Fa
        </Button>
      </>}
    </>
  );
};

export default EnableDisable2Fa;
