/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { fetchCurrentCompany, fetchCurrentCompanyCredits, fetchCurrentCompanyRequests } from "../../actions/company.actions";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { DashboardTemplate } from "../../templates/DashboardTemplate"
import { Message, MessageHeader, Segment } from 'semantic-ui-react'
import CompanySideMenu from "../../components/tabs/company/SideMenu";
import CompanyGeneralInfo from "../../components/tabs/company/GeneralInfo";
import CompanyAdvisorsTab from "../../components/tabs/company/AdvisorsTab";
import CompanyEstateSnapshotTab from "../../components/tabs/company/EstateSnapshotTab";
import CompanyCreditsGeneral from "../../components/tabs/company/CreditsGeneral";
import CompanyPurchasedCredits from "../../components/tabs/company/PurchasedCredits";
import CompanyRequestedCredits from "../../components/tabs/company/RequestedCredits";
import IntegrationSalesforce from "../../components/tabs/company/IntegrationSalesforce";
import { Page404 } from "../404";
import CompanyAdvisorGroups from "../../components/tabs/company/advisor-group";
import EnterprisePermissions from "../../components/tabs/company/enteprise-permissions";

const CompanyProfile = () => {
    const params = useParams();
    const location = useLocation();
    const history = useHistory();
    // Crear un objeto con los parámetros de query
    const queryParams = new URLSearchParams(location.search);
    const { companyId } = params;
    const [activeMenu, setActiveMenu] = useState('general')
    const dispatch = useDispatch();
    const [finishLoader, setFinishLoader] = useState(false);
    const me = useSelector(state => state.me);

    useEffect(() => {
      // console.log({CompanyProfile: companyId})
      dispatch(fetchCurrentCompany(companyId));
      dispatch(fetchCurrentCompanyCredits(companyId, 'all'));
      dispatch(fetchCurrentCompanyRequests(companyId));
      const tabActive = queryParams.get("tab");
      if(tabActive){
        setActiveMenu(tabActive);
      }
      console.log({tabActive})

      setTimeout(() => {
        setFinishLoader(true)
      },1000)
    }, []);

    const companyProfile = useSelector((state) => state.currentCompany);

    const changetab = (newTabValue) => {
      queryParams.set("tab", newTabValue);
      setActiveMenu(newTabValue);
      history.push(`${location.pathname}?${queryParams.toString()}`);
    }

    if(Object.keys(companyProfile).length < 1 && finishLoader){
      return <Page404 />
    }

    return (
        <DashboardTemplate scope='companies' permission="companies:company_view">
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'auto 1fr',
              gridAutoRows: '1fr',
              columnGap: '1em',
            }}
          >
            <CompanySideMenu activeMenu={activeMenu} changeMenu={changetab} />
            <div>
              {!companyProfile?.superAdmin && <>
                {/* <Segment style={{ margin: 0, marginBottom: "10px" }}> */}

                <Message negative>
                  <MessageHeader>Currently, there is no Super Admin. Please assign this role to someone.</MessageHeader>
                  <p style={{cursor: "pointer"}} onClick={() => setActiveMenu("advisors")}>We are going to assign an advisor.</p>
                </Message>
                {/* </Segment> */}
              </>}
              <Segment style={{ margin: 0 }}>
                {["general"].includes(activeMenu) && <CompanyGeneralInfo activeMenu={activeMenu} />}
                {["advisorGroup"].includes(activeMenu) && <CompanyAdvisorGroups activeMenu={activeMenu} />}
                {["enterprisePermissions"].includes(activeMenu) && me?.permissions?.includes("companies:show_permissions_tab") && <EnterprisePermissions />}
                {["advisors"].includes(activeMenu) && <CompanyAdvisorsTab activeTab={activeMenu} />}
                {["estate_snapshot"].includes(activeMenu) && <CompanyEstateSnapshotTab activeTab={activeMenu} />}
                {["creditsGeneral"].includes(activeMenu) && <CompanyCreditsGeneral activeMenu={activeMenu} />}
                {["fulltaxPurchased", "estatePurchased", "homePurchased", "autoPurchased"].includes(activeMenu) && <CompanyPurchasedCredits activeMenu={activeMenu} />}
                {["fulltaxRequests", "estateRequests", "homeRequests", "autoRequests"].includes(activeMenu) && <CompanyRequestedCredits activeMenu={activeMenu} />}
                {["integration_salesforce"].includes(activeMenu) && <IntegrationSalesforce activeMenu={activeMenu} />}
              </Segment>

            </div>
          </div>
        </DashboardTemplate>
    )
}

export default CompanyProfile