export const typeClient = (client) => {
  let type = client.fromIntegration?.origin || 'Client'

  let hasNumber = /[0-9]/.test(type)
  if(hasNumber){
    return "Client/Clone"
  }
  if(type === "Client"){
    return type
  }

  return `Client/${nameToUpperCase(type)}`
};


export const nameToUpperCase = (name) => {
  let finalResult = ""
  switch (name){
    case "precisefp": 
      finalResult = "Precise FP"
      break;

      case "mgp":
      finalResult = "MGP"
      break;

    default:
      finalResult = getFirstLetterInUpperCase(name)
  }

  return finalResult
}

export const getFirstLetterInUpperCase = (text) => {
  let words = text.split(" ");
  let wordsUpperCase = [];

  for (let i = 0; i < words.length; i++) {
    let firstLetter = words[i].charAt(0).toUpperCase();
    let otherLetter = words[i].slice(1);
    wordsUpperCase.push(firstLetter + otherLetter);
  }

  let stringUpperCase = wordsUpperCase.join(" ");
  return stringUpperCase
}