import { sysAPI } from "../apis/SysAPI";
import { commifyNumber } from "../helpers";

export const fetchCurrentClient = (clientId) => {
    return async (dispatch) => {
        try {
            const response = await sysAPI.get(`/clients/${clientId}`);
            if (response.data.client?.estateAssetsList) {
                response.data.client.estateAssetsList = response.data.client.estateAssetsList.map(asset => ({
                    ...asset,
                    value: commifyNumber((asset.value || '0') + ''),
                }));
            }

            dispatch(setCurrentClient(response.data.client));
        }
        catch (err) {
            console.error(err);
        }
    }
}

export const setCurrentClient = (client) => {
    return {
        type: 'set_current_client',
        payload: client,
    };
}

export const updateCurrentClientProp = (prop, value) => {
    return {
        type: 'update_current_client_prop',
        payload: { prop, value },
    };
}