import { sysAPI } from "../apis/SysAPI";

export const fetchCurrentAdvisorProfile = (advisorId, waterfall = true) => {
    return async (dispatch) => {
        try {
            const response = await sysAPI.get(`/advisor/${advisorId}`);
            dispatch(setCurrentAdvisorProfile(response.data.advisorProfile));
            if (waterfall) {
                dispatch(fetchCurrentAdvisorActivity(advisorId));
                dispatch(fetchCurrentAdvisorClients(advisorId));
            }
        }
        catch (err) {
            console.error(err);
        }
    }
}

export const setCurrentAdvisorProfile = (advisorProfile) => {
    return {
        type: 'set_current_advisor_profile',
        payload: advisorProfile,
    }
}

export const fetchCurrentAdvisorActivity = (advisorId) => {
    return async (dispatch) => {
        try {
            const extractionsResponse = await sysAPI.get(`/advisors/areas-extractions?advisorId=${advisorId}&startDate=2000-01-01&endDate=9999-12-31`);
            dispatch(setCurrentAdvisorActivity('areasExtractions', extractionsResponse.data.data));

            const recommendationsResponse = await sysAPI.get(`/advisors/areas-recommendations?advisorId=${advisorId}&startDate=2000-01-01&endDate=9999-12-31`);
            dispatch(setCurrentAdvisorActivity('areasRecommendations', recommendationsResponse.data.data));
        }
        catch (err) {
            console.error(err);
        }
    }
}

export const setCurrentAdvisorActivity = (section, activity) => {
    return {
        type: 'set_current_advisor_activity',
        payload: {
            section,
            activity,
        }
    }
}

export const fetchCurrentAdvisorClients = (advisorId) => {
    return async (dispatch) => {
        try {
            const response = await sysAPI.get(`/advisor/${advisorId}/clients`);
            dispatch(setCurrentAdvisorClients(response.data.clients));
        }
        catch (err) {
            console.error(err);
        }
    }
}

export const setCurrentAdvisorClients = (clients) => {
    return {
        type: 'set_current_advisor_clients',
        payload: clients,
    }
}