import { combineReducers } from "redux";
import { currentAdvisorActivityReducer, currentAdvisorClientsReducer, currentAdvisorProfileReducer } from "./advisor.reducers";
import { currentClientReducer } from "./client.reducers";
import { estateManualInputAssetsReducer, estateManualInputChangeDocumentsReducer, estateManualInputChangeReducer, estateManualInputDocumentsReducer, estateManualInputReducer, estateManualInputRequestReducer } from "./estate-manual-input.reducers";
import { currentAdvisorInvoicesReducer } from "./invoice.reducers";
import { areasExtractionsReducer, areasRecommendationsReducer } from "./metric.reducers";
import { meReducer } from "./sysuser.reducers";
import { currentCompanyReducer, currentCompanyCredits, currentCompanyRequests } from "./company.reducers";
import { snackbarReducer } from "./snackbar.reducers";

export default combineReducers({
    me: meReducer,

    // Advisor Profile
    currentAdvisorProfile: currentAdvisorProfileReducer,
    currentAdvisorActivity: currentAdvisorActivityReducer,
    currentAdvisorClients: currentAdvisorClientsReducer,

    // Metrics
    areasExtractions: areasExtractionsReducer,
    areasRecommendations: areasRecommendationsReducer,

    // Sales
    currentAdvisorInvoices: currentAdvisorInvoicesReducer,

    // Manual Input
    estateManualInput: estateManualInputReducer,
    estateManualInputAssets: estateManualInputAssetsReducer,
    estateManualInputRequest: estateManualInputRequestReducer,
    estateManualInputDocuments: estateManualInputDocumentsReducer,
    estateManualInputChange: estateManualInputChangeReducer,
    estateManualInputChangeDocuments: estateManualInputChangeDocumentsReducer,

    // Client
    currentClient: currentClientReducer,

    //Company
    currentCompany: currentCompanyReducer,
    currentCompanyCredits: currentCompanyCredits,
    currentCompanyRequests:currentCompanyRequests,

    //snackbar
    snackbar:snackbarReducer,
});