import { useState } from 'react';
import { Button, Checkbox, Input } from 'semantic-ui-react';

const FromMapperTax = ({ dataSend, setDataSend }) => {
  const [mapper, setMapper] = useState(dataSend?.props_mapper || []);

  const handleChangeValue = (value, key) => {
    setDataSend({ ...dataSend, [key]: value });
  };

  const handleRemoveMapper = (index) => {
    setMapper([]);
    let auxMapper = JSON.parse(JSON.stringify(mapper));
    auxMapper.splice(index, 1);
    setTimeout(() => {
      setMapper(auxMapper);
      handleChangeValue(auxMapper,"props_mapper")
    },50)
  };

  const handleUpdateMapper = (prop, index, value) => {
    setMapper([]);
    let auxMapper = JSON.parse(JSON.stringify(mapper));
    auxMapper[index][prop] = value
    setMapper(auxMapper);
    handleChangeValue(auxMapper, "props_mapper")
  };

  const handleChangeValueTableInitial = (value, key) => {
    const tableInitial = dataSend.tableInitial || {}
    tableInitial[key] = value
    console.log({tableInitial})
    setDataSend({ ...dataSend, tableInitial })
  }

  return (
    <div style={{display: "grid", gap:"10px"}}>
      <div>
        <div>Name of the table</div>
        <div>
          <Input fluid value={dataSend.tableName} onChange={(e, { value }) => handleChangeValue(value, 'tableName')} />
        </div>
      </div>
      <div>
        <div>Key to identify the client ID in this table</div>
        <div>
          <Input fluid value={dataSend.keyClientId} onChange={(e, { value }) => handleChangeValue(value, 'keyClientId')} />
        </div>
      </div>
      <div className='flex-gap-5'>
        <div style={{width:"32%"}}>
          <div>
            Table where found the correct ID
          </div>
          <div>
            <Input fluid value={dataSend.tableInitial?.tableName} onChange={(e, { value }) => handleChangeValueTableInitial(value, 'tableName')} />
          </div>
        </div>
        <div style={{width:"32%"}}>
          <div>
            Prop to use in the relation
          </div>
          <div>
            <Input fluid value={dataSend.tableInitial?.propToFind} onChange={(e, { value }) => handleChangeValueTableInitial(value, 'propToFind')} />
          </div>
        </div>
        <div style={{width:"32%"}}>
          <div>
            Get prop relation
          </div>
          <div>
            <Input fluid value={dataSend.tableInitial?.getPropRelation} onChange={(e, { value }) => handleChangeValueTableInitial(value, 'getPropRelation')} />
          </div>
        </div>
      </div>
      <div>
        <div>Configuration the Mapper</div>
        <div>
          <Button size='mini' onClick={() => setMapper([...mapper, {}])}>Add Prop</Button>
        </div>
        <div className='box-scroll'>
          {mapper.map((m,i) => (
            <div key={i} className='flex-gap-5 card-background-gray' style={{justifyContent:"space-between", alignItems:"center"}}>
              <div>
                <div>
                  Name Prop
                </div>
                <div>
                  <Input value={m.key} onChange={(e,{value}) => handleUpdateMapper("key", i, value)}  />
                </div>
              </div>
              <div>
                <div>
                  Our Prop
                </div>
                <div>
                  <Input value={m.value} onChange={(e,{value}) => handleUpdateMapper("value", i, value)} />
                </div>
              </div>
              <div>
                <div>
                  Default Value
                </div>
                <div>
                  <Input value={m.defaultValue} onChange={(e,{value}) => handleUpdateMapper("defaultValue", i, value)} />
                </div>
              </div>
              <div>
                {/* <div>
                  Force update
                </div> */}
                <div>
                  <Checkbox label="Force Update" checked={m.forceUpdate} onChange={(e,{checked}) => handleUpdateMapper("forceUpdate", i, checked)} />
                </div>
              </div>
              <div>
                <Button color='red' onClick={() => handleRemoveMapper(i)}>Remove</Button>
              </div>
            </div>
          ))}
        </div>
        <div>
          Notes: The "Force update" is for send the prop if already exists. but becareful, if the value is 0, it send 0 too.
        </div>
      </div>
    </div>
  );
};

export default FromMapperTax;
