import { useEffect, useState } from "react";
import { Button, Icon, Modal } from "semantic-ui-react";
import FormSaml from "./Form";
import { useSelector } from "react-redux";
import { sysAPI } from "../../apis/SysAPI";

const UpdateSaml = ({ refreshFunction, dataInfo, companies = [] }) => {
  const me = useSelector((state) => state.me);
  const [openModal, setOpenModal] = useState(false)
  const [info, setInfo] = useState({})

  const handleGetData = async () => {
    try {
      const {data} = await sysAPI.get(`/configs-saml/${dataInfo._id}`, {params:{
        decrypt: true
      }});
      delete data.data._id
      setInfo(data.data)
    } catch (error) {
      const message = error.response?.data?.message || error.message
      console.log({error})
      alert(message)
    }
  }

  const handleUpdate = async () => {
    try {
      const {data} = await sysAPI.put(`/configs-saml/${dataInfo._id}`, info);
      console.log({data})
      refreshFunction()
      setOpenModal(false)
    } catch (error) {
      const message = error.response?.data?.message || error.message
      console.log({error})
      alert(message)
    }
    console.log({info})
  }

  useEffect(() => {
    if(openModal){
      handleGetData()
    }

    // eslint-disable-next-line
  },[openModal])
  return (
    <>
      {me?.permissions?.includes("saml:saml_update") && (
        <>
          <Button icon onClick={() => setOpenModal(true)}>
            <Icon name="pencil" />
          </Button>

          <Modal size="small" open={openModal} onClose={() => setOpenModal(false)}>
            <Modal.Header>Updating Integration SAML</Modal.Header>
            <Modal.Content>
              <FormSaml info={info} setInfo={setInfo} companies={companies}  />
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={() => setOpenModal(false)}>Cancel</Button>
              <Button onClick={handleUpdate} color="green">
                Update
              </Button>
            </Modal.Actions>
          </Modal>
        </>
      )}
    </>
  );

}

export default UpdateSaml