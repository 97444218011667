import { useEffect, useState } from "react"
import { Redirect } from "react-router-dom";
import { sysAPI } from "../apis/SysAPI";

export const LogoutView = () => {
    const [loggedOut, setLoggedOut] = useState(0);

    useEffect(() => {
        const refreshToken = localStorage.getItem('refreshToken');
        sysAPI.post('/auth/logout', { refreshToken }).then(response => {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            setLoggedOut(1);
        }).catch(err => {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            setLoggedOut(1);
        });
    }, []);

    if (loggedOut === 1) {
        return <Redirect to='/login' />
    }

    return (
        <p>Please wait...</p>
    )
}