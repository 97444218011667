import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Input, Modal, Select, TextArea } from 'semantic-ui-react';
import { responseError } from '../../../../helpers';
import { sysAPI } from '../../../../apis/SysAPI';
import { advisorAPI } from '../../../../apis/AdvisorsAPI';
import { fetchCurrentCompanyCredits } from '../../../../actions/company.actions';

const BuyCredits = ({company}) => {
  const [openModal, setOpenModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const me = useSelector((state) => state.me);
  const [namesRequester, setNamesRequester] = useState([])
  const [dataSend, setDataSend] = useState({})
  const dispatch = useDispatch();

  const areasAvailables = [
    {key: 1, value: "fulltax", text: "Tax" },
    {key: 2, value: "estate", text: "Estate" },
    {key: 3, value: "home", text: "Home" },
    {key: 4, value: "auto", text: "Auto" },
  ]

  if(company?.uploadModel?.type !== "limited"){
    areasAvailables.splice(0,1);
  }

  const requestedByArea = ["Sales", "Customer Success", "Support", "Advisor"].map((e,i) => ({key: i, value: e, text: e}))

  const handleGetNamesOfRequester = async () => {
    try {
      const {data:sysUsers} = await sysAPI.get('/users');
      let users = sysUsers?.users || []
      users = users.filter(e => !e.permissions.includes("manual_inputs:show_in_the_list_reviewers"))
      users = users.map(e => {
        return {
          key: e._id,
          value: `${e.firstName} ${e.lastName} (${e.email})`,
          text: `${e.firstName} ${e.lastName}`
        }
      }) || []
      setNamesRequester(users)
    } catch (error) {
      responseError(error);
    }
  }

  const handleBuyCredits = async () => {
    setLoader(true);
    try {
      console.log({dataSend})
      await advisorAPI.post('/companies/upload-credits/add-credits', {
        companyId: company?._id,
        ...dataSend
      });
      setOpenModal(false);
      await dispatch(fetchCurrentCompanyCredits(company?._id, 'all'));
    } catch (error) {
      responseError(error);
    }
    setLoader(false);
  };

  const handleChangeValue = (value, prop) => {
    setDataSend({ ...dataSend, [prop]: value });
  }

  useEffect(() => {
    handleGetNamesOfRequester()
  },[])

  if (!me?.permissions?.includes('companies:buy_credits')) {
    return <></>;
  }

  const isLegacy = company?.uploadModel?.type === "legacy"

  return (
    <>
      <Button color="olive" onClick={() => setOpenModal(true)} disabled={isLegacy}>
        Buy credits
      </Button>

      <Modal open={openModal} size='tiny'>
        <Modal.Header>Buy credits</Modal.Header>
        <Modal.Content>
          <div style={{display: "grid", gap: "10px"}}>
            <div>
              <div>Area:</div>
              <div>
                <Select options={areasAvailables} fluid onChange={(e, {value}) => handleChangeValue(value, "area")} />
              </div>
            </div>
            <div>
              <div>Quantity of credits:</div>
              <div>
                <Input fluid type='number' onChange={(e, {value}) => handleChangeValue(value, "credits")} />
              </div>
            </div>
            <div>
              <div>Requested by:</div>
              <div>
                <Select options={requestedByArea} fluid onChange={(e, {value}) => handleChangeValue(value, "requestedArea")} />
              </div>
            </div>
            <div>
              <div>Name of the Requester:</div>
              <div>
                <Select options={namesRequester} fluid onChange={(e, {value}) => handleChangeValue(value, "requestedName")} />
              </div>
            </div>
            <div>
              <div>Additional Comments:</div>
              <Form>
                <TextArea placeholder="" onChange={(e, {value}) => handleChangeValue(value, "additonalComments")} />
              </Form>
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpenModal(false)}>Cancel</Button>
          <Button color="green" onClick={handleBuyCredits} loading={loader}>
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default BuyCredits;
