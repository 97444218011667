// eslint-disable-next-line
export default {
  //clientIDHouseOrApartment: "",
  //clientIDApartmentLossAssessment: "",
  //clientIDApartmentFloor: "",
  //clientIDRecentRenovation1: "",
  //clientIDImprovements1: "",
  //clientIDLossPayee: "",
  //clientIDHouseOneAboveStandardFinishes: "",
  //clientIDContentsPhotographed1: "",
  //clientIDInspectorEnteredHome1: "",
  //clientIDHouseOneEstimatedContentsValue: "0",
  //clientIDJewelryValue: "0",
  //clientIDSilverwareValue: "0",
  //clientIDFineArtValue: "0",
  //clientIDFurs: "0",
  //clientIDWineValue: "0",
  //clientIDAntiquesValue: "0",
  //clientIDMusicalInstrumentsValue: "0",
  //clientIDOtherCollectibles: "0",
  //clientIDLastTimeReappraised: "0",
  //clientIDValuePhysicalCash: "0",
  //clientIDTotalAssetValue: "0",
  //clientIDLastTimeShopped: "0",
  //clientIDCredits: "",
  //clientIDAccessToGroupUmbrellaThroughEmployer: "",
  //clientIDEnrolledToGroupUmbrella: "",
  //clientIDItemsForExhibiting: "",
  //clientIDExtraItemsForHomeInsurance: "",
  //clientIDHasSeparatePolicyForBoatsJetskisEtc: "",
  //clientIDOnAnyBoards: "",
  //clientIDDomesticHelp: "",
}
