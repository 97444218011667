import { useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import FromMapperTax from './Form';
import { advisorAPI } from '../../../../../apis/AdvisorsAPI';
import { fetchCurrentCompany } from '../../../../../actions/company.actions';
import { useDispatch } from 'react-redux';

const EditMapper = ({ data, index, companyProfile }) => {
    const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [dataSend, setDataSend] = useState(data || {});

  const handleUpdateMapper = async () => {
    setLoader(true);
    try {
      await advisorAPI.put(`/companies/${companyProfile._id}`, {
        [`_integrations.salesforce.settings.updateDataTax.taxMapper.${index}`]: dataSend,
      });
      await dispatch(fetchCurrentCompany(companyProfile._id));
      setOpenModal(false);

    } catch (error) {
      console.error({ error });
      alert(error?.response?.data?.message || error?.message || 'Error...');
    }
    setLoader(false);
  };

  return (
    <>
      <Button size="mini" color='grey' onClick={() => setOpenModal(!openModal)}>
        Edit
      </Button>

      <Modal open={openModal}>
        <Modal.Header>Edit Mapper Table: {data.tableName}</Modal.Header>
        <Modal.Content>
          <div>
            <FromMapperTax dataSend={dataSend} setDataSend={setDataSend} />
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpenModal(false)}>Cancel</Button>
          <Button onClick={handleUpdateMapper} color="green" loading={loader}>
            Update Table
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default EditMapper;
