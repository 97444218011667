import { Input } from "semantic-ui-react"
import FormPermissions from "./FormPermissions";
import { useEffect, useState } from "react";

const FormRoleEnterpriseSettings = ({info, setInfo, permissions}) => {
    const [newPermissions, setNewPermissions] = useState([...info?.enterprisePermissions || []])
    const changeProp = (key, value) => {
      setInfo({...info, [key]: value});
    }

    useEffect(() => {
      changeProp("enterprisePermissions", newPermissions)

      // eslint-disable-next-line
    },[newPermissions])

    return <div className="grid_fp">
      <div>
        <div>
          Name
        </div>
        <div>
          <Input disabled fluid value={info.name} onChange={(e,{value}) => changeProp("name", value)} />
        </div>
      </div>
      <div>
        <div>
          key
        </div>
        <div>
          <Input disabled fluid value={info.key} onChange={(e,{value}) => changeProp("key", value)} />
        </div>
      </div>
      <div>
        <FormPermissions
          permissions={newPermissions}
          setPermissions={setNewPermissions}
          defaultRoles={permissions}
        />
      </div>
    </div>
}

export default FormRoleEnterpriseSettings