import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon, Input } from 'semantic-ui-react';
import { fetchCurrentAdvisorProfile } from '../../../actions/advisor.actions';
import { sysAPI } from '../../../apis/SysAPI';
import { fpalphaAPI } from '../../../apis/FPAlphaAPI';

const ImportClients = () => {
  const dispatch = useDispatch();
  const selectFPClientFileInput = useRef();
  const [loaderUpload, setLoaderUpload] = useState(false);
  const advisorProfile = useSelector((state) => state.currentAdvisorProfile);

  const importFPClientInput_Change = async (e) => {
    console.log({ e });
    if (e.target.files?.length === 0) {
      return;
    }
    setLoaderUpload(true);
    try {
      const confirm = window.confirm(`Please confirm you want to inject the client included in ${e.target.files[0].name} to ${advisorProfile.fullName}.`);
      if (confirm) {
        const { data } = await sysAPI.post('/generate-code');
        const form = new FormData();
        form.append('fpclientSrc', e.target.files[0]);
        form.append('code', data.code);
        form.append('targetAdvisorId', advisorProfile._id);

        // eslint-disable-next-line
        const { data: dataImported } = await fpalphaAPI.post('/advisor/clientlist/add-prefilled', form, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        alert(`Clients were added to ${advisorProfile.fullName} successfully.`);
        dispatch(fetchCurrentAdvisorProfile(advisorProfile._id));
      }
    } catch (error) {
      console.error({ error });
      alert(`Something went wrong while adding ${e.target.files[0].name} clients to ${advisorProfile.fullName}.`);
    }
    e.target.value = null;
    setLoaderUpload(false);
  };

  const importFPClientBtn_Click = () => {
    selectFPClientFileInput.current.inputRef.current.click();
  };

  return (
    <>
      <Button icon color="blue" labelPosition="left" onClick={importFPClientBtn_Click} loading={loaderUpload}>
        <Icon name="file" />
        Import .fpclient
      </Button>

      <Input type="file" ref={selectFPClientFileInput} style={{ display: 'none' }} onChange={importFPClientInput_Change} />
    </>
  );
};
export default ImportClients;
