import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Message } from "semantic-ui-react";

export const ComponentTemplate = ({ children, scope, permission }) => {
  const me = useSelector((state) => state.me);

  useEffect(() => {}, []);

  if (!me) {
    return <div>Loading...</div>;
  }

  if (Object.keys(me).length < 1) {
    return <div>Loading...</div>;
  }

  if (permission && !me?.permissions?.includes(permission)) {
    return <Message color="red">Insufficient permissions. Please contact support for granting access.</Message>;
  }

  return <div className="component-template">{children}</div>;
};
