/* eslint-disable react-hooks/exhaustive-deps */
import { DashboardTemplate } from "../../templates/DashboardTemplate";
import { TabContainer } from "../../components/shared/TabContainer";
import { sysAPI } from "../../apis/SysAPI";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AddCompanyModal } from "../../components/modal/AddCompanyModal";
import { Button, Pagination, Container, Table, Input, Icon, Loader, Segment, Dimmer, Popup, Message, MessageHeader } from "semantic-ui-react";
import { useSelector } from "react-redux";
import MessageTablesEmpty from "../../components/globals/MessageTablesEmpty";
import { copyToClipboard } from "../../helpers";
import moment from "moment";

const DashboardCompanies = () => {
  const me = useSelector((state) => state.me);
  const history = useHistory();
  const pageSize = 15;
  const [companies, setCompanies] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState({ activePage: 1, searchInput: "" });
  const [searchInput, setSearchInput] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filterButtonIsDisabled, setFilterButtonIsDisabled] = useState(true)
  const [counterSearched, setCounterSearched] = useState(-1);
  const [showTabActions, setShowTabActions] = useState(false);
  const [isCopied, setIsCopied] = useState(false)

  const modelTypesOptions = {
    legacy: "Legacy",
    adoption: "Part Limited",
    limited: "Full Limited",
  };

  const modelOptions = {
    trial: "Trial",
    purchased: "Licensed",
  };

  const HeaderOptions = () => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        {me?.permissions?.includes("companies:add_company") && (
          <Button icon onClick={() => setOpenModal(true)}>
            <Icon name="add" />
          </Button>
        )}
      </div>
    );
  };

  const searchBtn_Click = () => {
    if(filterButtonIsDisabled){
      alert("not enough params to search");
      return
    }
    setIsLoading(true);
    setSearch({ activePage: 1, searchInput });
  };

  const pageChange = (e, { activePage }) => {
    setSearch({ ...search, activePage });
  };

  const apiCall = async () => {
    if (companies.length < 1) {
      setIsLoading(true);
    }
    //setCompanies([]);
    try {
      const { data } = await sysAPI.get(`/companies-v2`, {
        params: {
          ...search,
          pageSize,
        },
      });
      //console.log({data});
      setCompanies(data?.data?.data || []);
      setTotalPages(data.data.metadata.pages);
    } catch (error) {
      console.log({ error });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!openModal && counterSearched > -1) {
      apiCall();
    }
    setCounterSearched(counterSearched + 1);
    //apiCall();
  }, [search, openModal]);

  /* useEffect(() => {
    if (!openModal) {
      apiCall();
    }
  }, [openModal]); */

  useEffect(() => {
    if(searchInput.length <= 1){
      setFilterButtonIsDisabled(true)
    }else{
      setFilterButtonIsDisabled(false)
    }
  },[searchInput])

  useEffect(() => {
    const hasPermissionsToShowActions = me?.permissions?.filter(p => ["companies:copy_id"].includes(p)) || []
    if(hasPermissionsToShowActions.length > 0){
      setShowTabActions(true)
    }
  },[me])

  return (
    <>
      <DashboardTemplate scope="companies" permission="companies:company_view">
        <TabContainer title="Companies" subtitle="CRUD of Companies" icon="building" headerOptions={<HeaderOptions />}>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              gap: "12px",
            }}
          >
            <div>
              <Input
                value={searchInput}
                onChange={(e) => setSearchInput(e.currentTarget.value)}
                icon="search"
                iconPosition="left"
                onKeyPress={(e) => (e.charCode === 13 || e.keyCode === 13 || e.which === 13) && searchBtn_Click()}
              />
            </div>
            <div>
              <Button disabled={filterButtonIsDisabled} onClick={searchBtn_Click}>Filter</Button>
            </div>
          </div>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Type</Table.HeaderCell>
                <Table.HeaderCell>Upload Model</Table.HeaderCell>
                <Table.HeaderCell>POC</Table.HeaderCell>
                <Table.HeaderCell>Main Contact</Table.HeaderCell>
                <Table.HeaderCell>Super Admin</Table.HeaderCell>
                <Table.HeaderCell>Renewal Date</Table.HeaderCell>
                {showTabActions && <>
                  <Table.HeaderCell>Actions</Table.HeaderCell>
                </>}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {isLoading ? (
                <>
                  <Table.Row>
                    <Table.Cell colspan={12}>
                      <Segment style={{ height: "300px" }}>
                        <Dimmer active inverted>
                          <Loader>Loading</Loader>
                        </Dimmer>
                      </Segment>
                    </Table.Cell>
                  </Table.Row>
                </>
              ) : (
                <>
                  {companies.map((company, index) => (
                    <Table.Row key={index}>
                      <Table.Cell>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            history.push(`/dashboard/companies/${company._id}`);
                          }}
                        >
                          {company.name}
                        </span>
                      </Table.Cell>
                      <Table.Cell>{modelOptions[company?.modelType] || "Purchased"}</Table.Cell>
                      <Table.Cell>{modelTypesOptions[company?.uploadModel.type] || "Legacy"}</Table.Cell>
                      <Table.Cell>{company?.poc?.fullName}</Table.Cell>
                      <Table.Cell>{company?.mainContact?.name}</Table.Cell>
                      {
                        company?.superAdmin ? <>
                          <Table.Cell>
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                history.push(`/dashboard/advisors/${company?.superAdmin?._id}`);
                              }}
                            >
                              {company?.superAdmin?.fullName}
                            </span>
                          </Table.Cell>
                        </> : <>
                          <Table.Cell>
                            <Message negative size="mini" style={{margin: "0px 0px"}}>
                              <MessageHeader>No Super Admin</MessageHeader>
                            </Message>
                          </Table.Cell>
                        </>
                      }
                      <Table.Cell onClick={() => {}}>{moment(company?.renewalDate).format("MM/DD/YYYY")}</Table.Cell>
                      {showTabActions && <>
                        <Table.Cell>
                          {me?.permissions?.includes("companies:copy_id") && <>
                            <Popup
                              on='hover'
                              trigger={
                                <Icon name='barcode' style={{cursor:"pointer"}} size="large" onClick={() => {
                                  copyToClipboard(`${company._id}`, false)
                                  setIsCopied(true)
                                  setTimeout(() => {
                                    setIsCopied(false)
                                  }, 1000)
                                }} />
                              }
                              content={isCopied ? 'Copied': 'Click to copy ID'}
                              inverted
                            />
                          </>}
                          </Table.Cell>
                      </>}
                    </Table.Row>
                  ))}
                </>
              )}
            </Table.Body>
          </Table>
          {!isLoading && companies.length < 1 && <MessageTablesEmpty counterSearched={counterSearched} />}
          {totalPages > 1 && (
            <Container fluid textAlign="right">
              <Pagination totalPages={totalPages} activePage={search.activePage} onPageChange={pageChange} />
            </Container>
          )}
        </TabContainer>
      </DashboardTemplate>

      <AddCompanyModal modalState={openModal} setModalState={setOpenModal} />
    </>
  );
};

export default DashboardCompanies;
