import { useState } from "react"
import { Button, Checkbox, Icon, Modal } from 'semantic-ui-react';
import { responseError } from "../../../helpers";
import { sysAPI } from "../../../apis/SysAPI";

const ButtonRemoveDocumentCategorization = ({id, data, refreshFunction, area, clientId, assetIndex}) => {
    const [openModal, setOpenModal] = useState(false)
    const [loader, setLoader] = useState(false);
    const [confirmation, setConfirmation] = useState(false);

    const handleRemove = async () => {
        setLoader(true)
        try {
            const {data} = await sysAPI.delete(`/documents-categorization/${area}/${clientId}`, {
                params:{
                    index: assetIndex,
                    documentId: id
                }
            });

            alert(data.message || "Docuemnt deleted")
            refreshFunction();
            setOpenModal(false)
        } catch (error) {
            responseError(error, undefined, "ButtonRemoveDocumentCategorization.handleRemove")
        }
        setLoader(false)
    }


    return <>
        <Button icon size="mini" onClick={() => setOpenModal(!openModal)}>
            <Icon name="remove"  />
        </Button>

        <Modal open={openModal} size="mini">
            <Modal.Header>Remove Document</Modal.Header>
            <Modal.Content>
                <div style={{display:"grid", gap: "10px"}}>
                    This action can not be undone.
                    <Checkbox label="Are you sure delete it?" checked={confirmation} onClick={(e, {checked}) => setConfirmation(checked)} />
                </div>
            </Modal.Content>

            <Modal.Actions>
                <Button onClick={() => setOpenModal(false)}>Cancel</Button>
                <Button onClick={handleRemove} color="red" loading={loader} disabled={!confirmation}>
                    Delete
                </Button>
            </Modal.Actions>
        </Modal>
    </>
}
export default ButtonRemoveDocumentCategorization