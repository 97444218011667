import moment from 'moment';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, /* Container, */ Grid, Header, Icon, Input, Modal, Segment, Table } from 'semantic-ui-react';
import { paymentAPI } from '../../apis/PaymentAPI';

export const CreateInvoiceModal = ({ show, setShow, fetchInvoices }) => {
    const [items, setItems] = useState([{ description: '', amount: '0', quantity: '1' }]);
    const [file, setFile] = useState(null);
    const [isCreating, setIsCreating] = useState(false);
    const [title, setTitle] = useState('');
    const [startShowingDate, setStartShowingDate] = useState(moment().format('YYYY-MM-DD'));
    // eslint-disable-next-line
    const [expirationDate, setExpirationDate] = useState(moment().add(30, 'days').format('YYYY-MM-DD'));

    const selectFileInput = useRef();

    const advisorProfile = useSelector(state => state.currentAdvisorProfile);

    const addItemBtn_Click = () => {
        setItems([...items, { description: '', amount: '0', quantity: '1' }]);
    }

    const updateItem = (prop, item) => {
        return (e) => {
            const auxItems = [...items];
            const index = auxItems.indexOf(item);
            item[prop] = e.currentTarget.value;
            auxItems.splice(index, 1, item);
            setItems(auxItems);
        }
    }

    const deleteItemBtn_Click = (item) => {
        return () => {
            const auxItems = [...items];
            const index = auxItems.indexOf(item);
            auxItems.splice(index, 1);
            setItems(auxItems);
        }
    }

    const createBtn_Click = async () => {
        setIsCreating(true);
        
        try {
            const response = await paymentAPI.post('/invoice', {
                advisorId: advisorProfile._id,
                items,
                title,
                startShowingDate,
                expirationDate,
            });

            // Upload file
            if (file) {
                const formData = new FormData();
                formData.append( 
                    'file', 
                    file, 
                    file.name,
                );

                // Send file
                await paymentAPI.post(`/invoice/${response.data.invoice._id}/file`, formData);
            }

            setShow(false);
            fetchInvoices();
            setItems([{ description: '', amount: '0', quantity: '1' }]);
            setFile(null);
        }
        catch (err) {
            console.error(err);
            alert(`Couldn't create invoice. Please try again later.`);
        }

        setIsCreating(false);
    }

    const fileInput_Change = (e) => {
        setFile(e.target.files[0]);
    };

    return (
        <Modal open={show} onClose={() => setShow(false)} size='tiny'>
            <Modal.Header>Create Invoice</Modal.Header>
            <Modal.Content>
                <Input style={{ margin: '0 0 10px 0' }} fluid value={title} onChange={e => setTitle(e.currentTarget.value)} placeholder='Invoice Title' />
                <Grid columns={2}>
                    <Grid.Column>
                        <p>Bill to <b>{advisorProfile?.fullName}</b></p>
                    </Grid.Column>
                    <Grid.Column>
                        <p>Created on <b>{moment().format('MM/DD/YYYY')}</b></p>
                    </Grid.Column>
                </Grid>
                <Header style={{ marginTop: '10px' }}>Items</Header>
                <Table fixed>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width='10'>Description</Table.HeaderCell>
                            <Table.HeaderCell width='3'>#</Table.HeaderCell>
                            <Table.HeaderCell width='3'>Amount ($)</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {items.map(item => <Table.Row>
                            <Table.Cell style={{ display: 'flex', alignItems: 'center' }}>
                                <Icon name='delete' onClick={deleteItemBtn_Click(item)} />
                                <Input style={{ width: '100%', marginLeft: '5px' }} value={item.description} onChange={updateItem('description', item)} />
                            </Table.Cell>
                            <Table.Cell><Input fluid value={item.quantity} onChange={updateItem('quantity', item)} /></Table.Cell>
                            <Table.Cell><Input fluid value={item.amount} onChange={updateItem('amount', item)} /></Table.Cell>
                        </Table.Row>)}
                    </Table.Body>
                </Table>
                <Button onClick={addItemBtn_Click}>Add item</Button>
                <Segment style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div>{file ? file.name : '<No file selected>'}</div>
                    <Button onClick={() => selectFileInput.current.inputRef.current.click()}>Select invoice PDF</Button>
                    <Input ref={selectFileInput} type='file' onChange={fileInput_Change} style={{ display: 'none' }} />
                </Segment>
                <Grid fluid columns={1}>
                    {/* <Grid.Column>
                        <div style={{ marginBottom: '5px' }}>Expiration Date</div>
                        <Input fluid type='date' value={expirationDate} onChange={e => setExpirationDate(e.currentTarget.value)} />
                    </Grid.Column> */}
                    <Grid.Column>
                        <div style={{ marginBottom: '5px' }}>Start Showing Date</div>
                        <Input fluid type='date' value={startShowingDate} onChange={e => setStartShowingDate(e.currentTarget.value)} />
                    </Grid.Column>
                </Grid>
                <h3>Total: ${items?.reduce((total, item) => total + +item.quantity * +item.amount, 0)}</h3>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => setShow(false)}>Cancel</Button>
                <Button onClick={createBtn_Click} disabled={isCreating}>Create</Button>
            </Modal.Actions>
        </Modal>
    )
}