import { useState } from "react";
import { Button, Icon, Modal } from "semantic-ui-react";
import FormAdmin from "./Form";
import { sysAPI } from "../../apis/SysAPI";
import { useSelector } from "react-redux";

const UpdateAdmin = ({refreshFunction, dataInfo, sysRoles}) => {
  const me = useSelector((state) => state.me);
  const [openModal, setOpenModal] = useState(false)
  const [info, setInfo] = useState({
    firstName: dataInfo?.firstName || "",
    lastName: dataInfo?.lastName || "",
    email: dataInfo?.email || "",
    roles_ids: dataInfo?.roles_ids || [],
  })

  const handleUpdate = async () => {
    try {
      await sysAPI.put(`/users/${dataInfo._id}`,{...info})
      refreshFunction()
      setOpenModal(false)
    } catch (error) {
      console.log({error});
      alert(error.message)
    }
  }

  if (!me.permissions.includes("admin:admin_edit")) {
    return <div></div>;
  }

  return <div>
    <Button icon onClick={() => setOpenModal(!openModal)}>
      <Icon name="pencil"  />
    </Button>

    <Modal size='tiny' open={openModal} onClose={() => setOpenModal(false)} onOpen={() => setOpenModal(true)}>
        <Modal.Header>
          Update admin user {dataInfo.firstName} {dataInfo.lastName}
        </Modal.Header>
        <Modal.Content>
          <div>
            <FormAdmin info={info} setInfo={setInfo} sysRoles={sysRoles} isEmailDisabled={true} isFirstNameDisabled={true} isLastNameDisabled={true} />
          </div>

        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpenModal(false)}>Cancel</Button>
          <Button onClick={handleUpdate} color="green">Update</Button>
        </Modal.Actions>
      </Modal>

  </div>;
};

export default UpdateAdmin;
