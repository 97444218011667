import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Header, Label, Modal, Table } from "semantic-ui-react";
import { fetchCurrentAdvisorInvoices } from "../../actions/invoice.actions";
import { paymentAPI } from "../../apis/PaymentAPI";

const Payments = ({ payments, hideEvent }) => {
    const dispatch = useDispatch();
    const advisor = useSelector(state => state.currentAdvisorProfile);

    const setPaidBtn_Click = (paymentId) => {
        return () => {
            paymentAPI.post(`/invoice-payment/${paymentId}/set-paid`).then(response => {
                window.alert('The payment has been set as a successful payment.');
                dispatch(fetchCurrentAdvisorInvoices(advisor._id));
                hideEvent();
            });
        }
    };

    return (<>
        <Header>Payments</Header>
        <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Due Date</Table.HeaderCell>
                    <Table.HeaderCell>Amount</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {payments?.map(payment =>
                    <Table.Row>
                        <Table.Cell>{payment.scheduledDate}</Table.Cell>
                        <Table.Cell>${payment.amount}</Table.Cell>
                        <Table.Cell>
                            {payment.status === 'scheduled' && <Label color="orange">Scheduled</Label>}
                            {payment.status === 'succeeded' && <Label color="green">Paid</Label>}
                            {payment.status === 'pending' && <Label color="red">Pending</Label>}
                        </Table.Cell>
                        <Table.Cell style={{ textAlign: 'right' }}>
                            {(['scheduled', 'pending'].includes(payment.status)) &&
                                <Button onClick={setPaidBtn_Click(payment._id)}>Set as paid</Button>
                            }
                        </Table.Cell>
                    </Table.Row>
                )}
            </Table.Body>
        </Table>
    </>);
};

const Overview = ({ invoice }) => {
    return (<>
        <Header>Overview</Header>
        <Card.Group itemsPerRow={4}>
            <Card>
                <Card.Content>
                    <Card.Header content='Creation Date' />
                    <Card.Description>{invoice?.creationDate}</Card.Description>
                </Card.Content>
            </Card>
            <Card>
                <Card.Content>
                    <Card.Header content='Payment Plan' />
                    <Card.Description>{invoice?.paymentPlan}</Card.Description>
                </Card.Content>
            </Card>
            <Card>
                <Card.Content>
                    <Card.Header content='Payment Method' />
                    <Card.Description>{invoice?.paymentMethod}</Card.Description>
                </Card.Content>
            </Card>
            <Card>
                <Card.Content>
                    <Card.Header content='Expiration Date' />
                    <Card.Description>{invoice?.expirationDate}</Card.Description>
                </Card.Content>
            </Card>
        </Card.Group>
    </>);
};

const Items = ({ items }) => {
    return (<>
        <Header>Invoice Items</Header>
        <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Quantity (#)</Table.HeaderCell>
                    <Table.HeaderCell>Description</Table.HeaderCell>
                    <Table.HeaderCell>Amount ($)</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {items?.map(item =>
                    <Table.Row>
                        <Table.Cell>{item.quantity}</Table.Cell>
                        <Table.Cell>{item.description.includes('"product":"') 
                            ? `${JSON.parse(item.description).product} - ${JSON.parse(item.description).date}` 
                            : item.description} 
                        </Table.Cell>
                        <Table.Cell>${item.amount}</Table.Cell>
                    </Table.Row>
                )}
            </Table.Body>
        </Table>
    </>);
}

export const InvoiceModal = ({ invoice, show, hideEvent }) => {
    return (
        <Modal open={show} onClose={hideEvent} size='large'>
            <Modal.Header>
                {invoice?.title}
            </Modal.Header>
            <Modal.Content>
                <Overview invoice={invoice} />
                <Items items={invoice?.items} />
                <Payments payments={invoice?.payments} hideEvent={hideEvent} />
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={hideEvent}>Close</Button>
            </Modal.Actions>
        </Modal>
    )
}