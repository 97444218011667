import { useState } from "react";
import { Button, Icon, Input, Message, Modal } from "semantic-ui-react";
import { sysAPI } from "../../apis/SysAPI";
import { useSelector } from "react-redux";

const RemoveSaml = ({ refreshFunction, dataInfo }) => {
  const me = useSelector((state) => state.me);
  const [openModal, setOpenModal] = useState(false);
  const [validate, setValidate] = useState("");

  const handleRemove = async () => {
    try {
      await sysAPI.delete(`/configs-saml/${dataInfo._id}`);
      refreshFunction();
      setOpenModal(false)
    } catch (error) {
      console.log({error})
      alert(error.message)
    }
  }

  return (
    <>
      {me?.permissions?.includes("saml:saml_delete") && (
        <>
          <Button icon onClick={() => setOpenModal(true)}>
            <Icon name="remove" />
          </Button>

          <Modal size='tiny' open={openModal} onClose={() => setOpenModal(false)} onOpen={() => setOpenModal(true)}>
            <Modal.Header>
              Remove Integration SAML {dataInfo.name}
            </Modal.Header>
            <Modal.Content>
              <div>
                <p>For remove this integrations, just type "{dataInfo.name}" tu unlock the button Delete</p>
                <Input
                  fluid
                  value={validate}
                  onChange={(e) => setValidate(e.target.value)}
                  placeholder={`Type: ${dataInfo.name}`}
                />
                <div style={{marginTop: "10px"}}>
                  <Message color="red">
                    <h2>
                      ⚠️ Warning ⚠️
                    </h2>
                    <p>
                      Remember before of delete this integration
                      <ul>
                        <li>
                          Integrations configurated, they will stop working
                        </li>
                        <li>
                          The data will be lost forever
                        </li>
                        <li>
                          This action can not be undone
                        </li>
                      </ul>
                    </p>
                  </Message>
                </div>
              </div>

            </Modal.Content>
            <Modal.Actions>
              <Button onClick={() => setOpenModal(false)}>Cancel</Button>
              <Button onClick={handleRemove} color="red" disabled={validate !== dataInfo.name} >Delete</Button>
            </Modal.Actions>
          </Modal>
        </>
      )}
    </>
  );
};

export default RemoveSaml;
