/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Modal,
    Input,
    Message,
    Segment,
    Button,
    Form,
    Container,
    Select,
    Dimmer,
    Loader,
    MessageHeader,
    Dropdown
} from 'semantic-ui-react';
import { fpalphaAPI } from '../../apis/FPAlphaAPI';
import { sysAPI } from '../../apis/SysAPI';
import { addAdvisorBuilder } from '../../builders/addAdvisorBuilder';
import { useDispatch, useSelector } from "react-redux";
import { fetchCurrentCompany } from '../../actions/company.actions';
import { advisorAPI } from '../../apis/AdvisorsAPI';
import { setSnackbar } from '../../actions/snackbar.actions';

/* const AREAS = {
    'no_area': 'Select an area to add...',
    'estate': 'Estate Planning',
    'auto': 'Auto',
    'crossborder': 'Cross Border',
    'disability': 'Disability',
    'home': 'Home',
    'medical': 'Medical',
    'identity': 'Identity',
    'student': 'Student Loan',
    'expense': 'Expense',
    'fulltax': 'Tax Planning',
    'ltc': 'LTC',
    'mortgage': 'Mortgage',
    'life': 'Life Planning',
    'divorce': 'Divorce Planning',
    'property': 'Property',
    'creditor': 'Creditor',
    'elder': 'Elder care',
};

const FEATURES = {
    'no_disabled_feature': 'Select a feature to disable...',
    'prospect': 'Prospect',
    'clientPortal': 'Client Portal',
}; */

export const AddAdvisorModal = ({ modalState, setModalState, preselectedCompany, companyType }) => {
    const dispatch = useDispatch();
    const [type, setType] = useState('trial');
    // eslint-disable-next-line
    const defaultAreas = ['fulltax', 'estate', 'home', 'auto']
    //const [licenseDuration, setLicenseDuration] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const [enabledAreas, setEnabledAreas] = useState(defaultAreas);
    //const [selectedAreaIdToAdd, setSelectedAreaIdToAdd] = useState('no_area');
    const [companies, setCompanies] = useState([]);
    //const [pocs, setPocs] = useState([]);
    const [roles, setRoles] = useState([]);

    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [companyId, setCompanyId] = useState('');
    //const [poc, setPoc] = useState('');
    // eslint-disable-next-line
    const [role, setRole] = useState('');
    //const [showPocInput, setShowPocInput] = useState(false);
    const [disabledFeatures, setDisabledFeatures] = useState([]);
    //const [disabledFeatureIdToAdd, setDisabledFeatureIdToAdd] = useState('no_disabled_feature');
    const companyProfile = useSelector((state) => state.currentCompany);

    useEffect(() => {
        setType("trial")
        setFullName("");
        setEmail("");
        setPhone("");
        //setCompanyId("");
        setRole("");
        //setPoc("");
        setDisabledFeatures([]);
        //setDisabledFeatureIdToAdd("no_disabled_feature");
        setEnabledAreas(['fulltax', 'estate', 'home', 'auto'])
    },[modalState])

    useEffect(() => {
        getInitialData();
    }, []);

    const getInitialData = async () => {
        try {
            const {data:companies} = await sysAPI.get("/companies");
            setCompanies(companies.companies);

            /* const {data:pocs} = await sysAPI.get("/companies/pocs");
            setPocs(pocs); */

            const {data:roles} = await advisorAPI.get("/enterprise-settings/role");
            setRoles(roles?.data?.map(e => {
                return {
                    key: e.key,
                    value: e._id,
                    text: e.name,
                }
            }));

        } catch (error) {
            console.log({error})
        }
    }

   /*  const removeEnabledArea_Click = (areaId) => {
        return () => {
            const tempEnabledAreas = [...enabledAreas];
            tempEnabledAreas.splice(tempEnabledAreas.indexOf(areaId), 1);
            setEnabledAreas(tempEnabledAreas);
        }
    } */

    /* const removeDisabledFeature_Click = (featureId) => {
        return () => {
            const tempDisabledFeatures = [...disabledFeatures];
            tempDisabledFeatures.splice(tempDisabledFeatures.indexOf(featureId), 1);
            setDisabledFeatures(tempDisabledFeatures);
        }
    } */

    /* const addEnabledArea_Click = () => {
        if (selectedAreaIdToAdd === 'no_area' || enabledAreas.indexOf(selectedAreaIdToAdd) >= 0) return;

        const tempEnabledAreas = [...enabledAreas];
        tempEnabledAreas.push(selectedAreaIdToAdd);
        setEnabledAreas(tempEnabledAreas);
    } */

    /* const selectUnSelectAllAreas = () => {
        if(enabledAreas.length > 0){
            setEnabledAreas([]);
        }else{
            const areasAux = []
            for(const opt of areasSelectOptions){
                if(!["no_area"].includes(opt.value)){
                    areasAux.push(opt.value)
                }
            }
            setEnabledAreas(areasAux);
        }
    } */

    /* const addDisabledFeature_Click = () => {
        if (disabledFeatureIdToAdd === 'no_area' || disabledFeatures.indexOf(disabledFeatureIdToAdd) >= 0) return;

        const tempDisabledFeatures = [...disabledFeatures];
        tempDisabledFeatures.push(disabledFeatureIdToAdd);
        setDisabledFeatures(tempDisabledFeatures);
    } */


    const getCompanyInfo = () => {
        //console.log({companyProfile});
        if(companyProfile?._id){
            console.log("Exist company, updating info...")
            dispatch(fetchCurrentCompany(companyProfile._id));
        }
    }

    const addAdvisor = async () => {
        setShowLoader(true);
        // console.log('role here', role)
        const payload = addAdvisorBuilder(email, fullName, companyId, role, phone, enabledAreas, disabledFeatures, type);
        console.log({payload});
        try {
             console.log('Doing request');
            // eslint-disable-next-line
            const createAdvisorResponse = await fpalphaAPI.post('/advisor/homepage/advisors', payload);
            const message = `${fullName}'s account was created successfully. `
            dispatch(setSnackbar({message}))
            setModalState(false);
            getCompanyInfo();
        }
        catch (error) {
            const message = error?.response?.data?.message || error?.message || "Oops... something is wrong..."
            console.log('Error :c');
            console.error(error);
            dispatch(setSnackbar({message, status: "error"}))
        }
        setShowLoader(false);
    }

    //const areasSelectOptions = Object.entries(AREAS).map(area => ({ key: area[0], value: area[0], text: area[1] }));
    //const disabledFeaturesOptions = Object.entries(FEATURES).map(feature => ({ key: feature[0], value: feature[0], text: feature[1] }));

    useEffect(() => {
        if (modalState && preselectedCompany) {
            setCompanyId(preselectedCompany);
            setType(companyType === 'purchased' ? 'licensed' : 'trial');
        }
    }, [modalState]);

    useEffect(() => {
        if(type === "trial"){
            setEnabledAreas(defaultAreas)
        }
        else{
            setEnabledAreas([])
        }
    },[type])

    return (
        <Modal size='tiny' open={modalState} onClose={() => setModalState(false)} onOpen={() => setModalState(true)}>
            <Modal.Header as='h3'>Create an Advisor Account</Modal.Header>
            <Modal.Content>
                {/* <Segment>
                    <Form>
                        <Form.Group inline>
                            <Form.Field control={Radio} label='Trial' onChange={() => setType('trial')} checked={type === 'trial'} />
                            <Form.Field control={Radio} label='Licensed advisor' onChange={() => setType('licensed')} checked={type === 'licensed'} />
                        </Form.Group>
                        {type === 'trial' && (
                            <Message color='teal'>
                                This advisor will have 10 days of trial version.
                            </Message>
                        )}
                    </Form>
                </Segment> */}
                <Segment>
                    <Form>
                        <Form.Field control={Input} fluid icon='user' iconPosition='left' placeholder='Full name' value={fullName} onChange={e => setFullName(e.currentTarget.value)} />
                        <Form.Field control={Input} fluid icon='at' iconPosition='left' placeholder='Email address' value={email} onChange={e => setEmail(e.currentTarget.value)}  />
                        {/* <Form.Field control={Input} fluid icon='phone' iconPosition='left' placeholder='Phone number' value={phone} onChange={e => setPhone(e.currentTarget.value)}  /> */}
                        <div style={{marginBottom: "10px"}}>
                            Company:
                            <Dropdown disabled fluid placeholder='Search Company' value={companyId} search selection options={companies.map(e => ({text: e.name, value: e._id, key:e._id}))} style={{ flex: '1 0 auto', marginRight: '10px' }} onChange={(e, {value}) => setCompanyId(value)} />
                        </div>
                        <div>
                            Role:
                            <Select labeled fluid options={roles} placeholder='Role' onChange={(e, {value}) => setRole(value)} />
                        </div>
                    </Form>
                    {
                        companyProfile.superAdmin && roles.find(e => e.value === role)?.key === "super_admin" &&
                        <>
                            <Message warning>
                                <MessageHeader>A Super Admin already exists!</MessageHeader>
                                <p>If you assign this advisor to this role, the other Super Admin will become an Admin.</p>
                                <p>{companyProfile.superAdmin.fullName} ({companyProfile.superAdmin.email})</p>
                            </Message>
                        </>
                    }
                </Segment>
                {/* <Segment style={{ display: type === 'licensed' ? 'none' : 'block' }}> */}
                {/* <Segment>
                    <Form>
                        <Form.Group widths='equal' inline>
                            <Form.Field fluid control={Select} options={areasSelectOptions} value={selectedAreaIdToAdd} onChange={(e, elem) => setSelectedAreaIdToAdd(elem.value)} />
                            <Button onClick={addEnabledArea_Click} color='blue'>Add</Button>
                        </Form.Group>
                        <div  style={{display: 'flex', justifyContent:"start", marginBottom: "5px"}}>
                            <Button onClick={selectUnSelectAllAreas} color={enabledAreas.length > 0 ? 'red' : "black"}>{enabledAreas.length > 0 ? 'Unselect' : "Select"} All</Button>
                        </div>
                        <Label.Group size='large' fluid>
                            {enabledAreas.map(areaId => <Label key={areaId}>{AREAS[areaId]}<Icon onClick={removeEnabledArea_Click(areaId)} name='delete' /></Label>)}
                        </Label.Group>
                    </Form>
                </Segment> */}
                {/* <Segment>
                    <Form>
                        <Form.Group widths='equal' inline>
                            <Form.Field fluid control={Select} options={disabledFeaturesOptions} value={disabledFeatureIdToAdd} onChange={(e, elem) => setDisabledFeatureIdToAdd(elem.value)} />
                            <Button onClick={addDisabledFeature_Click} color='blue'>Add</Button>
                        </Form.Group>
                        <Label.Group size='large' fluid>
                            {disabledFeatures.map(feature => <Label key={feature}>{FEATURES[feature]}<Icon onClick={removeDisabledFeature_Click(feature)} name='delete' /></Label>)}
                        </Label.Group>
                    </Form>
                </Segment> */}
                <Container textAlign='right'>
                    <Button color='grey' onClick={() => setModalState(false)}>Cancel</Button>
                    <Button color='green' onClick={() => addAdvisor()}>Create account</Button>
                </Container>
                <Dimmer active={showLoader}>
                    <Loader size='large' indeterminate>Creating advisor account...</Loader>
                </Dimmer>
            </Modal.Content>
        </Modal>
    )
}