/* eslint-disable no-mixed-operators */
import { useEffect, /* useState */ } from "react"
import { DashboardTemplate } from "../templates/DashboardTemplate"
import { TabContainer } from "../components/shared/TabContainer"
//import { paymentAPI } from "../apis/PaymentAPI";
//import { Redirect } from "react-router-dom";
import { Header } from "semantic-ui-react";

export const QuickbooksDisconnectedView = () => {


    useEffect(() => {
        // paymentAPI.post('/quickbooks/callback', {url: window.location.href})
        // .then(response => {
        //     window.location.replace('/dashboard/quickbooks')
        // });
    }, []);



    return (
        <DashboardTemplate scope={'admin'}>
            <TabContainer>
                <Header as='h2'>QuickBooks disconnected</Header>
                <p>Your QuickBooks integration has been disconnected. You will no longer to be able to get the daily invoices.</p>
                <p>If you want to re-connect <a href='/dashboard/quickbooks'>click here</a></p>
            </TabContainer>
        </DashboardTemplate>
    )
}
